import React, {
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
  Children,
} from "react";
import { TPosition, TStyle, getModalPosition } from "../../lib/ui/getModalPosition";

export const useDivPosition = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [divPos, setDivPos] = useState<TPosition | undefined>(undefined);

  useEffect(() => {
    if (divRef.current) {
      const divRect = divRef.current.getBoundingClientRect();
      setDivPos({
        x: divRect.x,
        y: divRect.y,
        width: divRect.width,
        height: divRect.height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current]);

  return { divRef, divPos };
};

export const useChildStyles = ({
  parentPosition,
  divPos,
}: {
  parentPosition?: TPosition;
  divPos?: TPosition;
}) => {
  const [mStyle, setMStyle] = useState<TStyle | undefined>(undefined);

  useEffect(() => {
    if (parentPosition && divPos) {
      const mStyle = getModalPosition({ pPos: parentPosition, mPos: divPos });
      setMStyle(mStyle);
    }
  }, [parentPosition, divPos]);

  return mStyle;
};

/*
wraps a child component in a div with a useRef hook
after component load it gets the outer positions of the child with ref.current.getBoundingClientRect()
it passes the outer positions to the child as parentPosition
if within the child (e.g. SectionTitle) another component (e.g. OpenCloseModal) is wrapped itself in GetPositionWrapper, 
then SectionTitle passes its outer position as parentPosition to GetPositionWrapper around OpenCloseModal
and then within OpenCloseModal, if parent width is < 400, mStyle is available 
which sets the left or right of the modal not at the left or right of the open modal button, 
but at the left or right of the parent component (e.g. SectionTitle)
*/

export const GetPositionWrapper: FC<{
  parentPosition?: TPosition;
  children: ReactNode;
}> = ({ parentPosition, children }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [divPos, setDivPos] = useState(undefined as TPosition | undefined);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      const divRect = div.getBoundingClientRect();
      setDivPos({
        x: divRect.x,
        y: divRect.y,
        width: divRect.width,
        height: divRect.height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef]);

  const childrenWithProps = Children.map(children, (child) => {
    if (parentPosition && divPos) {
      const mStyle = getModalPosition({ pPos: parentPosition, mPos: divPos });
      if (React.isValidElement<{ mStyle?: TStyle }>(child)) {
        return React.cloneElement(child, { mStyle });
      }
    }
    if (divPos) {
      if (
        React.isValidElement<{
          parentPosition?: TPosition;
        }>(child)
      ) {
        return React.cloneElement(child, {
          parentPosition: divPos,
        });
      }
    }
    return child;
  });

  return <div ref={divRef}>{childrenWithProps}</div>;
};
