import { getCssForClassOrHtml } from "../../lib/render/css/getSelectorData";
import { snippetIF } from "../../data/types/snippets";

// TODO: selector connector if added, show it as selected/active
// TODO: Width/Height in code preview is Width/Width, add auto to height

export const getHtmlSelectorCss = (snippet: snippetIF) => {
  const selectors = snippet.data.selectors;
  const properties = snippet.data.properties;
  const stylepack = snippet.data.stylepacks?.find(s => s.id === snippet.data.editStylepackId);
  const allhtmls = selectors?.filter((s) => s.name === "html");
  const htmls = allhtmls?.filter((s) => s.value?.main?.value === "html");
  let htmlSelectorCss = "";
  htmls?.forEach((h) => {
    if (!snippet) return;

    const data = getCssForClassOrHtml({
      selector: h,
      selectors,
      properties,
      stylepack,
      lineBreak: "\n",
      addSpaceAtLineStart: "",
    });
    htmlSelectorCss += data.css;
  });

  if (htmlSelectorCss === "") return "";
  return `html {
${htmlSelectorCss}}`;
};
