import { Button } from "../atoms/button";
import { EFieldType } from "../../lib/items/getIds";

type TAssignId = {
  isSpecialFieldType?: EFieldType;
  isFieldDisabled?: boolean;
  displayName: string;

  assignedIds?: string[];
  fieldValue?: string;
  setValue?: (value: string) => void;
};
export const AssignId = ({
  isSpecialFieldType,
  isFieldDisabled,
  displayName,

  assignedIds,
  fieldValue,
  setValue,
}: TAssignId) => {
  return (
      <div className="btn-row">
        {(!assignedIds || assignedIds.length === 0) && (
          <Button disableBtn={true} btnOnClick={() => console.log("clicked")}>
            {isSpecialFieldType === EFieldType.selectPathId
              ? "No SVG Path with Id"
              : isSpecialFieldType === EFieldType.assignSvgGradientId
                ? "No SVG Gradient with Id"
                : isSpecialFieldType === EFieldType.assignSvgFilterId
                  ? "No SVG Filter with Id"
                  : isSpecialFieldType === EFieldType.selectKeyframeName
                    ? "No @keyframes"
                    : "No Datalist with Id"}
          </Button>
        )}
        {assignedIds &&
          assignedIds.length > 0 &&
          assignedIds.map((id) => {
            return (
              <Button
                key={id}
                activeBtn={id === fieldValue}
                disableBtn={isFieldDisabled}
                btnOnClick={() => {
                  const newValue = id === fieldValue ? "" : id;
                  setValue && setValue(newValue);
                }}
                dontDisableActiveBtn={true}
              >
                {id}
              </Button>
            );
          })}
      </div>
  );
};
