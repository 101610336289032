import { useStateContext } from "../../data/main/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { DisplayHideChildren2 } from "../modules/DisplayOrHideSubs2";
import { SectionTitle } from "../modules/SectionTitle";
import { SelectStylepackButtons } from "./SelectSnippet";
import { CopyStylepack } from "./CopyStylepack";
import { EditItem } from "../forms/EditItem";
import { AddOrRemoveStylepack } from "./AddOrRemoveStylepack";

export const Stylepack = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();

  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const stylepacks = editSnippet?.data.stylepacks;
  const editStylepackId = editSnippet?.data.editStylepackId;
  const editStylepack = stylepacks?.find((s) => s.id === editStylepackId);

  const itemType = itemTypes.STYLEPACK;

  return (
    <DisplayHideChildren2
      titles={["Select", "Edit", "Copy"]}
      disableTitles={editSnippet ? [] : ["Edit"]}
      isInnerTabs={true}
    >
      <div>
        <div className="mb-1r">
          <SectionTitle label="Select, add or remove stylepack" size="large" />
        </div>

        <div className="mb-1r">
          <AddOrRemoveStylepack
            selectedSnippet={editSnippet}
          />
        </div>
        <SelectStylepackButtons />
      </div>

      <EditItem itemType={itemType} editItem={editStylepack} />

      <CopyStylepack />
    </DisplayHideChildren2>
  );
};
