import { metaIF, EMetaFieldTypes } from "../../types/item";
import { pathHelp } from "./utils";

export const SVG_CONSTANTS_PATH: metaIF[] = [
  {
    name: "path",
    level: "block",
    category: ["PATH"],
    allowedChildren: [
      "title",
      "M",
      "L",
      "C",
      "S",
      "Q",
      "T",
      "A",
      "Z",
      "animate",
      "animateMotion",
      "animateTransform",
    ],
    availableFromLevel: 2,
    childrenAsAttributes: {
      attributeName: "d",
      childrenName: "d",
      children: ["M", "L", "C", "S", "Q", "T", "A", "Z"],
    },
    fieldsOrder: ["d"],
    fields: [
      {
        name: "d",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        help:
          "### path  \n" +
          "Draw the outline of basically anything with the help of the following commands: " +
          "M/m = moveto (starting point), L/n = lineto (line), C/c = curveto, S/s = smooth curveto, Q/q = quadratic Bézier curve, " +
          "T/t = smooth quadratic Bézier curveto, A = elliptical Arc and Z = closepath  \n" +
          pathHelp,
      },
    ],
    group: [
      {
        name: "d",
        fields: ["d"],
      },
    ],
    default: {
      d: {
        value: "M 10 10 L 90 10 L 10 50 L 90 50 L 10 90 L 90 90",
      },
    },
  },

  {
    name: "M",
    level: "inline",
    category: ["PATH"],
    allowedParent: ["path"],
    allowedSiblings: ["L", "C", "S", "Q", "T", "A"],
    addToShorthand: "d",
    fieldsOrder: ["main", "x", "y", "dx", "dy"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "M",
          },
          {
            id: "m",
          },
        ],
        onOff: true,
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["M"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["m"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["M"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["m"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
    ],
    group: [
      {
        name: "Moveto",
        fields: ["main"],
        help:
          "### Move to  \n" +
          "Moves the drawing pencil to an absolute (M) or relative (m) new position. m is different from M only when it's not the first element in the path and moves the pencil from the previous point the given amount of pixel to the left or right (dx) and up or down (dy).",
      },
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "dxDy",
        display: ["dx", "dy"],
        fields: ["dx", "dy"],
      },
    ],

    wrapperForGroup: [
      {
        name: "Moveto",
        fields: ["main", "x", "y", "dx", "dy"],
        wrapperFn: (vObj) => {
          if (vObj.main.value === "M") {
            return `M ${vObj.x.value},${vObj.y.value}`;
          }
          return `m ${vObj.dx.value},${vObj.dy.value}`;
        },
      },
    ],

    default: {
      main: { value: "M" },
      x: { value: 10 },
      y: { value: 50 },
      dx: { value: 0 },
      dy: { value: 0 },
    },
  },
  {
    name: "L",
    level: "inline",
    category: ["PATH"],
    allowedParent: ["path"],
    addToShorthand: "d",
    fieldsOrder: ["main", "x", "y", "dx", "dy"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "L",
          },
          {
            id: "l",
          },
        ],
        onOff: true,
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["L"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["l"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["L"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["l"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
    ],

    group: [
      {
        name: "line",
        fields: ["main"],
        help:
          "### L (Line)   \n" +
          "Draw a line from the current position to the given point.  \n" +
          "L 100 50, for example, draws a line to the point x 100 and y 50, and l 100 50 draws a line 100px to the right and 50px down.",
      },
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "dxDy",
        display: ["dx", "dy"],
        fields: ["dx", "dy"],
      },
    ],

    wrapperForGroup: [
      {
        name: "Moveto",
        fields: ["main", "x", "y", "dx", "dy"],
        wrapperFn: (vObj) => {
          if (vObj.main.value === "L") {
            return `L ${vObj.x.value},${vObj.y.value}`;
          }
          return `l ${vObj.dx.value},${vObj.dy.value}`;
        },
      },
    ],

    default: {
      main: { value: "L" },
      x: { value: 50 },
      y: { value: 100 },
      dx: { value: 50 },
      dy: { value: 50 },
    },
  },
  {
    name: "C",
    level: "inline",
    category: ["PATH"],
    allowedParent: ["path"],
    addToShorthand: "d",
    fieldsOrder: [
      "main",
      "x1",
      "y1",
      "dx1",
      "dy1",
      "x2",
      "y2",
      "dx2",
      "dy2",
      "x",
      "y",
      "dx",
      "dy",
    ],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "C",
          },
          {
            id: "c",
          },
        ],
        onOff: true,
      },
      {
        name: "x1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["C"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["c"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["C"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["c"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "x2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["C"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["c"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["C"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["c"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["C"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["c"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["C"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["c"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
    ],

    group: [
      {
        name: "bezier",
        fields: ["main"],
        help:
          "### C c (Cubic Curve)   \n" +
          "The Bezier Curve is build from 2 lines, which are described by 3 points; from the current point to x1 y1 or dx1 dy1; from x2 y2 or dx2 dy2 to x y or dx dy, the end point of the curve. The 2 lines describe the steepness and the height of the curve on its left and right side.  \n" +
          "The differnce between C and c is, that in a C curve all coordinates are absolute, whereas in a c curve they are relative to the point before the curve.  \n\n" +
          '<img src="/helpimgs/curve-c.svg"/>',
      },
      {
        name: "x1y1",
        display: ["x1", "y1"],
        fields: ["x1", "y1"],
      },
      {
        name: "dx1Dy1",
        display: ["dx1", "dy1"],
        fields: ["dx1", "dy1"],
      },
      {
        name: "x2y2",
        display: ["x2", "y2"],
        fields: ["x2", "y2"],
      },
      {
        name: "dx2Dy2",
        display: ["dx2", "dy2"],
        fields: ["dx2", "dy2"],
      },
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "dxDy",
        display: ["dx", "dy"],
        fields: ["dx", "dy"],
      },
    ],

    wrapperForGroup: [
      {
        name: "CubicCurve",
        fields: [
          "main",
          "x1",
          "y1",
          "dx1",
          "dy1",
          "x2",
          "y2",
          "dx2",
          "dy2",
          "x",
          "y",
          "dx",
          "dy",
        ],
        wrapperFn: (vObj) => {
          if (vObj.main.value === "C")
            return `C ${vObj.x1.value} ${vObj.y1.value}, ${vObj.x2.value} ${vObj.y2.value}, ${vObj.x.value} ${vObj.y.value}`;
          return `c ${vObj.dx1.value} ${vObj.dy1.value}, ${vObj.dx2.value} ${vObj.dy2.value}, ${vObj.dx.value} ${vObj.dy.value}`;
        },
      },
    ],

    default: {
      main: { value: "C" },
      x1: { value: 10 },
      y1: { value: 100 },
      dx1: { value: 0 },
      dy1: { value: 50 },
      x2: { value: 40 },
      y2: { value: 75 },
      dx2: { value: 25 },
      dy2: { value: 50 },
      x: { value: 50 },
      y: { value: 50 },
      dx: { value: 50 },
      dy: { value: 0 },
    },
  },
  {
    name: "S",
    level: "inline",
    category: ["PATH"],
    allowedParent: ["path"],
    addToShorthand: "d",
    fieldsOrder: ["main", "x2", "y2", "dx2", "dy2", "x", "y", "dx", "dy"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "S",
          },
          {
            id: "s",
          },
        ],
        onOff: true,
      },
      {
        name: "x2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["S"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["S"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["s"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy2",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["s"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["S"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["S"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["s"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["s"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
    ],

    group: [
      {
        name: "bezier",
        fields: ["main"],
        help:
          "### S s Smooth Curve  \n" +
          "Continues a curve by extending the second line from the previous curve in its full length and creating so the first line shaping the current curve. You enter the starting point of the second line x1 y1 and the end point x y.  \n\n" +
          '<img src="./curve-s.svg"/>',
      },
      {
        name: "x2y2",
        display: ["x2", "y2"],
        fields: ["x2", "y2"],
      },
      {
        name: "dx2Dy2",
        display: ["dx2", "dy2"],
        fields: ["dx2", "dy2"],
      },
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "dxDy",
        display: ["dx", "dy"],
        fields: ["dx", "dy"],
      },
    ],

    wrapperForGroup: [
      {
        name: "SeveralBezier",
        fields: ["main", "x2", "y2", "dx2", "dy2", "x", "y", "dx", "dy"],
        wrapperFn: (vObj) => {
          if (vObj.main.value === "S")
            return `S ${vObj.x2.value} ${vObj.y2.value}, ${vObj.x.value} ${vObj.y.value}`;
          return `s ${vObj.dx2.value} ${vObj.dy2.value}, ${vObj.dx.value} ${vObj.dy.value}`;
        },
      },
    ],

    default: {
      main: { value: "S" },
      x2: { value: 90 },
      y2: { value: -20 },
      dx2: { value: 35 },
      dy2: { value: -70 },
      x: { value: 100 },
      y: { value: 50 },
      dx: { value: 50 },
      dy: { value: 0 },
    },
  },
  {
    name: "Q",
    level: "inline",
    category: ["PATH"],
    allowedParent: ["path"],
    addToShorthand: "d",
    fieldsOrder: ["main", "x1", "y1", "dx1", "dy1", "x", "y", "dx", "dy"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "Q",
          },
          {
            id: "q",
          },
        ],
        onOff: true,
      },
      {
        name: "x1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["Q"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["Q"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["q"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy1",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["q"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["Q"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["q"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["Q"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["q"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
    ],

    group: [
      {
        name: "bezier",
        fields: ["main"],
        help:
          "### Q q Quadratic Curve  \n" +
          "Enter the end point of the first line x1 y1 or dx1 dy1 and the end point of the curve x y or dx dy. The line shaping the right side of the curve starts then with x1 y1 or dx1 dy1 and ends in x y or dx dy.",
      },
      {
        name: "x1y1",
        display: ["x1", "y1"],
        fields: ["x1", "y1"],
      },
      {
        name: "dx1Dy1",
        display: ["dx1", "dy1"],
        fields: ["dx1", "dy1"],
      },
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "dxDy",
        display: ["dx", "dy"],
        fields: ["dx", "dy"],
      },
    ],

    wrapperForGroup: [
      {
        name: "QuadraticCurve",
        fields: ["main", "x1", "y1", "dx1", "dy1", "x", "y", "dx", "dy"],
        wrapperFn: (vObj) => {
          if (vObj.main.value === "Q")
            return `Q ${vObj.x1.value} ${vObj.y1.value}, ${vObj.x.value} ${vObj.y.value}`;
          return `q ${vObj.dx1.value} ${vObj.dy1.value}, ${vObj.dx.value} ${vObj.dy.value}`;
        },
      },
    ],

    default: {
      main: { value: "Q" },
      x1: { value: 25 },
      y1: { value: -25 },
      dx1: { value: 15 },
      dy1: { value: -75 },
      x: { value: 90 },
      y: { value: 50 },
      dx: { value: 80 },
      dy: { value: 0 },
    },
  },
  {
    name: "T",
    level: "inline",
    category: ["PATH"],
    allowedParent: ["path"],
    addToShorthand: "d",
    fieldsOrder: ["main", "x", "y", "dx", "dy"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "T",
          },
          {
            id: "t",
          },
        ],
        onOff: true,
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["T"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["t"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["T"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "main",
          values: ["t"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
    ],

    group: [
      {
        name: "bezier",
        fields: ["main"],
        help:
          "### T t Quadratic Curve  \n" +
          "T t extends a Q q curve by adding another curve section with the same steepness and length of the 2 handles, but bottom up.  \n" +
          "If you have a Q q first, you can chain multiple T t curves afterward.  \n\n" +
          '<img src="./helpimgs/curve-t.svg"/>',
      },
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "dxDy",
        display: ["dx", "dy"],
        fields: ["dx", "dy"],
      },
    ],
    wrapperForGroup: [
      {
        name: "MultipleQuadraticBézier",
        fields: ["main", "x", "y", "dx", "dy"],
        wrapperFn: (vObj) => {
          if (vObj.main.value === "T")
            return `T ${vObj.x.value} ${vObj.y.value}`;
          return `t ${vObj.dx.value} ${vObj.dy.value}`;
        },
      },
    ],

    default: {
      main: { value: "t" },
      x: { value: 200 },
      y: { value: 50 },
      dx: { value: 100 },
      dy: { value: 0 },
    },
  },

  {
    name: "A",
    level: "inline",
    category: ["PATH"],
    allowedParent: ["path"],
    addToShorthand: "d",
    fieldsOrder: [
      "main",
      "rx",
      "ry",
      "xAxisRotation",
      "largeArcFlag",
      "sweepFlag",
      "x",
      "y",
      "dx",
      "dy",
    ],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "A",
          },
          {
            id: "a",
          },
        ],
        onOff: true,
      },
      {
        name: "rx",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "ry",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "xAxisRotation",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "largeArcFlag",
        type: EMetaFieldTypes.select,
        options: [{ id: "0" }, { id: "1" }],
        onOff: true,
      },
      {
        name: "sweepFlag",
        type: EMetaFieldTypes.select,
        options: [{ id: "0" }, { id: "1" }],
        onOff: true,
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["A"],
        },
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["A"],
        },
      },
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["a"],
        },
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["a"],
        },
      },
    ],

    group: [
      {
        name: "arc",
        fields: ["main"],
        help:
          "### A a Arc \n" +
          "The shape of the ellipse, which shapes the arc, is defined by its `rx` and `ry` radius.  \n" +
          "The line from `x` or `dx` to `y` or `dy` is the x-axis of the ellipse. If the x-axis width is smaller than 2 times the x-radius, then you define with the help of the value of `large arc flag` if the bigger or smaller part of the ellipse builds the arc. If the x-axis width is 2 times the x-radius of the ellipse or greater, then the x-axis goes through the center of the ellipse and half of the ellipse is visible.    \n" +
          "With a `sweep flag value` of 0 you display the visible part of the ellipse underneath the x-axis and with a value of 1 above it.  \n" +
          "With a `large arc flag` of 0 the smaller part of the ellipse is visible, with a value of 1 the bigger part.  \n" +
          "`x axis rotation` defines the angle of the y axis on the x axis of the ellipse. A value of 0 translates to a 90 degree angle, a positive value between 0 and 90 leans the ellipse to the left, and a value between 0 and -90 to the right.  \n\n" +
          '<img src="./helpimgs/svg-arc.svg"/>',
      },
      {
        name: "rxRy",
        display: ["rx", "ry"],
        fields: ["rx", "ry"],
      },
      {
        name: "xAxisRotation",
        display: ["x axis rotation"],
        fields: ["xAxisRotation"],
      },
      {
        name: "largeArcFlag",
        display: ["large arc flag"],
        fields: ["largeArcFlag"],
      },
      {
        name: "sweepFlag",
        display: ["sweep flag"],
        fields: ["sweepFlag"],
      },
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
      },
      {
        name: "dxDy",
        display: ["dx", "dy"],
        fields: ["dx", "dy"],
      },
    ],

    wrapperForGroup: [
      {
        name: "ArcG",
        fields: [
          "main",
          "rx",
          "ry",
          "xAxisRotation",
          "largeArcFlag",
          "sweepFlag",
          "x",
          "y",
          "dx",
          "dy",
        ],
        wrapperFn: (vObj) => {
          if (vObj.main.value === "A") {
            return `A ${vObj.rx.value} ${vObj.ry.value} ${vObj.xAxisRotation.value} ${vObj.largeArcFlag.value} ${vObj.sweepFlag.value} ${vObj.x.value} ${vObj.y.value}`;
          }
          return `a ${vObj.rx.value} ${vObj.ry.value} ${vObj.xAxisRotation.value} ${vObj.largeArcFlag.value} ${vObj.sweepFlag.value} ${vObj.dx.value} ${vObj.dy.value}`;
        },
      },
    ],

    default: {
      main: { value: "A" },
      rx: { value: 30 },
      ry: { value: 50 },
      xAxisRotation: { value: 0 },
      largeArcFlag: { value: "0" },
      sweepFlag: { value: "1" },
      x: { value: 150 },
      y: { value: 50 },
      dx: { value: 120 },
      dy: { value: 0 },
    },
  },

  {
    name: "Z",
    level: "inline",
    category: ["PATH"],
    allowedParent: ["path"],
    addToShorthand: "d",

    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "Z",
          },
        ],
        onOff: true,
        help:
          "### Z (end point of path)   \n" +
          "Closes the path by drawing a line between the penultimate and the start point of the path. ",
      },
    ],
    default: {
      main: {
        value: "Z",
      },
    },
  },
];
