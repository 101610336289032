import { FC, useEffect, useState, useRef } from "react";
import { metaFieldIF } from "../../data/types/item";
import { PredictiveSearch } from "./PredictiveSearch/Main2";
import { Tooltip } from "../atoms/tooltip";

type TStringInput = {
  fieldMeta: metaFieldIF;
  fieldValue: string;
  tooltipText?: string;
  isFieldDisabled?: boolean;
  setValue?: (value: string) => void;
};

export const StringInput: FC<TStringInput> = ({
  fieldMeta,
  fieldValue,
  tooltipText,
  isFieldDisabled,
  setValue,
}) => {
  const hasAutocomplete = fieldMeta.autocomplete;
  const [newValue, setNewValue] = useState(fieldValue || "");
  const [autocompleteListOn, setAutocompleteListOn] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNewValue(fieldValue || "");
  }, [fieldValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
          setAutocompleteListOn(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [autocompleteListOn, wrapperRef]);

  return (
    <div ref={wrapperRef}>
      <input
        title="Enter string"
        type="text"
        className="input"
        disabled={isFieldDisabled}
        value={newValue}
        onChange={(e) => {
          setNewValue(e.target.value);
        }}
        onFocus={() => {
          if (hasAutocomplete && !autocompleteListOn) {
            setAutocompleteListOn(true);
          }

          if (autocompleteValue === "") return;
          setNewValue(autocompleteValue);
          setAutocompleteValue("");
        }}
        onBlur={() => {
          if (newValue === fieldValue) return;
          if (setValue) {
            setValue(newValue);
          }
        }}
      />
      {hasAutocomplete && autocompleteListOn && (
        <PredictiveSearch
          autocomplete={hasAutocomplete}
          maxSuggestions={10}
          parentInput={newValue}
          setAutocompleteValue={setAutocompleteValue}
        />
      )}
      {tooltipText && <Tooltip tooltipText={tooltipText} />}
    </div>
  );
};
