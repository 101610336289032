import MainLayout from "../components/MainLayout";
import { SignInUpMain } from "../components/user/signinup/Main";

const SignInUp = () => {
  return (
    <MainLayout title="User" description="Sign up or Sign in">
      <SignInUpMain />
    </MainLayout>
  );
};

export default SignInUp;
