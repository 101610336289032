import { useEffect, useState, FC } from "react";
import {
  itemWithChildrenIF,
  itemIF,
  TSelectorData,
} from "../../data/types/item";
import { useStateContext } from "../../data/main/state";
import { itemTypes } from "../../lib/meta/getMetas";
import {
  getSelectorNameArray,
  getSelectorNameStr,
} from "../../lib/render/getSelectorName";
import { addCssStrForProperties } from "../../lib/render/css/getSelectorData";
import RenderElementAndChildren from "../render/HtmlOrSvgElementAndChildren";
import ReactDOMServer from "react-dom/server";
import { OpenCloseWithPositioning } from "../modules/TailwindModal";
import { TPosition } from "../../lib/ui/getModalPosition";

import "./EditItemCode.css";

const CodePreview: FC<{ itemType: itemTypes; editHtmlId?: number }> = ({
  itemType,
  editHtmlId,
}) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();

  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const htmls = editSnippet?.data?.htmls || [];

  const [htmlString, setHtmlString] = useState(noCodeMessage(itemType));

  useEffect(() => {
    setHtmlString("");

    if (!editSnippet) return;

    const editHtml = htmls.find((h) => h.id === editHtmlId);
    if (!editHtml) return;

    const staticHTML = ReactDOMServer.renderToString(
      <RenderElementAndChildren
        itemType={itemTypes.BLOCK_HTML}
        item={editHtml as itemWithChildrenIF}
        editSnippet={editSnippet}
      />
    );
    setHtmlString(staticHTML);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editHtmlId]);
  return <pre className="item-code-preview">{htmlString}</pre>;
};

const noCodeMessage = (itemType: string) => {
  return itemType === itemTypes.SVGS
    ? "Add SVG elements"
    : itemType === itemTypes.CLASSES_CSS
      ? "Add CSS properties"
      : itemType === itemTypes.SELECTORS
        ? "Add selectors"
        : itemType === itemTypes.BLOCK_HTML
          ? "Add html elements"
          : "ADD AN EVENT";
};

export const EditHtmlCodePreview: FC<{
  itemType: itemTypes;
  editHtmlId?: number;
  parentPosition?: TPosition;
}> = ({ editHtmlId, itemType, parentPosition }) => {
  return (
    <div className="item-code-preview-box form-elements-wrapper">
      <OpenCloseWithPositioning
        component={<CodePreview itemType={itemType} editHtmlId={editHtmlId} />}
        direction="right"
        isModalOpenDefault={true}
        icon="code"
        parentPosition={parentPosition}
      />
    </div>
  );
};

export const SelectorCodePreview: FC<{
  itemType: itemTypes;
  parentPosition?: TPosition;
}> = ({ itemType, parentPosition }) => {
  const {
    state: {
      snippet: { editSnippetId, snippets },
    },
  } = useStateContext();
  const editSnippet = snippets.find((s) => s.id === editSnippetId);

  const selectors = editSnippet?.data?.selectors;
  const editSelectorId = editSnippet?.data?.editSelectorId;
  const properties = editSnippet?.data?.properties || [];
  const editPropertyId = editSnippet?.data?.editPropertyId;

  const editStypepack = editSnippet?.data?.stylepacks?.find(
    (s) => s.id === editSnippet?.data?.editStylepackId
  );

  const [isOn, setIsOn] = useState(true);
  const [codeStr, setCodeStr] = useState(noCodeMessage(itemType));

  useEffect(() => {
    const editSelector = selectors?.find(
      (s: itemIF) => s.id === editSelectorId
    );
    if (!editSelector) return;

    const sData: TSelectorData = {
      selector: editSelector,
      nameStr: "",
    };
    const selectorNamesObjs = [] as { prefix: string; name: string }[];
    getSelectorNameArray({
      selector: editSelector,
      selectorNamesObjs,
      selectors: selectors as itemIF[],
    });
    sData.nameStr = getSelectorNameStr({
      selectorNamesObjs: selectorNamesObjs,
    });

    if (itemType === itemTypes.SELECTORS) {
      if (sData.nameStr !== "" && !isOn) {
        setIsOn(true);
      }
      setCodeStr(sData.nameStr);
    }

    const propsOfClass = properties?.filter(
      (p) => p.classId === editSelectorId
    ) as itemIF[];
    addCssStrForProperties({
      sData,
      properties: propsOfClass,
      selector: editSelector,
      stylepack: editStypepack,
      lineBreak: "\n",
      noCssPrefix: true,
    });

    const selectorAndEditPropertyCss = `${sData.nameStr}\n${sData.css}\n`;
    setCodeStr(selectorAndEditPropertyCss);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectors, editSelectorId, editPropertyId]);

  return (
    <div className="item-code-preview-box form-elements-wrapper">
      <OpenCloseWithPositioning
        component={<pre className="item-code-preview">{codeStr}</pre>}
        direction="right"
        isModalOpenDefault={true}
        icon="code"
        parentPosition={parentPosition}
      />
    </div>
  );
};

export const CssPropertyCodePreview = () => {
  const {
    state: {
      snippet: { editSnippetId, snippets },
    },
  } = useStateContext();

  const itemType = itemTypes.CLASSES_CSS;
  const editSnippet = snippets.find((s) => s.id === editSnippetId);

  const selectors = editSnippet?.data?.selectors;
  const editSelectorId = editSnippet?.data?.editSelectorId;
  const properties = editSnippet?.data?.properties || [];
  const editPropertyId = editSnippet?.data?.editPropertyId;

  const editStypepack = editSnippet?.data?.stylepacks?.find(
    (s) => s.id === editSnippet?.data?.editStylepackId
  );

  const [codeStr, setCodeStr] = useState(noCodeMessage(itemType));

  useEffect(() => {
    const editSelector = selectors?.find(
      (s: itemIF) => s.id === editSelectorId
    );
    if (!editSelector) return;

    const sData: TSelectorData = {
      selector: editSelector,
      nameStr: "",
    };
    const selectorNamesObjs = [] as { prefix: string; name: string }[];
    getSelectorNameArray({
      selector: editSelector,
      selectorNamesObjs,
      selectors: selectors as itemIF[],
    });
    sData.nameStr = getSelectorNameStr({
      selectorNamesObjs: selectorNamesObjs,
    });

    const propsOfClass = properties?.filter(
      (p) => p.classId === editSelectorId
    ) as itemIF[];
    const editPropsOfClass = propsOfClass.find((p) => p.id === editPropertyId);
    addCssStrForProperties({
      sData,
      properties: editPropsOfClass ? [editPropsOfClass] : [],
      selector: editSelector,
      stylepack: editStypepack,
      lineBreak: "\n",
    });

    const selectorAndEditPropertyCss = `${sData.nameStr}\n${sData.css}\n`;
    setCodeStr(selectorAndEditPropertyCss);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectors, editSelectorId, editPropertyId]);

  return (
    <div className="item-code-preview-box form-elements-wrapper">
      <OpenCloseWithPositioning
        component={<pre className="item-code-preview">{codeStr}</pre>}
        direction="right"
        isModalOpenDefault={true}
        icon="code"
      ></OpenCloseWithPositioning>
    </div>
  );
};
