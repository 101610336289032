import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";

export const CSS_PROPERTIES_COLUMN: metaIF[] = [
  {
    name: "column-count",
    category: ["COLUMN"],
    help: "### column-count  \nSee for example [w3schools - CSS Multiple Columns](https://www.w3schools.com/css/css3_multiple_columns.asp)",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        canBeAuto: true,
        canBeInherited: true,
        canBeInitial: true,
      },
    ],
    default: { main: { value: 3 } },
  },
  {
    name: "column-fill",
    category: ["COLUMN"],
    fieldsOrder: ["fill"],
    help: "### column-fill  \nSee for example [w3schools - CSS Multiple Columns](https://www.w3schools.com/css/css3_multiple_columns.asp)",
    fields: [
      {
        name: "fill",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "balance" }],
        canBeAuto: true,
        canBeInherited: true,
        canBeInitial: true,
      },
    ],
    default: { fill: { value: "balance" } },
  },
  {
    name: "column-gap",
    category: ["COLUMN"],
    help: "### column-gap  \nSee for example [w3schools - CSS Multiple Columns](https://www.w3schools.com/css/css3_multiple_columns.asp)",
    fieldsOrder: ["main", "gapLength"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "normal" }, { id: "value" }],
        canBeInherited: true,
        canBeInitial: true,
      },
      {
        name: "gapLength",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "gapG",
        fields: ["main"],
        display: ["gap"],
      },
      {
        name: "lengthG",
        fields: ["gapLength"],
        display: ["value"],
      },
    ],
    default: {
      main: { value: "normal" },
      gapLength: { value: 1, unit: "rem" },
    },
  },
  {
    name: "column-rule",
    category: ["COLUMN"],
    help: "### column-fill  \nSee for example [w3schools - CSS column-rule Property](https://www.w3schools.com/cssref/css3_pr_column-rule.asp)",
    fieldsOrder: ["ruleWidth", "gapLength"],
    fields: [
      {
        name: "ruleWidth",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          { id: "medium" },
          { id: "thin" },
          { id: "thick" },
          { id: "value" },
        ],
        canBeInherited: true,
        canBeInitial: true,
      },
      {
        name: "ruleWidthValue",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "ruleWidth",
          values: ["value"],
        },
      },
      {
        name: "ruleStyle",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "none",
          },
          {
            id: "hidden",
          },
          {
            id: "dotted",
          },
          {
            id: "dashed",
          },
          {
            id: "solid",
          },
          {
            id: "double",
          },
          {
            id: "groove",
          },
          {
            id: "ridge",
          },
          {
            id: "inset",
          },
          {
            id: "outset",
          },
        ],
        canBeInherited: true,
        canBeInitial: true,
      },
      {
        name: "ruleColor",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
      },
    ],
    group: [
      {
        name: "ruleWidthG",
        fields: ["ruleWidth", "ruleWidthValue"],
        display: ["width", "value"],
      },
      {
        name: "ruleStyleG",
        fields: ["ruleStyle"],
        display: ["style"],
      },
      {
        name: "ruleColorG",
        fields: ["ruleColor"],
        display: ["color"],
      },
    ],
    default: {
      ruleWidth: { value: "medium" },
      ruleWidthValue: { value: 3, unit: "px" },
      ruleStyle: { value: "solid" },
      ruleColor: { value: { r: 60, g: 188, b: 195, a: 1 } },
    },
  },
  {
    name: "column-span",
    category: ["COLUMN"],
    help: "### column-span  \nSee for example [w3schools - CSS Multiple Columns](https://www.w3schools.com/css/css3_multiple_columns.asp)",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "none" }, { id: "all" }],
        canBeInherited: true,
        canBeInitial: true,
      },
    ],
    default: { main: { value: "none" } },
  },
  {
    name: "column-width",
    category: ["COLUMN"],
    help: "### column-width  \nSee for example [w3schools - column-width](https://www.w3schools.com/cssref/css3_pr_column-width.asp)",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "px" },
          { id: "rem" },
          { id: "cm" },
          { id: "in" },
          { id: "pt" },
          { id: "em" },
          { id: "vw" },
          { id: "vh" },
        ],
        canBeAuto: true,
        canBeInherited: true,
        canBeInitial: true,
      },
    ],
    default: { main: { value: 33.3333, unit: "%" } },
  },
];
