import { actionIF } from "../../data/types/item";
import { settingsIF } from "../../data/settings/constants";
import { snippetIF, snippetsIF } from "../../data/types/snippets";
import { settingsReducer } from "./settings/reducer";
import { settingsAndSnippetsReducer } from "./settings/settingsAndSnippetsReducer";
import { snippetReducer } from "./snippet/reducer";
import { changeSnippetReducer } from "./snippet/reducerChangeSnippet";
import { itemsReducer } from "./items/reducer";
import { itemTypes } from "../meta/getMetas";

export const getItemKey = (str: string) => {
  return str === itemTypes.STYLEPACK
    ? "stylepacks"
    : str === itemTypes.SVGS
      ? "svgs"
      : str === itemTypes.CLASSES_CSS
        ? "properties"
        : str === itemTypes.SELECTORS || str === itemTypes.CONNECTOR
          ? "selectors"
          : "htmls";
};
export const getEditItemKey = (str: string) => {
  return str === itemTypes.STYLEPACK
    ? "editStylepackId"
    : str === itemTypes.SVGS
      ? "editSvgId"
      : str === itemTypes.CLASSES_CSS
        ? "editPropertyId"
        : str === itemTypes.SELECTORS || str === itemTypes.CONNECTOR
          ? "editSelectorId"
          : "editHtmlId";
};

const combineReducers = ({
  action,
  settings,
  snippet,
}: {
  action: actionIF;
  settings: settingsIF;
  snippet: snippetsIF;
}) => {
  const newSettings = settingsReducer({ settings, action });
  if (newSettings) {
    return {
      settings: newSettings,
      snippet,
    };
  }

  const sasResult = settingsAndSnippetsReducer({
    settings,
    snippet,
    action,
  });
  if (sasResult?.settings && sasResult?.snippet) {
    return sasResult;
  }

  const srNewSnippet = snippetReducer({
    snippet,
    action,
  });
  if (srNewSnippet) {
    return {
      settings,
      snippet: srNewSnippet,
    };
  }

  const csrNewSnippet = changeSnippetReducer({
    snippet,
    action,
  });
  if (csrNewSnippet) {
    return {
      settings,
      snippet: {
        ...csrNewSnippet,
        snippets: csrNewSnippet.snippets.map((s) => {
          if (s.id === csrNewSnippet.editSnippetId) {
            s.updatedAt = new Date().toISOString();
          }
          return s;
        }),
      },
    };
  }

  const editSnippetId = action.editSnippetId
    ? action.editSnippetId
    : snippet.editSnippetId;
  const editSnippet = snippet.snippets.find(
    (t: snippetIF) => t.id === editSnippetId
  )!;

  if (!editSnippet) {
    return {
      settings,
      snippet,
    };
  }

  const itemType = action.itemType;
  if (!itemType) {
    return {
      settings,
      snippet,
    };
  }

  const itemsKey = getItemKey(itemType);
  const editItemIdKey = getEditItemKey(itemType);
  let items = editSnippet.data[itemsKey];
  let editItemId = editSnippet.data[editItemIdKey];

  const irResult = itemsReducer({
    action,
    items: items || [],
    editItemId: editItemId || 0,
  });

  if (irResult?.items && (irResult.editItemId || irResult.editItemId === 0)) {
    const newData = {
      ...editSnippet.data,
      [itemsKey]: irResult.items,
      [editItemIdKey]: irResult.editItemId,
    };
    const newEditSnippet = {
      ...editSnippet,
      data: newData,
    } as snippetIF;

    return {
      settings,
      snippet: {
        ...snippet,
        snippets: snippet.snippets.map((s) => {
          if (s.id === editSnippetId) {
            return { ...newEditSnippet, updatedAt: new Date().toISOString() };
          }
          return s;
        }),
      },
    };
  }
};

export const mainReducer = (
  {
    settings,
    snippet,
  }: {
    settings: settingsIF;
    snippet: snippetsIF;
  },
  action: actionIF
) => {
  const result = combineReducers({ settings, snippet, action });

  // write snippets and settings to local storage
  if (result) {
    window.localStorage.setItem("snippetState", JSON.stringify(result.snippet));
    window.localStorage.setItem(
      "settingsState",
      JSON.stringify(result.settings)
    );
    return result;
  }

  console.log("mainReducer: no result");
  return {
    settings,
    snippet,
  };
};
