import { itemIF } from "../../data/types/item";

const buildSortedTree = (tree: itemIF[], parentId: number): itemIF[] => {
  const branch = tree.filter((item) => item.parent === parentId);
  const sortedBranch = branch.sort((a, b) => a.position - b.position);

  let result = [] as itemIF[];
  sortedBranch.forEach((item, index) => {
    // Create a new object with updated position
    const newItem = { ...item, position: index + 1 };
    result.push(newItem);
    // Recursively sort and add children
    const children = buildSortedTree(tree, item.id);
    result = result.concat(children);
  });

  return result;
};

export const sortTree2 = (tree: itemIF[]): itemIF[] => {
  if (tree.length === 0) return [];

  // Use a non-mutating sort by spreading into a new array
  const sortedTree = [...tree].sort((a, b) => a.position - b.position);

  // Determine if we have a flat structure or hierarchical structure based on presence of 'parent'
  if (tree.every((item) => item.parent === undefined)) {
    let result = [] as itemIF[];
    sortedTree.forEach((item, index) => {
      const newItem = { ...item, position: index + 1 };
      result.push(newItem);
    });
    return result;
  }

  return buildSortedTree(sortedTree, 0);
};

