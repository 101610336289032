import { FC, useEffect, useRef, useState } from "react";
import { metaFieldIF } from "../../data/types/item";
import { Tooltip } from "../atoms/tooltip";

type TEditFieldTextarea = {
  fieldMeta: metaFieldIF;
  displayName?: string;
  fieldValue: string;
  tooltipText?: string;
  isFieldDisabled?: boolean;
  setValue?: (value: string) => void;
};
export const EditFieldTextarea: FC<TEditFieldTextarea> = ({
  fieldMeta,
  displayName,
  fieldValue,
  tooltipText,
  isFieldDisabled,
  setValue,
}) => {
  const [newValue, setNewValue] = useState(fieldValue);

  // for autosize of textarea
  const textRef = useRef<any>();

  useEffect(() => {
    textRef.current.style.height = "auto";
    textRef.current.style.height = `${textRef.current.scrollHeight}px`;

    setNewValue(fieldValue)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  const fieldName = fieldMeta.name;

  return (
      <>
        <textarea
          title={`Enter ${fieldName}`}
          key={fieldName}
          disabled={isFieldDisabled}
          cols={45}
          rows={4}
          value={newValue}
          ref={textRef}
          onChange={(event) => {
            setNewValue(event.target.value);
          }}
          onBlur={() => {
            setValue && setValue(newValue);
          }}
        />
        {tooltipText && <Tooltip tooltipText={tooltipText} />}
      </>
  );
};
