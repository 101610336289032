import { itemIF } from "../../data/types/item";
import { getMeta, itemTypes } from "../../lib/meta/getMetas";

export const isRightBtnDisabled = ({
  itemType,
  items,
  editItem,
}: {
  itemType: string;
  items: itemIF[];
  editItem?: itemIF | undefined;
}) => {
  if (!editItem) {
    return true;
  }

  if (!items || items.length < 2) {
    return true;
  }

  const editItemName = editItem.name ? editItem.name : "";
  const meta = getMeta(itemType, editItemName);
  if (meta?.availableOnlyLevel) {
    return true;
  }

  // without sibling before edit item can't be pushed right
  const siblingsBefore = items.filter(
    (i) => i.parent === editItem.parent && i.position < editItem.position
  );
  if (siblingsBefore.length === 0) {
    return true;
  }

  // disable when sibling before has empty allowed children
  const closestSibling = siblingsBefore[siblingsBefore.length - 1];
  const closestSiblingMeta = getMeta(itemType, closestSibling.name);
  if (closestSiblingMeta?.allowedChildren) {
    if (
      closestSiblingMeta.allowedChildren.includes("inline") &&
      meta?.level === "inline"
    ) {
      return false;
    }
    if (meta && !closestSiblingMeta?.allowedChildren.includes(meta.name)) {
      return true;
    }
  }
  return false;
};

export const isLeftBtnDisabled = ({
  itemType,
  items,
  editItem,
}: {
  itemType: string;
  items: itemIF[];
  editItem: itemIF | undefined;
}) => {
  if (!editItem) {
    return true;
  }

  if (!items || items.length < 2) {
    return true;
  }

  const editItemName = editItem.name ? editItem.name : "";
  const meta = getMeta(itemType, editItemName);
  if (editItem.level === 1) {
    return true;
  }
  if (meta?.availableOnlyLevel) {
    return true;
  }
  // if edit item meta has availableFromLevel and level >= -> disable left
  if (
    meta?.availableFromLevel &&
    editItem.level &&
    meta.availableFromLevel >= editItem.level
  ) {
    return true;
  }
  if (
    itemType === itemTypes.SVGS &&
    editItem.level === 2 &&
    editItem.name !== "svg"
  ) {
    return true;
  }

  // is edit item explicitly allowed as sibling?
  const parent = items.find((i) => i.id === editItem.parent);
  const parentName = parent?.name ? parent.name : "";
  const parentMeta = getMeta(itemType, parentName);
  if (parentMeta?.allowedSiblings) {
    if (parentMeta.allowedSiblings.includes(editItemName)) {
      return false;
    }
    return true;
  }

  if (editItem.level && editItem.level > 2) {
    // is edit item explicitly allowed as child of parent of parent?
    const parentOfParent = items.find((i) => i.id === parent?.parent);
    const parentOfParentName = parentOfParent?.name ? parentOfParent.name : "";
    const parentOfParentMeta = getMeta(itemType, parentOfParentName);
    if (parentOfParentMeta?.allowedSiblings) {
      if (parentOfParentMeta.allowedSiblings.includes(editItemName)) {
        return false;
      }
      return true;
    }
  }

  if (meta?.allowedParent && parent) {
    // if parent is level 1 than it is impossible for edit item to be shifted left and still have an allowed parent
    if (parent.level === 1) {
      return true;
    }
    const parentOfParent = items.find((i) => i.parent === parent.id);
    const parentOfParentName = parentOfParent?.name ? parentOfParent.name : "";
    if (meta.allowedParent.includes(parentOfParentName)) {
      return false;
    }
    return true;
  }

  return false;
};

/*
returns true or false dependent on if the hiarchical item has a sibling before it
*/
export const isUpBtnDisabled = ({
  itemType,
  items,
  editItem,
}: {
  itemType: string;
  items: itemIF[];
  editItem?: itemIF | undefined;
}): boolean => {
  if (!editItem) {
    return true;
  }
  if (!items || items.length < 2) {
    return true;
  }
  // without sibling before edit item can't be pushed right
  const siblings = !items[0].parent ? items : items.filter((i) => i.parent === editItem.parent);
  if (siblings.length === 1) {
    return true;
  }

  const siblingsBefore = siblings.filter((s) => s.position < editItem.position);
  if (siblingsBefore.length === 0) {
    return true;
  }
  const siblingBefore = siblingsBefore[siblingsBefore.length - 1];
  const siblingBeforeMeta = getMeta(
    itemType,
    siblingBefore.name ? siblingBefore.name : ""
  );
  if (siblingBeforeMeta?.conditions?.first) {
    return true;
  }
  return false;
};

export const isDownBtnDisabled = ({
  itemType,
  items,
  editItem,
}: {
  itemType: string;
  items: itemIF[];
  editItem?: itemIF | undefined;
}): boolean => {
  if (!editItem) {
    return true;
  }
  if (!items || items.length < 2) {
    return true;
  }
  const editItemName = editItem.name ? editItem.name : "";
  const meta = getMeta(itemType, editItemName);
  if (meta?.conditions && meta.conditions.first) {
    return true;
  }
  // without sibling before edit item can't be pushed right
  const siblings = items.filter((i) => i.parent === editItem.parent && i.id !== editItem.id);
  if (siblings.length === 0) {
    return true;
  }

  const siblingsAfter = siblings.filter((s) => s.position > editItem.position);
  if (siblingsAfter.length === 0) {
    return true;
  }

  const siblingsBefore = siblings.filter((s) => s.position < editItem.position);

  if (siblingsBefore.length > 0) {
    const siblingBefore = siblingsBefore[siblingsBefore.length - 1];
    const siblingBeforeName = siblingBefore.name ? siblingBefore.name : "";
    const siblingBeforeMeta = getMeta(itemType, siblingBeforeName);
    if (siblingBeforeMeta?.conditions && siblingBeforeMeta.conditions.first) {
      return true;
    }
  }
  return false;
};