const url = process.env.REACT_APP_FRONTEND_API_URL;

export const addUserToGroup = async ({
  userPoolId,
  username,
  groupName,
}: {
  userPoolId: string;
  username: string;
  groupName: string;
}) => {
  try {
    const endpoint = `${url}/addUserToGroup`;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userPoolId,
        username,
        groupName,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to add user to group");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to add user to group");
  }
};
