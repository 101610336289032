import { actionIF, itemIF } from "../../../data/types/item";
import { itemTypes, getMeta } from "../../meta/getMetas";
import {
  ADD_FIELD_GROUP,
  REMOVE_FIELD_GROUP,
  ADD_ITEM,
  COPY_ITEM_AND_PATH,
  IMPORT_CSS_VARIABLE_FOR_EDIT,
  REPLACE_ITEM,
  REPLACE_ITEMS,
  ADD_REMOVE_CONNECTOR,
  ASSIGN_SVG_TO_HTML,
  ASSIGN_CLASS_TO_HTML_OR_SVG,
  UNASSIGN_CLASS_TO_HTML_OR_SVG,
  SET_EDIT_ITEM,
  ITEM_IN_TREE_DELETE,
  ITEM_IN_TREE_DELETE_CSS,
  ITEM_ATTRIBUTE_CHANGE,
  ASSIGN_CSS_VARIABLE,
  ITEM_EVENT_CHANGE,
  REMOVE_HTML_EVENT,
  ADD_EVENT_TO_HTML,
  MOVE_ITEM_DOWN,
  MOVE_ITEM_LEFT,
  MOVE_ITEM_RIGHT,
  MOVE_ITEM_UP,
  MOVE_ITEM_DOWN_CSS,
  MOVE_ITEM_UP_CSS,
  SET_EDIT_CSS_PROPERTY,
  SET_NEW_ITEM_VALUE,
  SET_GOOGLE_FONT_FOR_FONT_FAMILY,
} from "./actions";
import { assignClassToHtmlOrSvg } from "./assignClassToHtmlOrSvg";
import { deleteItem } from "./deleteItem";
import { changeItemValue } from "./changeItemValue";
import { itemEventChange } from "./handleEventChange";
import { mergeItems, setNewId } from "../../items/addItem";
import { addNewItem } from "../../items/addItems";
import {
  extractCssVariables,
  getVariablesWithNameValue,
} from "../../items/css/extractCssVariablesFromString";
import { moveItemsUpDownLeftOrRight } from "./moveItemsUpDownLeftOrRight";
import { moveItemDownCss } from "./moveItemUpOrDown";
import { addFieldGroup, removeFieldGroup } from "./fieldGroup";
import { addCssProperties } from "../../items/addCssProperties";
import { getAncestorIds } from "./getDescendantsAndAncestors";

export const itemsReducer = ({
  action,
  items,
  editItemId,
}: {
  action: actionIF;
  items: itemIF[];
  editItemId: number;
}): { items: itemIF[]; editItemId: number } | undefined => {

  switch (action.type) {
    case IMPORT_CSS_VARIABLE_FOR_EDIT:
      const importStr = action.value;
      const editSelectorId = action.editSelectorId;
      const editPropId = action.editPropId;
      if (!importStr || !editSelectorId) {
        console.warn(
          "IMPORT_CSS_VARIABLE_FOR_EDIT: no importStr or editSelectorId"
        );
        return { items, editItemId };
      }

      const variablesObj = extractCssVariables(importStr);
      const icvNewItems = getVariablesWithNameValue(variablesObj);

      const icvNewItems2 = addCssProperties({
        properties: items,
        selectorId: editSelectorId,
        editPropId,
        newProps: icvNewItems,
      });
      return {
        items: icvNewItems2.properties,
        editItemId: icvNewItems2.editPropId || 0,
      };

    case ADD_FIELD_GROUP:
      const addFieldGroupFieldName = action.fieldName;
      const addFieldGroupItemType = action.itemType;
      const afgEditItemId = action.editItemId ? action.editItemId : editItemId;
      const addFieldGroupEditItem = items.find((i) => i.id === afgEditItemId);
      if (
        !addFieldGroupEditItem ||
        !addFieldGroupFieldName ||
        !addFieldGroupItemType
      ) {
        console.warn("ADD_FIELD_GROUP: no editItem, fieldName or itemType");
        return { items, editItemId };
      }

      const addFieldGroupNewItem = addFieldGroup({
        fieldName: addFieldGroupFieldName,
        editItem: addFieldGroupEditItem,
        itemType: addFieldGroupItemType,
      });
      if (!addFieldGroupNewItem) {
        console.warn("ADD_FIELD_GROUP: no newItem found");
        return { items, editItemId };
      }

      const addFieldGroupNewItems = items.map((i) => {
        if (i.id === editItemId) {
          return addFieldGroupNewItem;
        }
        return i;
      }) as itemIF[];
      return { items: addFieldGroupNewItems, editItemId };

    case REMOVE_FIELD_GROUP:
      const rfFieldName = action.fieldName;
      const rfgEditItemId = action.editItemId ? action.editItemId : editItemId;
      const rfgEditItem = items.find((i) => i.id === rfgEditItemId);

      if (!rfFieldName || !rfgEditItem || !action.fieldGroupId) {
        console.warn(
          "REMOVE_FIELD_GROUP: no fieldName, editItem or fieldGroupId"
        );
        return { items, editItemId };
      }
      const fgId = action.fieldGroupId;
      if (!fgId) {
        console.warn("REMOVE_FIELD_GROUP: no fieldGroupId");
        return { items, editItemId };
      }

      return removeFieldGroup({
        items,
        editItemId: rfgEditItem.id,
        fieldName: rfFieldName,
        fieldGroupId: fgId,
      });

    case COPY_ITEM_AND_PATH:
      const cipEditItem = items.find((i) => i.id === editItemId);
      if (!cipEditItem) {
        console.warn("COPY_ITEM_AND_PATH: no editItem found");
        return { items, editItemId };
      }

      const ancestorIds: number[] = [];
      getAncestorIds({ items, item: cipEditItem, ancestorIds });
      const ancestors = items.filter((i) => ancestorIds.includes(i.id));
      const cIPNewItems = [...ancestors, cipEditItem];
      const cIPNewItemsNewIds = setNewId({ items, newItems: cIPNewItems });
      const level1s = items.filter((i) => i.level === 1);
      const maxPos = Math.max(...level1s.map((i) => i.position), 0);
      const newItemsUPos = cIPNewItemsNewIds.map((i) => {
        if (i.level === 1) {
          return {
            ...i,
            position: maxPos + 1,
          };
        }
        return i;
      });
      const newEditItem = newItemsUPos.find(
        (i) => i.level === cipEditItem.level
      );
      return {
        items: [...items, ...newItemsUPos],
        editItemId: newEditItem?.id || 0,
      };

    case SET_NEW_ITEM_VALUE:
      const itemType = action.itemType;
      const fieldName = action.fieldName;
      const newValue = action.value;
      if (
        !itemType ||
        !fieldName ||
        newValue === undefined ||
        newValue === null
      ) {
        console.warn("SET_NEW_ITEM_VALUE: no itemType, fieldName or newValue");
        return { items, editItemId };
      }

      return changeItemValue({ action, items, editItemId });

    case MOVE_ITEM_DOWN:
      if (!action.itemType) {
        console.warn("moveItemsDown: no itemType");
        return { items, editItemId };
      }
      return moveItemsUpDownLeftOrRight({
        items,
        editItem: items.find((i) => i.id === editItemId)!,
        direction: "down",
        itemType: action.itemType,
        classId: action.classId,
      });

    case MOVE_ITEM_LEFT:
      if (!action.itemType) {
        console.warn("moveItemsLeft: no itemType");
        return { items, editItemId };
      }
      return moveItemsUpDownLeftOrRight({
        items,
        editItem: items.find((i) => i.id === editItemId)!,
        direction: "left",
        itemType: action.itemType,
      });

    case MOVE_ITEM_RIGHT:
      if (!action.itemType) {
        console.warn("MOVE_ITEM_RIGHT: no itemType");
        return { items, editItemId };
      }
      return moveItemsUpDownLeftOrRight({
        items,
        editItem: items.find((i) => i.id === editItemId)!,
        direction: "right",
        itemType: action.itemType,
      });

    case MOVE_ITEM_UP:
      if (!action.itemType) {
        console.warn("moveItemsUp: no itemType");
        return { items, editItemId };
      }
      return moveItemsUpDownLeftOrRight({
        items,
        editItem: items.find((i) => i.id === editItemId)!,
        direction: "up",
        itemType: action.itemType,
        classId: action.classId,
      });

    case MOVE_ITEM_DOWN_CSS:
    case MOVE_ITEM_UP_CSS:
      const midSelectorId = action.selectorId;
      if (!midSelectorId) {
        console.warn("MOVE_ITEM_DOWN_CSS: no selectorId");
        return { items, editItemId };
      }
      const selectorProps = items.filter((i) => i.classId === midSelectorId);
      if (selectorProps.length === 0) {
        console.warn("MOVE_ITEM_DOWN_CSS: no selectorProps found");
        return { items, editItemId };
      }
      const updated = moveItemDownCss({
        items: selectorProps,
        editItemId,
        orUp: action.type === MOVE_ITEM_UP_CSS,
      });
      const merged = mergeItems({ oldItems: items, items: updated });

      return { items: merged, editItemId };

    case ADD_ITEM:
      const aIItemType = action.itemType;
      if (!action.item || !aIItemType) {
        console.warn("ADD_ITEM: no item or itemType");
        return { items, editItemId };
      }

      const addAfterItemId = action.addAfterItemId
        ? action.addAfterItemId
        : editItemId;

      const aiResult = addNewItem({
        itemType: aIItemType,
        newItem: action.item,
        itemsTo: items,
        itemToId: addAfterItemId,
      });

      return {
        items: aiResult.items,
        editItemId: aiResult.editItemId,
      };

    case REPLACE_ITEM:
      const replaceItem = action.item;
      return {
        items: items.map((i) => {
          if (replaceItem && i.id === replaceItem.id) {
            return replaceItem;
          }
          return i;
        }),
        editItemId,
      };

    case REPLACE_ITEMS:
      const replaceItems = action.items;
      return {
        items: replaceItems || items,
        editItemId,
      };

    case ADD_REMOVE_CONNECTOR:
      const itemName = action.name || "";
      const newItems = items.map((i) => {
        if (i.id === editItemId) {
          return {
            ...i,
            prefix:
              i.prefix?.name && i.prefix.name === itemName
                ? undefined
                : {
                    name: itemName,
                  },
          };
        }
        return i;
      });
      return {
        items: newItems,
        editItemId,
      };

    case ASSIGN_SVG_TO_HTML:
      return {
        items: items.map((html) => {
          if (html.id === editItemId) {
            if (action.svgId) {
              return {
                ...html,
                value: {
                  ...html.value,
                  htmlSvgCodeSnippetSvgId: {
                    value: action.svgId,
                  },
                },
              };
            }
          }
          return html;
        }),
        editItemId,
      };

    case ASSIGN_CLASS_TO_HTML_OR_SVG:
    case UNASSIGN_CLASS_TO_HTML_OR_SVG:
      if (!action.classIds || action.classIds.length === 0) {
        console.warn("ASSIGN_CLASS_TO_HTML_OR_SVG: no classIds");
        return { items, editItemId };
      }
      const itemId = action.editItemId ? action.editItemId : editItemId;
      const acNewItems = assignClassToHtmlOrSvg({
        itemId,
        items,
        classIds: action.classIds,
      });

      return {
        items: acNewItems,
        editItemId,
      };

    case SET_EDIT_ITEM:
      const newEditItemId = action.newEditItemId;
      if (!newEditItemId) {
        console.warn("SET_EDIT_ITEM: no newEditItemId");
        return { items, editItemId };
      }
      return {
        items,
        editItemId: newEditItemId,
      };

    case SET_EDIT_CSS_PROPERTY:
      const classPropsInCategory = items.filter((i) => {
        if (i.classId === action.newEditItemId) {
          const meta = getMeta(itemTypes.CLASSES_CSS, i.name);
          if (
            meta?.category.includes(
              action.selectedCategory ? action.selectedCategory : ""
            )
          ) {
            return true;
          }
        }
        return false;
      });
      return {
        items,
        editItemId:
          classPropsInCategory.length > 0 ? classPropsInCategory[0].id : 0,
      };

    case ITEM_IN_TREE_DELETE:
      const iTDItemType = action.itemType;
      if (!iTDItemType) {
        console.warn("ITEM_IN_TREE_DELETE: no itemType");
        return { items, editItemId };
      }
      return deleteItem({
        itemId: action.editItemId ? action.editItemId : editItemId,
        items,
        editItemId,
        itemType: iTDItemType,
      });

    case ITEM_IN_TREE_DELETE_CSS:
      const iDEditItem = items.find((i) => i.id === editItemId);
      if (!iDEditItem) {
        console.warn("ITEM_IN_TREE_DELETE_CSS: no editItem found");
        return { items, editItemId };
      }
      const itemsWithoutItem = items.filter((i) => i.id !== editItemId);
      return { items: itemsWithoutItem, editItemId: 0 };

    case ITEM_ATTRIBUTE_CHANGE:
      return changeItemValue({
        action,
        items,
        editItemId,
      });

    case SET_GOOGLE_FONT_FOR_FONT_FAMILY:
      return {
        items: items.map((i) => {
          if (i.id === editItemId && action.selectedOption) {
            const newI = {
              ...i,
              value: {
                ...i.value,
                google_font: { value: action.selectedOption.id },
                google_font_import: { value: action.selectedOption.import },
                google_font_css: { value: action.selectedOption.css },
              },
            };
            return newI;
          }
          return i;
        }),
        editItemId,
      };

    case ASSIGN_CSS_VARIABLE:
      return {
        items: items.map((property) => {
          if (property.id === editItemId && action.cssVariableName) {
            const assignedCssVariableName =
              property.value?.assignedCssVariableName?.value;
            const newProperty = {
              ...property,
              value: {
                ...property.value,
                assignedCssVariableName: {
                  value:
                    action.cssVariableName !== assignedCssVariableName
                      ? action.cssVariableName
                      : "",
                },
              },
            };
            return newProperty;
          }
          return property;
        }),
        editItemId,
      };

    // TODO: This case isn't being handled
    case ITEM_EVENT_CHANGE:
      return {
        items: itemEventChange({
          action,
          items,
          editItemId,
        }),
        editItemId,
      };

    case REMOVE_HTML_EVENT:
      return {
        items: items.map((i) => {
          const events = i.events as itemIF[];
          if (i.id === editItemId && events) {
            return {
              ...i,
              events: events.filter((e) => e.id !== action.editEventId),
            };
          }
          return i;
        }),
        editItemId,
      };

    case ADD_EVENT_TO_HTML:
      const newEvent = {
        id: 1,
        name: "event",
        position: 1,
        value: {
          type: { value: "onclick" },
          mode: { value: "Add" },
          classId: { value: 0 },
        },
      };
      return {
        items: items.map((i) => {
          if (i.id === editItemId) {
            const events = (i.events || []) as itemIF[];
            newEvent.id =
              events.reduce((id, e) => (e.id < id ? id : e.id), 0) + 1;
            newEvent.position =
              events.reduce(
                (pos, e) => (e.position < pos ? pos : e.position),
                0
              ) + 1;

            return {
              ...i,
              events: [...events, newEvent],
            };
          }
          return i;
        }),
        editItemId,
      };

    default:
  }
};
