import { FC } from "react";
import { useStateContext } from "../../data/main/state";
import { itemIF } from "../../data/types/item";
import { setEditItemId } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { getItemName } from "../../lib/render/getItemName";
import { getMetaWithAllFields } from "../../lib/meta/getMetaWithAllFields";
import { useScrollIntoView } from "../atoms/useScrollIntoView";
import { CssPropertyCodePreview } from "../render/EditItemCode";

export const SimpleSelectorTree: FC<{
  propsOfClass: itemIF[];
  editProp?: itemIF;
}> = ({ propsOfClass, editProp }) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const editSnippet = snippets.find((s) => s.id === editSnippetId);

  const listRef = useScrollIntoView(editProp);

  return (
      <div className="html-tree-container form-elements-wrapper" ref={listRef}>
        {propsOfClass.length === 0 && (
            <button type="button" className="button disabled">
              ADD PROPERTIES
            </button>
        )}
        {propsOfClass.length > 0 &&
          propsOfClass.map((prop) => {
            const meta = getMetaWithAllFields({
              itemType: itemTypes.CLASSES_CSS,
              itemName: prop.name,
            });
            return (
              <div className="html-tree-row" key={prop.id}>
                <button
                  className={`button small ${prop.id === editProp?.id ? "blue" : "blue-outline"}`}
                  onClick={() => {
                    dispatch(
                      setEditItemId({
                        itemType: itemTypes.CLASSES_CSS,
                        newEditItemId: prop.id,
                        editSnippetId: editSnippet?.id || "",
                      })
                    );
                  }}
                >
                  {getItemName({ item: prop, meta: meta })}
                </button>
                {prop.id === editProp?.id && <CssPropertyCodePreview />}
              </div>
            );
          })}
      </div>
  );
};
