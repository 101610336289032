import axios from "axios";

export const fetchUsernames = async (userIds: string[]) => {  
  const apiUrl = `${process.env.REACT_APP_USER_API_URL}/fetch-usernames`;
  const apiKey = process.env.REACT_APP_USER_API_KEY;

  const requestData = {
    userIds,
  };

  try {
    const response = await axios.post(apiUrl, requestData, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey, 
      },
    });

    return response.data;

  } catch (error) {
    console.error("Error fetching usernames:", error);
  }
};