import { FC, useEffect, useState } from "react";
import { useStateContext } from "../../data/main/state";
import { EMetaFieldTypes } from "../../data/types/item";
import { ReactComponent as Download } from "../../assets/imgs/download.svg";
import { importCssVariableForEditSelector } from "../../lib/reducer/items/actions";
import { SectionTitle } from "../modules/SectionTitle";
import { useDivPosition } from "../modules/GetPositionWrapper";
import { EditFieldTextarea } from "../forms/Textarea";
import { ActionMessage } from "../atoms/ActionMessage";

const importPropertiesHelp = `### Import CSS variables

Paste CSS variables into the box, click outside the box to activate the **import** button and then click the **import** button.

Please import with ; at the end of each line. For example:

--fs-xs: 0.8rem;  
--lh-xs: 1rem;  
--fs-s: 0.875rem;  
--lh-s: 1.2rem;  
--fs-base: 1rem;  
--lh-base: 1.375rem;
`;

export const ImportProperties: FC = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const editSelectorId = editSnippet?.data?.editSelectorId;
  const editSelectorProps = editSnippet?.data?.properties?.filter(
    (p) => p.classId === editSelectorId
  );

  const [noProps, setNoProps] = useState(editSelectorProps?.length || 0);
  const [msg, setMsg] = useState("");
  const [importStr, setImportStr] = useState("");

  useEffect(() => {
    const newNoProps = editSelectorProps?.length || 0;
    const added = newNoProps - noProps;
    if (added > 0) {
      setMsg(`${added} Variables imported successfully`);
      setNoProps(newNoProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSnippet?.updatedAt, editSelectorProps?.length]);

  const { divRef, divPos } = useDivPosition();

  return (
    <><div className="mb-1r">
      <SectionTitle
        label="Import CSS variables"
        help={importPropertiesHelp}
        size="large"
        parentPosition={divPos}
      />
      </div>
      <div
        className="flex justify-start form-elements-wrapper mb-1r"
        ref={divRef}
      >
        <button
          type="button"
          className={`button blue icon${!importStr.trim() || !editSelectorId ? " disabled" : ""}`}
          onClick={() => {
            if (!editSelectorId) return;
            if (!importStr.trim()) {
              setMsg("Please paste CSS variables to import");
              return;
            }
            dispatch(
              importCssVariableForEditSelector({
                value: importStr,
                editSelectorId: editSelectorId,
              })
            );
            setImportStr("");
          }}
        >
          <span className="sr-only">Import CSS variables</span>
          <Download />
        </button>
      </div>

      <label className="label">
        <span>Enter CSS variables</span>
        <EditFieldTextarea
          key="variables"
          fieldMeta={{
            name: "variables",
            type: EMetaFieldTypes.textarea,
            onOff: true,
          }}
          displayName="import CSS variables"
          fieldValue={importStr}
          setValue={(value: string) => setImportStr(value)}
        />
      </label>

      <ActionMessage msg={msg} setMsg={setMsg} />
    </>
  );
};
