import { FC, MouseEvent, ReactNode } from "react";

export const Button: FC<{
  btnClassName?: string[];
  activeBtn?: boolean | undefined;
  dontDisableActiveBtn?: boolean;
  disableBtn?: boolean;
  toogleClass?: string;
  btnOnClick: (event: MouseEvent<HTMLButtonElement>) => void;
  position?: number;
  children?: ReactNode;
  id?: number;
}> = ({
  btnClassName,
  activeBtn,
  dontDisableActiveBtn,
  disableBtn = false,
  toogleClass,
  btnOnClick,
  children,
  id
}) => {
  let btnClasses: string = "m-btn";
  if (btnClassName && btnClassName.length > 0) {
    btnClassName.forEach((name) => {
      btnClasses += ` ${name}`;
    });
  }
  if(disableBtn) {
    btnClasses += " disabled";
  }

  if (activeBtn) {
    btnClasses += " active";
  }

  const attr = {
    className: btnClasses,
    disabled: disableBtn,
  };

  return (
    <button data-id={id}
      {...attr}
      aria-label={children ? children.toString() : ""}
      onClick={(e) => {
        if (!activeBtn || dontDisableActiveBtn) {
          btnOnClick(e);
        }
      }}
    >
      {children}
    </button>
  );
};
