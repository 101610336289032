import axios from "axios";
import { Snippet } from "cssexy-be/src/generated/graphql";

const graphqlEndpoint = process.env.REACT_APP_SNIPPETS_API_URL || "";
const apiKey = process.env.REACT_APP_SNIPPETS_API_KEY || "";

const postHeader = {
  headers: {
    "Content-Type": "application/json",
    "x-api-key": apiKey,
  },
};

const GET_SNIPPET_QUERY = `
  query($id: ID!) {
    getSnippet(id: $id) {
      name
      description
      owner
      data
      id
      copyCount
      createdAt
      public
      updatedAt
    }
  }
`;

export const getServerSnippet = async (id: string) => {
  const requestPayload = {
    query: GET_SNIPPET_QUERY,
    variables: { id },
  };

  try {
    const response = await axios.post(
      graphqlEndpoint,
      JSON.stringify(requestPayload),
      postHeader
    );

    const snippet = response?.data?.data?.getSnippet as Snippet;
    if (!snippet) return null;

    return {
      ...snippet,
      data: snippet.data
        ? typeof snippet.data === "string"
          ? JSON.parse(snippet.data)
          : snippet.data
        : {},
    };
  } catch (error) {
    console.error(error);
  }
};
