import { prefix } from "inline-style-prefixer";
import { getSelectorNameArray, getSelectorNameStr } from "../getSelectorName";
import { addCssStrForProperties } from "./getSelectorData";
import { TPosAndSh, TSelectorData } from "../../../data/types/item";
import { TCreateComposedSelectorItem } from "./editComposedSelectorItemNew";

// Convert kebab-case to camelCase
const kebabToCamel = (str: string): string => {
  // in CSS variable names the leading -- should not be converted to camelCase
  if (str.startsWith("--")) {
    return str;
  }
  return str.replace(/-./g, (match) => match[1].toUpperCase());
};
// Convert camelCase to kebab-case
const camelToKebab = (str: string): string => {
  // in variable names there can be capital letters, don't convert them
  if (str.startsWith("--")) {
    return str;
  }
  return str.replace(/[A-Z]/g, (match) => "-" + match.toLowerCase());
};

const prefixCss = ({
  shortHand,
  key,
  noCssPrefix
}: {
  shortHand: { [key: string]: string[] };
  key: string;
  noCssPrefix?: boolean;
}) => {

  const propertyValue = shortHand[key][0];
  const cssKey = kebabToCamel(key);
  const cssObj = { [cssKey]: propertyValue };
  const cssObj2 = JSON.parse(JSON.stringify(cssObj));

  const prefixed2 = noCssPrefix ? cssObj2 : prefix(cssObj2);

  const result = {} as { [key: string]: string | string[] };
  const keys = Object.keys(prefixed2);
  keys.forEach((key) => {
    const cebabKey = camelToKebab(key);
    const value = prefixed2[key];
    result[cebabKey] = value;
  });

  return result;
};

const getCssCodeForShorthandItem = ({
  shortHand,
  key,
  lineBreak,
  addSpaceAtLineStart,
  noCssPrefix
}: {
  shortHand: { [key: string]: string[] };
  key: string;
  lineBreak?: string;
  addSpaceAtLineStart?: string;
  noCssPrefix?: boolean;
}) => {
  let propsStr = "";

  const result = prefixCss({ shortHand, key, noCssPrefix });

  const keys2 = Object.keys(result);
  keys2.forEach((key) => {
    let values = result[key];
    if (!Array.isArray(values)) {
      values = [values];
    }
    values?.forEach((value: string) => {
      propsStr += `${addSpaceAtLineStart}${key}: ${value};${lineBreak}`;
    });
  });

  return propsStr;

};

export const getCssCode = ({
  posAndShs,
  lineBreak = "",
  addSpaceAtLineStart = "   ",
  noCssPrefix = false,
}: {
  posAndShs: TPosAndSh[];
  lineBreak?: string;
  addSpaceAtLineStart?: string;
  noCssPrefix?: boolean;
}) => {
  let propsStr = "";
  posAndShs.forEach((item) => {
    const sh = item.sh;
    Object.entries(sh).forEach(([key, values]) => {
      /* a css property with addMultipleTimes in the meta data can be added several times sorted by position
    now it's possible to have, for example, 2 padding css props one after the other where the css in the second overwrites the first
    this works just for css properties with the same property name, padding, padding-top and padding are rendered as padding, padding, padding-top, there should't be 2 padding after each other, but it is possible to change padding to e.g. padding-left and then it works again
    this behaviour came in with a bug fix which rendered all css values for css properties with the same name in one css string like padding: 0.5rem 1rem 0.5rem 1rem 0.5rem 1rem 0.5rem 1rem -> css error   
    */
      const joinCss = values.filter((sh) => !sh.includes("ADDMULTIPLETIMES"));
      const separateCss = values
        .filter((sh) => sh.includes("ADDMULTIPLETIMES"))
        .map((sh) => sh.replace("ADDMULTIPLETIMES", ""));

      propsStr += getCssCodeForShorthandItem({
        shortHand: { [key]: joinCss },
        key,
        lineBreak,
        addSpaceAtLineStart,
        noCssPrefix
      });

      if (separateCss.length > 0) {
        separateCss.forEach((sc) => {
          propsStr += getCssCodeForShorthandItem({
            shortHand: { [key]: [sc] },
            key,
            lineBreak,
            addSpaceAtLineStart,
            noCssPrefix
          });
        });
      }
    });
  });
  return propsStr;
};

export const getCssForStep = ({
  selector,
  properties,
  lineBreak,
  addSpaceAtLineStart = "",
}: TCreateComposedSelectorItem) => {
  if (!selector || !properties) {
    console.warn("getCssForStep: no selector or properties");
    return {} as TSelectorData;
  }
  const stepProperties = properties.filter((p) => p.classId === selector.id);

  const newStepSData = {
    nameStr: `${selector.value?.step.value}% {`,
    selector,
  };

  addCssStrForProperties({
    sData: newStepSData,
    properties: stepProperties,
    selector,
    lineBreak,
    addSpaceAtLineStart,
  });

  return newStepSData as TSelectorData;
};

export const getCssForMedia = ({
  selector,
  selectors,
  stylepack
}: TCreateComposedSelectorItem) => {
  const selectorNamesObjs = [] as { prefix: string; name: string }[];
  getSelectorNameArray({
    selector: selector!,
    selectorNamesObjs,
    selectors: selectors!,
    stylepack,
  });
  return {
    selector,
    nameStr: getSelectorNameStr({
      selectorNamesObjs: selectorNamesObjs,
    }),
  } as TSelectorData;
};
