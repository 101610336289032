import { itemIF, metaIF } from "../../data/types/item";

export const getItemName = ({
  item,
  meta,
}: {
  item: itemIF;
  meta?: metaIF;
}) => {
  if (!meta) {
    return item.name;
  }

  if (
    meta.useFieldAsPropertyName &&
    item.value &&
    item.value[meta.useFieldAsPropertyName]
  ) {
    return `${item.value[meta.useFieldAsPropertyName].value}`;
  }

  if (meta.name === "variable") {
    return `--${item.value?.name.value}`;
  }

  if (meta.code) {
    return meta.code;
  }
  if (meta.display) {
    return meta.display;
  }

  // property name: has main fields options and do the options have a display value ? display value : meta.name

  const firstField = meta.fields ? meta.fields[0] : undefined;

  if (firstField && firstField.options && firstField.options[0].display) {
    const optionHasDisplayValue = firstField.options?.find(
      (o) => o.display && o.id === item?.value?.[firstField.name]?.value
    );
    if (optionHasDisplayValue && optionHasDisplayValue.display) {
      return optionHasDisplayValue.display;
    }
  }

  return meta.name;
};
