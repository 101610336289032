import { useState, useEffect, FC } from "react";
import { TAutocomplete } from "../../../data/types/item";
import { getFilterSortConvertFns } from "./helpers";
import PREDICTIVE_SEARCH_DATA from "./data";
import { PredictiveSearchItem } from "./types";
import { getPredictiveSearchItem } from "./utils";
import "./PredictiveSearch.css";

/*
is opened onFocus of the parent input
finds all items which contain the input string in code or name
if field has no value, don't show the list
onChange of the parent input value, update the list
on click on a list item (triggers onBlur in the main input), set value of the input (triggers onFocus in the main input)
main input and PredictiveSearch are wrapped in a div with a useRef, when the user clicks outside of the div, this closes the list (autocompleteOn is false)
*/

export const PredictiveSearch: FC<{
  autocomplete: TAutocomplete;
  maxSuggestions: number;
  parentInput: string;
  setAutocompleteValue: (value: string) => void;
}> = ({
  autocomplete,
  maxSuggestions,
  parentInput,
  setAutocompleteValue,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState(
    [] as PredictiveSearchItem[]
  );

  const { filterFn, sortFn } = getFilterSortConvertFns({
    autocomplete,
  });

  const searchableData = PREDICTIVE_SEARCH_DATA[autocomplete!];

  useEffect(() => {
    const relevantResults = searchableData.filter(filterFn(parentInput));
    const sortedResults = relevantResults.sort(sortFn(parentInput));
    const slicedResults = sortedResults.slice(0, maxSuggestions);
    setFilteredSuggestions(slicedResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentInput]);

  return (
    <div className="predictive-search-result">
      <div className="predictive-inner">
        {filteredSuggestions.map((suggestion) => {

          const { item, dislayText } = getPredictiveSearchItem(suggestion);

          return (
            <div
              key={item}
              className="predictive-search-item"
              onClick={() => {
                setAutocompleteValue(item)
              }}
            >
              {dislayText}
            </div>
          );
        })}
      </div>
    </div>
  );
};
