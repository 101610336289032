import { useState, useEffect } from "react";
import { UseNetworkChecker } from "../../helper/NetworkChecker";
import { useStateContext } from "../../../data/main/state";
import { TMsgType, TUserState } from "../../../lib/signinup/helpers";
import { SignIn } from "../SignIn";
import { SignUp } from "../SignUp"
import { ResetPassword } from "../ResetPassword";
import { CreateNewPasswordWithCode } from "../CreateNewPasswordWithCode"
import { VerifyEmailAfterSignUp } from "../VerifyEmailAfterSignup";

import "./SignInUp.css";

export const SignInUpMain = () => {
  const {
    state: {
      settings: { user },
    },
  } = useStateContext();
  const [signType, setSignType] = useState("signin" as TUserState);
  const [signInErrorMessage, setSignInErrorMessage] = useState({} as TMsgType);

  const isOnline = UseNetworkChecker();

  useEffect(() => {
    if (isOnline && signInErrorMessage.errMsg === "You are not online") {
      setSignInErrorMessage({
        ...signInErrorMessage,
        errMsg: "",
      });
    }
    if (!isOnline && signInErrorMessage.errMsg !== "You are not online") {
      setSignInErrorMessage({
        ...signInErrorMessage,
        errMsg: "You are not online",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    if (user?.userConfirmed === false) {
      setSignType("createnewpasswordsubmit");
    }
    if (user?.nextAction === "signin") {
      setSignType("signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (signType === "signup")
    return (
      <SignUp
        setSignType={setSignType}
      />
    );

  if (signType === "forgottenPassword") {
    return (
      <ResetPassword
        setSignType={setSignType}
      />
    );
  }

  if (signType === "createnewpasswordsubmit") {
    return (
      <CreateNewPasswordWithCode
        setSignType={setSignType}
      />
    );
  }

  if (signType === "verifyemail") {
    return (
      <VerifyEmailAfterSignUp
        setSignType={setSignType}
      />
    );
  }

  return (
    <SignIn
      setSignType={setSignType}
    />
  );
};
