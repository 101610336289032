import { FC, useState } from "react";
import { EditFieldSelect } from "./Select2";
import { TItemValue, metaFieldIF } from "../../data/types/item";
import { Tooltip } from "../atoms/tooltip";

type TStringInput = {
  fieldMeta: metaFieldIF;
  valueObj?: TItemValue;
  tooltipText?: string;
  isFieldDisabled?: boolean;
  setValue?: (value: number) => void;
  setUnit?: (value: string) => void;
};

export const NumberInput: FC<TStringInput> = ({
  fieldMeta,
  valueObj,
  tooltipText,
  isFieldDisabled,
  setValue,
  setUnit,
}) => {
  const fieldName = fieldMeta.name;
  const fieldValue = valueObj?.value ?? 0;
  const [newValue, setNewValue] = useState(fieldValue);
  return (
    <div className="unit-box">
      <input
        className="input"
        placeholder={`Enter ${fieldName}`}
        type="number"
        disabled={isFieldDisabled}
        value={newValue}
        onChange={(e) => {
          const newValue = e.target.value;
          const numberValue = Number(newValue);
          if (numberValue || numberValue === 0) setNewValue(numberValue);
        }}
        onBlur={() => {
          if (!setValue || fieldValue === newValue) return;
          setValue(newValue);
        }}
      />

      {fieldMeta.units && (
        <label className="label unit">
          <span>unit</span>
          <EditFieldSelect
            options={fieldMeta.units}
            fieldValue={valueObj?.unit}
            isFieldDisabled={isFieldDisabled}
            setValue={setUnit}
          />
        </label>
      )}

      {tooltipText && <Tooltip tooltipText={tooltipText} />}
    </div>
  );
};
