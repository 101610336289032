import { fetchUsernames } from "../lambda/fetchUsernames";

export const getUserNamesForUserIds = async (userIds: string[]) => {
    const uniqueUserIds = Array.from(new Set(userIds)) as string[];
    if (uniqueUserIds.length === 0) {
      return {};
    }
    const response = await fetchUsernames(uniqueUserIds);
    if (response && typeof response === "string") {
      const parsedResponse = JSON.parse(response);
      if (parsedResponse.usernames) {
        return parsedResponse.usernames;
      }
    }
  }
  