import React, { useState, FC, useEffect } from "react";
import { useStateContext } from "../../data/main/state";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { getUserId } from "../../lib/signinup/handleTokens";
import { OpenPopup } from "../modules/OpenPopup";
import { OpenCloseModal } from "../modules/TailwindModal";
import "./SortAndFilter.css";

const getActiveField = (dir?: string, field?: string) => {
  if (dir === "DESC") {
    if (field === "updatedAt") {
      return "Newest";
    } else if (field === "copyCount") {
      return "Most popular";
    } else {
      return "Title";
    }
  }
};

const PopupSort: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
  setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ sortAndFilterParams, setSortAndFilterParams, setIsMenuOpen }) => {
  const dir = sortAndFilterParams.sort?.direction as string | undefined;
  const field = sortAndFilterParams.sort?.field as string | undefined;
  const isActive = getActiveField(dir, field);

  const sort = sortAndFilterParams.sort || { direction: "DESC" };

  return (
    <div className="simple-menu">
      <a
        href="#home"
        className={`menu-item ${isActive === "Newest" ? "active" : ""}`}
        onClick={() => {
          if (isActive === "Newest") return;

          setSortAndFilterParams({
            ...sortAndFilterParams,
            sort: {
              ...sort,
              field: "updatedAt",
            },
          });

          setIsMenuOpen && setIsMenuOpen(false);
        }}
      >
        Newest
      </a>
      <a
        href="#home"
        className={`menu-item ${isActive === "Most popular" ? "active" : ""}`}
        onClick={() => {
          if (isActive === "Most popular") return;

          setSortAndFilterParams({
            ...sortAndFilterParams,
            sort: {
              ...sort,
              field: "copyCount",
            },
          });

          setIsMenuOpen && setIsMenuOpen(false);
        }}
      >
        Most popular
      </a>
      <a
        href="#home"
        className={`menu-item ${isActive === "Title" ? "active" : ""}`}
        onClick={() => {
          if (isActive === "Title") return;

          setSortAndFilterParams({
            ...sortAndFilterParams,
            sort: {
              direction: "ASC",
              field: "name",
            },
          });

          setIsMenuOpen && setIsMenuOpen(false);
        }}
      >
        Title
      </a>
    </div>
  );
};

const MineOrAllPublic: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ sortAndFilterParams, setSortAndFilterParams, setIsModalOpen }) => {
  const [userId, setUserId] = useState<string | undefined>(undefined);
  useEffect(() => {
    const userId = getUserId();
    setUserId(userId);
  }, []);

  const filter = sortAndFilterParams.filter || {};
  const allSnippetsFilter =
    !sortAndFilterParams.filter ||
    (filter.public === true && filter.owner === undefined);

  return (
    <div className="simple-menu">
      <a
        href="#home"
        className={`menu-item${allSnippetsFilter ? " active" : ""}`}
        onClick={() => {
          setSortAndFilterParams({
            ...sortAndFilterParams,
            filter: {
              ...filter,
              public: true,
              owner: undefined,
            },
          });
          setIsModalOpen && setIsModalOpen(false);
        }}
      >
        All snippets
      </a>
      <a
        href="#home"
        className={`menu-item${!allSnippetsFilter ? " active" : ""}`}
        onClick={() => {
          setSortAndFilterParams({
            ...sortAndFilterParams,
            filter: {
              ...filter,
              public: undefined,
              owner: userId,
            },
          });
          setIsModalOpen && setIsModalOpen(false);
        }}
      >
        My snippets
      </a>
    </div>
  );
};

const FilterPopup: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ sortAndFilterParams, setSortAndFilterParams, setIsModalOpen }) => {
  const tags = [
    "Tailwind",
    "form",
    "modal",
    "button",
    "menu",
    "tooltip",
    "icon",
    "breadcrumbs",
  ].sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

  const names = sortAndFilterParams.filter?.name as string[] | [];
  const [selectedTags, setSelectedTags] = useState<string[]>(names || []);

  const applyBtnDisabled = selectedTags?.length === 0;

  return (
    <div className="form-elements-wrapper">
      <div className="flex wrap gap-05">
        {tags.map((tag) => {
          return (
            <label key={tag} className="label-in-product-filter">
              <input
                type="checkbox"
                className="checkbox"
                value={tag}
                checked={selectedTags.includes(tag)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedTags([...selectedTags, tag]);
                  } else {
                    setSelectedTags(selectedTags.filter((t) => t !== tag));
                  }
                }}
              />
              <span className="label-span">{tag}</span>
            </label>
          );
        })}
      </div>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className={`button blue${applyBtnDisabled ? " disabled" : ""}`}
          disabled={applyBtnDisabled}
          onClick={() => {
            const filter = sortAndFilterParams.filter || {};
            setSortAndFilterParams({
              ...sortAndFilterParams,
              filter: {
                ...filter,
                name: selectedTags.length > 0 ? selectedTags : [],
              },
            });

            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Apply
        </button>
        <button
          type="button"
          className={`button gray-outline${applyBtnDisabled ? " disabled" : ""}`}
          disabled={applyBtnDisabled}
          onClick={() => {
            setSelectedTags([]);
            const filter = sortAndFilterParams.filter || {};
            setSortAndFilterParams({
              ...sortAndFilterParams,
              filter: {
                ...filter,
                name: [],
              },
            });

            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

const FilterCombined: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ sortAndFilterParams, setSortAndFilterParams, setIsModalOpen }) => {
  const {
    state: {
      settings: { user },
    },
  } = useStateContext();

  return (
    <>
      {user?.email && (
        <div className="m-1r border-b-l4">
          <MineOrAllPublic
            sortAndFilterParams={sortAndFilterParams}
            setSortAndFilterParams={setSortAndFilterParams}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      )}
      <div className="p-1r pl-2r">
        <FilterPopup
          sortAndFilterParams={sortAndFilterParams}
          setSortAndFilterParams={setSortAndFilterParams}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </>
  );
};

export const SortAndFilterGallery: FC<{
  sortAndFilterParams: QueryGetSnippetsArgs;
  setSortAndFilterParams: React.Dispatch<
    React.SetStateAction<QueryGetSnippetsArgs>
  >;
}> = ({ sortAndFilterParams, setSortAndFilterParams }) => {
  return (
    <section className="filters-section">
      <h2 className="sr-only">Product filters</h2>
      <div className="sort-and-filter-gallery">
        <div className="sort-container">
          <OpenPopup
            PopupInner={
              <PopupSort
                sortAndFilterParams={sortAndFilterParams}
                setSortAndFilterParams={setSortAndFilterParams}
              />
            }
          />
        </div>
        <div className="filter-container">
          <OpenCloseModal
            title="Select filter"
            icon="open"
            direction="right"
            component={
              <FilterCombined
                sortAndFilterParams={sortAndFilterParams}
                setSortAndFilterParams={setSortAndFilterParams}
              />
            }
          ></OpenCloseModal>
        </div>
      </div>
    </section>
  );
};
