import {
  PredictiveSearchItem,
  isLanguageItem,
  isFontItem,
  isHtmlSelectorItem,
  isTagItem,
} from "./types";

export const getPredictiveSearchItem = (suggestion: PredictiveSearchItem) => {
  const languageItem = isLanguageItem(suggestion);
  const fontItem = isFontItem(suggestion);
  const htmlSelectorItem = isHtmlSelectorItem(suggestion);
  const tagItem = isTagItem(suggestion);
  const dislayText = languageItem
    ? `${languageItem.code} (${languageItem.language})`
    : fontItem
      ? fontItem.name
      : htmlSelectorItem
        ? htmlSelectorItem
        : tagItem
          ? tagItem
          : "";
  const item = languageItem
    ? languageItem.code
    : fontItem
      ? fontItem.name
      : htmlSelectorItem
        ? htmlSelectorItem
        : tagItem
          ? tagItem
          : "";

  return { item, dislayText };
};
