import { metaFieldIF, EMetaFieldTypes } from "../../types/item";

export const allFieldsHrefId: metaFieldIF = {
  name: "href",
  type: EMetaFieldTypes.selectPathId,
  wrapperFn: (valObj) => `#${valObj.value}`,
  help:
    "### href  \n" +
    "Defines a link to a resource via the resource id. You see the ids of the elements of the current SVG. Click in the id of the element, which you want to assign.",
};

export const pathHelp =
  "#### Absolute and relative values  \n" +
  "The `uppercase` character stands for `absolute` values and `lowercase` for `relative` values. " +
  "M 100 100, for example, moves the pen to the coordinate 100,100, whereas m 100 100 moves the pen 100 pixel down and 100 pixel up.  \n" +
  "#### Drawing board and direction of movement  \n" +
  "The size of the drawing board for the current path is defined by the `width` and `height` of the parent SVG element. " +
  "The 0|0 coordinate is at the `top-left` of the drawing board. " +
  "If you move to another coorddinate e.g. with M 100 50 or L 100 50, " +
  "then the first value is the x-value and the second the y-value and the point is 100 to the `right` and 50 `down` from 0|0.";
