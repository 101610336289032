import { FC, useEffect, useState } from "react";
import { snippetIF } from "../../data/types/snippets";
import { PreviewWithToolbar2 } from "./PreviewWithToolbar2";

import "./Preview.css";
import { renderCssAndHtmlForSnippet } from "../../lib/snippets/renderCssAndHtmlForSnippet";

export const Preview: FC<{ snippet?: snippetIF }> = ({ snippet }) => {
  const [name, setName] = useState("");
  const [html, setHtml] = useState("");
  const [fonts, setFonts] = useState("");
  const [htmlCss, setHtmlCss] = useState("");

  useEffect(() => {
    const asyncFn = async () => {
      if (!snippet) return;

      const newS = await renderCssAndHtmlForSnippet(snippet);
      setName(newS.name);
      if (newS.htmlString) {
        setHtml(newS.htmlString);
      }
      if(newS.googleFontImportString) {
        setFonts(newS.googleFontImportString);
      }
      if(newS.htmlSelectorCss) {
        setHtmlCss(newS.htmlSelectorCss);
      }
    };
    asyncFn();
  }, [snippet]);

  if (!snippet)
    return null;

  return (
    <PreviewWithToolbar2
      snippetName={name}
      snippetHtml={html}
      snippetFonts={fonts}
      htmlCss={htmlCss}
    />
  );
};

export default Preview;
