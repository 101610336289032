import { FC } from "react";
import { useStateContext } from "../../data/main/state";
import {
  itemTypes,
  getCategoryMetasForItemType,
} from "../../lib/meta/getMetas";
import { getMetaWithAllAndCanBeFields } from "../../lib/meta/getMetaWithAllAndCanBeFields";
import { metaIF, itemIF } from "../../data/types/item";
import { addItem } from "../../lib/reducer/items/actions";
import { TCSSCategory } from "../../data/css/constants/all";
import { SectionTitle } from "../modules/SectionTitle";
import {
  separetSetOnParent,
  isCssPropsBtnDisabled,
} from "../../utils/css/main";

const getNewCssProperty = ({
  m,
  editSelector,
}: {
  m: metaIF;
  editSelector?: itemIF;
}) => {
  const newItem = {} as itemIF;
  newItem.name = m.name;

  const itemType = itemTypes.CLASSES_CSS;

  const metaWithAllAndCanBeFields = getMetaWithAllAndCanBeFields({
    itemType,
    item: newItem,
  });
  if (!metaWithAllAndCanBeFields) {
    console.error("Can't add ", m.name, " without metaWithAllAndCanBeFields");
    return;
  }

  if (!editSelector) {
    console.error("Can't add ", m.name, " without editSelector");
    return;
  }
  newItem.classId = editSelector.id;

  if (
    !metaWithAllAndCanBeFields?.default ||
    !metaWithAllAndCanBeFields.fields ||
    metaWithAllAndCanBeFields.fields.length === 0
  ) {
    console.error(
      "Can't add ",
      metaWithAllAndCanBeFields.name,
      " without default value or fields"
    );
    return;
  }

  newItem.value = metaWithAllAndCanBeFields.default;

  return newItem;
};

const addCssPropertyOtherHelp =
  "### Add CSS property  \nThe following properties are disbaled under the given circumstances:  \n" +
  "- `content` and `counter-increment`: are used just within the pseudo-elements `::before` and `::after`.  \n" +
  "- `variable`: has an effect and is correct in the root element only.";



interface IAddCssProperty {
  items: itemIF[];
  editSelector?: itemIF;
  selectedCategory: TCSSCategory;
}
export const AddCssProperty: FC<IAddCssProperty> = ({
  items,
  editSelector,
  selectedCategory,
}) => {
  const {
    state: {
      snippet: { editSnippetId, snippets },
    },
    dispatch,
  } = useStateContext();
  const itemType = itemTypes.CLASSES_CSS;
  const cssMetaForSelectedCategory = getCategoryMetasForItemType({
    category: selectedCategory,
    itemType,
  });

  const parentChild = separetSetOnParent(cssMetaForSelectedCategory);

  const child = parentChild?.child?.filter((i) => {
    if (!i) {
      return false;
    }
    return true;
  });
  const parent = parentChild?.parent?.filter((i) => {
    if (!i) {
      return false;
    }
    return true;
  });

  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const editPropertyId = editSnippet?.data?.editPropertyId;

  let addCssPropertyHelp =
    selectedCategory === "OTHER" ? addCssPropertyOtherHelp : "";

  return (
    <>
      <div className="mt-2r mb-1r">
        <SectionTitle
          label="Select property"
          help={addCssPropertyHelp !== "" ? addCssPropertyHelp : undefined}
        />
      </div>
      <div>
        {child && (
          <div className="mb-05r">
            <SectionTitle label="Set on parent" />
          </div>
        )}
        <div className="btn-row mb-1r">
          {parent?.map((m) => {
            const isDisabled = isCssPropsBtnDisabled({
              meta: m,
              items,
              editSelector,
            });
            return (
              <button key={m.name}
                type="button"
                className={`button blue-outline${isDisabled ? " disabled" : ""}`}
                onClick={() => {
                  if (isDisabled) return;

                  const newItem1 = getNewCssProperty({ m, editSelector });
                  if (!newItem1) return;

                  dispatch(
                    addItem({
                      itemType,
                      item: newItem1,
                      addAfterItemId: editPropertyId,
                    })
                  );
                }}
              >
                {m.name}
              </button>
            );
          })}
        </div>
      </div>

      {child && (
        <div>
          <div className="mb-05r">
            <SectionTitle label="Set on child" />
          </div>
          <div className="btn-row mb-1r">
            {child.map((m) => {
              const isDisabled = isCssPropsBtnDisabled({
                meta: m,
                items,
                editSelector,
              });

              return (
                <button key={m.name}
                  type="button"
                  className={`button blue-outline${isDisabled ? " disabled" : ""}`}
                  onClick={() => {
                    if (isDisabled) return;

                    const newItem1 = getNewCssProperty({ m, editSelector });
                    if (!newItem1) return;

                    dispatch(
                      addItem({
                        itemType,
                        item: newItem1,
                        addAfterItemId: editPropertyId,
                      })
                    );
                  }}
                >
                  {m.name}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
