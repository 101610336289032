import { serverSnippetIF } from "../../data/types/snippets";
import { getNewSnippetAfterChangeInItems } from "./getNewSnippetAfterchangeInItems";
import { getHtmlSelectorCss } from "../render/getHtmlSelectorCss";
import { getGoogleFontImportStringForSnippet } from "./getGoogleFontImportString";
import {
  generateHtmlForSnippet,
  generateSvgString,
} from "../render/generateHtmlString";

export const renderHtmlForSvg = async (
  snippet: serverSnippetIF
): Promise<serverSnippetIF> => {
  const newS = { ...snippet } as serverSnippetIF;

  const htmlSelectorCss = getHtmlSelectorCss(newS!);
  newS.htmlSelectorCss = htmlSelectorCss;

  const googleFontImportString = getGoogleFontImportStringForSnippet({
    snippet: newS,
  });
  newS.googleFontImportString = googleFontImportString;

  const snippetHtml = generateSvgString(newS);
  return { ...newS, htmlString: snippetHtml };
};

export const renderCssAndHtmlForSnippet = async (
  snippet: serverSnippetIF
): Promise<serverSnippetIF> => {
  const newS = { ...snippet } as serverSnippetIF;
  const snippetCss = getNewSnippetAfterChangeInItems({
    editSnippet: newS,
  });
  newS.snippetCss = snippetCss;

  const htmlSelectorCss = getHtmlSelectorCss(newS!);
  newS.htmlSelectorCss = htmlSelectorCss;

  const googleFontImportString = getGoogleFontImportStringForSnippet({
    snippet: newS,
  });
  newS.googleFontImportString = googleFontImportString;

  const snippetHtml = generateHtmlForSnippet(newS);
  return { ...newS, htmlString: snippetHtml };
};
