import { FC } from "react";
import { snippetIF } from "../../data/types/snippets";
import { useStateContext } from "../../data/main/state";
import { defaultStylepack2 } from "../../data/stylepack/default";
import { ReactComponent as Add } from "../../assets/imgs/add.svg";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import { addItem } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { SectionTitle } from "../modules/SectionTitle";
import { Tooltip } from "../atoms/tooltip";
import { itemInTreeDelete } from "../../lib/reducer/items/actions";

const stylepackHelp = `### Add default fonts and colours to a snippet

Each snippet has a stylepack that contains default fonts and colours. The colours are displayed in all colour pickers in your snippet, like e.g. within the CSS properties *background-color* or *border*/*border-color*. The stylepack fonts are available in CSS font-family, where a font can be assigned to an HTML element, the whole snippet, the whole document ...

Select here a snippet and see its stylepack. In the *Edit* tab you can add or remove fonts and colours. In the *Copy* tab you can select another snippet and copy its stylepack into the current snippet.

`;

export const AddOrRemoveStylepack: FC<{
  selectedSnippet?: snippetIF;
}> = ({ selectedSnippet }) => {
  const { dispatch } = useStateContext();

  // TODO: remove stylepack from snippet.data for all snippets (local and serverp)

  const stylepacks = selectedSnippet?.data.stylepacks;
  const stylepack = stylepacks?.find(
    (s) => s.id === selectedSnippet?.data.editStylepackId
  );

  const itemType = itemTypes.STYLEPACK;

  return (
    <div className="form-elements-wrapper">
      <SectionTitle label="Add, copy or remove" help={stylepackHelp} />

      <div className="flex gap-1 mt-1r">
        <button
          type="button"
          className="button blue icon"
          onClick={() => {
            if (!selectedSnippet) return;

            dispatch(
              addItem({
                item: defaultStylepack2,
                itemType,
                editSnippetId: selectedSnippet.id,
              })
            );
          }}
        >
          <span className="sr-only">Add stylepack</span>
          <Add />
        </button>

        <button
          type="button"
          className={`button orange icon tooltip-container-parent${!stylepack ? " disabled" : ""}`}
          onClick={() => {
            if (!stylepack) return;

            dispatch(
              itemInTreeDelete({
                itemType,
                editSnippetId: selectedSnippet?.id,
              })
            );
          }}
        >
          <span className="sr-only">Delete stylepack</span>
          <Delete />
          <Tooltip
            tooltipText="Removes the selected stylepack"
            direction="bottom-right"
          />
        </button>
      </div>
    </div>
  );
};