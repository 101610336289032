import { FC, useEffect, ReactNode } from "react";
import { useStateContext } from "../data/main/state";
import { setIsLoading } from "../lib/reducer/settings/actions";
import { Spinner } from "./atoms/loading";
import { NavbarBottom, TopNavNew2 } from "./navbar/Navbar2";

interface IMainLayout {
  title: string;
  description: string;
  children?: ReactNode;
}
const MainLayout: FC<IMainLayout> = ({ title, description, children }) => {
  const {
    state: {
      settings: { isLoading },
    },
    dispatch,
  } = useStateContext();

  useEffect(() => {
    if (isLoading) {
      dispatch(setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <div className="page-wrapper">
        <TopNavNew2 />

        {children}
        <div className="push"></div>
      </div>
      <NavbarBottom />
    </>
  );
};

export default MainLayout;
