import { TObject, itemIF, metaIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { getMetaWithAllFields } from "../meta/getMetaWithAllFields";
import { getItemValuesAsObj } from "./getItemValuesAsObject";
import { buildCombinations } from "../../utils/render/css/build-combinations";

const getSelectorNameStringFromObj = ({
  valueObj,
  meta,
}: {
  valueObj: { [key: string]: string };
  meta: metaIF;
}) => {
  let selectorName = "";

  Object.keys(valueObj).forEach((key) => {
    const space = selectorName === "" ? "" : " ";
    const pre = meta.prefix ? meta.prefix : "";
    selectorName += space + pre + valueObj[key];
  });
  return selectorName;
};

const getSelectorPreValue = (selector?: TObject) => {
  if (!selector || selector.level === 1) {
    return "";
  }
  if (selector.prefix) {
    if (["AMPERSAND"].includes(selector.prefix.name)) {
      return "";
    }
    const prefixMeta = getMetaWithAllFields({
      itemType: itemTypes.CONNECTOR,
      itemName: selector.prefix.name,
    });
    if (prefixMeta) {
      const prefixDisplay = prefixMeta.display
        ? prefixMeta.display
        : prefixMeta?.name;
      return ` ${prefixDisplay} `;
    }
  }
  return " ";
};


// get just the one selector name and not the squence of the selector names (when the selector has ancestor and children and/or connectors in the selector tree) -> getSelectorNameArray
export const getSelectorName = ({
  selector,
  selectors,
  stylepack
}: {
  selector?: itemIF;
  selectors?: itemIF[];
  stylepack?: itemIF;
}) => {
  const meta = getMetaWithAllFields({
    itemType: itemTypes.SELECTORS,
    itemName: selector?.name!,
  });

  if (
    !meta ||
    !selector?.value ||
    meta.name === "::before" ||
    meta.name === "::after"
  ) {
    return meta?.display ? meta.display : selector?.name;
  }

  const valueObj: TObject = {};
  getItemValuesAsObj({
    itemType: itemTypes.SELECTORS,
    item: selector,
    items: selectors,
    attr: valueObj,
    stylepack
  });

  const selectorNameStr = getSelectorNameStringFromObj({
    valueObj,
    meta,
  });

  const beforeName =
    selector.name === "animation"
      ? "animation: "
      : selector.name === "@media"
        ? "@media "
        : "";

  const selectorName = `${beforeName}${selectorNameStr}`;

  return selectorName;
};

export const getSelectorNameArray = ({
  selector,
  selectorNamesObjs,
  selectors,
  stylepack
}: {
  selector: itemIF;
  selectorNamesObjs: { prefix: string; name: string }[];
  selectors: itemIF[];
  stylepack?: itemIF;
}) => {
  const selectorName = getSelectorName({
    selector,
    selectors,
    stylepack
  });

  const prefix = getSelectorPreValue(selector);
  selectorNamesObjs.unshift({
    prefix,
    name: selectorName!,
  });

  const parent = selectors?.find((s) => s.id === selector.parent);

  if (!parent || parent.name === "@media") {
    return;
  }

  getSelectorNameArray({
    selector: parent,
    selectorNamesObjs,
    selectors,
    stylepack
  });
};

export const getSelectorNameStr = ({
  selectorNamesObjs,
}: {
  selectorNamesObjs: { prefix: string; name: string }[];
}) => {
  // build groups of comma connected selectors
  const allGroups: string[][] = [];
  let count = 0;
  selectorNamesObjs.forEach((no, index) => {
    if (index < count) {
      return;
    }

    if (
      selectorNamesObjs[index + 1] &&
      selectorNamesObjs[index + 1].prefix === " , "
    ) {
      // if next item has prefix, add this to the current item instead of its comma prefix
      const group = [`${no.prefix}${no.name}`];
      count += 1;
      while (
        selectorNamesObjs[count] &&
        selectorNamesObjs[count].prefix === " , "
      ) {
        const ns = selectorNamesObjs[count];
        group.push(`${no.prefix}${ns.name}`);
        count += 1;
      }
      allGroups.push(group);
    } else {
      allGroups.push([`${no.prefix}${no.name}`]);
      count += 1;
    }
  });

  // loop through and build combinations
  const resultArray = buildCombinations(allGroups);
  return resultArray.join(", ");
};
