import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  AuthenticationResultType,
} from "@aws-sdk/client-cognito-identity-provider";

const client = new CognitoIdentityProviderClient({ region: "eu-west-2" });

export const signInGetTokens = async ({
  username,
  password,
  rememberMe,
}: {
  username: string;
  password: string;
  rememberMe?: boolean;
}) => {
  const clientId = process.env.REACT_APP_CLIENT_ID;

  try {
    const command = new InitiateAuthCommand({
      AuthFlow: "USER_PASSWORD_AUTH", // Use string literal here
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
      ClientId: clientId,
    });

    const response = await client.send(command);
    const authResult =
      response.AuthenticationResult as AuthenticationResultType;
    if (authResult) {
      const tokens = {
        IdToken: authResult.IdToken, // can be useful for displaying user information
        AccessToken: authResult.AccessToken, // required to authenticate with the API
        RefreshToken: authResult.RefreshToken, // long-lived token that can be used to obtain new access tokens
      };
      
      if (rememberMe) {
        window.localStorage.setItem("tokens", JSON.stringify(tokens));
      } else {
        window.sessionStorage.setItem("tokens", JSON.stringify(tokens));
      }

      return true;
    }
  } catch (e) {
    throw new Error((e as Error).message);
  }
};
