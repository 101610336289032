import { FC, useEffect, useState } from "react";
import { itemTypes } from "../../lib/meta/getMetas";
import { snippetIF } from "../../data/types/snippets";
import RenderElementAndChildren from "./HtmlOrSvgElementAndChildren";
import { buildTree } from "../../lib/items/build-tree";
import { itemWithChildrenIF } from "../../data/types/item";

const HtmlTree: FC<{
  editSnippet?: snippetIF;
}> = ({ editSnippet }) => {
  const [branches, setBranches] = useState([] as itemWithChildrenIF[]);

  useEffect(() => {
    if (!editSnippet || !editSnippet.data?.htmls) {
      setBranches([]);
    } else {
      const newBranches = buildTree(editSnippet.data.htmls);
      setBranches(newBranches);
    }
  }, [editSnippet]);

  if (!editSnippet || branches.length === 0) return null;
  return (
    <>
      {branches.map((item) => {
        return (
          <RenderElementAndChildren
            key={item.id}
            itemType={itemTypes.BLOCK_HTML}
            item={item}
            editSnippet={editSnippet}
          />
        );
      })}
    </>
  );
};
export default HtmlTree;