import { ChangeEvent, TouchEvent, FC } from "react";
import { metaFieldIF } from "../../data/types/item";

type TEditFieldCheckbox = {
  fieldMeta: metaFieldIF;
  isFieldDisabled?: boolean;
  isChecked: boolean;
  tooltipText?: string;
  setValue?: (value: boolean) => void;
};
export const EditFieldCheckbox: FC<TEditFieldCheckbox> = ({
  fieldMeta,
  isFieldDisabled,
  isChecked,
  tooltipText,
  setValue,
}) => {
  const fieldName = fieldMeta?.name;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setValue && setValue(isChecked);
  };

  const handleTouchStart = (event: TouchEvent<HTMLInputElement>) => {
    if (isFieldDisabled) {
      event.preventDefault();
    }
  };

  const handleTouchEnd = (event: TouchEvent<HTMLInputElement>) => {
    if (isFieldDisabled) {
      event.preventDefault();
    }
  };

  return (
      <input
        title={`Select ${fieldName}`}
        type="checkbox"
        checked={isChecked}
        disabled={isFieldDisabled}
        className="checkbox"
        onChange={handleChange}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      />
  );
};
