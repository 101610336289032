import {
  CognitoIdentityProviderClient,
  ChangePasswordCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { getAccessToken } from "./handleTokens";

const client = new CognitoIdentityProviderClient({ region: "eu-west-2" });

export const changePassword = async ({
  previousPassword,
  proposedPassword,
}: {
  previousPassword: string;
  proposedPassword: string;
}) => {
  const AccessToken = await getAccessToken();
  const command = new ChangePasswordCommand({
    AccessToken,
    PreviousPassword: previousPassword,
    ProposedPassword: proposedPassword,
  });

  try {
    const response = await client.send(command);
    if(response.$metadata.httpStatusCode === 200) {
      return true;
    }
  } catch (error) {
    console.error("Error changing password", error);
    throw error;
  }
};
