import { itemIF, itemValueIF } from "../../../data/types/item";
import { getNewItemBody } from "../../tree/manipulateItemNew";
import { itemTypes } from "../../../lib/meta/getMetas";

type CssVariableObject = {
  name: string;
  value: string;
};

export const extractCssVariables = (cssString: string): CssVariableObject[] => {
  const regex = /--(.*?): (.*?);/g;
  let matches;
  const variables: CssVariableObject[] = [];

  while ((matches = regex.exec(cssString)) !== null) {
    variables.push({ name: matches[1], value: matches[2] });
  }

  return variables;
};

export const getVariablesWithNameValue = (
  nvs: CssVariableObject[]
): itemIF[] => {
  const newItems = [] as itemIF[];
  for (const nv of nvs) {
    const newItem = getNewItemBody({
      itemName: "variable",
      itemType: itemTypes.CLASSES_CSS,
    });
    const value = {
      name: {
        value: nv.name,
      },
      string: {
        value: nv.value,
      },
    } as itemValueIF;
    newItem.value = value;
    newItem.classId = 1;
    newItems.push(newItem);
  }
  return newItems;
};
