import { itemIF } from "../../data/types/item";

/*
return just classes with unique names with an array of ids of classes with the same name
*/

export const getClassesWithSameNameIds = (selectors: itemIF[]) => {
    const allClasses = selectors.filter((s) => s.name === "class");
    const uniqueNames = [] as string[]
    const classesUniqueNames = allClasses.filter((s) => {
      const cn = s.value?.main?.value
      if(!uniqueNames.includes(cn)) {
        uniqueNames.push(cn)
        return true
      }
      return false    
    });
  
    const sameNameClassIdsAdded = classesUniqueNames.map((s) => {
      const sameNameClasses = allClasses.filter((s2) => s2.value?.main?.value === s.value?.main?.value && s2.id !== s.id)
      return {
        ...s,
        sameNameClassesIds: sameNameClasses.map((s) => s.id)
      }
    })
  
    return sameNameClassIdsAdded;
  };