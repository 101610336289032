import { actionIF } from "../../../data/types/item";
import { SELECT_PAGE } from "./actions";
import { PAGES, SUB_PAGES, settingsIF } from "../../../data/settings/constants";
import { snippetsIF } from "../../../data/types/snippets";

export const settingsAndSnippetsReducer = ({
  action,
  snippet,
  settings,
}: {
  action: actionIF;
  snippet: snippetsIF;
  settings: settingsIF;
}) => {
  try {
    switch (action.type) {
      case SELECT_PAGE:
        if (!action.page) {
          console.warn("SELECT_PAGE no page is defined");
          return { snippet, settings };
        }
        if (action.page) {
          settings = {
            ...settings,
            selectedPage: action.page,
            selectedSubPage: SUB_PAGES.SNIPPETS,
          };

          if (action.page === PAGES.CODE) {
            const snippets = snippet.snippets;
            const eSnippet = snippets.find(
              (s) => s.id === snippet.editSnippetId
            );
            const editSnippet = eSnippet ? eSnippet : snippets[0];

            if (editSnippet) {
              snippet = {
                ...snippet,
                snippets: snippets,
                editSnippetId: editSnippet.id,
              };
            }
          }
        }

        return { snippet, settings };

      default:
    }
  } catch (error) {
    console.error("settingsAndSnippetsReducer error", error);
  }
};
