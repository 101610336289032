import { FC } from "react";
import { TMsgType } from "../../lib/signinup/helpers";
import "./formErrOrSuccessMessage.css";

export const FormInputMessage: FC<{ msg: TMsgType }> = ({ msg }) => {
  return (
    <div className={`error-or-success-msg${msg.errMsg ? " error" : msg.successMsg ? " success" : ""}`}>
      {msg.errMsg ? msg.errMsg : msg.successMsg ? msg.successMsg : " "}
    </div>
  );
};
