import { setIsLoading } from "../reducer/settings/actions";
import { TMsgType, TFormData } from "../signinup/helpers";
import { changePassword } from "../signinup/changePassword";

export const updatePasswordFn = async ({
  formData,
  setSignInErrorMessage,
  dispatch,
}: {
  formData: TFormData;
  setSignInErrorMessage: (signInErrorMessage: TMsgType) => void;
  dispatch: (value: any) => void;
}) => {
  if (formData.password && formData.password2) {
    try {
      dispatch(setIsLoading(true));
      const oldPassword = formData.password.data;
      const newPassword = formData.password2.data;
      const response = await changePassword({
        previousPassword: oldPassword,
        proposedPassword: newPassword,
      });
      if (response) {
        setSignInErrorMessage({
          successMsg: "Password changed successfully",
        });
      }
    } catch (e) {
      setSignInErrorMessage({
        errMsg: (e as Error).message,
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  }
};
