import React, { useEffect, useState } from "react";
import { useStateContext } from "../../data/main/state";
import { TMsgType, TFormData } from "../../lib/signinup/helpers";
import { getUserName, getEmail } from "../../lib/signinup/handleTokens";
import { InputWithValidation, UpdateEmail } from "./InputWithValidation";
import { FormInputMessage } from "../atoms/formErrOrSuccessMessage";
import { updatePasswordFn } from "../../lib/user/updatePw";
import { updateEmail } from "../../lib/user/updateEmail";
import { VerticalTab } from "../modules/VerticalTab";
import { ReactComponent as Password } from "../../assets/imgs/password.svg";
import { ReactComponent as AccountCircle } from "../../assets/imgs/account-face.svg";

import "./Account.css";

export const AccountWrapper = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="account-main">
      <div className="account-wrapper">
        <div className="login-inner">
          <div className="p-2r">
            <h1 className="h1">{title}</h1>
            <div className="login-form">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Profil = () => {
  const un = getUserName();
  const em = getEmail();
  const [formData, setFormData] = useState({
    username: { data: un, errorMessage: "" },
    email: { data: em, errorMessage: "" },
  } as TFormData);

  const username = formData.username?.data;
  const updateUsernameBtnIsDisabled =
    !username || username.length < 3 || username === un;

  const emailObj = formData.email;
  const updateEmailBtnIsDisabled =
    !emailObj || emailObj.errorMessage || emailObj.data === em;

  const updateCognitoEmail = async () => {
    if (updateEmailBtnIsDisabled) return;

    console.log("update in cognito email --------------------------------- ", formData.email.data);
    const response = await updateEmail(formData.email.data);
    console.log(response);
  };

  return (
    <div className="login-inner p-12r">
      <h1>Profil</h1>
      <div className="login-form">
        <div className="login-form-field mt-2r">
          <InputWithValidation
            type="text"
            inputData={formData.username!}
            label="Your username"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                username: {
                  ...formData.username,
                  data: newData,
                  errorMessage:
                    newData.length < 3
                      ? "The username must have at least 3 alphanumeric characters"
                      : "",
                },
              });
            }}
          />
          <FormInputMessage msg={{ errMsg: formData.username?.errorMessage }} />
        </div>
        <div className="login-form-field mt-1r mb-2r">
          <button
            type="submit"
            aria-label="Update password"
            className={`login-button primary${updateUsernameBtnIsDisabled ? " disabled" : ""}`}
            onClick={() => {
              if (updateUsernameBtnIsDisabled) return;

              console.log("update username");
            }}
          >
            Update username
          </button>
        </div>

        <div className="login-form-field mt-2r">
          <UpdateEmail
            inputData={formData}
            setInputData={setFormData}
            label="Your email"
          />
          <FormInputMessage msg={{ errMsg: formData.email?.errorMessage }} />
        </div>
        <div className="login-form-field mt-1r mb-2r">
          <button
            type="submit"
            aria-label="Update password"
            className={`login-button primary${updateEmailBtnIsDisabled ? " disabled" : ""}`}
            onClick={() => {
              updateCognitoEmail();
            }}
          >
            Update email
          </button>
        </div>
      </div>
    </div>
  );
};

export const ChangePassword = () => {
  const [formData, setFormData] = useState({
    email: { data: "", errorMessage: "" },
    password: { data: "", errorMessage: "" },
    password2: { data: "", errorMessage: "" },
    verify: { data: "", errorMessage: "" },
  } as TFormData);
  const { dispatch } = useStateContext();
  const [signInErrorMessage, setSignInErrorMessage] = useState({} as TMsgType);

  useEffect(() => {
    if (signInErrorMessage.successMsg) {
      setFormData({
        ...formData,
        password: { data: "", errorMessage: "" },
        password2: { data: "", errorMessage: "" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInErrorMessage.successMsg]);

  const updatePasswordBtnIsDisabled =
    formData.password.data.length < 8 || formData.password2.data.length < 8;

  return (
    <div className="login-inner p-12r">
      <h1>Change your password</h1>
      <div className="login-form">
        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.password}
            label="Old password"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                password: {
                  ...formData.password,
                  data: newData,
                },
              });
            }}
            isPasswordWithEye={true}
          />
          <FormInputMessage msg={{ errMsg: formData.password.errorMessage }} />
        </div>

        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.password2}
            label="New password"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                password2: {
                  ...formData.password2,
                  data: newData,
                  errorMessage:
                    newData.length < 8
                      ? "The password must have at least 8 alphanumeric characters"
                      : "",
                },
              });
            }}
            isPasswordWithEye={true}
          />
          <FormInputMessage msg={{ errMsg: formData.password2.errorMessage }} />
        </div>
        <div className="login-form-field mt-3r mb-1r">
          <button
            type="submit"
            aria-label="Update password"
            className={`login-button primary${updatePasswordBtnIsDisabled ? " disabled" : ""}`}
            disabled={updatePasswordBtnIsDisabled}
            onClick={() => {
              if (!updatePasswordBtnIsDisabled) {
                updatePasswordFn({
                  formData,
                  setSignInErrorMessage,
                  dispatch,
                });
              }
            }}
          >
            Update password
          </button>
          <FormInputMessage msg={signInErrorMessage} />
        </div>
      </div>
    </div>
  );
};

const ProfileBtn = () => {
  return (
    <>
      <AccountCircle />
      <span>Profile</span>
    </>
  );
};

const PasswordBtn = () => {
  return (
    <>
      <Password />
      <span>Password</span>
    </>
  );
};

export const Account = () => {
  return (
    <div className="mt-2r">
      <VerticalTab
        buttonAndComponents={[
          { button: <ProfileBtn />, component: <Profil /> },
          { button: <PasswordBtn />, component: <ChangePassword /> },
        ]}
      />
    </div>
  );
};
