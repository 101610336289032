import { FC } from "react";
import { itemIF } from "../../data/types/item";
import { useStateContext } from "../../data/main/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { assignClassToHtmlOrSvg } from "../../lib/reducer/items/actions";
import { getClassesWithSameNameIds } from "../../lib/items/getClassesWithSameNameIds";
import { SortAndFindChildren } from "../modules/SortAndFind/SortAndFindItems";

/* the class has in sameNameClassesIds the ids of the classes with the same name  
mark the class name button as active when edit item has in assignedClasses one of the class ids with this name
before dispatching assignClassToHtmlOrSvg check if the id of the class already assigned is passed 
*/
const getAllreadyAssignedClassId = ({
  editItem,
  classItem,
}: {
  editItem: itemIF;
  classItem: itemIF;
}): number => {
  if (editItem.assignedClasses && editItem.assignedClasses.length > 0) {
    const assigned = editItem.assignedClasses.find((ac) =>
      classItem.sameNameClassesIds?.includes(ac)
    );
    if (assigned) {
      return assigned;
    }
  }
  return classItem.id;
};

const AssignClassToHtmlBtns = ({
  items,
  editItem,
  itemType,
}: {
  items?: itemIF[];
  editItem: itemIF | undefined;
  itemType: itemTypes;
}) => {
  const { dispatch } = useStateContext();

  if (!items || items.length === 0) {
    return (
      <div className="mb-2">
        <button className="button disabled" disabled>
          NO CLASS IN SNIPPET
        </button>
      </div>
    );
  }

  return (
    <div className="btn-row mt-1r">
      {items.map((c) => {
        const assigned = editItem?.assignedClasses?.find(
          (ac) => c.id === ac || c.sameNameClassesIds?.includes(ac)
        );

        return (
          <button
            type="button"
            key={c.id}
            className={`button ${assigned ? "blue" : "blue-outline"}`}
            onClick={() => {
              if (!editItem) return;
              dispatch(
                assignClassToHtmlOrSvg({
                  itemType: itemType,
                  classIds: [
                    getAllreadyAssignedClassId({ editItem, classItem: c }),
                  ],
                })
              );
            }}
          >
            {`${c.value?.main.value}`}
          </button>
        );
      })}
    </div>
  );
};



export const AssignClassToHtml: FC<{
  itemType: itemTypes;
  title?: string;
}> = ({ itemType, title }) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();
  const editSnippet = snippets?.find((s) => s.id === editSnippetId);
  const selectors = editSnippet?.data.selectors;
  const classesWithSameNameIds = getClassesWithSameNameIds(selectors || []);

  const editItem =
    itemType === itemTypes.BLOCK_HTML
      ? editSnippet?.data.htmls.find(
          (h: itemIF) => h.id === editSnippet?.data.editHtmlId
        )
      : editSnippet?.data.svgs.find(
          (s) => s.id === editSnippet?.data.editSvgId
        );

  return (
    <SortAndFindChildren allItems={classesWithSameNameIds} title={title}>
      <AssignClassToHtmlBtns editItem={editItem} itemType={itemType} />
    </SortAndFindChildren>
  );
};
