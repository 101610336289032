import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";
import { getValueStr } from "../../../lib/reducer/items/getItemData";

export const CSS_PROPERTIES_SHADOW: metaIF[] = [
  {
    name: "box-shadow",
    category: ["SHADOW"],
    addMultipleTimes: true,
    addToShorthand: "box-shadow",
    shorthandSeparator: ", ",
    fieldsOrder: [
      "main",
      "h-offset",
      "v-offset",
      "blur",
      "spread",
      "color",
      "inset",
    ],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "value" }, { id: "none" }],
        help:
          "#### box-shadow\n" +
          "Draw one or multiple shadows outside or inside the selected element.  \n\n" +
          "If you select `value` you can define the horizontal (`h-offset`) and vertical (`v-offset`) position of the shadow, " +
          "its size (`spread`), its `blur` factor, colour and set the flag `inset` to draw the shadow inside the selected element's sides.  \n\n" +
          "If you want to apply **multiple shadows** to the selected element, " +
          "add multiple `box-shadow` properties to the selector. " +
          "The one deeper down in the list are drawn on top of the others.  \n\n" +
          "`background-shadow: none` is, for example, helpful when you want to remove a shadow, " +
          "which is set for a group of elements, for individual elements in the group.",
      },
      {
        name: "h-offset",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "px" }],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "v-offset",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "px" }],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "blur",
        type: EMetaFieldTypes.number,
        onOff: true,
        conditions: {
          greaterOrEqual0: true,
        },
        units: [{ id: "px" }],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "spread",
        type: EMetaFieldTypes.number,
        units: [{ id: "px" }, { id: "rem" }],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "inset",
        type: EMetaFieldTypes.select,
        options: [{ id: "none" }, { id: "inset" }],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],
    group: [
      {
        name: "shadow",
        fields: ["main"],
      },
      {
        name: "hVOffset",
        display: ["h-offset", "v-offset"],
        fields: ["h-offset", "v-offset"],
      },
      {
        name: "blurG",
        display: ["blur"],
        fields: ["blur"],
      },
      {
        name: "colourG",
        display: ["color"],
        fields: ["color"],
      },
      {
        name: "spreadG",
        display: ["spread"],
        fields: ["spread"],
      },
      {
        name: "insetG",
        display: ["inset"],
        fields: ["inset"],
      },
    ],
    wrapperForGroup: [
      {
        name: "boxShadowGW",
        fields: [
          "main",
          "h-offset",
          "v-offset",
          "blur",
          "spread",
          "color",
          "inset",
        ],
        wrapperFn: (vObj, items) => {
          if (vObj.main.value === "none") {
            return "none";
          }
          const hOffsetStr = `${getValueStr({
            valObj: vObj["h-offset"],
          })}`;
          const vOffsetStr = `${getValueStr({
            valObj: vObj["v-offset"],
          })}`;
          const blurStr = `${getValueStr({
            valObj: vObj.blur,
          })}`;
          const spreadStr = vObj.spread.onOff
            ? `${getValueStr({
                valObj: vObj.spread,
              })}`
            : "";
          const colorStr = `${getValueStr({
            valObj: vObj.color,
          })}`;
          const insetStr = vObj.inset.onOff
            ? `${vObj.inset.value === "none" ? "" : " inset"}`
            : "";
          const offsetAndBlur = `${hOffsetStr} ${vOffsetStr} ${blurStr}`;
          const spreadColorInset = ` ${spreadStr} ${colorStr} ${insetStr}`;
          return offsetAndBlur + spreadColorInset;
        },
      },
    ],

    default: {
      main: { value: "value" },
      "h-offset": { value: 0, unit: "px" },
      "v-offset": { value: 2, unit: "px" },
      blur: { value: 2, unit: "px" },
      spread: { value: 0, unit: "px" },
      color: { value: { r: 0, g: 0, b: 0, a: 0.14 } },
      inset: { value: "none" },
    },
  },
  {
    name: "text-shadow",
    category: ["SHADOW"],
    addMultipleTimes: true,
    addToShorthand: "text-shadow",
    shorthandSeparator: ", ",
    fieldsOrder: ["main", "h-offset", "v-offset", "blur", "color"],

    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [{ id: "value" }, { id: "none" }],
        help:
          "#### text-shadow\n" +
          "Draw one or multiple shadows on the selected element.  \n" +
          "If you select `value` you can define the horizontal (`h-offset`) and vertical (`v-offset`) " +
          "position of the shadow, its `blur` factor and colour.  \n" +
          "If you want to apply **multiple shadows** to the selected element, " +
          "add multiple `text-shadow` properties. " +
          "The one deeper down in the list are drawn on top of the others.  \n" +
          "`text-shadow: none` is, for example, helpful when you want to remove a shadow, " +
          "which is set for a group of elements, for individual elements in the group.",
      },
      {
        name: "h-offset",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "v-offset",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "blur",
        type: EMetaFieldTypes.number,
        onOff: true,
        conditions: {
          greaterOrEqual0: true,
        },
        units: standardUnits,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
    ],

    group: [
      {
        name: "shadow",
        fields: ["main"],
      },
      {
        name: "hOffsetG",
        display: ["h-offset"],
        fields: ["h-offset"],
      },
      {
        name: "vOffset",
        display: ["v-offset"],
        fields: ["v-offset"],
      },
      {
        name: "blurG",
        display: ["blur"],
        fields: ["blur"],
      },
      {
        name: "colorG",
        display: ["color"],
        fields: ["color"],
      },
    ],

    default: {
      main: { value: "value" },
      "h-offset": { value: 5, unit: "px" },
      "v-offset": { value: 5, unit: "px" },
      blur: { value: 5, unit: "px" },
      color: { value: { r: 68, g: 68, b: 68, a: 1 } },
    },
  },
];
