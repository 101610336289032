import { FC } from "react";
import { itemIF } from "../../data/types/item";
import { itemTypes } from "../../lib/meta/getMetas";
import RenderElementAndChildren from "../render/HtmlOrSvgElementAndChildren";
import { getTopSvg } from "../../utils/render/htmlsvg/svg";
import { addBranch } from "../../lib/items/build-tree";
import { snippetIF } from "../../data/types/snippets";

const NoSvg: FC<{ htmlParentClassName?: string }> = ({
  htmlParentClassName,
}) => {
  return (
    <svg
      width="70"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      className={htmlParentClassName || ""}
    >
      <text x="5px" y="15px">
        NO SVG
      </text>
    </svg>
  );
};

/*
SVGs are always standalone units means, the SVG selectors and CSS is rendered within the SVG and not added to the central CSS 
* */
const RenderSvg: FC<{
  svgId?: number;
  htmlParentClassName?: string;
  editSnippet: snippetIF;
}> = ({ svgId, htmlParentClassName, editSnippet }) => {
  const editSvgId = svgId || svgId === 0 ? svgId : editSnippet?.data.editSvgId;
  const svgs = editSnippet?.data?.svgs;
  const editSvg = svgs?.find((s: itemIF) => {
    return s.id === editSvgId;
  });
  if (!editSvg) {
    return <NoSvg htmlParentClassName={htmlParentClassName} />;
  }
  const topSvg = getTopSvg({
    editSvg,
    svgs,
  });

  if (!topSvg) {
    return <NoSvg htmlParentClassName={htmlParentClassName} />;
  }

  const svgBranch = addBranch({ topInBranch: topSvg, items: svgs });
  if (!svgBranch) {
    return <NoSvg htmlParentClassName={htmlParentClassName} />;
  }

  const SvgElement = () => (
    <>
      {svgBranch && (
        <RenderElementAndChildren
          itemType={itemTypes.SVGS}
          item={svgBranch}
          htmlParentClassName={htmlParentClassName || ""}
          editSnippet={editSnippet}
        />
      )}
    </>
  );

  return <SvgElement />;
};
export default RenderSvg;
