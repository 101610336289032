import { FC } from "react";
import { itemIF } from "../../data/types/item";
import { CSS_CATEGORIES, TCSSCategory } from "../../data/css/constants/all";
import { snippetIF } from "../../data/types/snippets";
import { useDivPosition } from "../modules/GetPositionWrapper";
import { SectionTitle } from "../modules/SectionTitle";

const selectCategoryHelp =
  "### Select Category  \n" +
  "Click on a `category` to see the CSS property in this category in the section `Add CSS Property`. CSS properties in `Add CSS Property` can be added to the `selector` which is active in the `Selector Tree`.  \n";

type SelectCssCategoryProps = {
  selectedCategory: TCSSCategory;
  setSelectedCategory: (selectedCategory: TCSSCategory) => void;
  editSelector?: itemIF;
  editSnippet?: snippetIF;
};
export const SelectCssCategory: FC<SelectCssCategoryProps> = ({
  selectedCategory,
  setSelectedCategory,
  editSelector,
  editSnippet,
}) => {
  const { divRef, divPos } = useDivPosition();
  const isDisabled =
    editSnippet?.data?.editSelectorId === 0 || editSelector?.name === "@media";
  const cssCategories = Object.keys(CSS_CATEGORIES) as TCSSCategory[];

  return (
    <>
      <div className="mt-1r mb-1r" ref={divRef}>
        <SectionTitle
          label="Select category"
          help={selectCategoryHelp}
          parentPosition={divPos}
        />
      </div>
      <div className="flex wrap gap-05r">
        {cssCategories.map((name) => (
          <button key={name}
            type="button"
            className={`button ${name === selectedCategory ? "blue" : "gray-outline"}${isDisabled ? " disabled" : ""}`}
            onClick={() => {
              setSelectedCategory(name);
            }}
          >
            {name}
          </button>
        ))}
      </div>
    </>
  );
};
