import React, { FC, useEffect, useRef } from "react";

export const ActionMessage: FC<{
  msg: string;
  setMsg: (msg: string) => void;
}> = ({ msg, setMsg }) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (msg !== "") {
          timeoutRef.current = setTimeout(() => {
            setMsg("");
          }, 3000);
        }
        return () => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [msg]);
    
  return (
    <div className={`info${msg.includes("success") ? " success" : " warning"}`}>
      {msg}
    </div>
  );
};
