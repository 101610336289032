import { metaIF, EMetaFieldTypes } from "../../types/item";

export const svgGradientsHelp =
  "### SVG gradient  \n" +
  "How it works:  \n" +
  "- Add a `gradient` element to any SVG. If you include it in a `defs` tag, you mark it as a resource which can be used in various SVGs.  \n" +
  "- Add at least 2 `stops` as children of the `gradient`. Within a `stop` set a color and the point up to which the color should be painted, for example 'green 50%'.  \n" +
  "- Assign an `Id` to the gradient in `Edit`, attribute `Id`. Assing the gradient to one or more SVG elements: click on the element, on `Edit` and then in `Assign Gradient` on the Id.  \n\n" +
  "If you are looking for general information about SVG gradient, see for example [MDN - Gradients in SVG](https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Gradients)";

const spreadMethodHelp =
  "### spreadMethod  \n" +
  "Choose here the method for repeating the gradient within the boundaries `x1` and `x2`.  \n" +
  "If just `x1` is set, the gradient is repeated twice, once before `x1` and once after.  \n" +
  "If `x1` and `x2` are set, the gradient is repeated as often as it fits in with a step width of `x2` - `x1`. For example, if `x1` is 25% and `x2` 50% the gradient is repeated 4 times.  \n" +
  "The value `repeat` means that the gradient is repeated forward from the first to the last `stop` of the gradient. If `spreadMethod` is set to `reflect`, then the gradient runs from the last to the first stop  and then, reversed, from the first to the last and so on.  \n" +
  "See also [MDN - spreadMethod](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/spreadMethod)";
export const SVG_CONSTANTS_GRADIENTS: metaIF[] = [
  {
    name: "linearGradient",
    level: "block",
    category: ["GRADIENT"],
    allowedChildren: ["title", "stop"],
    fieldsOrder: ["x1", "x2", "y1", "y2", "spreadMethod"],
    help: svgGradientsHelp,
    fields: [
      {
        name: "x1",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "x2",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "y1",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "y2",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "spreadMethod",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "pad",
          },
          {
            id: "reflect",
          },
          {
            id: "repeat",
          },
        ],
        help: spreadMethodHelp,
      },
    ],
    group: [
      {
        name: "x1Y1",
        display: ["x1", "y1"],
        fields: ["x1", "y1"],
        help:
          "### x1 y1 x2 y2  \n" +
          "With x1,y1 and x2,y2 you can draw a line which sets the direction and the length of the linear gradient. The default values are 0% 0% to 100% 0%. If you want to draw a gradient from the left top corner to its center, for example, enter 0% 0% to 50% 50%.  \n" +
          "For more information see for example [MDN - linearGradient](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/linearGradient)",
      },
      {
        name: "x2Y2",
        display: ["x2", "y2"],
        fields: ["x2", "y2"],
      },
      {
        name: "repeat",
        display: ["repeat"],
        fields: ["spreadMethod"],
      },
    ],
    default: {
      x1: {
        value: 0,
        unit: "%",
      },
      y1: {
        value: 0,
        unit: "%",
      },
      x2: {
        value: 100,
        unit: "%",
      },
      y2: {
        value: 0,
        unit: "%",
      },
      spreadMethod: {
        value: "repeat",
      },
    },
  },
  {
    name: "radialGradient",
    level: "block",
    category: ["GRADIENT"],
    allowedChildren: ["title", "stop"],
    fieldsOrder: ["cx", "cy", "r", "fx", "fy", "spreadMethod"],
    help: svgGradientsHelp,
    fields: [
      {
        name: "cx",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "cy",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "r",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "fx",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "fy",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
      },
      {
        name: "spreadMethod",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "pad",
          },
          {
            id: "reflect",
          },
          {
            id: "repeat",
          },
        ],
      },
    ],
    group: [
      {
        name: "cxCy",
        display: ["cx", "cy"],
        fields: ["cx", "cy"],
        help:
          "### radialGradient  \n" +
          "`cx` and `cy` define the `center` of the gradient in %.  \n" +
          "`r` describes how much of the shape, in %, is filled with the gradient. A `radius` of 50% fills the shape in full. With a `radius` of 25% the shape is filled half or, with a repeat `spreadMethod`, repeated 2 times.  \n" +
          "fx and fx set the `focus` point. The `focus` and the `center` point together stretch the gradient center. With `fx` `fy` set to 25% 50%, for example, and `cx` `cy` to 50% 25%, you define a radial gradient in the gradient from 25% 50% to 50% 25%.  \n" +
          "If you move the `focus` point towards the sides of the shape or steepen the line build from `focus` and `center` point, the outer handles from the `focus` point, which build the roundness of the inner gradient, cut the original shape.",
      },
      {
        name: "radius",
        display: ["r"],
        fields: ["r"],
      },
      {
        name: "fxFy",
        display: ["fx", "fy"],
        fields: ["fx", "fy"],
      },
      {
        name: "repeat",
        display: ["repeat"],
        fields: ["spreadMethod"],
      },
    ],
    default: {
      cx: {
        value: 50,
        unit: "%",
      },
      cy: {
        value: 50,
        unit: "%",
      },
      r: {
        value: 50,
        unit: "%",
      },
      fx: {
        value: 50,
        unit: "%",
      },
      fy: {
        value: 50,
        unit: "%",
      },
      spreadMethod: {
        value: "repeat",
      },
    },
  },
  {
    name: "stop",
    level: "inline",
    category: ["GRADIENT"],
    allowedChildren: ["title"],
    fieldsOrder: ["offset", "stop-color", "stop-opacity"],
    fields: [
      {
        name: "offset",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }, { id: "px" }],
        onOff: true,
      },
      {
        name: "stop-color",
        javascript: "stopColor",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
      },
      {
        name: "stop-opacity",
        javascript: "stopOpacity",
        type: EMetaFieldTypes.number,
      },
    ],
    group: [
      {
        name: "offsetG",
        fields: ["offset"],
        display: ["offset"],
      },
      {
        name: "stopColorG",
        fields: ["stop-color"],
        display: ["stop-color"],
      },
      {
        name: "stopOpacityG",
        fields: ["stop-opacity"],
        display: ["stop-opacity"],
      },
    ],
    default: {
      offset: { value: 25, unit: "%" },
      "stop-color": { value: { r: 60, g: 188, b: 195, a: 1 } },
      "stop-opacity": { value: 0.5 },
    },
  },
];
