import { itemIF, EMetaFieldTypes, TItemValue } from "../../data/types/item";
import { getMeta, itemTypes } from "../meta/getMetas";
import { getMetaWithAllFields } from "../meta/getMetaWithAllFields";
import { sortTree2 } from "./sortTree";

export const testDoublePosition = (tree: itemIF[]) => {
  const hasDoublePosition = tree.find((i) => {
    const samePos = tree.filter((t) => t.position === i.position);
    if (samePos.length > 1) {
      return true;
    }
    return false;
  });
  return hasDoublePosition;
};

export type TassignedIdsAndStr = {
  snippetId: string;
  itemId: number;
  str: string;
};

export const sortTemplateSnippets = (snippets: itemIF[]) => {
  let position = 1;
  return snippets.map((i) => {
    const newI = {
      ...i,
      position,
    };
    position += 1;
    return newI;
  });
};

export const moveItemUp = ({
  items,
  editItem,
}: {
  items: itemIF[];
  editItem: itemIF;
}) => {
  const itemsMoved = items.map((i, index) => {
    if (i.parent === editItem.parent && i.position === editItem.position - 1) {
      return {
        ...i,
        position: i.position + 1,
      };
    }
    if (i.id === editItem.id) {
      return {
        ...i,
        position: i.position - 1,
      };
    }
    return i;
  });
  return sortTree2(itemsMoved);
};

export const moveItemDown = ({
  items,
  editItem,
}: {
  items: itemIF[];
  editItem: itemIF;
}) => {
  const itemsMoved = items.map((i, index) => {
    if (i.id === editItem.id) {
      return {
        ...i,
        position: i.position + 1,
      };
    }
    if (i.parent === editItem.parent && i.position === editItem.position + 1) {
      return {
        ...i,
        position: i.position - 1,
      };
    }
    return i;
  });
  return sortTree2(itemsMoved);
};

export const isDescendantOfEditItem = (
  item: itemIF,
  editItem: itemIF,
  tree: itemIF[]
): boolean => {
  if (item.parent === editItem.id) {
    return true;
  }
  if (item.parent && item.parent > 0) {
    const parent = tree.find((i) => i.id === item.parent) || tree[0];
    return isDescendantOfEditItem(parent, editItem, tree);
  }
  return false;
};

export const getNewItemBody = ({
  itemName,
  itemType,
  snippetId,
}: {
  itemName: string;
  itemType: itemTypes;
  snippetId?: string;
}) => {
  const newItem = {
    id: 0,
    level: 1,
    parent: 0,
    position: 1,
    name: itemName,
  } as itemIF;
  if (snippetId) newItem.snippetId = snippetId;
  const meta = getMetaWithAllFields({ itemType, itemName });

  if (meta?.default && meta.fields) {
    const metaDefaultCopy = JSON.parse(JSON.stringify(meta.default));
    newItem.value = metaDefaultCopy;
  }

  const addOrRemoveFieldGroupFields = meta?.fields?.filter(
    (f) => f.type === EMetaFieldTypes.addOrRemoveFieldGroup
  );

  addOrRemoveFieldGroupFields?.forEach((f, index) => {
    const arMeta = getMeta(itemType, f.name);
    if (arMeta?.default) {
      const newArDefaultCopy = JSON.parse(
        JSON.stringify(arMeta.default)
      ) as TItemValue;
      const arNewItem = {
        id: index + 2,
        level: 1,
        parent: newItem.id,
        position: index + 2,
        name: f.name,
        value: newArDefaultCopy,
      };
      const newValue = newItem.value || {};
      const newValueField = newValue[f.name] || {};
      const arNewValue = (newValueField.value || []) as TItemValue[];
      arNewValue.push(arNewItem);

      newItem.value = {
        ...newValue,
        [f.name]: {
          ...newValueField,
          value: arNewValue,
        },
      };
    }
  });

  // add for selectors in the categories "attribute", "pseudo-element", "pseudo" the prefix AMPERSAND
  if (itemType === itemTypes.SELECTORS) {
    let inCategory = false;
    ["attribute", "pseudo-element", "pseudo", "child", "state"].forEach((c) => {
      if (meta?.category.includes(c)) {
        inCategory = true;
      }
    });
    if (inCategory) {
      newItem.prefix = { name: "AMPERSAND" };
    }
  }

  return newItem;
};
