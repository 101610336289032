import { itemIF, metaIF, metaFieldIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { isValueDefault } from "../reducer/items/getItemData";

// if fields are dependent on a certain value of another field, don't display when the value of the other field is not the dependent on value
const getIsDependentFieldDisabled = ({
  fieldMeta,
  item,
  items,
}: {
  fieldMeta: metaFieldIF;
  item: itemIF;
  items?: itemIF[];
}) => {
  if (fieldMeta.dependentOn) {
    const dependentOnValue = getFieldItemValue({
      item,
      items,
      metaName: item.name,
      dependentOnField: fieldMeta.dependentOn.field,
    });
    if (!dependentOnValue) {
      return true;
    }
    if (
      !fieldMeta.dependentOn.values.includes(dependentOnValue) &&
      !fieldMeta.dependentOn.dontDisableDependentOnField
    ) {
      return true;
    }
    return false;
  }

  if (
    fieldMeta.dependentOnValueInOtherField &&
    fieldMeta.dependentOnValueInOtherField.field &&
    fieldMeta.dependentOnValueInOtherField.wrapperFn
  ) {
    if (item.value) {
      const dependentFieldValue =
        item.value && item.value[fieldMeta.dependentOnValueInOtherField.field]
          ? item.value[fieldMeta.dependentOnValueInOtherField.field].value
          : undefined;
      if (dependentFieldValue) {
        const conditionIsMet = fieldMeta.dependentOnValueInOtherField.wrapperFn(
          item.value[fieldMeta.name],
          dependentFieldValue
        );
        if (!conditionIsMet) {
          return true;
        }
      }
    }
  }
  return false;
};

const getFieldItemValue = ({
  item,
  items,
  metaName,
  dependentOnField,
}: {
  item: itemIF;
  items: itemIF[] | undefined;
  metaName: string;
  dependentOnField: string;
}) => {  if (item.value && item.value[dependentOnField]) {
    return item.value[dependentOnField].value;
  }
  const addOrRemoveFieldGroupMainItem = items?.find(
    (item) => item.value && item.value[metaName]
  );
  if (
    addOrRemoveFieldGroupMainItem?.value &&
    addOrRemoveFieldGroupMainItem.value[dependentOnField]
  ) {
    return addOrRemoveFieldGroupMainItem.value[dependentOnField].value;
  }
  if (dependentOnField.endsWith("Select")) {
    return "value";
  }
};

export const getDependendOnDisabledFields = ({
  meta,
  item,
  items,
  disabledFields,
}: {
  meta?: metaIF;
  item: itemIF;
  items: itemIF[] | undefined;
  disabledFields: string[];
}) => {
  if (!meta?.fields) {
    return;
  }
  meta.fields.forEach((fieldMeta: metaFieldIF) => {
    const isDependentFieldDisabled = getIsDependentFieldDisabled({
      fieldMeta,
      item,
      items,
    });
    if (isDependentFieldDisabled && !disabledFields.includes(fieldMeta.name)) {
      disabledFields.push(fieldMeta.name);
    }
  });

  // are fields dependent on disabled fields? -> disable
  meta.fields.forEach((fieldMeta) => {
    if (
      fieldMeta?.dependentOn &&
      disabledFields.includes(fieldMeta.dependentOn?.field)
    ) {
      if (!disabledFields.includes(fieldMeta.name)) {
        disabledFields.push(fieldMeta.name);
      }
    }
  });
};

export const getGroupsToHide = ({
  meta,
  item,
  items,
  hiddenGroups,
}: {
  meta?: metaIF;
  item: itemIF;
  items: itemIF[] | undefined;
  hiddenGroups: string[];
}) => {
  if (meta?.group) {
    meta.group.forEach((g) => {
      if (!g) {
        console.error("error in group meta");
        console.error(g);
        return;
      }

      if (g.dependentOn) {
        const dependentOnValue = getFieldItemValue({
          item,
          items,
          metaName: meta.name,
          dependentOnField: g.dependentOn.field,
        });
        if (!dependentOnValue) {
          hiddenGroups.push(g.name);
          return;
        }
        if (!g.dependentOn.values.includes(dependentOnValue)) {
          hiddenGroups.push(g.name);
        }
        return;
      }
    });
  }
};

const disableFieldInRender = ({
  fieldMeta,
  item,
  firstFieldInGroupMeta,
}: {
  fieldMeta: metaFieldIF;
  item: itemIF;
  firstFieldInGroupMeta?: metaFieldIF;
}) => {
  if (fieldMeta.dontRender) {
    return true;
  }
  if (!item.value || !item.value[fieldMeta.name]) {
    // disable if field has no value
    return true;
  }
  /*
  check if field or the first field in the group has set onOff to true 
  in the item or in the field meta data
  * */

  const itemFieldOnOff = firstFieldInGroupMeta ? item.value[firstFieldInGroupMeta.name].onOff : item.value[fieldMeta.name].onOff;
  const fieldMetaOnOff = firstFieldInGroupMeta ? firstFieldInGroupMeta.onOff : fieldMeta.onOff;
  if (!itemFieldOnOff && !fieldMetaOnOff) {
    return true;
  }
  const isDefault = isValueDefault(item.value[fieldMeta.name], fieldMeta);
  if (isDefault) {
    return true;
  }
  return false;
};

const disableDependentAutoGeneratedField = ({
  meta,
  item,
  disabledFields,
  itemType,
}: {
  meta?: metaIF;
  item: itemIF;
  disabledFields: string[];
  itemType: string;
}) => {
  // example width value 100px -> Select field is value or calculate -> remove Select field
  if (itemType === itemTypes.CLASSES_CSS) {
    meta?.fields?.forEach((fm) => {
      if (disabledFields.includes(fm.name)) {
        return;
      }
      if (fm.name.endsWith("Select")) {
        if (!item.value) {
          return;
        }
        if (
          item.value[fm.name] &&
          (item.value[fm.name].value === "value" ||
            item.value[fm.name].value === "calculate")
        ) {
          if (!disabledFields.includes(fm.name)) {
            disabledFields.push(fm.name);
          }
          return;
        }
      }
    });
  }
};

// if fields are dependentOn another field, disable other field if:
// - field dependentOn does not have the dontDisable
export const disableDependingOnValueDependOnFieldOrField = ({
  meta,
  item,
  disabledFields,
}: {
  meta: metaIF;
  item: itemIF;
  disabledFields: string[];
}) => {
  // example border: all 1px solid blue; -> if the condition for at least one dependent field is fullfilled -> disable dependentOn.field
  /*
    get all dependentOn.fields
    loop through dependentOn fields: get dependentOn.field item.value 
    is value in dependentOn.values of field? 
      yes -> disable dependentOn.field, no -> disable field
    * */
  const dependentOnFields2 = meta?.fields?.reduce((r, fm) => {
    if (!fm) {
      console.error("error in field meta");
      console.error(meta);
      return r;
    }

    if (
      fm.dependentOn?.field &&
      !fm.dependentOn.dontDisableDependentOnField &&
      !r.includes(fm.dependentOn.field)
    ) {
      r.push(fm.dependentOn.field);
    }
    return r;
  }, [] as string[]);

  // metaBorder: dependentOnFields2 = ["main"]
  dependentOnFields2?.forEach((fieldName) => {
    if (item.value && item.value[fieldName]) {
      // borderItem: value of main field is "all"
      const dependentOnFieldValue = item.value[fieldName].value;
      meta?.fields?.forEach((fm) => {
        if (!fm) {
          console.error("error in field meta");
          console.error(meta);
          return;
        }

        if (fm.dependentOn?.field === fieldName) {
          // all fields dependent on the value in "main" to be "all"
          if (fm.dependentOn.values.includes(dependentOnFieldValue)) {
            // allowed value -> disable dependentOn.field
            if (!disabledFields.includes(fieldName)) {
              disabledFields.push(fieldName);
            }
          } else {
            // value is not allowed for field -> disable field
            if (!disabledFields.includes(fm.name)) {
              disabledFields.push(fm.name);
            }
          }
        }
      });
    }
  });
};

export const getFirstFieldInGroupMeta = ({
  fieldName,
  meta,
}: {
  fieldName: string;
  meta: metaIF;
}): metaFieldIF | undefined => {
  const group = meta.group;

  if (!group) {
    return;
  }

  for (const g of group) {
    if (g.fields.includes(fieldName)) {
      const firstFieldInGroup = g.fields[0];
      if (firstFieldInGroup !== fieldName) {
        return meta.fields?.find((f) => f.name === firstFieldInGroup);
      }
    }
  }
};

// are all dependent fields disabled? no -> disable dependentOn.field
export const disableDependentOnFields = ({
  meta,
  item,
  disabledFields,
  itemType,
}: {
  meta: metaIF;
  item: itemIF;
  disabledFields: string[];
  itemType: string;
}) => {
  if (!meta.fields) {
    return;
  }

  // disable in render
  meta.fields.forEach((fm) => {
    if (!fm) {
      console.error("error in field meta");
      console.error(meta);
      return;
    }

    const isDisabled = disableFieldInRender({
      fieldMeta: fm,
      item,
      firstFieldInGroupMeta: getFirstFieldInGroupMeta({
        fieldName: fm.name,
        meta,
      }),
    });

    if (isDisabled) {
      if (!disabledFields.includes(fm.name)) {
        disabledFields.push(fm.name);
      }
    }
  });

  disableDependentAutoGeneratedField({
    meta,
    item,
    disabledFields,
    itemType,
  });

  disableDependingOnValueDependOnFieldOrField({
    meta,
    item,
    disabledFields,
  });
};
