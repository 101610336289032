import { itemTypes, getMeta } from "./getMetas";
import { metaIF, groupIF } from "../../data/types/item";

const clone = require("rfdc")();

// fold metaAll into meta
// if item value is missing the metaAll fields values -> add metaAll field default

const combineMeta = ({
    meta,
    metaAll,
    itemName,
  }: {
    meta: metaIF;
    metaAll: metaIF;
    itemName?: string;
  }): metaIF | undefined => {
    if (!meta && !metaAll) {
      return undefined;
    }
    if (!metaAll) {
      return meta;
    }
    if (!meta) {
      return metaAll;
    }
    const omitFields = itemName
      ? metaAll.fields
          ?.filter((f) => f.notOnItem && f.notOnItem.includes(itemName))
          .map((f) => f.name)
      : [];
  
    const metaCombined: metaIF = clone(meta);
    if (metaAll.fieldsOrder) {
      const checkForOmittedFieldNames = metaAll.fieldsOrder.filter(
        (fieldName) => !omitFields || !omitFields.includes(fieldName)
      );
      const metaFieldsOrder = meta.fieldsOrder ? meta.fieldsOrder : ["main"];
      metaCombined.fieldsOrder = [
        ...metaFieldsOrder,
        ...checkForOmittedFieldNames,
      ];
    }
    if (metaAll.fields) {
      const checkForOmittedFields = metaAll.fields.filter(
        (f) => !omitFields || !omitFields.includes(f.name)
      );
  
      if (meta.fields) {
        metaCombined.fields = [...meta.fields, ...checkForOmittedFields];
      } else {
        metaCombined.fields = checkForOmittedFields;
      }
    }
  
    if (metaAll.group) {
      // if meta doesn't have a group: add a group for each field
  
      const checkForOmmitedFieldsInGroup = metaAll.group.filter((g) => {
        if (
          !omitFields ||
          !omitFields.find((fieldName) => g.fields.includes(fieldName))
        ) {
          return true;
        }
        return false;
      });
  
      if (!meta.group) {
        const newGroup = [] as groupIF[];
        meta.fields?.forEach((f) => {
          newGroup.push({
            name: `${f.name}G`,
            fields: [f.name],
            display: [f.name],
          });
        });
        metaCombined.group = [...newGroup, ...checkForOmmitedFieldsInGroup];
      } else {
        metaCombined.group = [...meta.group, ...checkForOmmitedFieldsInGroup];
      }
    }
    if (metaAll.wrapperForGroup) {
      if (meta.wrapperForGroup) {
        metaCombined.wrapperForGroup = [
          ...meta.wrapperForGroup,
          ...metaAll.wrapperForGroup,
        ];
      } else {
        metaCombined.wrapperForGroup = metaAll.wrapperForGroup;
      }
    }
    if (metaAll.default) {
      if (meta.default) {
        metaCombined.default = { ...meta.default, ...metaAll.default };
      } else {
        metaCombined.default = metaAll.default;
      }
    }
    return metaCombined;
  };
  


// automatically add "ALL" fields with one function call
export const getMetaWithAllFields = ({
    itemType,
    itemName,
  }: {
    itemType: itemTypes;
    itemName: string;
  }) => {
    const meta = getMeta(itemType, itemName);
  
    const metaAll = getMeta(itemType, "ALL");
    if (!meta && !metaAll) {
      return;
    }
    if (!meta) {
      return metaAll;
    }
  
    if(meta.category.includes("addOrRemoveFieldGroup")) {
      return meta;
    }
  
    if (itemType === itemTypes.BLOCK_HTML && itemName === "text") {
      return meta;
    }
    if (!metaAll) {
      return meta;
    }
    const combinedMeta = combineMeta({ meta, metaAll });
    return combinedMeta;
  };
  