import {
  actionIF,
  itemIF,
  itemAttributeChangeIF,
} from "../../../data/types/item";

export const getItemEventChange = ({
  data,
  item,
}: {
  data: itemAttributeChangeIF;
  item: itemIF;
}) => {
  const { fieldName, value, editEventId } = data;
  if (!fieldName) {
    console.warn("No fieldName, we didn't make any changes");
    return item;
  }

  return {
    ...item,
    events: item.events?.map((e) => {
      if (e.id === editEventId) {
        const oldValue =
          e.value && e.value[fieldName] ? e.value[fieldName].value : "";
        const newValue = value === oldValue ? "" : value;
        return {
          ...e,
          value: {
            ...e.value,
            [fieldName]: {
              value: newValue,
            },
          },
        };
      }
      return e;
    }),
  };
};

export const itemEventChange = ({
  action,
  items,
  editItemId,
}: {
  action: actionIF;
  items: itemIF[];
  editItemId?: number;
}) => {
  // if current item is event: change field of the current event

  const itemId = action.editItemId || editItemId;
  if (!itemId) {
    console.warn("No edit item id, we didn't make any changes");
    return items;
  }

  const editItem = items.find((i) => i.id === itemId);
  if (!editItem) {
    console.warn("No edit item, we didn't make any changes");
    return items;
  }
  const { fieldName, value, editEventId } = action;
  const data = {
    fieldName,
    value,
    editItemId: itemId,
    editEventId,
  } as itemAttributeChangeIF;

  const changedItem = getItemEventChange({ data, item: editItem });
  return items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};
