import axios from "axios";

const graphqlEndpoint = process.env.REACT_APP_SNIPPETS_API_URL || "";
const apiKey = process.env.REACT_APP_SNIPPETS_API_KEY || "";

export const deleteSnippetGQL = async ({ id }: { id: string }) => {
  try {
    const graphqlQuery = {
      query: `
      mutation DeleteSnippet($id: ID!) {
        deleteSnippet(id: $id) {
          id
        }
      }
    `,
      variables: { id: id },
    };

    const response = await axios.post(
      graphqlEndpoint,
      JSON.stringify(graphqlQuery),
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      }
    );

    if (response.data.data.deleteSnippet.id) {
      return "Snippet deleted successfully";
    }
  } catch (error) {
    console.error("Error deleting snippet: ", error);
  }
};
