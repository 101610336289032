import React, { useState, useRef, FC, ReactNode, cloneElement } from "react";
import { useOutsideAlerter } from "../atoms/detectClickOutside";
import { ReactComponent as Menu } from "../../assets/imgs/menu.svg";
import { ReactComponent as MoreHorizontally } from "../../assets/imgs/more-horizontal.svg";
import { ReactComponent as MoreVertically } from "../../assets/imgs/more-vertical.svg";
import { ReactComponent as AccountCircle } from "../../assets/imgs/account-face.svg";
import { ReactComponent as Widgets } from "../../assets/imgs/widgets.svg";
import { ReactComponent as SortArrowDown } from "../../assets/imgs/SortArrowDown.svg";

import "./NavbarMenu.css";

type TIcon =
  | "menu"
  | "account"
  | "widgets"
  | "down"
  | "more-vertically"
  | "more-horizontally";

export const OpenMenuButtonAndModal: FC<{
  icon?: TIcon;
  direction?: "left" | "right";
  children: ReactNode;
}> = ({ icon = "menu", direction = "left", children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter({ ref: wrapperRef, setOpen: setIsMenuOpen });

  const childrenWithProps = React.Children.map(children, (child) => {
    if (
      React.isValidElement<{
        setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
      }>(child)
    ) {
      return cloneElement(child, { setIsMenuOpen });
    }
    return child;
  });

  return (
    <div className="open-menu">
      <button
        className="open-sub-menu-btn"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <span className="sr-only">Open menu</span>
        {icon === "account" ? (
          <AccountCircle />
        ) : icon === "widgets" ? (
          <Widgets />
        ) : icon === "down" ? (
          <SortArrowDown />
        ) : icon === "more-horizontally" ? (
          <MoreHorizontally />
        ) : icon === "more-vertically" ? (
          <MoreVertically />
        ) : (
          <Menu />
        )}
      </button>
      {isMenuOpen && (
        <div
          className={`open-submenu-after-btn${direction === "right" ? " right" : ""} mt-05r`}
          ref={wrapperRef}
        >
          {childrenWithProps}
        </div>
      )}
    </div>
  );
};
