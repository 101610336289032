import { useState, useEffect } from "react";
import { useStateContext } from "../../data/main/state";
import { itemIF, itemWithChildrenIF } from "../../data/types/item";
import { setEditItemId } from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { buildTree } from "../../lib/items/build-tree";
import { getSelectorName } from "../../lib/render/getSelectorName";
import { getOpenItemsIds } from "../../utils/open-and-close-branch";
import { RenderTreeButtonAndChildren } from "../forms/EditTree";
import { useScrollIntoView } from "../atoms/useScrollIntoView";

export const SelectorTree = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();
  const editSnippet = snippets.find((s) => s.id === editSnippetId);

  const items = editSnippet?.data?.selectors || ([] as itemIF[]);
  const editSelectorId = editSnippet?.data?.editSelectorId;
  const editItem = items?.find((s: itemIF) => s.id === editSelectorId);

  const [branches, setBranches] = useState([] as itemWithChildrenIF[]);
  useEffect(() => {
    const updateBranches = () => {
      if (!editSnippet || !items) {
        setBranches([]);
      } else {
        const selectorsPlusDisplayName = items.map((s: itemIF) => {
          return {
            ...s,
            name: getSelectorName({
              selector: s,
              selectors: items,
            }),
          } as itemIF;
        });

        const newBranches = buildTree(selectorsPlusDisplayName);

        setBranches(newBranches);
      }
    };
    updateBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSnippet, editSnippet?.updatedAt]);

  const listRef = useScrollIntoView(editItem);

  if (!editSnippet) return <div>No Snippet found</div>;

  const openItemsIds = getOpenItemsIds({
    items,
    item: editItem,
  });

  const itemOnClick = (item: itemIF) => {
    dispatch(
      setEditItemId({ itemType: itemTypes.SELECTORS, newEditItemId: item.id })
    );
  };

  return (
    <div className="tree-table" ref={listRef}>
      <div className="html-tree-container form-elements-wrapper">
        {branches.map((item: itemIF) => {
          return (
            <RenderTreeButtonAndChildren
              key={item.id}
              itemType={itemTypes.SELECTORS}
              item={item}
              editItem={editItem}
              editFn={itemOnClick}
              openItemsIds={openItemsIds}
            />
          );
        })}
      </div>
    </div>
  );
};
