import Markdown  from "react-markdown";
import { termsAndConditions } from "../static/terms-and-conditions";
import MainLayout from "../components/MainLayout";

const Terms = () => (
  <MainLayout title="Terms and Conditions" description="Terms and Conditions">
    <div className="container">
      <Markdown>{termsAndConditions}</Markdown >
    </div>
  </MainLayout>
);

export default Terms;
