import React, { FC, useEffect, useState } from "react";
import { itemIF } from "../../../data/types/item";
import { getSortedAndFoundItems } from "./helpers";
import { ReactComponent as Search } from "../../../assets/imgs/search.svg";
import { SectionTitle } from "../SectionTitle";

export type TSortBy =
  | "name A-Z"
  | "name Z-A"
  | "updated New-Old"
  | "updated Old-New";

export const optionsNameOnly = ["name A-Z", "name Z-A"] as TSortBy[];

export const SearchInputAndSortSelect: FC<{
  options: TSortBy[];
  sort: TSortBy;
  setSort: (sort: TSortBy) => void;
  setFindItem: (findItem: string) => void;
}> = ({ options, sort, setSort, setFindItem }) => {
  return (
    <>
      <div className="flex gap-1 wrap">
        <div className="position-relative">
          <div className="icon-left">
            <Search />
          </div>
          <label className="label">
            <span className="sr-only">Enter search expression</span>

            <input
              type="text"
              placeholder="Enter search expression"
              className="icon-in-input-left"
              onChange={(event) => {
                const containsNotAllowedCharacters =
                  /[!@#$%^&*(),.?":{}|<>]/.test(event.target.value);
                if (!containsNotAllowedCharacters) {
                  setFindItem(event.target.value);
                }
              }}
            />
          </label>
        </div>
        <label className="label">
          <span className="sr-only">Select sort direction</span>
          <select
            title="Sort by"
            value={sort}
            onChange={(e) => {
              const option = e.target.value as TSortBy;
              setSort(option);
            }}
          >
            {options.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </select>
        </label>
      </div>
    </>
  );
};

type SortAndFindItemsProps = {
  allItems: itemIF[];
  title?: string;
  children: React.ReactNode;
};
export const SortAndFindChildren: FC<SortAndFindItemsProps> = ({
  allItems,
  title,
  children,
}) => {
  const [findItem, setFindItem] = useState("");
  const [sort, setSort] = useState("name A-Z" as TSortBy);
  const [sfItems, setSfItems] = useState(allItems);

  useEffect(() => {
    const newItems = getSortedAndFoundItems({
      items: allItems,
      findItem,
      sort,
      getName: (item) => {
        if (item.value?.name) return item.value?.name?.value;
        return item.value?.main.value;
      },
    });
    setSfItems(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, findItem, allItems]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement<{ items?: itemIF[] }>(child)) {
      return React.cloneElement(child, { items: sfItems });
    }
  });

  return (
    <div className="form-elements-wrapper">
      {title && (
        <div className="mb-1r">
          <SectionTitle label={title!} />
        </div>
      )}
      <SearchInputAndSortSelect
        options={optionsNameOnly}
        setFindItem={setFindItem}
        sort={sort}
        setSort={setSort}
      />
      {childrenWithProps}
    </div>
  );
};
