import { metaIF, EMetaFieldTypes } from "../types/item";

export const EVENT_META: metaIF[] = [
  {
    name: "event",
    category: ["EVENT"],
    fieldsOrder: ["type", "mode", "classId"],
    fields: [
      {
        name: "type",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "onclick",
          },
        ],
        onOff: true,
      },
      {
        name: "mode",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "Add",
          },
          {
            id: "Toggle",
          },
          {
            id: "Remove",
          },
        ],
        onOff: true,
      },
      {
        name: "classId",
        type: EMetaFieldTypes.x,
        onOff: true,
      },
    ],
    group: [
      {
        name: "type",
        fields: ["type"],
      },
      {
        name: "mode",
        fields: ["mode"],
      },
      {
        name: "classId",
        fields: ["classId"],
      },
    ],
    default: {
      type: { value: "onclick" },
      mode: { value: "Add" },
      classId: { value: 0 },
    },
  },
];

export const getEventMeta = (name: string) => {
  return EVENT_META.find((a: metaIF) => a.name === name);
};
