import { RgbaColor } from "react-colorful";
import { itemIF } from "../../../data/types/item";

const clone = require("rfdc")();

export const hexToRgba = (hex: string): RgbaColor | null => {
  
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, "");

  // Validate hex string length and characters
  if (![3, 6, 8].includes(hex.length) || !/^[0-9a-fA-F]+$/.test(hex)) {
    return null;
  }

  const expandHex = (shortHex: string): string => {
    return shortHex
      .split("")
      .map((char) => char + char)
      .join("");
  };

  // Expand shorthand hex to full form if necessary
  if (hex.length === 3) {
    hex = expandHex(hex);
  }

  // Convert hex values to RGBA
  const hexToDecimal = (hexValue: string): number => parseInt(hexValue, 16);

  let r, g, b, a;
  if (hex.length === 6) {
    r = hexToDecimal(hex.slice(0, 2));
    g = hexToDecimal(hex.slice(2, 4));
    b = hexToDecimal(hex.slice(4, 6));
    a = 1; // Default alpha value
  } else if (hex.length === 8) {
    r = hexToDecimal(hex.slice(0, 2));
    g = hexToDecimal(hex.slice(2, 4));
    b = hexToDecimal(hex.slice(4, 6));
    a = hexToDecimal(hex.slice(6, 8)) / 255;
  } else {
    return null;
  }

  return { r, g, b, a };
};

export const rgbaToHex = (color?: RgbaColor): string => {
  if(!color) return "#00000000";

  const { r, g, b, a } = color;
  const toHex = (n?: number): string => {
    if (!n) return "00";
    const hex = n.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const alpha = Math.round(a * 255);

  // return ("#" + toHex(alpha) + toHex(r) + toHex(g) + toHex(b)).toUpperCase();
  return ("#" + toHex(r) + toHex(g) + toHex(b) + toHex(alpha)).toUpperCase();
};

export function RGBToHex(r: Number, g: Number, b: Number) {
  let rStr = r.toString(16);
  let gStr = g.toString(16);
  let bStr = b.toString(16);

  if (rStr.length === 1) rStr = "0" + rStr;
  if (gStr.length === 1) gStr = "0" + gStr;
  if (bStr.length === 1) bStr = "0" + bStr;

  return "#" + rStr + gStr + bStr;
}

export function hexToRGB(h: string) {
  let r = "0",
    g = "0",
    b = "0";

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return "rgb(" + +r + "," + +g + "," + +b + ")";
}

export const getUniqueColor = (color: itemIF, colors: itemIF[]): itemIF => {
  const c2 = color.value?.main?.value;

  if (
    colors.find((c) => {
      const c1 = c.value?.main.value;
      if (c1 && c2) {
        if (c1.r === c2.r && c1.g === c2.g && c1.b === c2.b) {
          return true;
        }
      }
      return false;
    })
  ) {
    const newColor = clone(color);
    if (c2.r < 255) {
      newColor.value.main.value.r = c2.r + 1;
      return getUniqueColor(newColor, colors);
    }
    if (c2.g < 255) {
      newColor.value.main.value.g = c2.g + 1;
      return getUniqueColor(newColor, colors);
    }
    if (c2.b < 255) {
      newColor.value.main.value.b = c2.b + 1;
      return getUniqueColor(newColor, colors);
    }
    newColor.value.main.value.r = c2.r - 1;
    return getUniqueColor(newColor, colors);
  }
  return color;
};
