import { itemIF } from "../../data/types/item";
import { itemTypes, getMeta } from "../../lib/meta/getMetas";
export const copyIsDisabled = (editSvg?: itemIF) => {
  if (!editSvg) {
    return true;
  }
  const meta = getMeta(itemTypes.SVGS, editSvg.name);
  if (meta?.conditions) {
    if (meta.conditions.first) {
      return true;
    }
    if (meta.conditions.last) {
      return true;
    }
  }
  return false;
};

export const getAllChildrenOfTopSvg = ({
  svg,
  svgs,
  allChildren,
}: {
  svg: itemIF;
  svgs?: itemIF[];
  allChildren: itemIF[];
}) => {
  if (svgs) {
    svgs
      .filter((s) => s.parent === svg.id)
      .forEach((c) => {
        allChildren.push(c);
        getAllChildrenOfTopSvg({ svg: c, svgs, allChildren });
      });
  }
};
