import {
    CognitoIdentityProviderClient,
    UpdateUserAttributesCommand,
  } from "@aws-sdk/client-cognito-identity-provider";
  import { getAccessToken } from "../signinup/handleTokens";
  
  const client = new CognitoIdentityProviderClient({ region: 'eu-west-2' });
  
  export const updateEmail = async (newEmail: string) => {
    try {
      // Get the user's access token (authenticated user)
      const AccessToken = await getAccessToken();
  
      // Set up the request to update the user's email
      const command = new UpdateUserAttributesCommand({
        AccessToken,
        UserAttributes: [
          { Name: 'email', Value: newEmail }, // Update email
        ],
      });
  
      const response = await client.send(command);
  
      // Check if the response indicates success
      if (response.$metadata.httpStatusCode === 200) {
        console.log('Email update initiated. Please verify the new email.');
      }
    } catch (error) {
      console.error('Error updating email:', error);
    }
  };
  