import { FC } from "react";
import { snippetIF } from "../../data/types/snippets";
import { EMetaFieldTypes } from "../../data/types/item";
import { useStateContext } from "../../data/main/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { getSetValueFn } from "../../lib/form/getSetValueFn";
import { getLocalDateAndTime } from "../../lib/dates/format";
import { MainEditField } from "./MainEditField";
import { useDivPosition } from "../modules/GetPositionWrapper";
import { PublishUnpublishBtn } from "../cloud/PublishUnpublishSnippetBtn";
import { changeSnippet } from "../../lib/reducer/snippet/actions";
import { StringInput } from "./StringField";

export const EditSnippet: FC<{ snippet: snippetIF; isDisabled?: boolean }> = ({
  snippet,
  isDisabled,
}) => {
  const { dispatch } = useStateContext();
  const { divRef, divPos } = useDivPosition();

  return (
    <>
      <div className="form-elements-wrapper">
        <div className="mb-1r">
          <label className={`label${isDisabled ? " disabled" : ""}`}>
            <span>name</span>
            <StringInput
              fieldMeta={{
                name: "name",
                type: EMetaFieldTypes.string,
                onOff: true,
              }}
              fieldValue={snippet.name}
              setValue={(value: string) => {
                dispatch(
                  changeSnippet({
                    fieldName: "name",
                    value,
                  })
                );
              }}
              isFieldDisabled={isDisabled}
            />
          </label>
        </div>

        <div  className="flex mb-1r" ref={divRef}>
          <PublishUnpublishBtn divPos={divPos} isDisabled={isDisabled} />
        </div>

        <div className="flex">
          <label className="label">
            <span>updated at</span>
            <MainEditField
              key="updatedAt"
              fieldMeta={{
                name: "updatedAt",
                type: EMetaFieldTypes.readonly,
                onOff: true,
              }}
              valueObj={{
                value: getLocalDateAndTime(snippet.updatedAt!),
              }}
              isFieldDisabled={true}
              setValueFn={getSetValueFn({
                itemType: itemTypes.SNIPPET,
                fieldName: "updatedAt",
                dispatch,
              })}
            />
          </label>
        </div>

        <div className="flex mt-1r">
          <label className={`label${isDisabled ? " disabled" : ""}`}>
            <span>description</span>
            <MainEditField
              key="description"
              fieldMeta={{
                name: "description",
                type: EMetaFieldTypes.textarea,
                onOff: true,
              }}
              valueObj={{ value: snippet.description }}
              setValueFn={getSetValueFn({
                itemType: itemTypes.SNIPPET,
                fieldName: "description",
                dispatch,
              })}
              isFieldDisabled={isDisabled}
            />
          </label>
        </div>
      </div>
    </>
  );
};

export const EditItemSnippet = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();
  const editSnippet = snippets.find((snippet) => snippet.id === editSnippetId);
  if (!editSnippet) {
    return null;
  }
  return <EditSnippet snippet={editSnippet} />;
};
