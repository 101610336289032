import { itemIF, itemValueIF, metaIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";
import { EMetaFieldTypes } from "../../data/types/item";

export type TMaxIdAndPos = {
  maxId: number;
  maxPos?: number;
};

export const resetIdAndPos = ({
  item,
  maxIdAndPos,
}: {
  item: itemIF;
  maxIdAndPos: TMaxIdAndPos;
}) => {
  const copyItem = {
    ...item,
    id: maxIdAndPos.maxId,
  };
  maxIdAndPos.maxId += 1;
  if (maxIdAndPos.maxPos && copyItem.level === 1) {
    copyItem.position = maxIdAndPos.maxPos;
    maxIdAndPos.maxPos += 1;
  }
  return copyItem;
};

export enum EFieldType {
  selectPathId = "selectPathId",
  assignSvgGradientId = "assignSvgGradientId",
  assignSvgFilterId = "assignSvgFilterId",
  inputInLabelId = "inputInLabelId",
  otherAnimationId = "otherAnimationId",
  selectKeyframeName = "selectKeyframeName",
}

export const getAssignedIdFieldType = (
  fieldType: EMetaFieldTypes
): EFieldType | undefined => {
  return fieldType === "selectKeyframeName"
    ? EFieldType.selectKeyframeName
    : fieldType === "otherAnimationId"
      ? EFieldType.otherAnimationId
      : fieldType === "inputInLabelId"
        ? EFieldType.inputInLabelId
        : fieldType === "assignSvgFilterId"
          ? EFieldType.assignSvgFilterId
          : fieldType === "assignSvgGradientId"
            ? EFieldType.assignSvgGradientId
            : fieldType === "selectPathId"
              ? EFieldType.selectPathId
              : (undefined as EFieldType | undefined);
};

export const addSnippetIdToAttributeValues = ({
  item,
  meta,
}: {
  item: itemIF;
  meta: metaIF;
}) => {
  const newValue = { ...item.value } as itemValueIF;
  if (meta.fields) {
    meta.fields.forEach((fieldMeta) => {
      if (!fieldMeta) {
        return;
      }
      const isAssignIdField = getAssignedIdFieldType(fieldMeta.type);
      if (!isAssignIdField && fieldMeta.name !== "id") {
        return;
      }
      const fieldName = fieldMeta.name;
      if (
        newValue[fieldName] &&
        newValue[fieldName].value !== "" &&
        newValue[fieldName].value !== 0
      ) {
        if (newValue[fieldName].onOff || newValue[fieldName].onOff !== false) {
          newValue[fieldName] = {
            ...newValue[fieldName],
            value: newValue[fieldName].value,
          };
        }
      }
    });
  }

  const newItem = {
    ...item,
    value: newValue,
  };
  return newItem;
};

export const getAllAssignedIdsInSnippet = ({
  snippet,
  editItemId,
  isSpecialFieldType,
}: {
  snippet?: snippetIF;
  editItemId?: number;
  isSpecialFieldType?: EFieldType;
}) => {
  // find all fields with fieldName 'id' and onOff set to true

  const assignedIds = [] as string[];
  if (!snippet) {
    return assignedIds;
  }

  if (isSpecialFieldType === EFieldType.selectKeyframeName) {
    snippet.data.selectors
      .filter((s) => s.name === "@keyframes")
      .forEach((s) => {
        if (s.value?.main?.value) {
          assignedIds.push(s.value.main.value);
        }
      });
  }

  if (isSpecialFieldType === EFieldType.otherAnimationId) {
    snippet.data.svgs.forEach((a) => {
      if (
        a.name === "animate" &&
        a.value?.id?.onOff &&
        a.value.id.value !== "" &&
        a.value.id.value !== 0 &&
        a.id !== editItemId
      ) {
        assignedIds.push(a.value.id.value);
      }
    });
  }

  if (isSpecialFieldType === EFieldType.selectPathId) {
    // display ids of all SVG pathes within the snippet
    snippet.data.svgs.forEach((svg) => {
      if (svg.value?.id?.onOff && svg.value.id.value && svg.id !== editItemId) {
        assignedIds.push(svg.value.id.value);
      }
    });
  }

  if (isSpecialFieldType === EFieldType.assignSvgGradientId) {
    // show ids on svg gradients in current snippet
    snippet.data.svgs.forEach((svg) => {
      if (svg.name.includes("Gradient")) {
        if (svg.value?.id?.onOff && svg.value.id.value) {
          assignedIds.push(svg.value.id.value);
        }
      }
    });
  }

  if (isSpecialFieldType === EFieldType.assignSvgFilterId) {
    snippet.data.svgs.forEach((svg) => {
      if (svg.name === "filter") {
        if (svg.value?.id?.onOff && svg.value.id.value) {
          assignedIds.push(svg.value.id.value);
        }
      }
    });
  }

  if (isSpecialFieldType === EFieldType.inputInLabelId) {
    const labelCanBeDefinedFor = [
      { name: "input" },
      { name: "meter" },
      { name: "progress" },
      { name: "select" },
      { name: "textarea" },
    ];
    snippet.data.htmls.forEach((h) => {
      if (
        labelCanBeDefinedFor.find((i) => i.name === h.name) &&
        h.id !== editItemId &&
        h.value?.id?.onOff &&
        h.value.id.value
      ) {
        if (!assignedIds.includes(h.value.id.value)) {
          assignedIds.push(h.value.id.value);
        }
      }
    });
  }
  return assignedIds;
};
