import { metaIF, EMetaFieldTypes } from "../../types/item";

export const keyframesHelp =
  "### keyframes and animations  \n\n" +
  "In the steps of a `keyframe` you describe the behavior of the animation by adding CSS properties to each step, for example a change of colour from green to blue.  \n\n" +
  "You give the `keyframe` a name. This name can be selected in `animations`.  \n\n" +
  "Define within the animation how the behaviour in the keyframe's steps should be applied to the HTML element. You can set the timeline, declare how often the animation should be run through ...  \n\n" +
  "You can add an animation as child of a class in the Selector Tree. When you assign the class to an HTML element, the animation will run on or in the element.  \n\n" +
  "To learn about animation and keyframe in general, see for example [w3schools - CSS - Animations](https://www.w3schools.com/css/css3_animations.asp)";

export const SELECTORS_ANIMATION: metaIF[] = [
  {
    name: "@keyframes",
    level: "block",
    category: ["animation"],
    availableOnlyLevel: 1,
    allowedChildren: ["step"],
    prefix: "@keyframes ",

    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.string,
        help: keyframesHelp,
        onOff: true,
      },
    ],
    default: {
      main: {
        value: "spin",
      },
    },
  },
  {
    name: "step",
    level: "inline",
    category: ["animation"],
    allowedParent: ["@keyframes"],
    allowedSiblings: ["step"],
    allowedChildren: [],
    addMultipleTimes: true,
    fieldsOrder: ["step"],
    prefix: "step ",
    fields: [
      {
        name: "step",
        type: EMetaFieldTypes.number,
        units: [{ id: "%" }],
        onOff: true,
        help: keyframesHelp,
      },
    ],
    default: {
      step: {
        value: 100,
        unit: "%",
      },
    },
  },
];
