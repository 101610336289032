import { RGBColor } from "react-color";
import {
  EMetaFieldTypes,
  TValueType,
  conditionIF,
} from "../../data/types/item";
import { itemTypes } from "../../lib/meta/getMetas";
import { changeSnippet } from "../../lib/reducer/snippet/actions";
import { setNewItemValue } from "../../lib/reducer/items/actions";

export type TGetSetValueFnParams = {
  addOrRemoveEditItemId?: number;
  conditions?: conditionIF;
  dispatch: React.Dispatch<any>;
  editItemId?: number;
  fieldGroupName?: string;
  fieldName?: string;
  itemType?: itemTypes;
  editSnippetIdOther?: string;
};

export type TGetSetValueFn = {
  (type: EMetaFieldTypes.colourPicker): (value: RGBColor) => void;
  (
    type: EMetaFieldTypes.string,
    valueType?: TValueType
  ): (value: string) => void;
  (
    type: EMetaFieldTypes.boolean,
    valueType?: TValueType
  ): (value: boolean) => void;
  (type: EMetaFieldTypes.number): (value: number) => void;
};

export const getSetValueFn = ({
  addOrRemoveEditItemId,
  conditions,
  editItemId,
  fieldGroupName,
  fieldName,
  itemType,
  editSnippetIdOther,
  dispatch,
}: TGetSetValueFnParams): TGetSetValueFn => {

  return ((type: EMetaFieldTypes, valueType?: TValueType) => {
    if (type === EMetaFieldTypes.boolean) {
      return (value: boolean) => {
        if (itemType === itemTypes.SNIPPET) {
          dispatch(
            changeSnippet({
              fieldName,
              value,
              editSnippetId: editSnippetIdOther,
            })
          );
          return;
        }
        dispatch(
          setNewItemValue({
            addOrRemoveEditItemId,
            editItemId,
            fieldGroupName,
            fieldName,
            itemType,
            value,
            valueType,
            editSnippetId: editSnippetIdOther
          })
        );
      };
    }

    if (type === EMetaFieldTypes.colourPicker) {
      return (value: RGBColor) => {
        dispatch(
          setNewItemValue({
            addOrRemoveEditItemId,
            editItemId,
            fieldGroupName,
            fieldName,
            itemType,
            value,
            editSnippetId: editSnippetIdOther
          })
        );
      };
    }

    if (type === EMetaFieldTypes.number) {
      return (value: number) => {
        if (conditions) {
          if (conditions.min || conditions.min === 0) {
            if (value && value < conditions.min) {
              return;
            }
          }
          if (conditions.max || conditions.max === 0) {
            if (value > conditions.max) {
              return;
            }
          }
        }
        dispatch(
          setNewItemValue({
            addOrRemoveEditItemId,
            editItemId,
            fieldGroupName,
            fieldName,
            itemType,
            value,
            editSnippetId: editSnippetIdOther
          })
        );
      };
    }

    return (value: string) => {
      if (itemType === itemTypes.SNIPPET) {
        dispatch(
          changeSnippet({
            fieldName,
            value,
            editSnippetId: editSnippetIdOther,
          })
        );
      } else {
        dispatch(
          setNewItemValue({
            addOrRemoveEditItemId,
            editItemId,
            fieldGroupName,
            fieldName,
            itemType,
            value,
            valueType,
            editSnippetId: editSnippetIdOther
          })
        );
      }
    };
  }) as TGetSetValueFn;
};
