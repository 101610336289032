import { TObject, itemIF, metaIF, metaFieldIF } from "../../../data/types/item";
import { itemTypes } from "../../meta/getMetas";
import { snippetDataIF } from "../../../data/types/snippets";

export const getEditItemDataStr = ({
  itemType,
}: {
  itemType: itemTypes;
}): keyof snippetDataIF => {
  return itemType === itemTypes.STYLEPACK ? "editStylepackId" : itemType === itemTypes.BLOCK_HTML
    ? "editHtmlId"
    : itemType === itemTypes.SELECTORS
      ? "editSelectorId"
      : itemType === itemTypes.CLASSES_CSS
        ? "editPropertyId"
        : "editSvgId";
};

export const getItemsDataStr = (itemType: itemTypes): keyof snippetDataIF => {
  const dataStr = itemType === itemTypes.STYLEPACK ? "stylepacks" :
    itemType === itemTypes.BLOCK_HTML
      ? "htmls"
      : itemType === itemTypes.SELECTORS
        ? "selectors"
        : itemType === itemTypes.CLASSES_CSS
          ? "properties"
          : "svgs";
  return dataStr;
};

export const isValueDefault = (
  valueObj: TObject,
  fieldMeta: metaFieldIF
): boolean => {
  if (fieldMeta.default) {
    let countDefault = 0,
      countEqual = 0;
    Object.keys(fieldMeta.default).forEach((d) => {
      if (fieldMeta.default && fieldMeta.default[d] === valueObj[d]) {
        countEqual += 1;
      }
      countDefault += 1;
    });
    if (countDefault === countEqual) {
      return true;
    }
  }
  return false;
};

const getRgbaString = (obj: TObject) =>
  `rgba(${obj.r},${obj.g},${obj.b},${obj.a})`;

export const getGroupValue = ({
  fieldName,
  meta,
  item,
  items,
  attr,
}: {
  fieldName: string;
  meta?: metaIF;
  item: itemIF;
  items: itemIF[] | undefined;
  attr: TObject;
}) => {
  if (!item.value) return;
  const fmG = meta?.group?.find((f) => f?.name === fieldName);
  if (fmG && fmG.wrapperFn && item.value) {
    const noValue = fmG.fields?.find((name) => item.value && !item.value[name]);
    if (!noValue) {
      attr[fieldName] = fmG.wrapperFn(item.value);
    }
  }
  const wgm = meta?.wrapperForGroup?.find((f) => f?.name === fieldName);
  if (wgm && wgm.wrapperFn && item.value) {
    try {
      const result = wgm.wrapperFn(item.value, items);
      if (result) {
        attr[fieldName] = result;
      }
    } catch (err) {
      console.error(err);
    }
  }
};

export const getValueStr = ({ valObj }: { valObj: TObject }) => {
  if (!valObj || valObj.value === false) {
    return "";
  }

  const unit = valObj
    ? valObj.unit === "s"
      ? "s"
      : valObj.unit && valObj.value !== 0 && valObj.value !== "0"
        ? valObj.unit
        : ""
    : "";

  if (valObj.value === true || valObj.value === false) {
    return valObj.value;
  }

  return valObj
    ? valObj.value || valObj.value === 0
      ? valObj.value.r || valObj.value.r === 0
        ? getRgbaString(valObj.value)
        : `${valObj.value}${unit}`
      : ""
    : "";
};

export const getDependentOnOptionDisplayName = ({
  fieldMeta,
  meta,
}: {
  fieldMeta?: metaFieldIF;
  meta: metaIF;
}) => {
  if(!fieldMeta) return;
  if (fieldMeta.dependentOn?.field) {
    const dependentOnField = meta.fields?.find(
      (f) => f.name === fieldMeta.dependentOn?.field
    );
    if (dependentOnField?.options) {
      const option = dependentOnField.options.find(
        (o) => o.id === fieldMeta.name
      );
      if (option?.display) {
        return option.display;
      }
    }
  }
};

export const getFieldName = ({
  fieldMeta,
  item,
}: {
  fieldMeta?: metaFieldIF;
  item: itemIF;
}) => {
  if (!fieldMeta) {
    return item.name;
  }
  if (fieldMeta.javascript) {
    return fieldMeta.javascript;
  }

  if (fieldMeta.useValueAsKeyOfField && item.value) {
    return item.value[fieldMeta.name].value;
  }
  return fieldMeta.name === "main" ? item.name : fieldMeta.name;
};

export const getGroupAttributeName = ({
  groupName,
  meta,
}: {
  groupName: string;
  meta: metaIF;
}) => {
  if (meta.group) {
    const group = meta.group.find((g) => g.name === groupName);
    if (group) {
      if (group.javascript) {
        return group.javascript;
      }
      return groupName;
    }
  }
  if (meta.wrapperForGroup) {
    const group = meta.wrapperForGroup.find((g) => g.name === groupName);
    if (group) {
      if (group.javascript) {
        return group.javascript;
      }
      return groupName;
    }
  }
};
