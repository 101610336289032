import { metaIF, EMetaFieldTypes } from "../../types/item";

export const CSS_PROPERTIES_LIST: metaIF[] = [
  {
    name: "list-style-position",
    category: ["LIST"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "outside",
          },
          {
            id: "inside",
          },
        ],
        help:
          "### list-style-position  \n" +
          "Choose if the bullet point should be `inside` or `outside` of the outer box of the `li` element.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["list-style-position"],
      },
    ],
    default: {
      main: {
        value: "inside",
      },
    },
  },
  {
    name: "list-style",
    category: ["LIST"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "disc",
          },
          {
            id: "armenian",
          },
          {
            id: "circle",
          },
          {
            id: "cjk-ideographic",
          },
          {
            id: "decimal",
          },
          {
            id: "decimal-leading-zero",
          },
          {
            id: "georgian",
          },
          {
            id: "hebrew",
          },
          {
            id: "hiragana",
          },
          {
            id: "hiragana-iroha",
          },
          {
            id: "katakana",
          },
          {
            id: "katakana-iroha",
          },
          {
            id: "lower-alpha",
          },
          {
            id: "lower-greek",
          },
          {
            id: "lower-latin",
          },
          {
            id: "lower-roman",
          },
          {
            id: "none",
          },
          {
            id: "square",
          },
          {
            id: "upper-latin",
          },
          {
            id: "upper-roman",
          },
        ],
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["list-style"],
      },
    ],
    default: {
      main: {
        value: "none",
      },
    },
  },
];
