import { actionIF } from "../../../data/types/item";
import { settingsIF } from "../../../data/settings/constants";
import {
  SET_IS_LOADING,
  SELECT_SUB_PAGE,
  SET_CURRENT_USER,
  SELECT_CATEGORY,
  SET_RENDERED_HTML_STRING,
  SETTINGS_SET_SCROLLPROPS,
  USER_DATA_IN_SETTINGS,
} from "./actions";

export const settingsReducer = ({
  settings,
  action,
}: {
  settings: settingsIF;
  action: actionIF;
}): settingsIF | undefined => {
  try {
    switch (action.type) {
      case USER_DATA_IN_SETTINGS:
        if (!action.userData) {
          return settings;
        }
        return {
          ...settings,
          user: action.userData,
        };

      case SET_IS_LOADING:
        return {
          ...settings,
          isLoading: action.isLoading,
        };

      case SELECT_CATEGORY:
        if (!action.categoryName) {
          console.warn("SELECT_CATEGORY no categoryName is defined");
          return settings;
        }
        return {
          ...settings,
          selectedCategory: action.categoryName,
        };

      case SELECT_SUB_PAGE:
        if (!action.subPage) {
          console.warn("SELECT_SUB_PAGE no subPage is defined");
          return settings;
        }
        return {
          ...settings,
          selectedSubPage: action.subPage,
        };

      case SET_CURRENT_USER:
        if (!action.userId) {
          console.warn("SET_CURRENT_USER no userId is defined");
          return settings;
        }
        return {
          ...settings,
          user: {
            ...settings.user,
            id: action.userId,
          },
        };

      case SET_RENDERED_HTML_STRING:
        return {
          ...settings,
          renderedHtml: action.renderedHtml,
        };

      case SETTINGS_SET_SCROLLPROPS:
        return {
          ...settings,
          scrollPos: action.scrollPos,
        };

      default:
    }
  } catch (error) {
    console.error("settingsReducer error", error);
  }
};
