import { itemIF } from "../data/types/item";

const addDescendantIds = ({
  item,
  items,
  itemsInBranch,
}: {
  item: itemIF;
  items: itemIF[];
  itemsInBranch: number[];
}) => {

  const parent = items.find((i) => i.id === item.parent);
  if (parent) {
    if (parent.level && item.level && parent.level >= item.level) {
      console.log("ERROR: parent is on lower or equal level, it shouldn't ", item, " ", parent);
    }
    itemsInBranch.push(parent.id);
    addDescendantIds({
      item: parent,
      items,
      itemsInBranch,
    });
  }
};

export const getOpenItemsIds = ({
  items,
  item,
}: {
  items?: itemIF[];
  item?: itemIF;
}) => {

  // console.log("getOpenItemsIds ", item, " ", items);
  // all branches but the branch with the edit item should be closes
  if (!item || !items) {
    return [];
  }
  const itemsInBranch = [item.id];

  if (item.id === item.parent) {
    return itemsInBranch;
  }

  addDescendantIds({ item, items, itemsInBranch });
  return itemsInBranch;
};
