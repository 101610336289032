import { useState, FC, useEffect } from "react";
import { useStateContext } from "../../data/main/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { itemIF } from "../../data/types/item";
import { TCSSCategory } from "../../data/css/constants/all";
import { setEditItemId } from "../../lib/reducer/items/actions";
import { useDivPosition } from "../modules/GetPositionWrapper";
import { DisplayHideChildren2 } from "../modules/DisplayOrHideSubs2";
import { EditItem } from "../forms/EditItem";
import { MoveOrDeleteSelector } from "./MoveOrDeleteSelector";
import { SelectorTree } from "./SelectorTree";
import { AddConnectorBtns } from "./AddConnectorButtons";
import { SelectCssCategory } from "./SelectCategory";
import { AddCssProperty } from "./AddProperty";
import { MoveOrDeleteCssPropsOfSelector } from "./MoveOrDeleteProps";
import { SimpleSelectorTree } from "./SelectorTreeSimple";
import { AddSelectorBoxWithButtons } from "./AddSelector";
import { ImportProperties } from "./ImportProperties";
import { TSelOrProp } from "../navbar/MidNav";
import { SectionTitle } from "../modules/SectionTitle";

const selectorHelpText = `### Selector Tree   \n 
  How to build a selector tree (with some restrictions to get into healthy CSS habits):  \n
  - Go to the **Add** tab to add selectors.  \n
  - On level 1 there must be a "class", "@media" or "@keyframes" selector.  \n
  - To build more targeted rules, add further selectors as children.  \n
  - From level 2 onwards, you can fine tune the relationship between parent and child in the **Add** tab via connectors.  \n
  - You'll see a certain selector in the code preview only, when it (or one of its namesakes or ancestors or namesakes of the ancestors) is assigned to a HTML and has CSS properties.`;

const CRUDCSS: FC<{ selOrProp: TSelOrProp }> = ({ selOrProp }) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const [selectedCategory, setSelectedCategory] = useState(
    "POSITION" as TCSSCategory
  );

  const selectors = editSnippet?.data?.selectors || ([] as itemIF[]);
  const editSelectorId = editSnippet?.data?.editSelectorId;
  const editSelector = selectors?.find((s: itemIF) => s.id === editSelectorId);
  const editPropertyId = editSnippet?.data?.editPropertyId;

  const properties = editSnippet?.data?.properties || [];

  const propsOfClass =
    (properties?.filter((p) => p.classId === editSelectorId) as itemIF[]) || [];
  const sortedItems = [...propsOfClass].sort((a, b) => a.position - b.position);

  const editProperty = sortedItems?.find(
    (i: itemIF) => i.id === editSnippet?.data?.editPropertyId
  );

  useEffect(() => {
    if (!editProperty && sortedItems?.[0]) {
      dispatch(
        setEditItemId({
          itemType: itemTypes.CLASSES_CSS,
          newEditItemId: sortedItems[0].id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSnippet?.data?.properties]);

  const { divRef, divPos } = useDivPosition();
  if (!editSnippet) return null;

  return (
    <div className="form-elements-wrapper mb-2r">
      {selOrProp === "SELECTOR" && (
        <DisplayHideChildren2 titles={["Tree", "Add", "Edit", "Import"]}>
          <>
            <SectionTitle
              label="Select, move, clone or delete selector"
              size="large"
            />
            <div className="mt-1r pr-3r">
              <SectionTitle label="Move, clone or delete" />
            </div>

            <div className="mt-1r">
              <MoveOrDeleteSelector items={selectors} editItem={editSelector} />
            </div>
            <div className="mt-1r" ref={divRef}>
              <SectionTitle
                label="Selector tree"
                help={selectorHelpText}
                direction="top-right"
                parentPosition={divPos}
              />
            </div>

            <SelectorTree />
          </>
          <>
            <div className="mb-1r">
              <SectionTitle label="Add selector" size="large" />
            </div>
            <AddConnectorBtns editSelector={editSelector} />
            <AddSelectorBoxWithButtons
              selectors={selectors}
              editSelector={editSelector}
            />
          </>

          <EditItem itemType={itemTypes.SELECTORS} editItem={editSelector} />

          <ImportProperties />
        </DisplayHideChildren2>
      )}

      {selOrProp === "PROPERTY" && (
        <DisplayHideChildren2 titles={["Tree", "Add", "Edit"]}>
          <>
            <div className="mb-1r">
              <SectionTitle
                label="Select, move, copy or delete CSS properties"
                size="large"
              />
            </div>
            <div className="mb-1r">
              <MoveOrDeleteCssPropsOfSelector
                items={sortedItems}
                editItem={editProperty}
                editSelectorId={editSelectorId}
                editPropertyId={editPropertyId}
              />
            </div>
            <div className="mb-1r">
              <SectionTitle label="CSS properties" />
            </div>

            <SimpleSelectorTree
              propsOfClass={sortedItems}
              editProp={editProperty}
            />
          </>
          <>
          <div className="mb-1r">
              <SectionTitle
                label="Add CSS properties"
                size="large"
              />
            </div>

            <SelectCssCategory
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              editSelector={editSelector}
              editSnippet={editSnippet}
            />
            <AddCssProperty
              items={sortedItems}
              editSelector={editSelector}
              selectedCategory={selectedCategory}
            />
          </>
          <div className="form-elements-wrapper">
            <EditItem
              itemType={itemTypes.CLASSES_CSS}
              editItem={editProperty}
            />
          </div>
        </DisplayHideChildren2>
      )}
    </div>
  );
};

export default CRUDCSS;
