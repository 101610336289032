import { FC } from "react";
import { snippetIF } from "../../data/types/snippets";
import { ReactComponent as GalleryIcon } from "../../assets/imgs/gallery.svg";
import { ReactComponent as Path } from "../../assets/imgs/arrow-right.svg";

import "./Breadcrumb.css";

export const GalleryBreadcrumb: FC<{
    setSelectedSnippet: (selectedSnippet?: snippetIF) => void;
  }> = ({ setSelectedSnippet }) => {
    return (
      <div className="breadcrumb-wrapper">
        <div className="breadcrumb-nav">
          <ol className="ol">
            <li className="li">
              <a
                className="a link"
                href="#home"
                onClick={(e) => {
                  setSelectedSnippet(undefined);
                }}
              >
                <GalleryIcon className="svg" />
                Gallery
              </a>
            </li>
            <li className="li">
              <Path className="svg path" />
              <a className="a" href="#home" onClick={(e) => e.preventDefault()}>
                Preview
              </a>
            </li>
          </ol>
        </div>
      </div>
    );
  };
  