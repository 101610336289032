import { metaIF } from "../../types/item";

export const SELECTORS_STATE: metaIF[] = [
  {
    name: ":active",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :active  \n" +
      "See for example [w3schools - CSS Links](https://www.w3schools.com/css/css_link.asp)",
  },
  {
    name: ":link",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :link  \n" +
      "See for example [w3schools - CSS Links](https://www.w3schools.com/css/css_link.asp)",
  },
  {
    name: ":visited",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :visited  \n" +
      "See for example [w3schools - CSS Links](https://www.w3schools.com/css/css_link.asp)",
  },
  {
    name: ":checked",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :checked  \n" +
      "Selects checked radio buttons (`input` with type `radio`), checkboxes (`input` with type `checkbox`) and options (`option` within a `select`).  \n" +
      'For example: `<input type="checkbox" id="buy"/><label for="buy">Click to buy</label>`. You can style the label dependent on the state of the checkbox with `input:checked + label { background: red }`.',
  },
  {
    name: ":disabled",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :disabled  \n" +
      'Selects disabled elements. For example `<input type="text" disabled />` can be styles with `input:disabled { opacity: 0.65; cursor: not-allowed; }`.',
  },
  {
    name: ":enabled",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :enabled  \n" +
      "Selects enabled elements. An element is enabled if it can be activated (selected, clicked on, typed into, etc.) or accept focus.  \n" +
      'For example `<input type="text" />` can be styles with `input:enabled { opacity: 1; cursor: pointer; }`.',
  },
  {
    name: ":empty",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :empty  \n" +
      "Selects elements with no child element and no text nodes.  \n" +
      'For example in `<div.cssexy><div/><div><textarea/><input type="text"/></div>` you can style with `.cssexy :empty { with: 100px; height: 100px; background: blue; }` the first `div` and the `textarea` and `input` in the second div.',
  },
  {
    name: ":focus",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :focus  \n" +
      "Selects `input` elements with focus. A text `input`, for example, has focus as long as the cursor is in its value field.",
  },
  {
    name: ":hover",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :hover   \n" +
      "Selects an element when the mouse hovers over it.  \n" +
      "`div.row:hover p`, for example, styles all `p` elements within a `div` with the class `.row` when the user hovers over the `div`.",
    warning:
      "### :hover and the pseudo elements ::before and ::after  \n" +
      "You can't hover over a pseudo element, respectively you can but nothing happens if you do. To add a hover effect to a pseudo element, add the hover first and the pseudo second, like in `:hover::before { background: red; }`",
  },
  {
    name: ":in-range",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :in-range  \n" +
      "Selects inputs of the type `date`, `number` or `range` with the attribute `min` and/or `max` set and a value within the defined interval.  \n" +
      'Example: `<input type="number" min="0" max="1" value="0.5">` `input:in-range { border: 1px solid blue; }`',
  },
  {
    name: ":out-of-range",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :out-of-range  \n" +
      "Selects inputs of the type `date`, `number` or `range` with the attribute `min` and/or `max` set and a value outside the defined interval.  \n" +
      'Example: `<input type="number" min="0" max="1" value="1.5">` `input:out-of-range { border: 1px solid red; }`',
  },
  {
    name: ":invalid",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :invalid  \n" +
      "The `:invalid` selector works for form elements with restrictions, like an `input` of the type `email` which validates for a valid email address. With `:valid` and `:invalid` you can indicate if the given condition is fulfilled.  \n" +
      'Example: `input[type = "email"]:invalid { outline:1px solid red; }`',
  },

  {
    name: ":valid",
    level: "inline",
    category: ["state"],
    availableFromLevel: 2,
    help:
      "### :valid  \n" +
      "The `:valid` selector works for form elements with restrictions, like an `input` of the type `email` which validates for a valid email address. With `:valid` and `:invalid` you can indicate if the given condition is fulfilled.  \n" +
      'Example: `input[type = "email"]:valid { outline:1px solid green; }`',
  },
];
