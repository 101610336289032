import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "../../css/constants/utils";

export const lengthAdjustField = {
  name: "lengthAdjust",
  type: EMetaFieldTypes.select,
  options: [
    {
      id: "spacing",
    },
    {
      id: "spacingAndGlyphs",
    },
  ],
  help:
    "### lengthAdjust  \n" +
    "Defines how the text is stretched or compressed to fit the width defined by the `textLength` attribute. When `spacing` is selected, the space between the letters only is in- or decreased. `spacingAndGlyphs` stretches or compresses additionally the the width of the letters." +
    "See also [MDN - lengthAdjust](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/lengthAdjust)",
};
export const textLengthField = {
  name: "textLength",
  type: EMetaFieldTypes.number,
  units: [{ id: "%", min: 0, max: 100 }, { id: "px" }],
  dependentOn: {
    field: "lengthAdjust",
    values: ["spacing", "spacingAndGlyphs"],
  },
};

export const SVG_CONSTANTS_TEXT: metaIF[] = [
  {
    name: "svg-text",
    display: "text",
    level: "block",
    category: ["TEXT"],
    allowedChildren: [
      "title",
      "animate",
      "animateMotion",
      "animateTransform",
      "textPath",
    ],
    fieldsOrder: [
      "main",
      "x",
      "y",
      "dx",
      "dy",
      "rotate",
      "lengthAdjust",
      "textLength",
      "text-anchor",
    ],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.textarea,
        dontRender: true,
        onOff: true,
      },
      {
        name: "x",
        type: EMetaFieldTypes.number,
        units: [{ id: "%", min: 0, max: 100 }, { id: "px" }],
        onOff: true,
        default: {
          value: 0,
        },
      },
      {
        name: "y",
        type: EMetaFieldTypes.number,
        units: [{ id: "%", min: 0, max: 100 }, { id: "px" }],
        onOff: true,
        default: {
          value: 0,
        },
      },
      {
        name: "dx",
        type: EMetaFieldTypes.number,
        units: [{ id: "%", min: 0, max: 100 }, { id: "px" }],
        default: {
          value: 0,
        },
      },
      {
        name: "dy",
        type: EMetaFieldTypes.number,
        units: [{ id: "%", min: 0, max: 100 }, { id: "px" }],
        default: {
          value: 0,
        },
      },
      {
        name: "rotate",
        type: EMetaFieldTypes.number,
        default: {
          value: 0,
        },
      },
      lengthAdjustField,
      textLengthField,
      {
        name: "text-anchor",
        javascript: "textAnchor",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "start",
          },
          {
            id: "middle",
          },
          {
            id: "end",
          },
        ],
        help: "### text-anchor  \nSee for example [MDN - text-anchor](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/text-anchor)",
      },
    ],
    group: [
      {
        name: "text",
        fields: ["main"],
      },
      {
        name: "xy",
        display: ["x", "y"],
        fields: ["x", "y"],
        help: "### x y  \nStarting points of the text baselines.",
      },
      {
        name: "dxDy",
        display: ["dx", "dy"],
        fields: ["dx", "dy"],
        help:
          "### dx dy  \n" +
          "Shifts the text horizontally and vertically from its current position",
      },
      {
        name: "rotate",
        fields: ["rotate"],
        help:
          "### rotate  \n" +
          "Rotates each letter individually. If you want to rotate the whole text object, use CSS `transform` `rotate`.",
      },
      {
        name: "lengthAdjustTextLength",
        display: ["lengthAdjust", "textLength"],
        fields: ["lengthAdjust", "textLength"],
      },
      {
        name: "text-anchorG",
        fields: ["text-anchor"],
      },
    ],

    default: {
      main: {
        value: "TEXT IT",
      },
      x: {
        value: 10,
        unit: "px",
      },
      y: {
        value: 50,
        unit: "px",
      },
      dx: {
        value: 0,
        unit: "px",
      },
      dy: {
        value: 0,
        unit: "px",
      },
      rotate: {
        value: 0,
      },
      lengthAdjust: {
        value: "spacing",
      },
      textLength: {
        value: 100,
        unit: "%",
      },
      "text-anchor": {
        value: "middle",
      },
    },
  },

  {
    name: "textPath",
    level: "inline",
    category: ["TEXT"],
    allowedChildren: ["title"],
    fieldsOrder: ["main", "href", "lengthAdjust", "textLength", "startOffset"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.textarea,
        onOff: true,
        dontRender: true,
        help: "### textPath  \nSee for example [MDN - textPath](https://developer.mozilla.org/en-US/docs/Web/SVG/Element/textPath)",
      },
      {
        name: "href",
        type: EMetaFieldTypes.selectPathId,
        onOff: true,
        wrapperFn: (valueObj) => `#${valueObj.value}`,
      },
      lengthAdjustField,
      textLengthField,
      { name: "startOffset", type: EMetaFieldTypes.number, units: standardUnits },
    ],
    group: [
      {
        name: "text",
        fields: ["main"],
      },
      {
        name: "href",
        fields: ["href"],
      },
      {
        name: "lengthAdjustTextLength",
        display: ["lengthAdjust", "textLength"],
        fields: ["lengthAdjust", "textLength"],
      },
      {
        name: "startOffsetG",
        fields: ["startOffset"],
        display: ["startOffset"],
      },
    ],
    default: {
      main: {
        value: "CSS EXtreme & easY",
      },
      href: {
        value: 0,
      },
      lengthAdjust: {
        value: "spacing",
      },
      textLength: {
        value: 100,
        unit: "%",
      },
      startOffset: {
        value: 25,
        unit: "%",
      },
    },
  },
];
