import MainLayout from "../components/MainLayout";
import { Account } from "../components/user/Account";

const UserPage = () => {
  return (
    <MainLayout title="User" description="Sign up or Sign in">
      <Account />
    </MainLayout>
  );
};

export default UserPage;
