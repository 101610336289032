import { useRef, useLayoutEffect } from "react";
import { itemIF } from "../../data/types/item";

export const useScrollIntoView = (selectedItem?:itemIF) => {
    const listRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
      const attemptScrollIntoView = () => {
        if (!listRef?.current || !selectedItem) {
          return;
        }
  
        const selectedElement = listRef.current.querySelector(
          `[data-id="${selectedItem.id}"]`
        );
        // HTML tree has many children and it's hard to find out when the last child is rendered,
        // so if the selectedItem button can't be found, try again after timeout
        if (selectedElement) {
          selectedElement.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          setTimeout(attemptScrollIntoView, 100);
        }
      };
  
      if (listRef.current && selectedItem) {
        attemptScrollIntoView();
      }
    }, [selectedItem]);

    return listRef;
}