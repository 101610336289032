import { snippetIF } from "../../data/types/snippets";
import { getUserId } from "../signinup/handleTokens";
import { updateSnippetGQL } from "../lambda/updatesnippet";
import { MutationUpdateSnippetArgs } from "cssexy-be/src/generated/graphql";

export const updateServerSnippet = async ({
  editSnippet,
}: {
  editSnippet?: snippetIF;
}) => {
  if (!editSnippet) {
    console.error("No editSnippet found");
    return;
  }

  const cognitoUserId = getUserId();
  if (!cognitoUserId) {
    console.warn("No user id found");
    return editSnippet;
  }

  const snippetInput = {
    id: editSnippet.id,
    copyCount: editSnippet.copyCount,
    data: editSnippet.data,
    description: editSnippet.description,
    name: editSnippet.name,
    public: editSnippet.public ? true : false,
    updatedAt: editSnippet.updatedAt || new Date().toISOString()
  } as MutationUpdateSnippetArgs;

  const response = await updateSnippetGQL({
    snippetData: snippetInput,
  });

  return response.data.updateSnippet as snippetIF;
};