import React from "react";
import { RgbaColor } from "react-colorful";
import {
  EMetaFieldTypes,
  IOptions,
  TItemValue,
  metaFieldIF,
} from "../../data/types/item";
import { TGetSetValueFn } from "../../lib/form/getSetValueFn";
import { PopoverPicker } from "./ColourfulPicker";
import { EditFieldCheckbox } from "./Checkbox";
import { EditFieldTextarea } from "./Textarea";
import { NumberInput } from "./NumberField";
import { StringInput } from "./StringField";
import { EditFieldSelect } from "./Select2";
import { UploadInput } from "./UploadInput";

type MainEditFieldProps = {
  fieldMeta: metaFieldIF;
  displayName?: string;
  valueObj?: TItemValue;
  isFieldDisabled?: boolean;

  options?: IOptions[];
  presetColours?: RgbaColor[];

  children?: React.ReactNode;

  tooltipText?: string;

  setValueFn?: TGetSetValueFn;
};

export const MainEditField: React.FC<MainEditFieldProps> = ({
  fieldMeta,
  displayName,
  valueObj,
  isFieldDisabled,

  options,
  presetColours,
  
  tooltipText,

  setValueFn,
}) => {
  const type = fieldMeta.type;
  return (
    <>
      {type === EMetaFieldTypes.readonly && <div>{valueObj?.value}</div>}

      {type === EMetaFieldTypes.boolean && (
        <EditFieldCheckbox
          fieldMeta={fieldMeta}
          isChecked={(valueObj?.value as boolean) || false}
          tooltipText={tooltipText}
          isFieldDisabled={isFieldDisabled}
          setValue={setValueFn && setValueFn(EMetaFieldTypes.boolean)}
        />
      )}
      {type === EMetaFieldTypes.colourPicker && (
        <PopoverPicker
          color={valueObj?.value as RgbaColor}
          presetColours={presetColours}
          isFieldDisabled={isFieldDisabled}
          setValue={setValueFn && setValueFn(EMetaFieldTypes.colourPicker)}
        />
      )}
      {(type === EMetaFieldTypes.select ||
        type === EMetaFieldTypes.dynamicSelect ||
        type === EMetaFieldTypes.propertyOfClassAndAll) && (
        <EditFieldSelect
          options={options!}
          fieldValue={valueObj?.value}
          tooltipText={tooltipText}
          isFieldDisabled={isFieldDisabled}
          setValue={setValueFn && setValueFn(EMetaFieldTypes.string)}
        />
      )}
      {type === EMetaFieldTypes.number && (
        <NumberInput
          fieldMeta={fieldMeta}
          valueObj={valueObj}
          tooltipText={tooltipText}
          isFieldDisabled={isFieldDisabled}
          setValue={setValueFn && setValueFn(EMetaFieldTypes.number)}
          setUnit={setValueFn && setValueFn(EMetaFieldTypes.string, "unit")}
        />
      )}
      {type === EMetaFieldTypes.string && (
        <StringInput
          fieldMeta={fieldMeta}
          fieldValue={valueObj?.value as string}
          tooltipText={tooltipText}
          isFieldDisabled={isFieldDisabled}
          setValue={setValueFn && setValueFn(EMetaFieldTypes.string)}
        />
      )}
      {type === EMetaFieldTypes.textarea && (
        <EditFieldTextarea
          fieldMeta={fieldMeta}
          displayName={displayName}
          fieldValue={valueObj?.value as string}
          tooltipText={tooltipText}
          isFieldDisabled={isFieldDisabled}
          setValue={setValueFn && setValueFn(EMetaFieldTypes.string)}
        />
      )}
      {type === EMetaFieldTypes.upload && (
        <UploadInput isFieldDisabled={isFieldDisabled} />
      )}
    </>
  );
};
