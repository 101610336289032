import { itemIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import {
  getAssignedSelectorIds,
  getAssignedSelectors,
  getAssignedClassNames,
} from "./getAssignedSelectorsNew";
import { addNewItemWithDescendants } from "./cloneItem";
import { getSelectorProperties } from "./addItems";

/*
check htmlsOSvgs assignedClasses for oldSelectorId and replace with newSelectorId
*/

export type TOldNewIds = { oldId: number; newId: number };
export const replaceAssignedClassId = ({
  htmlsOSvgs,
  oldNewIds,
}: {
  htmlsOSvgs: itemIF[];
  oldNewIds: TOldNewIds[];
}) => {
  const newHtmlsOSvgs = htmlsOSvgs.map((h) => {
    const newAssignedClasses = h.assignedClasses?.map((ac) => {
      const hasOldId = oldNewIds.find((id) => id.oldId === ac);
      if (hasOldId) {
        return hasOldId.newId;
      }
      return ac;
    });
    return { ...h, assignedClasses: newAssignedClasses };
  });
  return newHtmlsOSvgs;
};

export const addSelectorsAndProperties = ({
  htmlsOSvgs,
  selectorsFrom,
  selectorsTo,
  propertiesFrom,
  propertiesTo,
}: {
  htmlsOSvgs: itemIF[];
  selectorsFrom: itemIF[];
  selectorsTo: itemIF[];
  propertiesFrom: itemIF[];
  propertiesTo: itemIF[];
}): {
  htmlsOSvgs: itemIF[];
  selectors: itemIF[];
  properties: itemIF[];
} | undefined => {
  const assignedIds = [] as number[];
  getAssignedSelectorIds({
    items: htmlsOSvgs,
    assignedSelectorIds: assignedIds,
  });

  if(assignedIds.length === 0) {
    return;
  }

  const assignedClassNames = getAssignedClassNames({
    assignedSelectorIds: assignedIds,
    selectors: selectorsFrom,
  });

  const assignedSelectors = getAssignedSelectors({
    assignedClassNames: assignedClassNames || [],
    selectors: selectorsFrom,
    properties: propertiesFrom,
  });

  let newSelectors = [...selectorsTo] as itemIF[];
  const sItemType = itemTypes.SELECTORS;

  /* above finds all same name and all dependent selectors of the selector to copy, but returns just the top selector in a branch on level 1. The descendants are added below.
  @media is an exception. Same name selectors are often found one on a certain level and the other on that level + 1 under the query selector.
  if you copy a selector and a same name selector is underneath a query selector, not just the top selector is found but on level query selector and on level 2 the same name selector as a child 

  filter out all selectors which are kids of queries
  loop through assignedSelectors, if you find a query, add first the query to new selectors and then its child, where you use the query as item to
  **/

  const queries = assignedSelectors.filter((s) => s.name === "@media");
  const queryKids = assignedSelectors.filter((s) =>
    queries.find((q) => q.id === s.parent)
  );
  const queryKidsIds = queryKids.map((k) => k.id);
  const assignedSelectorsNoQueryKids = assignedSelectors.filter(
    (s) => !queryKidsIds.includes(s.id)
  );

  let oldNewIds = [] as TOldNewIds[];
  assignedSelectorsNoQueryKids.forEach((s) => {
    if (s.name === "@media") {
      // for @media selectors addNewItemWithDescendants always comes back just with the item, no descendants
      const qResult = addNewItemWithDescendants({
        itemType: sItemType,
        itemsFrom: selectorsFrom,
        itemId: s.id,
        itemsTo: newSelectors,
      });
      if (qResult) {
        newSelectors = qResult.items;
        if (qResult.oldNewIds) {
          oldNewIds = [...oldNewIds, ...qResult.oldNewIds];
        }
      }
      const queryKid = queryKids.find((k) => k.parent === s.id);
      const oNQuery = oldNewIds.find((on) => on.oldId === s.id);
      const query = newSelectors.find((ns) => ns.id === oNQuery?.newId);

      if (queryKid && query) {
        const qkResult = addNewItemWithDescendants({
          itemType: sItemType,
          itemsFrom: selectorsFrom,
          itemId: queryKid.id,
          itemsTo: newSelectors,
          itemToId: query.id,
        });
        if (qkResult) {
          newSelectors = qkResult.items;
          if (qkResult.oldNewIds) {
            oldNewIds = [...oldNewIds, ...qkResult.oldNewIds];
          }
        }
      }
    } else {
      const sResult = addNewItemWithDescendants({
        itemType: sItemType,
        itemsFrom: selectorsFrom,
        itemId: s.id,
        itemsTo: newSelectors,
      });
      if (sResult) {
        newSelectors = sResult.items;
        if (sResult.oldNewIds) {
          oldNewIds = [...oldNewIds, ...sResult.oldNewIds];
        }
      }
    }
  });


  const newhtmlsOSvgs = replaceAssignedClassId({
    htmlsOSvgs,
    oldNewIds: oldNewIds,
  });

  const newProperties = getSelectorProperties({
    propertiesFrom,
    propertiesTo,
    oldNewIds: oldNewIds,
  });

  return {
    htmlsOSvgs: newhtmlsOSvgs,
    selectors: newSelectors,
    properties: newProperties,
  };
};
