import {
  CognitoIdentityProviderClient,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand
} from "@aws-sdk/client-cognito-identity-provider";

import { setIsLoading } from "../reducer/settings/actions";
import { TFormData, TUserState, TMsgType } from "./helpers";

const client = new CognitoIdentityProviderClient({
  region: "eu-west-2",
});

export const sendForgottenPassword = async ({
  formData,
  setFormData,
  setSignType,
  setSignInErrorMessage,
  dispatch,
}: {
  formData: TFormData;
  setFormData: (formData: TFormData) => void;
  setSignType: (signType: TUserState) => void;
  setSignInErrorMessage: (signInErrorMessage: TMsgType) => void;
  dispatch?: (value: any) => void;
}) => {
  dispatch && dispatch(setIsLoading(true));

  try {
    const email = formData.email.data;
    const clientId = process.env.REACT_APP_CLIENT_ID;

    const command = new ForgotPasswordCommand({
      ClientId: clientId,
      Username: email,
    });
    const response = await client.send(command);

    if (response.CodeDeliveryDetails) {
      setFormData({
        ...formData,
        password: {
          ...formData.password,
          data: "",
        },
        password2: {
          ...formData.password2,
          data: "",
        },
      });
      setSignInErrorMessage({
        successMsg:
          "We sent a verification code to your email " +
          email +
          ". Please enter the code and create a new password.",
        errMsg: "",
      });
      setSignType("createnewpasswordsubmit");
    }
  } catch (e) {
    setSignInErrorMessage({
      successMsg: "We could not reset your password. Please try again later.",
      errMsg: (e as Error).message,
    });
  } finally {
    dispatch && dispatch(setIsLoading(false));
  }
};

export const collectNewPassword = async ({
  formData,
  setFormData,
  setSignType,
  setSignInErrorMessage,
  dispatch,
}: {
  formData: TFormData;
  setFormData: (TFormData: TFormData) => void;
  setSignType: (signType: TUserState) => void;
  setSignInErrorMessage: (signInErrorMessage: TMsgType) => void;
  dispatch?: (value: any) => void;
}) => {
  try {
    dispatch && dispatch(setIsLoading(true));

    const email = formData.email.data;
    const code = formData.verify.data;
    const newPassword = formData.password.data;
    const clientId = process.env.REACT_APP_CLIENT_ID;

    const command = new ConfirmForgotPasswordCommand({
      ClientId: clientId,
      Username: email,
      ConfirmationCode: code, 
      Password: newPassword,
    });
    await client.send(command);

    setSignInErrorMessage({
      successMsg: "You've succesfully created a new password. Please sign in.",
      errMsg: "",
    });
    setSignType("signin");
  } catch (e) {
    setSignInErrorMessage({
      successMsg: "",
      errMsg: (e as Error).message,
    });
  } finally {
    dispatch && dispatch(setIsLoading(false));

    setFormData({
      ...formData,
      verify: {
        ...formData.verify,
        data: "",
      },
      password: {
        ...formData.password,
        data: "",
      },
    });
  }
};