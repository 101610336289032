import { itemIF } from "../../data/types/item";

export const setNewId = ({
  items,
  newItems,
}: {
  items: itemIF[];
  newItems: itemIF[];
}): itemIF[] => {
  const maxId = Math.max(...items.map((i) => i.id), 0);
  const reset = [] as { oldId: number; newId: number }[];
  const newItemsWithNewId = newItems.map((item, i) => {
    const newId = maxId + 1 + i;
    reset.push({ oldId: item.id, newId });
    return {
      ...item,
      id: newId,
    };
  });
  // if in newItems are children of items with resit id, update parent id
  const newItemsWithNewParent = newItemsWithNewId.map((item) => {
    const parent = reset.find((r) => r.oldId === item.parent);
    if (parent) {
      return {
        ...item,
        parent: parent.newId,
      };
    }
    return item;
  });
  return newItemsWithNewParent;
};

export const mergeItems = ({
  oldItems,
  items,
}: {
  oldItems: itemIF[];
  items: itemIF[];
}) => {
  const updated = oldItems.map((p) => {
    const updatedItem = items.find((i) => i.id === p.id);
    return updatedItem ? updatedItem : p;
  });

  const newItems = items.filter((i) => !updated.find((p) => p.id === i.id));

  const merged = [...updated, ...newItems];

  return merged;
};

