import {
  metaFieldIF,
  metaIF,
  groupIF,
  wrapperForGroupIF,
  EMetaFieldTypes
} from "../../types/item";
import { pathHelp, allFieldsHrefId } from "./utils";

export const allFieldsAnimation: { [key: string]: metaFieldIF } = {
  beginAnimationId: {
    name: "beginOtherAnimationId",
    type: EMetaFieldTypes.otherAnimationId,
  },
  beginEventOtherAnimation: {
    name: "beginEventOtherAnimation",
    type: EMetaFieldTypes.select,
    options: [
      {
        id: "begin",
      },
      {
        id: "end",
      },
    ],
  },
  beginEventThisAnimation: {
    name: "beginEventThisAnimation",
    type: EMetaFieldTypes.select,
    options: [
      { id: "focus" },
      { id: "blur" },
      { id: "click" },
      { id: "dblclick" },
      { id: "mousedown" },
      { id: "mouseover" },
      { id: "mouseup" },
      { id: "keydown" },
      { id: "keyup" },
      { id: "select" },
      { id: "resize" },
      { id: "scroll" },
    ],
  },
  beginSeconds: {
    name: "begin",
    type: EMetaFieldTypes.number,
    units: [{ id: "s" }, { id: "ms" }],
  },
  endEventField: {
    name: "endEvent",
    javascript: "end",
    type: EMetaFieldTypes.select,
    options: [
      {
        id: "value",
      },
      { id: "focus" },
      { id: "blur" },
      { id: "click" },
      { id: "dblclick" },
      { id: "mousedown" },
      { id: "mouseover" },
      { id: "mouseup" },
      { id: "keydown" },
      { id: "keyup" },
      { id: "select" },
      { id: "resize" },
      { id: "scroll" },
      {
        id: "none",
      },
    ],
  },
  endField: {
    name: "end",
    type: EMetaFieldTypes.number,
    units: [{ id: "s" }, { id: "ms" }],
    dependentOn: {
      field: "endEvent",
      values: ["value"],
    },
  },
  calcModeField: {
    name: "calcMode",
    type: EMetaFieldTypes.select,
    options: [
      { id: "discrete" },
      { id: "linear" },
      { id: "paced" },
      { id: "spline" },
    ],
    help: "### calcMode  \nSee for example [MDN - calcMode](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/calcMode)",
  },
};
const allFieldsGroupAnimation: { [key: string]: groupIF } = {
  beginOtherAnimationGroup: {
    name: "beginOtherAnimationG",
    fields: ["beginOtherAnimationId"],
    display: ["other animation id"],
  },
  beginOtherAnimationEventGroup: {
    name: "beginOtherAnimationEventG",
    fields: ["beginEventOtherAnimation"],
    display: ["other animation event"],
  },
  beginThisAnimationEventGroup: {
    name: "beginEventThisAnimationG",
    fields: ["beginEventThisAnimation"],
    display: ["begin event"],
  },
  beginAnimationSecondsGroup: {
    name: "beginAnimationSecondsG",
    fields: ["begin"],
    display: ["begin"],
  },
  endGroup: {
    name: "endG",
    fields: ["endEvent", "end"],
    display: ["end", "value"],
    help:
      "### end  \n" +
      "Set here a corresponding event to the event selected in `begin`, like `mouseup` when `begin` is `mousedown`.  \n" +
      "If the animation is a child of a `filter` effect, then the `end` of the animation must be `none` or a `value`, otherwise the animation won't run.",
  },
  calcModeGroup: {
    name: "calcModeFieldG",
    fields: ["calcMode"],
    display: ["calcMode"],
  },
};

const wrapperForGroupAnimation: { [key: string]: wrapperForGroupIF } = {
  beginAnimation: {
    name: "beginAnimationGroup",
    javascript: "begin",
    fields: [
      "beginOtherAnimationId",
      "beginEventOtherAnimation",
      "beginEventThisAnimation",
      "begin",
    ],
    wrapperFn: (valueObj) => {
      // is other animation is selected as anchor for begin event?
      let otherAnimationBeginStr = "";
      // does the current animation begin dependent on an event of the other animate with the id  beginOtherAnimationId
      if (
        valueObj?.beginOtherAnimationId?.onOff &&
        valueObj?.beginOtherAnimationId?.value &&
        valueObj.beginOtherAnimationId.value !== ""
      ) {
        // hyphen is used in begin as pluse or minuse value ()
        const replaceHyphen = valueObj.beginOtherAnimationId.value.replace(
          "-",
          `-`
        );
        // is an event defined for the other
        const otherAnimationEvent = valueObj?.beginEventOtherAnimation?.onOff
          ? valueObj.beginEventOtherAnimation.value
          : "begin";
        otherAnimationBeginStr = `${replaceHyphen}.${otherAnimationEvent}`;
      }

      let beginEventThisAnimationStr = "";
      if (
        otherAnimationBeginStr === "" &&
        valueObj?.beginEventThisAnimation?.onOff
      ) {
        beginEventThisAnimationStr = valueObj.beginEventThisAnimation.value;
      }
      const beginEvent = `${otherAnimationBeginStr}${beginEventThisAnimationStr}`;

      let beginSecondsStr = "";
      if (valueObj?.begin?.onOff) {
        beginSecondsStr = `${
          beginEvent === "" || valueObj.begin.value < 0 ? "" : "+ "
        }${valueObj.begin.value}${valueObj.begin.unit}`;
      }

      const str = `${beginEvent}${beginSecondsStr}`;
      return str;
    },
  },
};

export const SVG_CONSTANTS_ANIMATE: metaIF[] = [
  {
    name: "animate",
    level: "block",
    category: ["ANIMATE"],
    allowedChildren: ["title"],
    fieldsOrder: [
      "href",
      "attributeName",
      "from",
      "to",
      "values",
      "keyTimes",
      "keySplines",
      "dur",
      "beginEventThisAnimation",
      "beginOtherAnimationId",
      "beginEventOtherAnimation",
      "begin",
      "endEvent",
      "end",
      "repeatCount",
      "repeatCountValue",
      "calcMode",
      "fill",
    ],
    fields: [
      {
        name: "href",
        type: EMetaFieldTypes.selectPathId,
        wrapperFn: (valObj) => `#${valObj.value}`,
      },
      {
        name: "attributeName",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      {
        name: "from",
        type: EMetaFieldTypes.string,
      },
      {
        name: "to",
        type: EMetaFieldTypes.string,
      },

      {
        name: "values",
        type: EMetaFieldTypes.textarea,
      },
      {
        name: "keyTimes",
        type: EMetaFieldTypes.string,
        dependentOnValueInOtherField: {
          field: "values",
          wrapperFn: (valueObj) => {
            if (valueObj.value && valueObj.dependentValue) {
              const splitValues = valueObj.dependentValue.value.split(";");
              const splitKeyTimes = valueObj.value.value.split(";");
              if (
                splitValues.length === splitKeyTimes.length &&
                splitKeyTimes[0].trim() === "0" &&
                splitKeyTimes[splitKeyTimes.length - 1].trim() === "1"
              ) {
                return true;
              }
            }
            return false;
          },
        },
      },
      {
        name: "keySplines",
        type: EMetaFieldTypes.textarea,
      },
      {
        name: "dur",
        type: EMetaFieldTypes.number,
        units: [{ id: "s" }, { id: "ms" }],
        onOff: true,
      },

      allFieldsAnimation.beginAnimationId,
      allFieldsAnimation.beginEventOtherAnimation,
      allFieldsAnimation.beginEventThisAnimation,
      allFieldsAnimation.beginSeconds,
      allFieldsAnimation.endEventField,
      allFieldsAnimation.endField,
      allFieldsAnimation.calcModeField,
      {
        name: "repeatCount",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "indefinite",
          },
          {
            id: "value",
          },
        ],
        onOff: true,
      },

      {
        name: "repeatCountValue",
        javascript: "repeatCount",
        type: EMetaFieldTypes.number,
        onOff: true,
        dependentOn: {
          field: "repeatCount",
          values: ["value"],
        },
      },
      {
        name: "fill",
        type: EMetaFieldTypes.select,
        options: [{ id: "freeze" }, { id: "remove" }],
      },
    ],
    group: [
      {
        name: "hrefG",
        fields: ["href"],
        display: ["path id"],
      },
      {
        name: "attribute",
        fields: ["attributeName"],
        help:
          "### attribute  \n" +
          "Enter here the name of the `attribute`, which you want to animate. It can be the name of a CSS property, like `opacity`, or the name of an attribute of the parent SVG element, for example in a SVG circle the horizental center `cx`.  \n" +
          "If you want to animate a value of a filter effect like `feColorMatrix`, enter the name of the attribute with value, in `feColorMatrix` for example `values`.",
      },
      {
        name: "fromToG",
        fields: ["from", "to"],
        display: ["from", "to"],
      },
      {
        name: "duration",
        fields: ["dur"],
      },
      {
        name: "values",
        fields: ["values"],
        help:
          "### values  \n" +
          "Enter here a list of values, separated with semicolons, which describe the path of the animation. If the animation `attribute` is `opacity`, for example, one possible path could be 1;0.2;0, if you want the element to first become transparent fast, then linger a bit around and then be gone.",
      },
      {
        name: "keyTimes",
        fields: ["keyTimes"],
        help:
          "### keyTimes  \n" +
          "Corresponds with the list in `values` and defines when the animation reaches the states in `values` as a semicolon separated list of values between 0 and 1.",
        warning:
          "### Same number of values  \n" +
          "`KeyTimes` and values must have the same number of values. For example above you could make the pause in the middle longer by setting keyTimes to 0;0.75;1",
      },
      {
        name: "keySplinesG",
        fields: ["keySplines"],
        display: ["keySplines"],
      },
      allFieldsGroupAnimation.beginOtherAnimationGroup,
      allFieldsGroupAnimation.beginThisAnimationEventGroup,
      allFieldsGroupAnimation.beginAnimationSecondsGroup,
      allFieldsGroupAnimation.endGroup,
      {
        name: "repeatG",
        display: ["repeatCount", "value"],
        fields: ["repeatCount", "repeatCountValue"],
      },
      allFieldsGroupAnimation.calcModeGroup,
      {
        name: "fillG",
        fields: ["fill"],
        display: ["fill"],
        help:
          "### animation fill mode  \n" +
          "`Freeze` keeps the state of the last animation frame and `remove` keeps the state of the first animation frame.",
      },
    ],
    wrapperForGroup: [wrapperForGroupAnimation.beginAnimation],

    default: {
      href: {
        value: 0,
      },
      attributeName: {
        value: "fill",
      },
      from: {
        value: "red",
      },
      to: {
        value: "blue",
      },

      values: {
        value: "red;yellow;blue",
        onOff: true,
      },

      keyTimes: {
        value: "0;0.3;1",
      },
      keySplines: {
        value: "0.165, 0.84, 0.44, 1",
      },

      dur: {
        value: 2,
        unit: "s",
      },

      beginOtherAnimationId: {
        value: 0,
      },
      beginEventOtherAnimation: {
        value: "end",
      },
      beginEventThisAnimation: {
        value: "click",
      },

      begin: {
        value: 2,
        unit: "s",
      },
      endEvent: {
        value: "value",
      },
      end: {
        value: 2,
        unit: "s",
      },
      endValue: {
        value: 3,
        unit: "s",
      },

      repeatCount: { value: "indefinite" },
      repeatCountValue: { value: 3 },
      calcMode: {
        value: "linear",
      },
      fill: {
        value: "freeze",
      },
    },
  },
  {
    name: "animateMotion",
    allowedChildren: ["title", "mpath"],
    level: "block",
    category: ["ANIMATE"],
    fieldsOrder: [
      "href",
      "path",
      "dur",
      "beginEventThisAnimation",
      "beginOtherAnimationId",
      "beginEventOtherAnimation",
      "begin",
      "endEvent",
      "end",
      "repeatCount",
      "repeatCountValue",
      "rotateSelect",
      "rotate",
    ],
    fields: [
      allFieldsHrefId,
      {
        name: "path",
        type: EMetaFieldTypes.textarea,
        help:
          "### path  \n" +
          "Draw the outline of basically anything with the help of the following commands: " +
          "M/m = moveto (starting point), L/n = lineto (line), C/c = curveto, S/s = smooth curveto, Q/q = quadratic Bézier curve, " +
          "T/t = smooth quadratic Bézier curveto, A = elliptical Arc and Z = closepath  \n" +
          pathHelp,
      },

      {
        name: "dur",
        type: EMetaFieldTypes.number,
        units: [{ id: "s" }, { id: "ms" }],
        onOff: true,
        help:
          "### animateMotion  \n" +
          "Add `animateMotion` as a child of a shape, like a `circle`, and then enter the `duration`, the `repeat count` and the `path` of the animation.  \n" +
          "You can copy the path from a path element within the current SVG. Alternatively, to connect the animateMotion to a path element, add `mpath` as a child of `animateMotion` and assign the `id` of the path element to the `href` attribute of `mpath`.",
      },
      allFieldsAnimation.beginAnimationId,
      allFieldsAnimation.beginEventOtherAnimation,
      allFieldsAnimation.beginEventThisAnimation,
      allFieldsAnimation.beginSeconds,
      allFieldsAnimation.endEventField,
      allFieldsAnimation.endField,
      {
        name: "repeatCount",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "indefinite",
          },
          {
            id: "value",
          },
        ],
      },
      {
        name: "repeatCountValue",
        type: EMetaFieldTypes.number,
        onOff: true,
        dependentOn: {
          field: "repeatCount",
          values: ["value"],
        },
      },
      {
        name: "rotateSelect",
        javascript: "rotate",
        type: EMetaFieldTypes.select,
        options: [{ id: "auto" }, { id: "auto-reverse" }, { id: "value" }],
        help: "### rotate  \nThis attribute defines a rotation applied to the element animated along a path, usually to make it pointing in the direction of the animation.",
      },
      {
        name: "rotate",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "rotateSelect",
          values: ["value"],
        },
      },
    ],

    group: [
      {
        name: "hrefG",
        fields: ["href"],
        display: ["href"],
      },
      {
        name: "pathG",
        fields: ["path"],
        display: ["path"],
      },
      {
        name: "duration",
        fields: ["dur"],
      },
      allFieldsGroupAnimation.beginOtherAnimationGroup,
      allFieldsGroupAnimation.beginOtherAnimationEventGroup,
      allFieldsGroupAnimation.beginThisAnimationEventGroup,
      allFieldsGroupAnimation.beginAnimationSecondsGroup,
      {
        name: "repeatG",
        fields: ["repeatCount", "repeatCountValue"],
        display: ["repeatCount", "value"],
      },
      {
        name: "rotateG",
        fields: ["rotateSelect", "rotate"],
        display: ["rotate", "value"],
      },
    ],
    default: {
      href: {
        value: 0,
      },
      path: { value: "M20,50 C20,-50 180,150 180,50 C180-50 20,150 20,50 z" },
      dur: {
        value: 5,
        unit: "s",
      },

      beginOtherAnimationId: {
        value: 0,
      },
      beginEventOtherAnimation: {
        value: "end",
      },
      beginEventThisAnimation: {
        value: "click",
      },
      begin: {
        value: 2,
        unit: "s",
      },
      endEvent: {
        value: "value",
      },
      end: {
        value: 2,
        unit: "s",
      },
      endValue: {
        value: 3,
        unit: "s",
      },

      repeatCount: { value: "indefinite" },
      repeatCountValue: { value: 3 },
      rotateSelect: {
        value: "auto",
      },
      rotate: {
        value: 45,
      },
    },
  },
  {
    name: "animateTransform",
    allowedChildren: [],
    level: "block",
    category: ["ANIMATE"],
    help:
      "### animateTransform  \nAllows to control translation, scaling, rotation, and/or skewing of its parent SVG element.  \n" +
      "See for example [Animating transformations](https://css-tricks.com/guide-svg-animations-smil/#animating-transformations-the-animatetransform-element).",
    fieldsOrder: [
      "attributeName",
      "attributeType",
      "type",
      "from",
      "to",
      "dur",
      "beginEvent",
      "begin",
      "endEvent",
      "end",
      "repeatCount",
      "fill",
    ],
    fields: [
      {
        name: "attributeName",
        type: EMetaFieldTypes.select,
        options: [{ id: "transform" }],
        onOff: true,
      },
      {
        name: "attributeType",
        type: EMetaFieldTypes.select,
        options: [{ id: "XML" }],
      },
      {
        name: "type",
        type: EMetaFieldTypes.select,
        options: [
          { id: "translate" },
          { id: "scale" },
          { id: "rotate" },
          { id: "skewX" },
          { id: "skewY" },
        ],
      },
      {
        name: "from",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      {
        name: "to",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      {
        name: "dur",
        type: EMetaFieldTypes.number,
        units: [{ id: "s" }, { id: "ms" }],
        onOff: true,
      },
      allFieldsAnimation.beginAnimationId,
      allFieldsAnimation.beginEventOtherAnimation,
      allFieldsAnimation.beginEventThisAnimation,
      allFieldsAnimation.beginSeconds,
      allFieldsAnimation.endEventField,
      allFieldsAnimation.endField,
      allFieldsAnimation.calcModeField,
      {
        name: "repeatCount",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "indefinite",
          },
          {
            id: "value",
          },
        ],
      },
      {
        name: "repeatCountValue",
        type: EMetaFieldTypes.number,
        onOff: true,
        dependentOn: {
          field: "repeatCount",
          values: ["value"],
        },
      },
    ],

    group: [
      {
        name: "attributeNameG",
        fields: ["attributeName"],
        display: ["attributeName"],
      },
      {
        name: "attributeTypeG",
        fields: ["attributeType"],
        display: ["attributeType"],
      },
      {
        name: "typeG",
        fields: ["type"],
        display: ["type"],
      },
      {
        name: "fromToG",
        fields: ["from", "to"],
        display: ["from", "to"],
      },
      {
        name: "durationG",
        fields: ["dur"],
        display: ["duration"],
      },
      allFieldsGroupAnimation.beginOtherAnimationGroup,
      allFieldsGroupAnimation.beginThisAnimationEventGroup,
      allFieldsGroupAnimation.beginAnimationSecondsGroup,
      allFieldsGroupAnimation.endGroup,
      {
        name: "repeatG",
        fields: ["repeatCount", "repeatCountValue"],
        display: ["repeat count", "value"],
      },
    ],

    wrapperForGroup: [wrapperForGroupAnimation.beginAnimation],

    default: {
      attributeName: {
        value: "transform",
      },
      attributeType: {
        value: "XML",
      },
      type: {
        value: "rotate",
      },
      from: {
        value: "0 75 75",
      },
      to: {
        value: "360 75 75",
      },
      dur: {
        value: 2,
        unit: "s",
      },
      beginEvent: {
        value: "value",
      },
      begin: {
        value: 1,
        unit: "s",
      },
      endEvent: {
        value: "value",
      },
      endValue: {
        value: 3,
        unit: "s",
      },
      repeatCount: { value: "indefinite" },
      repeatCountValue: { value: 3 },
    },
  },
];
