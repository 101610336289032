import { FC, useState } from "react";
import { TFormData, TFieldData } from "../../lib/signinup/helpers";
import { ReactComponent as Visibility } from "../../assets/imgs/visibility.svg";
import { ReactComponent as VisibilityOff } from "../../assets/imgs/visibility_off.svg";
import { ValidateEmail } from "../../utils/user/main";

export type TSignData = {
  data: string;
  errorMessage: string;
};

export const InputWithValidation: FC<{
  inputData: { data: string; errorMessage: string };
  onChange: (newData: string) => void;
  label?: string;
  isPasswordWithEye?: boolean;
  isDisabled?: boolean;
  type?: "text";
}> = ({ inputData, onChange, label, isPasswordWithEye, isDisabled, type }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <>
      <label className="login-label">
        <span className="login-label-span">{label}</span>
        <div className="input-wrapper">
          <input
            type={
              type && type === "text"
                ? "text"
                : isPasswordWithEye
                  ? isPasswordVisible
                    ? "text"
                    : "password"
                  : "email"
            }
            name="email1"
            value={inputData.data}
            disabled={isDisabled}
            className="login-input"
            onChange={(e) => {
              const newData = e.target.value;
              onChange(newData);
            }}
          />
          {isPasswordWithEye && (
            <span
              className="input-svg right"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible && <Visibility className="fill-blue" />}
              {!isPasswordVisible && <VisibilityOff />}
            </span>
          )}
        </div>
      </label>
    </>
  );
};

export const UpdateEmail: FC<{
  inputData: TFormData;
  setInputData: (newInputData: TFormData) => void;
  label?: string;
  isDisabled?: boolean;
}> = ({ inputData, setInputData, label, isDisabled }) => {
  const email = inputData.email.data
  return (
    <>
      <label className="login-label">
        <span className="login-label-span">{label}</span>
        <div className="input-wrapper">
          <input
            type="email"
            name="email"
            value={email}
            disabled={isDisabled}
            className="login-input"
            onChange={(e) => {
              const isValidEmail = ValidateEmail(e.target.value);
              const newEmailData = {
                ...inputData.email,
                data: e.target.value,
                errorMessage: isValidEmail ? "" : "Please enter valid email",
              } as TFieldData;
              setInputData({
                ...inputData,
                email: newEmailData,
              });
            }}
          />
        </div>
      </label>
    </>
  );
};
