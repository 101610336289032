import { itemIF } from "../../data/types/item";
import {
  getKids,
  getAncestors,
} from "../reducer/items/getDescendantsAndAncestors";
import { getKeyframesAndStepsForAssignedAnimations } from "../render/css/animation";

export const getAssignedAnimations = ({
  selectors,
  properties,
  assignedClassNames,
}: {
  selectors: itemIF[];
  properties: itemIF[];
  assignedClassNames: string[];
}) => {
  const animations = properties?.filter((p) => p.name === "animation");
  const assignedAnimations = [] as itemIF[];

  animations?.forEach((animation) => {
    const parent = selectors.find((s) => s.id === animation.classId);
    if (!parent) {
      return;
    }
    const ancestors = [parent] as itemIF[];
    getAncestors({ item: parent, items: selectors, ancestors });
    const assignedClass = ancestors.find(
      (selector) =>
        selector.name === "class" &&
        selector.value?.main?.value &&
        assignedClassNames.includes(selector.value?.main?.value)
    );
    if (assignedClass) {
      assignedAnimations.push(animation);
    }
  });
  return assignedAnimations;
};

const hasPropsOrIsExempt = ({
  selector,
  properties,
}: {
  selector: itemIF;
  properties: itemIF[];
}) => {
  // exclude:
  // step is a selector but is rendered like a CSS property of @keyframes
  if (["step", "@keyframes"].includes(selector.name)) {
    return false;
  }
  // must be rendered event without CSS properties
  if (["::before", "::after"].includes(selector.name)) {
    return true;
  }
  // render if selector has CSS properties
  if (properties.find((p) => p.classId === selector.id)) {
    return true;
  }

  return false;
};

export const getChildrenSelectors = ({
  selector,
  selectors,
  properties,
  assignedSelectorsPlusChildren,
}: {
  selector: itemIF;
  selectors: itemIF[];
  properties: itemIF[];
  assignedSelectorsPlusChildren: itemIF[];
}) => {
  const kids = [] as itemIF[];
  getKids({
    item: selector,
    items: selectors,
    kids,
  });

  kids.forEach((k) => {
    if (
      hasPropsOrIsExempt({
        selector: k,
        properties,
      })
    ) {
      assignedSelectorsPlusChildren.push(k);
    }
  });
};

export const getAssignedSelectors = ({
  assignedClassNames,
  selectors,
  properties,
}: {
  assignedClassNames: string[];
  selectors: itemIF[];
  properties: itemIF[];
}) => {
  const assignedLevel1Classes = selectors?.filter((s) => {
    const fieldValue = s.value?.main?.value;
    return (
      s.name === "class" &&
      s.value?.main?.value &&
      assignedClassNames.includes(
        typeof fieldValue === "string" ? fieldValue : ""
      )
    );
  });
  const assignedSelectorsPlusChildren: itemIF[] = [];
  assignedLevel1Classes?.forEach((selector) => {
    assignedSelectorsPlusChildren.push(selector);
    getChildrenSelectors({
      selector,
      selectors,
      properties,
      assignedSelectorsPlusChildren,
    });
  });

  const assignedAnimations = getAssignedAnimations({
    selectors,
    properties,
    assignedClassNames,
  });

  const assignedKeyframesAndSteps = getKeyframesAndStepsForAssignedAnimations({
    assignedAnimations,
    selectors,
    properties,
  });

  const selectorsWithoutDescendants = assignedSelectorsPlusChildren.filter(
    (s) => {
      const parent = assignedSelectorsPlusChildren.find(
        (p) => p.id === s.parent
      );
      if (parent) {
        return false;
      }
      return true;
    }
  );

  const newSelectorsWithMedia = [] as itemIF[];
  selectorsWithoutDescendants.forEach((s) => {
    const ancestors = [] as itemIF[];
    getAncestors({ item: s, items: selectors, ancestors });
    const ancestorIsMedia = ancestors.find(
      (a) => a.name === "@media" && a.level === 1
    );
    if(ancestorIsMedia) {
      newSelectorsWithMedia.push(ancestorIsMedia);
      newSelectorsWithMedia.push({
        ...s,
        parent: ancestorIsMedia.id,
        level: ancestorIsMedia.level! + 1,
      });
    }
    else {
      newSelectorsWithMedia.push(s);
    }
  });

  return [...newSelectorsWithMedia, ...assignedKeyframesAndSteps];
};

const getCommaConnectedAncestors = ({
  selector,
  selectors,
  found,
}: {
  selector: itemIF;
  selectors: itemIF[];
  found: itemIF[];
}) => {
  if (selector.prefix?.name === "COMMA") {
    const parent = selectors.find((s) => s.parent === selector.id);
    if (parent) {
      found.push(parent);
      getCommaConnectedAncestors({
        selector: parent,
        selectors,
        found,
      });
    }
  }
};

const getCommaConnectedDescendants = ({
  selector,
  selectors,
  found,
}: {
  selector: itemIF;
  selectors: itemIF[];
  found: itemIF[];
}) => {
  const commaChildren = selectors?.filter(
    (s) => s.parent === selector.id && s.prefix?.name === "COMMA"
  );
  commaChildren.forEach((s) => {
    found.push(s);
    getCommaConnectedDescendants({
      selector: s,
      selectors,
      found,
    });
  });
};

const isCommaConnected = ({
  names,
  selector,
  selectors,
}: {
  names: string[];
  selector: itemIF;
  selectors: itemIF[];
}) => {
  const found = [] as itemIF[];
  getCommaConnectedDescendants({
    selector,
    selectors,
    found,
  });
  getCommaConnectedAncestors({
    selector,
    selectors,
    found,
  });

  if (
    found.find(
      (s) =>
        s.name === "class" &&
        s.value?.main?.value &&
        names.includes(s.value.main.value)
    )
  ) {
    return true;
  }
};

/*
gets the id of those classes that are assigned to a html in the snippet
the class can be directly assigned or its a comma selected list of selectors, where the css properties are just once defined for all selectors
returns just unique names of classes to avoid mulitple renders when the class with this name is more than once assigned 
*/

export const getAssignedClassNames = ({
  assignedSelectorIds,
  selectors,
}: {
  assignedSelectorIds: number[];
  selectors: itemIF[];
}) => {
  if (!selectors) {
    return;
  }
  const classes = selectors?.filter((s) => s.name === "class");
  const names = [] as string[];
  classes.forEach((c) => {
    if (assignedSelectorIds.includes(c.id) && c.value?.main?.value) {
      if (!names.includes(c.value.main.value)) {
        names.push(c.value.main.value);
      }
    }
  });
  const namesAndCommaConnected = [] as string[];
  classes.forEach((s) => {
    const className = s.value?.main?.value;
    if (!className || names.includes(className)) {
      return;
    }
    if (
      isCommaConnected({
        names,
        selector: s,
        selectors,
      })
    ) {
      namesAndCommaConnected.push(className);
    }
  });
  return [...names, ...namesAndCommaConnected];
};

const getAssignedIds = ({
  items,
  assignedIds,
}: {
  items: itemIF[];
  assignedIds: number[];
}) => {
  items.forEach((item) => {
    if (item?.assignedClasses && item.assignedClasses.length > 0) {
      item.assignedClasses.forEach((id) => {
        if (!assignedIds.includes(id)) {
          assignedIds.push(id);
        }
      });
    }
  });
};

const getEventClassIds = ({
  items,
  assignedIds,
}: {
  items: itemIF[];
  assignedIds: number[];
}) => {
  items?.forEach((item) => {
    if (item?.events && item.events.length > 0) {
      item.events.forEach((event) => {
        const classId = event.value?.classId?.value;
        if (classId && classId > 0 && !assignedIds.includes(classId)) {
          assignedIds.push(classId);
        }
      });
    }
  });
};

export const getAssignedSelectorIds = ({
  items,
  assignedSelectorIds,
}: {
  items: itemIF[];
  assignedSelectorIds: number[];
}) => {
  if (!items) {
    return;
  }
  getAssignedIds({
    items,
    assignedIds: assignedSelectorIds,
  });
  getEventClassIds({
    items,
    assignedIds: assignedSelectorIds,
  });
};
