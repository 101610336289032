import Markdown from 'react-markdown'
import { privacy } from "../static/privacy-policy";
import MainLayout from "../components/MainLayout";

const PrivacyPage = () => (
  <MainLayout title="Privacy Policy" description="Privacy Policy">
    <div className="container">
      <Markdown>{privacy}</Markdown>
    </div>
  </MainLayout>
);

export default PrivacyPage;
