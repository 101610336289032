import { PAGES, SUB_PAGES } from "../../../data/settings/constants";
import { IUser } from "../../../data/user/constants";

export const SET_IS_LOADING = "SET_IS_LOADING";
export const setIsLoading = (isLoading: boolean) => {
  return {
    type: SET_IS_LOADING,
    isLoading,
  };
};

export const USER_DATA_IN_SETTINGS = "USER_DATA_IN_SETTINGS";
export const userDataInSettings = ({ userData }: { userData: IUser }) => {
  return {
    type: USER_DATA_IN_SETTINGS,
    userData,
  };
};

export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const selectCategory = (categoryName: string) => ({
  type: SELECT_CATEGORY,
  categoryName,
});

// if new selected page is CODE or PAGE, check if the edit SNIPPET is of the same type
export const SELECT_PAGE = "SELECT_PAGE";
export const selectPage = (page: PAGES) => ({
  type: SELECT_PAGE,
  page,
});

export const SELECT_SUB_PAGE = "SELECT_SUB_PAGE";
export const selectSubPage = (subPage: SUB_PAGES) => ({
  type: SELECT_SUB_PAGE,
  subPage,
});

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const setCurrentUser = (userId: string) => ({
  type: SET_CURRENT_USER,
  userId,
});

export const SET_RENDERED_HTML_STRING = "SET_RENDERED_HTML_STRING";
export const setRenderedHtmlString = (renderedHtml: string) => ({
  type: SET_RENDERED_HTML_STRING,
  renderedHtml,
});


export const REMOVE_EDIT_COLOR_FROM_PALETTE = "REMOVE_EDIT_COLOR_FROM_PALETTE";
export const removeEditColorFromPalette = () => ({
  type: REMOVE_EDIT_COLOR_FROM_PALETTE,
});

export const SET_COLOR_PALETTE_EDIT_ITEM_ID = "SET_COLOR_PALETTE_EDIT_ITEM_ID";
export const setColorPaletteEditItemId = (editItemId: number | string) => ({
  type: SET_COLOR_PALETTE_EDIT_ITEM_ID,
  editItemId,
});

export const SETTINGS_SET_SCROLLPROPS = "SETTINGS_SET_SCROLLPROPS";
export const settingsSetScrollPos = (scrollPos: number) => ({
  type: SETTINGS_SET_SCROLLPROPS,
  scrollPos,
});
