import { metaIF } from "../../data/types/item";

export const getCategoryMetas = ({
  category,
  categories,
  metas,
}: {
  category: string;
  categories: {
    [key: string]: { order?: string[] };
  };
  metas: metaIF[];
}): metaIF[] => {
  if (categories[category].order) {
    const metasInOrder = [] as metaIF[];
    categories[category].order?.forEach((name) => {
      const metaInCategory = metas.find((meta) => meta.name === name);
      if (metaInCategory) {
        metasInOrder.push(metaInCategory);
      }
    });
    return metasInOrder;
  }
  return metas.filter((meta) => meta.category.includes(category));
};
