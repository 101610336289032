import { FC } from "react";
import { useStateContext } from "../../data/main/state";
import { itemIF } from "../../data/types/item";
import { ReactComponent as ArrowDown } from "../../assets/imgs/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "../../assets/imgs/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/imgs/arrow-right.svg";
import { ReactComponent as ArrowUp } from "../../assets/imgs/arrow-up.svg";
import { ReactComponent as Copy } from "../../assets/imgs/copy-all.svg";
import { ReactComponent as ContentCopy } from "../../assets/imgs/copy.svg";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import {
  copyItemAndPath,
  moveItemDown,
  moveItemLeft,
  moveItemRightAction,
  moveItemUp,
} from "../../lib/reducer/items/actions";
import { deleteSelectorDescendantsAndProperties } from "../../lib/reducer/snippet/actions";
import {
  isLeftBtnDisabled,
  isRightBtnDisabled,
  isDownBtnDisabled,
  isUpBtnDisabled,
} from "../../utils/html/MoveOrDelete";
import { itemTypes } from "../../lib/meta/getMetas";
import { cloneItemAndChildren } from "../../lib/reducer/items/actions";
import { Tooltip } from "../atoms/tooltip";
import { OpenCloseWithPositioning } from "../modules/TailwindModal";
import { useDivPosition } from "../modules/GetPositionWrapper";

const ConfirmSelectorDelete: FC<{
  editItem?: itemIF;
  setIsModalOpen?: (isOpen: boolean) => void;
}> = ({ editItem, setIsModalOpen }) => {
  const { dispatch } = useStateContext();

  return (
    <div className="p-1r pl-15r">
      <p>
        Do you really want to delete the selector, its descendants and their CSS
        properties?
      </p>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className="button orange"
          onClick={() => {
            if (!editItem) {
              console.warn("editSnippet or editItem is not defined");
              return;
            }
            dispatch(deleteSelectorDescendantsAndProperties({}));
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Delete
        </button>
        <button
          type="button"
          className="button gray-outline"
          onClick={() => {
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export const MoveOrDeleteSelector: FC<{
  items: itemIF[];
  editItem?: itemIF;
}> = ({ items, editItem }) => {
  const { dispatch } = useStateContext();

  const itemType = itemTypes.SELECTORS;

  const isDisabledLeft = editItem
    ? isLeftBtnDisabled({
        itemType,
        items,
        editItem,
      })
    : true;
  const isDisabledRight = editItem
    ? isRightBtnDisabled({
        itemType,
        items,
        editItem,
      })
    : true;

  const isDisabledUp = isUpBtnDisabled({
    itemType,
    items,
    editItem,
  });
  const isDisabledDown = editItem
    ? isDownBtnDisabled({
        itemType,
        items,
        editItem,
      })
    : true;

    const { divRef, divPos } = useDivPosition();

  return (
      <div className="mt-1r btn-row" ref={divRef}>
        <button
          type="button"
          className={`button blue-outline icon${isDisabledLeft ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabledLeft) return;

            dispatch(moveItemLeft({ itemType }));
          }}
        >
          <span className="sr-only">Move left</span>
          <ArrowLeft />
        </button>

        <button
          type="button"
          className={`button blue-outline icon${isDisabledRight ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabledRight) return;

            dispatch(moveItemRightAction({ itemType }));
          }}
        >
          <span className="sr-only">Move right</span>
          <ArrowRight />
        </button>

        <button
          type="button"
          className={`button blue-outline icon${isDisabledUp ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabledUp) return;

            dispatch(moveItemUp({ itemType }));
          }}
        >
          <span className="sr-only">Move up</span>
          <ArrowUp />
        </button>

        <button
          type="button"
          className={`button blue-outline icon${isDisabledDown ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabledDown) return;

            dispatch(moveItemDown({ itemType }));
          }}
        >
          <span className="sr-only">Move down</span>
          <ArrowDown />
        </button>

        <button
          type="button"
          className={`button blue icon${!editItem ? " disabled" : ""} tooltip-container-parent`}
          onClick={() => {
            if (!editItem) return;

            dispatch(cloneItemAndChildren({ itemType: itemTypes.SELECTORS }));
          }}
        >
          <span className="sr-only">Copy selector, deswcendants and CSS</span>
          <Copy />
          <Tooltip
            tooltipText="Copy the selector, all its descendants, and their CSS properties."
            direction="left-center"
          />
        </button>

        <button
          type="button"
          className={`button blue-outline icon${!editItem ? " disabled" : ""} tooltip-container-parent`}
          onClick={() => {
            if (!editItem) return;

            dispatch(copyItemAndPath(itemType));
          }}
        >
          <span className="sr-only">Copy selector and ancestors</span>
          <ContentCopy />
          <Tooltip
            tooltipText={`Copy selector and its ancestors, but no CSS, to use e.g. in media querries.`}
            direction="left-center"
          />
        </button>

        <OpenCloseWithPositioning
          title="Delete selector"
          component={<ConfirmSelectorDelete editItem={editItem} />}
          parentPosition={divPos}
        >
          <button
            type="button"
            className={`button orange icon ${!editItem ? " disabled" : ""}`}
            onClick={() => {}}
          >
            <span className="sr-only">Delete selector</span>
            <Delete />
          </button>
        </OpenCloseWithPositioning>
      </div>
  );
};
