import { itemIF } from "../../../data/types/item";





export const getassignedClassNames = ({
  assignedIds,
  selectors,
  postfix = "",
}: {
  assignedIds: number[] | undefined;
  selectors?: itemIF[] | undefined;
  postfix?: string;
}) => {
  let classNames = "";
  if (!assignedIds || assignedIds.length === 0 || !selectors) {
    return classNames;
  }
  assignedIds.forEach((selectorId) => {
    const selector = selectors.find((sel) => sel.id === selectorId);
    if (selector && selector.value?.main?.value) {
      classNames += `${classNames === "" ? "" : " "}${selector.value.main.value
        }${postfix}`;
    }
  });
  return classNames;
};



