import React, { FC } from "react";
import { ReactComponent as SplitScreenVertically } from "../../assets/imgs/split-screen-vertically.svg";
import { EditFieldSelect } from "../forms/Select2";

import "./Toolbar.css";

const widthHeightOptions = [
  { id: "320px" },
  { id: "480px" },
  { id: "768px" },
  { id: "1024px" },
  { id: "1280px" },
  { id: "1440px" },
  { id: "1600px" },
  { id: "1920px" },
  { id: "2560px" },
  { id: "3200px" },
  { id: "3840px" },
];

const ScreenWidthHeightInputs: FC<{
  previewWidth: string;
  setPreviewWidth: (previewWidth: string) => void;
  previewHeight: string;
  setPreviewHeight: (previewHeight: string) => void;
  splitScreenMode?: boolean;
}> = ({
  previewWidth,
  setPreviewWidth,
  previewHeight,
  setPreviewHeight,
  splitScreenMode,
}) => {
  return (
    <div className="flex gap-1">
      <label className="label">
        <span className="blue">Width</span>
        <EditFieldSelect
          options={[{ id: "100%" }, ...widthHeightOptions]}
          fieldValue={previewWidth}
          isFieldDisabled={splitScreenMode}
          setValue={(value: string) => {
            setPreviewWidth(value);
          }}
        />
      </label>
      <label className="label">
        <span className="blue">Height</span>
        <EditFieldSelect
          options={widthHeightOptions}
          fieldValue={previewHeight}
          isFieldDisabled={splitScreenMode}
          setValue={(value: string) => {
            setPreviewHeight(value);
          }}
        />
      </label>
    </div>
  );
};

const FullSplitScreenButtons: FC<{
  splitScreenMode: boolean;
  setSplitScreenMode: (splitScreenMode: boolean) => void;
}> = ({ splitScreenMode, setSplitScreenMode }) => {
  return (
    <label className="label right">
      <span className="blue">Split Screen</span>
      <div
        className={`toolbar-button${splitScreenMode ? " active" : ""}`}
        onClick={() => {
          setSplitScreenMode(!splitScreenMode);
        }}
      >
        <SplitScreenVertically />
      </div>
    </label>
  );
};

export const PreviewToolbar: FC<{
  pWidth: string;
  setPWidth: (previewWidth: string) => void;
  pHeight: string;
  setPHeight: (previewHeight: string) => void;
  splitScreenMode: boolean;
  setSplitScreenMode: (splitScreenMode: boolean) => void;
}> = ({
  pWidth,
  setPWidth,
  pHeight,
  setPHeight,
  splitScreenMode,
  setSplitScreenMode,
}) => {
  return (
    <div className="container preview-toolbar form-elements-wrapper">
      <ScreenWidthHeightInputs
        previewWidth={pWidth}
        setPreviewWidth={setPWidth}
        previewHeight={pHeight}
        setPreviewHeight={setPHeight}
        splitScreenMode={splitScreenMode}
      />
      <FullSplitScreenButtons
        splitScreenMode={splitScreenMode}
        setSplitScreenMode={setSplitScreenMode}
      />
    </div>
  );
};
