import { useEffect, MutableRefObject } from "react";

export const useOutsideAlerter = ({
  ref,
  setOpen,
  onClose
}: {
  ref: MutableRefObject<HTMLElement | null>;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
}) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
        if (onClose) {
          onClose();
        }
      }
    }

    // Adding event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setOpen, onClose]);
};
