import { FC } from "react";
import { RgbaColor } from "react-colorful";
import {
  itemIF,
  metaIF,
  groupIF,
  EMetaFieldTypes,
} from "../../data/types/item";
import { getOptions } from "../../lib/items/helper";
import {
  getSetValueFn,
  TGetSetValueFnParams,
} from "../../lib/form/getSetValueFn";
import {
  getAllAssignedIdsInSnippet,
  getAssignedIdFieldType,
} from "../../lib/items/getIds";
import { MainEditField } from "./MainEditField";
import { AssignId } from "./AssignId";
import { Tooltip } from "../atoms/tooltip";
import { snippetIF } from "../../data/types/snippets";

const getPresetColours = (snippet: snippetIF): RgbaColor[] => {
  const stylepacks = snippet?.data?.stylepacks;
  const stylepack = stylepacks?.find(
    (s) => s.id === snippet?.data?.editStylepackId
  );
  const presetColourItems = stylepack?.value?.stylepackColors
    ?.value as itemIF[];
  return presetColourItems?.map(
    (c: itemIF) => c.value?.color.value as RgbaColor
  );
};

export const RenderGroupFieldsOnly: FC<{
  group: groupIF;
  dependendOnDisabledFields: string[];
  meta?: metaIF;
  isGroupDisabled?: boolean;
  editItem: itemIF;
  setValueFnParamsObj: TGetSetValueFnParams;
  tooltipText?: string;
  editSnippet?: snippetIF;
}> = ({
  group,
  dependendOnDisabledFields,
  meta,
  isGroupDisabled,
  editItem,
  setValueFnParamsObj,
  tooltipText,
  editSnippet
}) => {
  const presetColours = editSnippet && getPresetColours(editSnippet);
  return (
    <>
      {group.fields.map((fieldName, fieldIndex) => {
        if (dependendOnDisabledFields.includes(fieldName)) {
          return null;
        }
        const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);
        if (!fieldMeta) {
          return null;
        }

        const options = getOptions({
          fieldMeta,
          snippet: editSnippet,
          editItemId: editItem?.id!,
        });

        // TODO: check condition in the input component and show error message

        const setValueFn = getSetValueFn({
          ...setValueFnParamsObj,
          fieldName,
          conditions: fieldMeta.conditions,
        });

        const displayName = group.display?.[fieldIndex] || fieldMeta.name;

        if (
          fieldMeta.type === EMetaFieldTypes.selectKeyframeName ||
          fieldMeta.type === EMetaFieldTypes.assignSvgFilterId ||
          fieldMeta.type === EMetaFieldTypes.assignSvgGradientId ||
          fieldMeta.type === EMetaFieldTypes.selectPathId
        ) {
          const assignedFieldType = getAssignedIdFieldType(fieldMeta.type);
          const assignedIds = getAllAssignedIdsInSnippet({
            snippet: editSnippet,
            editItemId: editItem.id,
            isSpecialFieldType: assignedFieldType,
          });

          return (
            <label
              className={`label${isGroupDisabled ? " disabled" : ""}`}
              key={fieldIndex}
            >
              <span>{displayName}</span>
              <AssignId
                key={fieldName}
                isSpecialFieldType={assignedFieldType}
                assignedIds={assignedIds}
                displayName={displayName}
                isFieldDisabled={isGroupDisabled}
                fieldValue={editItem.value?.[fieldName]?.value}
                setValue={setValueFn && setValueFn(EMetaFieldTypes.string)}
              />
            </label>
          );
        }

        return (
          <label className="label" key={fieldIndex}>
            <span>{displayName}</span>
            <MainEditField
              key={fieldIndex}
              fieldMeta={fieldMeta}
              displayName={group.display?.[fieldIndex]}
              valueObj={editItem.value?.[fieldName]}
              isFieldDisabled={isGroupDisabled}
              options={options}
              setValueFn={setValueFn}
              presetColours={presetColours}
            />
            {tooltipText && <Tooltip tooltipText={tooltipText} />}
          </label>
        );
      })}
    </>
  );
};
