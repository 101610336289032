import { useEffect, useState } from "react";
import { useStateContext } from "../data/main/state";
import { setEditSnippetId } from "../lib/reducer/snippet/actions";
import { SUB_PAGES } from "../data/settings/constants";
import CRUDHtml from "../components/html/Main2";
import Mysnippets from "../components/mySnippets/Main2";
import CRUDCSS from "../components/css/Main2";
import CRUDSVG from "../components/svg/Main2";
import MainLayout from "../components/MainLayout";
import Preview from "../components/preview/Editor";
import { SvgPreview } from "../components/preview/SvgPreview";
import { MidNav, TSelOrProp } from "../components/navbar/MidNav";
import moment from "moment";

const Code = () => {
  const {
    state: {
      settings: { selectedSubPage },
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const [selOrProp, setSelOrProp] = useState("SELECTOR" as TSelOrProp);

  const editSnippet = snippets.find((s) => s.id === editSnippetId);

  useEffect(() => {
    if (!editSnippet && snippets.length > 0) {
      let newestSnippet = snippets[0];
      for (const s of snippets) {
        if (moment(s.updatedAt).isAfter(moment(newestSnippet.updatedAt))) {
          newestSnippet = s;
        }
      }
      dispatch(setEditSnippetId({ snippetId: snippets[0].id }));
    }
  }, [editSnippet, snippets, dispatch]);

  return (
    <MainLayout
      title="Snippet"
      description="Define reusable units of HTML, Selectors, CSS and SVGs with the help of a Snippet."
    >
      {selectedSubPage !== SUB_PAGES.SVG && <Preview snippet={editSnippet} />}
      {selectedSubPage === SUB_PAGES.SVG && (
        <SvgPreview snippet={editSnippet} />
      )}

      <div className="mt-2r">
        <MidNav selOrProp={selOrProp} setSelOrProp={setSelOrProp} />
      </div>

      <div className="container mt-2r">
        {selectedSubPage === SUB_PAGES.SNIPPETS && <Mysnippets />}
        {selectedSubPage === SUB_PAGES.HTML && <CRUDHtml />}
        {selectedSubPage === SUB_PAGES.CSS && <CRUDCSS selOrProp={selOrProp} />}
        {selectedSubPage === SUB_PAGES.SVG && <CRUDSVG />}
      </div>
    </MainLayout>
  );
};

export default Code;
