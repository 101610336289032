import { FC } from "react";
import MainLayout from "../components/MainLayout";
import Gallery from "../components/library/Gallery";

const LibraryPage: FC<{ isGallery?: boolean }> = ({ isGallery }) => {
  return (
    <MainLayout title="Gallery" description="The world of Snippets">
      <Gallery />
    </MainLayout>
  );
};
export default LibraryPage;
