import React, { useState, useRef, FC } from "react";
import { ReactComponent as SortArrowDown } from "../../assets/imgs/SortArrowDown.svg";
import { useOutsideAlerter } from "../atoms/detectClickOutside";
import "./OpenPopup.css";

export const OpenPopup: FC<{
    PopupInner: React.ReactElement<{
      setIsMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    }>;
    buttonText?: string;
    direction?: "right";
    width?: string;
  }> = ({ PopupInner, buttonText = "Sort", direction, width = "w-10r" }) => {
    const wrapperRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useOutsideAlerter({ ref: wrapperRef, setOpen: setIsMenuOpen });
  
    return (
      <div className={`button-and-popup-container`}>
        <button
          type="button"
          className="button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {buttonText}
          <SortArrowDown />
        </button>
        <div
          className={`popup-container ${width}${isMenuOpen ? "" : " off"}${direction === "right" ? " right" : ""}`}
          ref={wrapperRef}
        >
          {React.cloneElement(PopupInner, { setIsMenuOpen })}
        </div>
      </div>
    );
  };
  