import { itemIF } from "../../data/types/item";
import { snippetIF } from "../../data/types/snippets";

export const buildFontImportStr = (googleFamilies: string[]) => {
  if (googleFamilies.length === 0) {
    return "";
  }
  const googleFamiliesStrs = googleFamilies.map((fam) => `family=${fam}`);
  const families = googleFamiliesStrs.join("&");

  const importStr = `@import url('https://fonts.googleapis.com/css2?${families}&display=swap');`;
  
  return importStr;
};

export const getGoogleFontImportStringForSnippet = ({
  snippet,
}: {
  snippet: snippetIF;
}) => {
  const snippetFontFamilies = snippet?.data?.properties?.filter(
    (p) => p.name === "font-family"
  );

  if (!snippetFontFamilies) {
    return "";
  }

  const fontFamilyNames = [] as string[];
  snippetFontFamilies.forEach((f) => {
    if (f.value?.main?.value && !fontFamilyNames.includes(f.value?.main?.value)) {
      fontFamilyNames.push(f.value?.main?.value);
    }
  });

  const stylepack = snippet?.data.stylepacks?.find(s => s.id === snippet.data.editStylepackId);

  const fonts = stylepack?.value?.stylepackFonts.value as itemIF[];
  
  const newImportFamilyNames = [] as string[];
  fontFamilyNames.forEach((ffName) => {
    const font = fonts?.find(f => f.value?.font?.value === ffName); 
    if(font?.value?.importFamilyName) {
      newImportFamilyNames.push(font.value.importFamilyName.value);
    }
  });

  return buildFontImportStr(newImportFamilyNames);
};
