import { settingsIF, initialSettings } from "../settings/constants";
import { snippetsIF } from "../types/snippets";
import {initialSnippets} from "../snippet/initalValues";

export interface initialMainIF {
  settings: settingsIF;
  snippet: snippetsIF;
}

export const initialMain: initialMainIF = {
  settings: initialSettings,
  snippet: {
    snippets: initialSnippets.snippets,
    editSnippetId: initialSnippets.editSnippetId,
  },
};
