import { itemValueIF } from "../../../data/types/item";

export type LanguageItem = {
  language: string;
  code: string;
};

export type TFontItem = {
  import: string;
  css: string;
  name: string;
};

export type HtmlSelectorItem = string;

export type TagItem = string;

export type PredictiveSearchItem =
  | LanguageItem
  | TFontItem
  | HtmlSelectorItem
  | TagItem;

  type TLanguage = {
    language: string;
    code: string;
  };
  
  type THtmlSelector = string; // Assuming HtmlSelectorItem is simply a string for this example.
  
  export type TFilterFn<T> = (userInput: string) => (item: T) => boolean;
  export type TSortFn<T> = (userInput: string) => (itemA: T, itemB: T) => number;
  export type TConvertToItemValueFn<T> = (data: T) => itemValueIF | undefined
  
  type TPredictiveSearchHelper<T> = {
    filter: TFilterFn<T>;
    sort: TSortFn<T>
    convertToItemValue?: TConvertToItemValueFn<T>
  };
  
  export type TPredictiveSearchHelpers = {
    language: TPredictiveSearchHelper<TLanguage>;
    fonts: TPredictiveSearchHelper<TFontItem>;
    htmlSelectors: TPredictiveSearchHelper<THtmlSelector>;
    tags: TPredictiveSearchHelper<HtmlSelectorItem>;
  };
  

export const isLanguageItem = (item: PredictiveSearchItem) => {
  if ((item as LanguageItem).code) {
    return item as LanguageItem;
  }
};

export const isFontItem = (item: PredictiveSearchItem) => {
  if ((item as TFontItem).name) {
    return item as TFontItem;
  }
};

export const isHtmlSelectorItem = (item: PredictiveSearchItem) => {
  if ((typeof item as HtmlSelectorItem) === "string") {
    return item as HtmlSelectorItem;
  }
};

export const isTagItem = (item: PredictiveSearchItem) => {
  if ((typeof item as TagItem) === "string") {
    return item as TagItem;
  }
};

export type SetMethods = {
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setFilteredSuggestions: React.Dispatch<React.SetStateAction<LanguageItem[]>>;
};
