import {
  LanguageItem,
  TFontItem,
  HtmlSelectorItem,
  TPredictiveSearchHelpers,
  TFilterFn,
  TSortFn,
  TConvertToItemValueFn,
} from "./types";
import { itemValueIF, TAutocomplete } from "../../../data/types/item";

export const convertFontsDataToItemValue = (font: TFontItem) => {
  if (!font.name || !font.css || !font.import) return;
  const fontAsItemValue: itemValueIF = {};

  fontAsItemValue.default = {
    value: font.name,
  };
  fontAsItemValue.newCss = {
    value: font.css,
  };
  fontAsItemValue.importFamilyName = {
    value: font.import,
  };
  return fontAsItemValue;
};

const languageFilter = (userInput: string) => {
  return (item: LanguageItem) => {
    if (!userInput) return false;
    if (!item.language && !item.code) return false;
    if (!item.code) return false;

    const matchesLanguage = item.language
      .toLowerCase()
      .includes(userInput.toLowerCase());
    if (matchesLanguage) return true;

    return item.code.toLowerCase().includes(userInput.toLowerCase());
  };
};

const languageSort = (userInput: string) => {
  return (itemA: LanguageItem, itemB: LanguageItem) => {
    const userInputLength = userInput.length;
    if (
      itemA.code.slice(0, userInputLength).toLowerCase() ===
      userInput.toLowerCase()
    ) {
      return -1;
    }
    if (
      itemB.code.slice(0, userInputLength).toLowerCase() ===
      userInput.toLowerCase()
    ) {
      return 1;
    }
    if (
      itemA.language.slice(0, userInputLength).toLowerCase() ===
      userInput.toLowerCase()
    ) {
      return -1;
    }
    if (
      itemB.language.slice(0, userInputLength).toLowerCase() ===
      userInput.toLowerCase()
    ) {
      return 1;
    }
    return -1;
  };
};

const fontFilter = (userInput: string) => {
  return (item: TFontItem) => {
    if (!item.name || !userInput) return false;

    return item.name.toLowerCase().includes(userInput.toLowerCase());
  };
};

const fontSort = (userInput: string) => {
  return (itemA: TFontItem, itemB: TFontItem) => {
    if (
      itemA.name.slice(0, userInput.length).toLowerCase() ===
      userInput.toLowerCase()
    ) {
      return -1;
    }
    if (
      itemB.name.slice(0, userInput.length).toLowerCase() ===
      userInput.toLowerCase()
    ) {
      return 1;
    }
    if (itemA.name.toLowerCase() > itemB.name.toLowerCase()) {
      return 1;
    }
    if (itemA.name.toLowerCase() < itemB.name.toLowerCase()) {
      return -1;
    }
    return -1;
  };
};

const htmlSelectorsFilter = (userInput: string) => {
  return (item: HtmlSelectorItem) => {
    if (!item) return false;
    if (!userInput) return false;

    return item.toLowerCase().includes(userInput.toLowerCase());
  };
};

const htmlSelectorsSort = (userInput: string) => {
  return (itemA: HtmlSelectorItem, itemB: HtmlSelectorItem) => {
    const userInputLength = userInput.length;
    if (
      itemA.slice(0, userInputLength).toLowerCase() === userInput.toLowerCase()
    ) {
      return -1;
    }
    if (
      itemB.slice(0, userInputLength).toLowerCase() === userInput.toLowerCase()
    ) {
      return 1;
    }
    return -1;
  };
};

const tagsFilter = (userInput: string) => {
  return (item: HtmlSelectorItem) => {
    if (!userInput || !item) return false;

    return item.toLowerCase().includes(userInput.toLowerCase());
  };
};

const tagsSort = (userInput: string) => {
  return (itemA: HtmlSelectorItem, itemB: HtmlSelectorItem) => {
    const userInputLength = userInput.length;
    if (
      itemA.slice(0, userInputLength).toLowerCase() === userInput.toLowerCase()
    ) {
      return -1;
    }
    if (
      itemB.slice(0, userInputLength).toLowerCase() === userInput.toLowerCase()
    ) {
      return 1;
    }
    return -1;
  };
};

export const PREDICTIVE_SEARCH_HELPERS: TPredictiveSearchHelpers = {
  language: {
    filter: languageFilter,
    sort: languageSort,
  },
  fonts: {
    filter: fontFilter,
    sort: fontSort,
    convertToItemValue: convertFontsDataToItemValue,
  },
  htmlSelectors: {
    filter: htmlSelectorsFilter,
    sort: htmlSelectorsSort,
  },
  tags: {
    filter: tagsFilter,
    sort: tagsSort,
  },
};

export const isValidKey = (
  key: any
): key is typeof PREDICTIVE_SEARCH_HELPERS => {
  return key in PREDICTIVE_SEARCH_HELPERS;
};

export const getFilterSortConvertFns = ({
  autocomplete = "fonts",
}: {
  autocomplete?: TAutocomplete;
}) => {
  let filterFn: TFilterFn<any> = PREDICTIVE_SEARCH_HELPERS.fonts
    .filter as TFilterFn<TFontItem>;
  let sortFn: TSortFn<any> = PREDICTIVE_SEARCH_HELPERS.fonts
    .sort as TSortFn<TFontItem>;
  let convertFn: TConvertToItemValueFn<any> = PREDICTIVE_SEARCH_HELPERS.fonts
    .convertToItemValue as TConvertToItemValueFn<TFontItem>;

  if (isValidKey(autocomplete)) {
    switch (autocomplete) {
      case "language":
        filterFn = PREDICTIVE_SEARCH_HELPERS.language
          .filter as TFilterFn<LanguageItem>;
        sortFn = PREDICTIVE_SEARCH_HELPERS.language
          .sort as TSortFn<LanguageItem>;
        break;
      case "htmlSelectors":
        filterFn = PREDICTIVE_SEARCH_HELPERS.htmlSelectors
          .filter as TFilterFn<string>;
        sortFn = PREDICTIVE_SEARCH_HELPERS.htmlSelectors
          .sort as TSortFn<string>;
        break;
      case "tags":
        filterFn = PREDICTIVE_SEARCH_HELPERS.tags.filter as TFilterFn<string>;
        sortFn = PREDICTIVE_SEARCH_HELPERS.tags.sort as TSortFn<string>;
        break;
    }
  }
  return { filterFn, sortFn, convertFn };
};
