import { setIsLoading } from "../reducer/settings/actions";
import {
  CognitoIdentityProviderClient,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { TFormData, TUserState, TMsgType } from "./helpers";

const client = new CognitoIdentityProviderClient({ region: "eu-west-2" });

export const signUpFn = async ({
  formData,
  setSignType,
  setSignInErrorMessage,
  dispatch,
}: {
  formData: TFormData;
  setSignType: (signType: TUserState) => void;
  setSignInErrorMessage: (signInErrorMessage: TMsgType) => void;
  dispatch?: (value: any) => void;
}) => {
  try {
    dispatch && dispatch(setIsLoading(true));

    const email = formData.email.data;
    const password = formData.password.data;
    const username = formData.username?.data;
    const ClientId = process.env.REACT_APP_CLIENT_ID;

    const signUpParams = {
      ClientId: ClientId,
      Username: email,
      Password: password,
      UserAttributes: [{ Name: "email", Value: email },{
        Name: "preferred_username",
        Value: username as string,
      }]
    };

    const signUpResponse = await client.send(new SignUpCommand(signUpParams));
    if (signUpResponse) {
      setSignInErrorMessage({
        successMsg:
          "We sent a verification code to your email " +
          email +
          ". Please enter the code to verify your email",
        errMsg: "",
      });
      setSignType("verifyemail");
    }
  } catch (err) {
    setSignInErrorMessage({
      successMsg: "",
      errMsg: (err as Error).message,
    });
  } finally {
    dispatch && dispatch(setIsLoading(false));
  }
};
