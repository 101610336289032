import { FC, ReactNode } from "react";
import "./alert.css";

export const Info: FC<{
  isAlert?: boolean;
  children: ReactNode;
}> = ({ isAlert, children }) => {
  return (
    <div
      className={`text-align-center alert-msg${
        isAlert ? " alert-msg-color" : ""
      }`}
    >
      {children}
    </div>
  );
};
