import {
  CognitoIdentityProviderClient,
  ConfirmSignUpCommand,
  ResendConfirmationCodeCommand,
} from "@aws-sdk/client-cognito-identity-provider";

import { addUserToGroup } from "../lambda/addUserToGroup";
import { setIsLoading } from "../reducer/settings/actions";
import { TFormData, TUserState, TMsgType } from "./helpers";

const client = new CognitoIdentityProviderClient({ region: "eu-west-2" });
const userPoolId = process.env.REACT_APP_USER_POOL_ID;

export const verifyFn = async ({
  formData,
  setFormData,
  setSignType,
  setSignInErrorMessage,
  dispatch,
}: {
  formData: TFormData;
  setFormData: (TFormData: TFormData) => void;
  setSignType: (signType: TUserState) => void;
  setSignInErrorMessage: (signInErrorMessage: TMsgType) => void;
  dispatch?: (value: any) => void;
}) => {
  try {
    dispatch && dispatch(setIsLoading(true));

    const email = formData.email.data;
    const code = formData.verify.data;
    const clientId = process.env.REACT_APP_CLIENT_ID;

    const confirmSignUpParams = {
      Username: email,
      ClientId: clientId,
      ConfirmationCode: code,
    };

    const confirmSignUpResponse = await client.send(
      new ConfirmSignUpCommand(confirmSignUpParams)
    );

    if (confirmSignUpResponse) {
      await addUserToGroup({
        userPoolId: userPoolId as string,
        username: email,
        groupName: "everyone",
      });

      setSignType("signin");
      setSignInErrorMessage({
        successMsg: "You are now successfully verified. Please sign in.",
        errMsg: "",
      });      
    }
  } catch (err) {
    setSignInErrorMessage({
      successMsg: "",
      errMsg: (err as Error).message,
    });
  } finally {
    dispatch && dispatch(setIsLoading(false));

    setFormData({
      ...formData,
      verify: {
        ...formData.verify,
        data: "",
      },
    });
  }
};

export const resendSignUpVerificationCode = async ({
  formData,
  setSignInErrorMessage,
  dispatch,
}: {
  formData: TFormData;
  setSignInErrorMessage: (signInErrorMessage: TMsgType) => void;
  dispatch?: (value: any) => void;
}) => {
  try {
    dispatch && dispatch(setIsLoading(true));

    const email = formData.email.data;
    const clientId = process.env.REACT_APP_CLIENT_ID;

    const command = new ResendConfirmationCodeCommand({
      ClientId: clientId,
      Username: email,
    });

    await client.send(command);

    setSignInErrorMessage({
      successMsg:
        "A new verification code has been sent to your email: " +
        email +
        ". Please check your inbox.",
      errMsg: "",
    });
  } catch (err) {
    setSignInErrorMessage({
      successMsg: "",
      errMsg: "Failed to resend the verification code. Please try again later.",
    });
  } finally {
    dispatch && dispatch(setIsLoading(false));
  }
};
