import { itemIF } from "../../../data/types/item";

const getOnclickClassName = ({
  oldClassName,
  eventClassNameWithPostfix,
  mode,
}: {
  oldClassName: string;
  eventClassNameWithPostfix: string;
  mode: string;
}) => {
  const newClassNameAdd = `${oldClassName}${
    oldClassName === "" ? "" : " "
  }${eventClassNameWithPostfix}`;
  const newClassNameRemove = `${oldClassName.replace(
    eventClassNameWithPostfix,
    ""
  )}`;

  if (mode === "Add" && !oldClassName.includes(eventClassNameWithPostfix)) {
    return [newClassNameAdd];
  }
  if (mode === "Remove" && oldClassName.includes(eventClassNameWithPostfix)) {
    return [newClassNameRemove];
  }
  if (mode === "Toggle") {
    if (oldClassName.includes(eventClassNameWithPostfix)) {
      return [oldClassName, newClassNameRemove];
    } else {
      return [oldClassName, newClassNameAdd];
    }
  }
};

export const getEventClassNames = ({
  itemEvent,
  selectors,
  oldClassName = "",
  postfix,
}: {
  itemEvent: itemIF;
  selectors: itemIF[];
  oldClassName?: string;
  postfix?: string;
}) => {
  const mode = itemEvent.value?.mode.value;
  const newId = itemEvent.value?.classId?.value;
  const eventSelector = selectors.find((s) => s.id === newId);
  if (!eventSelector) {
    return;
  }
  const eventClassName = eventSelector.value?.main?.value;
  if (!eventClassName) {
    return;
  }
  const eventClassNameWithPostfix = `${eventClassName}${postfix}`;
  return getOnclickClassName({
    oldClassName,
    eventClassNameWithPostfix,
    mode,
  });
};

export const getNewEventClassName = ({
  mode,
  eventClassNames,
  oldClassName = "",
}: {
  mode: string;
  eventClassNames: string[];
  oldClassName?: string;
}) => {
  if (
    mode === "Add" &&
    eventClassNames[0] &&
    eventClassNames[0] !== "" &&
    !oldClassName.includes(eventClassNames[0])
  ) {
    return `${oldClassName}${oldClassName === "" ? "" : " "}${
      eventClassNames[0]
    }`;
  }
  if (
    mode === "Remove" &&
    eventClassNames[0] &&
    eventClassNames[0] !== "" &&
    oldClassName.includes(eventClassNames[0])
  ) {
    return `${oldClassName.replace(eventClassNames[0], "").trim()}`;
  }
  if (
    mode === "Toggle" &&
    eventClassNames.length === 2 &&
    eventClassNames.filter((classname) => classname === "").length < 2
  ) {
    // eventClassNames has both names: before and after toggle
    const currentClassNameIndex = eventClassNames.findIndex(
      (classname) => classname === oldClassName
    );
    if (currentClassNameIndex === 0) {
      return eventClassNames[1];
    } else {
      return eventClassNames[0];
    }
  }
};
