import { metaIF, itemIF, TItemsInCategories } from "../../data/types/item";
import { isAllowedAddElement } from "./getAddAsSiblingOrChild";

const disableAddElement = ({
  m,
  allowed,
}: {
  m: metaIF;
  allowed?: string[];
}) => {
  if (allowed) {
    if (allowed.includes("inline")) {
      if (m.level !== "inline") {
        return true;
      }
    } else {
      if (!allowed.includes(m.name)) {
        return true;
      }
    }
  }
  return false;
};

export const addItemIsDisabled = ({
  m,
  em,
  pm,
}: {
  m: metaIF;
  em: metaIF;
  pm?: metaIF;
}) => {
  if (em.name === "@media" || em.name === "@keyframes") {
    if (m.availableFromLevel === 2) {
      return true;
    }
  }
  // if add element meta has a allowed parents list, then the edit element or its parent must be in the list
  if (m.allowedParent) {
    if (
      m.allowedParent.includes(em.name) ||
      (pm && m.allowedParent.includes(pm.name))
    ) {
      return false;
    }
    return true;
  }

  const mAllowedSiblings = isAllowedAddElement({
    allowed: m.allowedSiblings,
    m: em,
  });
  if (mAllowedSiblings) {
    return false;
  }

  const emAllowedSiblings = isAllowedAddElement({
    allowed: em.allowedSiblings,
    m: m,
  });
  if (emAllowedSiblings) {
    return false;
  }

  if (pm) {
    const pmAllowedChildren = isAllowedAddElement({
      allowed: pm.allowedChildren,
      m: m,
    });
    if (pmAllowedChildren) {
      return false;
    }
  }

  // add item is disabled
  const emNotAllowedChildren = disableAddElement({
    allowed: em.allowedChildren,
    m: m,
  });

  const mNotAllowedSiblings = disableAddElement({
    allowed: m.allowedSiblings,
    m: em,
  });
  const emNotAllowedSiblings = disableAddElement({
    allowed: em.allowedSiblings,
    m: m,
  });

  if (emNotAllowedChildren && (mNotAllowedSiblings || emNotAllowedSiblings)) {
    return true;
  }
  return false;
};

export const getAddItemIsDisabled = ({
  editItem,
  parent,
  items,
}: {
  editItem?: itemIF;
  parent?: itemIF;
  items: TItemsInCategories[];
}) => {
  const metas = items.reduce((r, iic) => {
    iic.items.forEach((m) => r.push(m));
    return r;
  }, [] as metaIF[]);
  const editMeta = metas.find((m) => m.name === editItem?.name);
  const parentMeta = metas.find((m) => m.name === parent?.name);

  if (!editMeta) {
    return metas.filter((h) => h.level === "inline").map((m) => m.name);
  }
  return metas
    .filter((m) => {
      const isDisabled = addItemIsDisabled({ m, em: editMeta, pm: parentMeta });
      if (isDisabled) {
        return true;
      }
      return false;
    })
    .map((m) => m.name);
};
