import { metaIF, EMetaFieldTypes } from "../../types/item";

export const SELECTORS_CHILD: metaIF[] = [
  {
    name: ":first-child",
    level: "inline",
    category: ["child"],
    availableFromLevel: 2,
    help: "### :first-child  \nSee for example [w3schools - :first-child](https://www.w3schools.com/cssref/sel_firstchild.asp)",
  },
  {
    name: ":first-of-type",
    level: "inline",
    category: ["child"],
    availableFromLevel: 2,
    help: "### :first-of-type  \nSee for example [w3schools - :first-of-type](https://www.w3schools.com/cssref/sel_first-of-type.asp)",
  },
  {
    name: ":last-child",
    level: "inline",
    category: ["child"],
    availableFromLevel: 2,
    help: "### :last-child  \nSee for example [w3schools - :last-child](https://www.w3schools.com/cssref/sel_last-child.asp)",
  },
  {
    name: ":last-of-type",
    level: "inline",
    category: ["child"],
    availableFromLevel: 2,
    help: "### :last-of-type  \nSee for example [w3schools - :last-child](https://www.w3schools.com/cssref/sel_last-of-type.asp)",
  },

  // TODO: fields in a group don't need all to have onOff set to true, just the first field in the group needs the onOff flag for the whole group to be active per default and can't be deactivated

  {
    name: ":nth-child",
    level: "inline",
    category: ["child"],
    availableFromLevel: 2,
    fieldsOrder: ["main", "value", "formula"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          { id: "even" },
          { id: "odd" },
          { id: "value" },
          { id: "formula" },
        ],
        wrapperFn: (valueObj) => `:nth-child(${valueObj.value})`,
        onOff: true,
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        wrapperFn: (valueObj) => `:nth-child(${valueObj.value})`,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "formula",
        type: EMetaFieldTypes.string,
        wrapperFn: (valueObj) => `:nth-child(${valueObj.value})`,
        onOff: true,
        dependentOn: {
          field: "main",
          values: ["formula"],
        },
      },
    ],
    help: "### :nth-child  \nSee for example [w3schools - :nth-child](https://www.w3schools.com/cssref/sel_nth-child.asp)",
    group: [
      {
        name: "mainG",
        display: [":nth-child", "value", "formula"],
        fields: ["main", "value", "formula"],
      },
    ],

    default: {
      main: {
        value: "odd",
      },
      value: {
        value: 2,
        onOff: true,
      },
      formula: {
        value: "n+3",
      },
    },
  },
  {
    name: ":nth-last-child",
    level: "inline",
    category: ["child"],
    availableFromLevel: 2,
    fieldsOrder: ["main", "value", "formula"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          { id: "even" },
          { id: "odd" },
          { id: "value" },
          { id: "formula" },
        ],
        wrapperFn: (valueObj) => `:nth-last-child(${valueObj.value})`,
        onOff: true,
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        wrapperFn: (valueObj) => `:nth-last-child(${valueObj.value})`,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "formula",
        type: EMetaFieldTypes.string,
        wrapperFn: (valueObj) => `:nth-last-child(${valueObj.value})`,
        dependentOn: {
          field: "main",
          values: ["formula"],
        },
      },
    ],
    group: [
      {
        name: "mainG",
        display: [":nth-last-child", "value", "formula"],
        fields: ["main", "value", "formula"],
      },
    ],
    help: "### :nth-last-child  \nSee for example [w3schools - :nth-last-child](https://www.w3schools.com/cssref/sel_nth-last-child.asp)",
    default: {
      main: {
        value: "odd",
      },
      value: {
        value: 2,
      },
      formula: {
        value: "n+3",
      },
    },
  },
  {
    name: ":nth-last-of-type",
    level: "inline",
    category: ["child"],
    availableFromLevel: 2,
    fieldsOrder: ["main", "value"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          { id: "even" },
          { id: "odd" },
          { id: "value" },
          { id: "formula" },
        ],
        wrapperFn: (valueObj) => `:nth-last-of-type(${valueObj.value})`,
        onOff: true,
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        wrapperFn: (valueObj) => `:nth-last-of-type(${valueObj.value})`,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "formula",
        type: EMetaFieldTypes.string,
        wrapperFn: (valueObj) => `:nth-last-of-type(${valueObj.value})`,
        dependentOn: {
          field: "main",
          values: ["formula"],
        },
      },
    ],
    group: [
      {
        name: "mainG",
        display: [":nth-last-of-type", "value"],
        fields: ["main", "value"],
      },
    ],
    help: "### :nth-last-of-type  \nSee for example [w3schools - :nth-last-of-type](https://www.w3schools.com/cssref/sel_nth-last-of-type.asp)",
    default: {
      main: {
        value: "odd",
      },
      value: {
        value: 2,
      },
    },
  },
  {
    name: ":nth-of-type",
    level: "inline",
    category: ["child"],
    availableFromLevel: 2,
    fieldsOrder: ["main", "value"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [
          { id: "even" },
          { id: "odd" },
          { id: "value" },
          { id: "formula" },
        ],
        wrapperFn: (valueObj) => `:nth-of-type(${valueObj.value})`,
        onOff: true,
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        wrapperFn: (valueObj) => `:nth-of-type(${valueObj.value})`,
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "formula",
        type: EMetaFieldTypes.string,
        wrapperFn: (valueObj) => `:nth-of-type(${valueObj.value})`,
        dependentOn: {
          field: "main",
          values: ["formula"],
        },
      },
    ],
    group: [
      {
        name: "mainG",
        display: [":nth-of-type", "value"],
        fields: ["main", "value"],
      },
    ],
    help: "### :nth-of-type  \nSee for example [w3schools - :nth-of-type](https://www.w3schools.com/cssref/sel_nth-of-type.asp)",
    default: {
      main: {
        value: "odd",
      },
      value: {
        value: 2,
      },
    },
  },
];
