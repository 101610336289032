import { itemIF } from "../../../data/types/item";
import { snippetIF } from "../../../data/types/snippets";
import { getFeaturingSelectors } from "./getFeaturingSelectors";
import { getCssForStep, getCssForMedia } from "./getCssCode";
import { getCssForClassOrHtml } from "./getSelectorData";

export type TCreateComposedSelectorItem = {
  selector?: itemIF;
  selectors?: itemIF[];
  properties?: itemIF[];
  stylepack?: itemIF;
  lineBreak?: string;
  addSpaceAtLineStart?: string;
};

const createComposedSelectorItem = ({
  selector,
  selectors,
  properties,
  lineBreak,
  addSpaceAtLineStart = "",
  stylepack
}: TCreateComposedSelectorItem) => {
  const data = {
    selector,
    selectors,
    properties,
    lineBreak,
    addSpaceAtLineStart,
    stylepack
  } as TCreateComposedSelectorItem;
  if (selector?.name === "@keyframes") {
    const ssForKeyframes = {
      nameStr: `@keyframes ${selector.value?.main.value}`,
      selector,
    };
    return ssForKeyframes;
  } else if (selector?.name === "step") {
    const cssForStep = getCssForStep(data);
    return cssForStep;
  } else if (selector?.name === "@media") {
    const cssForMedia = getCssForMedia(data);
    return cssForMedia;
  } else {
    const cssForClassOrHtml = getCssForClassOrHtml(data);
    return cssForClassOrHtml;
  }
};

export const initializeComposedSelectorItems = ({
  snippet,
}: {
  snippet: snippetIF;
}) => {
  const linebreak = "\n";

  const htmls = snippet.data?.htmls || [];
  const selectors = snippet.data?.selectors || [];
  const properties = snippet.data?.properties || [];

  const stylepack = snippet.data?.stylepacks?.find(
    (s) => s.id === snippet.data?.editStylepackId
  );

  const { classSelectors, mediaSelectors, keyframeSelectors } =
    getFeaturingSelectors({
      htmls,
      selectors,
      properties,
    });

  const classComposedSelectorItems = classSelectors.map((item) =>
    createComposedSelectorItem({
      selector: item,
      selectors,
      properties,
      lineBreak: linebreak,
      stylepack
    })
  );

  const mediaComposedSelectorItems = mediaSelectors.map((item) =>
    createComposedSelectorItem({
      selector: item,
      selectors,
      properties,
      lineBreak: linebreak,
      stylepack
    })
  );

  const keyframeComposedSelectorItems = keyframeSelectors.map((item) =>
    createComposedSelectorItem({
      selector: item,
      selectors,
      properties,
      lineBreak: linebreak,
      stylepack
    })
  );

  return {
    classComposedSelectorItems,
    mediaComposedSelectorItems,
    keyframeComposedSelectorItems,
  };
};
