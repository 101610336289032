import { itemIF } from "../../data/types/item";

/*
  Add the item after the editItem or at the end of the items array of the respective selector.
  Increment the position of the properties after the editProp, if necessary.
  */

export const addCssProperties = ({
  properties,
  selectorId,
  editPropId,
  newProps,
}: {
  properties: itemIF[];
  selectorId: number;
  editPropId?: number;
  newProps: itemIF[];
}): {
  properties: itemIF[];
  editPropId: number;
} => {
  if (newProps.length === 0) {
    console.warn("No new properties to add");
    return { properties, editPropId: editPropId || 0 };
  }

  const maxPropsId = Math.max(...properties.map((p) => p.id), 0);
  const newPropsUId = newProps.map((p, i) => ({
    ...p,
    id: maxPropsId + i + 1,
    classId: selectorId,
  }));

  const editPropIndex = properties.findIndex((p) => p.id === editPropId);
  const editProp = properties[editPropIndex];
  if (editProp) {
    const newProps = properties.map((p) => {
      if (p.position > editProp.position) {
        return { ...p, position: p.position + newPropsUId.length };
      }
      return p;
    });
    const newPropsUPos = newPropsUId.map((p, i) => {
      return { ...p, position: editProp.position + i + 1 };
    });
    const updated = [
      ...newProps.slice(0, editPropIndex + 1),
      ...newPropsUPos,
      ...newProps.slice(editPropIndex + 1),
    ];
    return {
      properties: updated,
      editPropId: newPropsUPos[0].id,
    };
  } else {
    const maxPos = Math.max(...properties.map((p) => p.position), 0);
    const newPropsUPos = newPropsUId.map((p, i) => {
      return { ...p, position: maxPos + i + 1 };
    });
    const updated = [...properties, ...newPropsUPos];
    return {
      properties: updated,
      editPropId: newPropsUPos[0].id,
    };
  }
};
