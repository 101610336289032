import { snippetIF } from "../../data/types/snippets";
import { buildTree } from "../items/build-tree";
import { itemTypes } from "../meta/getMetas";
import { renderToString } from "react-dom/server";
import RenderElementAndChildren from "../../components/render/HtmlOrSvgElementAndChildren";
import RenderSvg from "../../components/preview/RenderSvg";

export const generateSvgString = (snippet: snippetIF): string => {
  return renderToString(<RenderSvg editSnippet={snippet} />);
};

export const generateHtmlForSnippet = (snippet: snippetIF): string => {
  const branches = buildTree(snippet.data.htmls);
  const html = renderToString(
    <>
      {snippet.snippetCss && <style dangerouslySetInnerHTML={{__html:snippet.snippetCss}}/>}
      {branches.map((item) => {
        return (
          <RenderElementAndChildren
            key={item.id}
            itemType={itemTypes.BLOCK_HTML}
            item={item}
            editSnippet={snippet}
          />
        );
      })}
    </>
  );
  return html;
};
