import { actionIF } from "../../../data/types/item";
import { snippetIF, snippetsIF } from "../../../data/types/snippets";
import { itemIF } from "../../../data/types/item";
import {
  DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES,
  CHANGE_SNIPPET,
} from "./actions";
import {
  COPY_SVG_FROM_OTHER_SNIPPET,
  CLONE_ITEM_AND_CHILDREN,
} from "../items/actions";
import { addNewItemWithDescendants } from "../../items/cloneItem";
import { deleteSelectorAndProperties } from "../items/deleteItem";
import { getEditItemDataStr, getItemsDataStr } from "../items/getItemData";
import { addSelectorsAndProperties } from "../../items/addSelectorsAndProperties";
import { itemTypes } from "../../meta/getMetas";
import { getNewSnippetAfterChangeInItems } from "../../snippets/getNewSnippetAfterchangeInItems";
import { getSelectorProperties } from "../../items/addItems";

type TSnippetReducerNew = {
  snippet: snippetsIF;
  action: actionIF;
};

export const changeSnippetReducer = ({
  snippet,
  action,
}: TSnippetReducerNew) => {
  const editSnippet = snippet.snippets.find(
    (s) => s.id === snippet.editSnippetId
  );
  const itemType = action.itemType;

  try {
    switch (action.type) {
      case DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES:
        if (!editSnippet) {
          console.warn(
            "DELETE_SELECTOR_DESCENDANTS_AND_PROPERTIES: no editSnippet"
          );
          return snippet;
        }
        const selectors = editSnippet?.data?.selectors;
        const editItemId = editSnippet?.data?.editSelectorId || 0;
        const itemId = action.selectorId ? action.selectorId : editItemId;
        const properties = editSnippet?.data?.properties;

        const deleteResult = deleteSelectorAndProperties({
          items: selectors,
          itemId,
          editItemId,
          properties,
        });

        return {
          ...snippet,
          snippets: snippet.snippets.map((s) => {
            if (s.id === snippet.editSnippetId) {
              return {
                ...s,
                data: {
                  ...s.data,
                  selectors: deleteResult.items,
                  editSelectorId: deleteResult.editItemId,
                  properties: deleteResult.properties,
                  editPropertyId: 0,
                },
              };
            }
            return s;
          }) as snippetIF[],
        };

      // TODO: when copying html/svg between snippets, so far the css variables are not copied
      // TODO: @selector media kids are not copied

      case COPY_SVG_FROM_OTHER_SNIPPET:
      case CLONE_ITEM_AND_CHILDREN:
        // close within the edit snippet
        if (!itemType || !editSnippet) {
          console.warn("CLONE_ITEM_AND_CHILDREN: no itemType or editSnippet");
          return snippet;
        }

        /* function addNewItemWithDescendants can be used to clone items within the edit snippet or from one snippet to the other. 
        If it's the latter, snippetFrom = snippetTo  */

        const snippetFrom = action.snippetFromId
          ? snippet.snippets.find((s) => s.id === action.snippetFromId)
          : editSnippet;
        if (!snippetFrom) {
          console.warn("CLONE_ITEM_AND_CHILDREN: no snippetFrom");
          return snippet;
        }

        const snippetTo = editSnippet;

        const ciaItemsDataStr = getItemsDataStr(itemType);
        const ciaEditItemDataStr = getEditItemDataStr({ itemType });

        /*
        if snippet from id !== snippet to id, 
          snippet from is selected by the user and passed in the action object
          the item to be copied is also selected by the user and passed in the action object as itemFromId
          the item to be copied after is also selected by the user and passed in the action object as itemToId

        if snippet from id === snippet to id,
          snippet from and to id is editSnippetId in the settings
          the item to be copied is editItemId in the settings
          the item to be copied after is editItemId in the settings
        */
        const ciaItemsFrom = snippetFrom.data[ciaItemsDataStr] as itemIF[];
        const ciaItemFromId =
          snippetFrom.id === snippetTo.id
            ? (snippetFrom.data[ciaEditItemDataStr] as number)
            : action.itemFromId;

        if (!ciaItemsFrom || !ciaItemFromId) {
          console.warn(
            "CLONE_ITEM_AND_CHILDREN: no items from or item from id"
          );
          return snippet;
        }

        const ciaItemsTo =
          snippetFrom.id === snippetTo.id
            ? ciaItemsFrom
            : (snippetTo.data[ciaItemsDataStr] as itemIF[]);

        const ciaItemToId =
          snippetFrom.id === snippetTo.id ? ciaItemFromId : action.itemToId;

        const ciacResult = addNewItemWithDescendants({
          itemType,
          itemsFrom: ciaItemsFrom,
          itemId: ciaItemFromId,
          itemsTo: ciaItemsTo,
          itemToId: ciaItemToId,
          isCopyBetweenSnippets: snippetFrom.id !== snippetTo.id,
        });

        if (!ciacResult) {
          console.warn("CLONE_ITEM_AND_CHILDREN: no ciacResult");
          return snippet;
        }

        const newItemsAdded = ciacResult.items;
        const newEditItemId = ciacResult.editItemId;

        let newSnippetTo = {
          ...snippetTo,
          data: {
            ...snippetTo.data,
            [ciaItemsDataStr]: newItemsAdded,
            [ciaEditItemDataStr]: newEditItemId,
          },
        } as snippetIF;

        if (
          snippetFrom.id !== snippetTo.id &&
          itemType !== itemTypes.SELECTORS
        ) {
          const itemIds = ciaItemsTo.map((i) => i.id);
          const aiNewItems = ciacResult.items.filter(
            (i) => !itemIds.includes(i.id)
          );

          const aSPResult = addSelectorsAndProperties({
            htmlsOSvgs: aiNewItems,
            selectorsFrom: snippetFrom.data.selectors as itemIF[],
            selectorsTo: snippetTo.data.selectors as itemIF[],
            propertiesFrom: snippetFrom.data.properties as itemIF[],
            propertiesTo: snippetTo.data.properties as itemIF[],
          });

          if (!aSPResult) {
            return {
              ...snippet,
              snippets: snippet.snippets.map((s) => {
                if (s.id === snippetTo.id) {
                  return newSnippetTo;
                }
                return s;
              }),
            };
          }

          const newItemsACReset = aSPResult.htmlsOSvgs;
          const allItems = newItemsAdded.map((i) => {
            const newItem2 = newItemsACReset.find((ni) => ni.id === i.id);
            if (newItem2) {
              return newItem2;
            }
            return i;
          });

          newSnippetTo = {
            ...newSnippetTo,
            data: {
              ...newSnippetTo.data,
              [ciaItemsDataStr]: allItems,
              selectors: aSPResult.selectors,
              properties: aSPResult.properties,
            },
          } as snippetIF;
        }

        if (
          snippetFrom.id === snippetTo.id &&
          itemType === itemTypes.SELECTORS
        ) {
          const newProperties = getSelectorProperties({
            propertiesFrom: snippetFrom.data.properties as itemIF[],
            propertiesTo: snippetFrom.data.properties as itemIF[],
            oldNewIds: ciacResult.oldNewIds,
          });
          newSnippetTo = {
            ...newSnippetTo,
            data: {
              ...newSnippetTo.data,
              properties: newProperties,
            },
          } as snippetIF;
        }

        const newSnippetCss = getNewSnippetAfterChangeInItems({
          editSnippet: newSnippetTo,
        });
        const newSnippetToUpdateCss = {
          ...newSnippetTo,
          snippetCss: newSnippetCss,
        } as snippetIF;

        return {
          ...snippet,
          snippets: snippet.snippets.map((s) => {
            if (s.id === snippetTo.id) {
              return newSnippetToUpdateCss;
            }
            return s;
          }),
        };

      case CHANGE_SNIPPET:
        const { fieldName, value } = action;
        if (
          !fieldName ||
          (!value && value !== "" && value !== 0 && value !== false)
        ) {
          console.warn("CHANGE_SNIPPET: no fieldName");
          return snippet;
        }

        snippet.snippets = snippet.snippets.map((s) => {
          if (s.id === snippet.editSnippetId) {
            return {
              ...s,
              [fieldName]: value,
            };
          }
          return s;
        });

        return snippet;

      default:
    }
  } catch (error) {
    console.error("snippetReducer error", error);
    return snippet;
  }
};
