import { itemIF } from "../../../data/types/item";

export const moveItemDownCss = ({
  items,
  editItemId,
  orUp,
}: {
  items: itemIF[];
  editItemId: number;
  orUp?: boolean;
}): itemIF[] => {
  const sorted = [...items].sort((a, b) => a.position - b.position);
  const propsNewPos = sorted.map((p, i) => {
    return {
      ...p,
      position: i + 1,
    };
  });
  const editItemIndex = propsNewPos.findIndex((p) => p.id === editItemId);
  if (editItemIndex === -1) {
    console.warn("MOVE_ITEM_DOWN_CSS: no editItem found");
    return items;
  }

  if (orUp) {
    return propsNewPos.map((p, i) => {
      if (i === editItemIndex) {
        return {
          ...p,
          position: p.position - 1,
        };
      }
      if (i === editItemIndex - 1) {
        return {
          ...p,
          position: p.position + 1,
        };
      }
      return p;
    });
  }

  const mdUpdated = propsNewPos.map((p, i) => {
    if (i === editItemIndex) {
      return {
        ...p,
        position: p.position + 1,
      };
    }
    if (i === editItemIndex + 1) {
      return {
        ...p,
        position: p.position - 1,
      };
    }
    return p;
  });
  return mdUpdated;
};
