import { PredictiveSearchItem } from "./types";

const language_codes = [
  { language: "Afar", code: "aa" },
  { language: "Abkhazian", code: "ab" },
  { language: "Avestan", code: "ae" },
  { language: "Afrikaans", code: "af" },
  { language: "Akan", code: "ak" },
  { language: "Amharic", code: "am" },
  { language: "Aragonese", code: "an" },
  { language: "Arabic", code: "ar" },
  { language: "Assamese", code: "as" },
  { language: "Avaric", code: "av" },
  { language: "Aymara", code: "ay" },
  { language: "Azerbaijani", code: "az" },
  { language: "Bashkir", code: "ba" },
  { language: "Belarusian", code: "be" },
  { language: "Bulgarian", code: "bg" },
  { language: "Bihari", code: "bh" },
  { language: "Bislama", code: "bi" },
  { language: "Bambara", code: "bm" },
  { language: "Bengali", code: "bn" },
  { language: "Tibetan", code: "bo" },
  { language: "Breton", code: "br" },
  { language: "Bosnian", code: "bs" },
  { language: "Chechen", code: "ce" },
  { language: "Chamorro", code: "ch" },
  { language: "Corsican", code: "co" },
  { language: "Cree", code: "cr" },
  { language: "Czech", code: "cs" },
  { language: "Chuvash", code: "cv" },
  { language: "Welsh", code: "cy" },
  { language: "Danish", code: "da" },
  { language: "German", code: "de" },
  { language: "Dzongkha", code: "dz" },
  { language: "Ewe", code: "ee" },
  { language: "Greek", code: "el" },
  { language: "English", code: "en" },
  { language: "Esperanto", code: "eo" },
  { language: "Spanish", code: "es" },
  { language: "Estonian", code: "et" },
  { language: "Basque", code: "eu" },
  { language: "Persian", code: "fa" },
  { language: "Fulah", code: "ff" },
  { language: "Finnish", code: "fi" },
  { language: "Fijian", code: "fj" },
  { language: "Faroese", code: "fo" },
  { language: "French", code: "fr" },
  { language: "Irish", code: "ga" },
  { language: "Gaelic", code: "gd" },
  { language: "Galician", code: "gl" },
  { language: "Guarani", code: "gn" },
  { language: "Gujarati", code: "gu" },
  { language: "Manx", code: "gv" },
  { language: "Hausa", code: "ha" },
  { language: "Hebrew", code: "he" },
  { language: "Hindi", code: "hi" },
  { language: "Hiri Motu", code: "ho" },
  { language: "Croatian", code: "hr" },
  { language: "Haitian", code: "ht" },
  { language: "Hungarian", code: "hu" },
  { language: "Armenian", code: "hy" },
  { language: "Herero", code: "hz" },
  { language: "Indonesian", code: "id" },
  { language: "Igbo", code: "ig" },
  { language: "Inupiaq", code: "ik" },
  { language: "Ido", code: "io" },
  { language: "Icelandic", code: "is" },
  { language: "Italian", code: "it" },
  { language: "Inuktitut", code: "iu" },
  { language: "Japanese", code: "ja" },
  { language: "Javanese", code: "jv" },
  { language: "Georgian", code: "ka" },
  { language: "Kongo", code: "kg" },
  { language: "Kikuyu", code: "ki" },
  { language: "Kuanyama", code: "kj" },
  { language: "Kazakh", code: "kk" },
  { language: "Greenlandic", code: "kl" },
  { language: "Central Khmer", code: "km" },
  { language: "Kannada", code: "kn" },
  { language: "Korean", code: "ko" },
  { language: "Kanuri", code: "kr" },
  { language: "Kashmiri", code: "ks" },
  { language: "Kurdish", code: "ku" },
  { language: "Komi", code: "kv" },
  { language: "Cornish", code: "kw" },
  { language: "Kirghiz", code: "ky" },
  { language: "Latin", code: "la" },
  { language: "Luxembourgish", code: "lb" },
  { language: "Ganda", code: "lg" },
  { language: "Lingala", code: "ln" },
  { language: "Lao", code: "lo" },
  { language: "Lithuanian", code: "lt" },
  { language: "Luba-Katanga", code: "lu" },
  { language: "Latvian", code: "lv" },
  { language: "Malagasy", code: "mg" },
  { language: "Marshallese", code: "mh" },
  { language: "Maori", code: "mi" },
  { language: "Macedonian", code: "mk" },
  { language: "Malayalam", code: "ml" },
  { language: "Mongolian", code: "mn" },
  { language: "Marathi", code: "mr" },
  { language: "Malay", code: "ms" },
  { language: "Maltese", code: "mt" },
  { language: "Burmese", code: "my" },
  { language: "Nauru", code: "na" },
  { language: "Nepali", code: "ne" },
  { language: "Ndonga", code: "ng" },
  { language: "Dutch; Flemish", code: "nl" },
  { language: "Norwegian Nynorsk", code: "nn" },
  { language: "Norwegian", code: "no" },
  { language: "Ojibwa", code: "oj" },
  { language: "Oromo", code: "om" },
  { language: "Oriya", code: "or" },
  { language: "Ossetian", code: "os" },
  { language: "Panjabi", code: "pa" },
  { language: "Pali", code: "pi" },
  { language: "Polish", code: "pl" },
  { language: "Pashto", code: "ps" },
  { language: "Portuguese", code: "pt" },
  { language: "Quechua", code: "qu" },
  { language: "Romansh", code: "rm" },
  { language: "Rundi", code: "rn" },
  { language: "Romanian", code: "ro" },
  { language: "Russian", code: "ru" },
  { language: "Kinyarwanda", code: "rw" },
  { language: "Sanskrit", code: "sa" },
  { language: "Sardinian", code: "sc" },
  { language: "Sindhi", code: "sd" },
  { language: "Northern Sami", code: "se" },
  { language: "Sango", code: "sg" },
  { language: "Sinhala", code: "si" },
  { language: "Slovak", code: "sk" },
  { language: "Slovenian", code: "sl" },
  { language: "Samoan", code: "sm" },
  { language: "Shona", code: "sn" },
  { language: "Somali", code: "so" },
  { language: "Albanian", code: "sq" },
  { language: "Serbian", code: "sr" },
  { language: "Swati", code: "ss" },
  { language: "Sotho", code: "st" },
  { language: "Sundanese", code: "su" },
  { language: "Swedish", code: "sv" },
  { language: "Swahili", code: "sw" },
  { language: "Tamil", code: "ta" },
  { language: "Telugu", code: "te" },
  { language: "Tajik", code: "tg" },
  { language: "Thai", code: "th" },
  { language: "Tigrinya", code: "ti" },
  { language: "Turkmen", code: "tk" },
  { language: "Tagalog", code: "tl" },
  { language: "Tswana", code: "tn" },
  { language: "Tonga", code: "to" },
  { language: "Turkish", code: "tr" },
  { language: "Tsonga", code: "ts" },
  { language: "Tatar", code: "tt" },
  { language: "Twi", code: "tw" },
  { language: "Tahitian", code: "ty" },
  { language: "Uighur", code: "ug" },
  { language: "Ukrainian", code: "uk" },
  { language: "Urdu", code: "ur" },
  { language: "Uzbek", code: "uz" },
  { language: "Venda", code: "ve" },
  { language: "Vietnamese", code: "vi" },
  { language: "Volapük", code: "vo" },
  { language: "Walloon", code: "wa" },
  { language: "Wolof", code: "wo" },
  { language: "Xhosa", code: "xh" },
  { language: "Yiddish", code: "yi" },
  { language: "Yoruba", code: "yo" },
  { language: "Zhuang", code: "za" },
  { language: "Chinese", code: "zh" },
  { language: "Zulu", code: "zu" },
];

const fonts = [
  {
    import: "Alegreya",
    css: "'Alegreya', serif",
    name: "Alegreya",
  },
  {
    import: "Amatic+SC",
    css: "'Amatic SC', cursive",
    name: "Amatic SC",
  },
  {
    import: "Cinzel",
    css: "'Cinzel', serif",
    name: "Cinzel",
  },
  {
    import: "Flavors",
    css: "'Flavors', cursive",
    name: "Flavors",
  },
  {
    import: "Josefin+Slab",
    css: "'Josefin Slab', serif",
    name: "Josefin Slab",
  },
  { import: "Lato", css: "'Lato', sans-serif", name: "Lato" },
  {
    import: "Lora:ital@1",
    css: "'Lora', serif",
    name: "Lora Regular Italic",
  },
  {
    import: "Merriweather+Sans",
    css: "'Merriweather Sans', sans-serif",
    name: "Merriweather Sans",
  },
  {
    import: "Montserrat",
    css: "'Montserrat', sans-serif",
    name: "Montserrat",
  },
  {
    import: "Open+Sans",
    css: "'Open Sans', sans-serif",
    name: "Open Sans",
  },
  {
    import: "PT+Sans",
    css: "'PT Sans', sans-serif",
    name: "PT Sans",
  },
  {
    import: "PT+Sans+Narrow",
    css: "'PT Sans Narrow', sans-serif",
    name: "PT Sans Narrow",
  },
  {
    import: "Playfair+Display",
    css: "'Playfair Display', serif",
    name: "Playfair Display",
  },
  {
    import: "Raleway",
    css: "'Raleway', sans-serif",
    name: "Raleway Regular",
  },
  {
    import: "Roboto",
    css: "'Roboto', sans-serif",
    name: "Roboto",
  },
  {
    import: "Roboto+Mono",
    css: "'Roboto Mono', monospace",
    name: "Roboto Mono",
  },
  { name_actual: "serif", name: "Serif (generic)" },
  { name_actual: "sans-serif", name: "Sans-serif (generic)" },
  { name_actual: "monospace", name: "Monospace (generic)" },
  { name_actual: "cursive", name: "Cursive (generic)" },
  { name_actual: "fantasy", name: "Fantasy (generic)" },
  { name_actual: "system-ui", name: "System-ui (generic)" },
  { name_actual: "ui-serif", name: "Ui-serif (generic)" },
  { name_actual: "ui-sans-serif", name: "Ui-sans-serif (generic)" },
  { name_actual: "ui-monospace", name: "Ui-monospace (generic)" },
  { name_actual: "ui-rounded", name: "Ui-rounded (generic)" },
  { name_actual: "emoji", name: "Emoji (generic)" },
  { name_actual: "math", name: "Math (generic)" },
  { name_actual: "fangsong", name: "Fangsong (generic)" },
];

const htmlSelectors = [
  "a",
  "abbr",
  "acronym",
  "address",
  "applet",
  "area",
  "article",
  "aside",
  "audio",
  "b",
  "base",
  "basefont",
  "bdi",
  "bdo",
  "big",
  "blockquote",
  "br",
  "button",
  "canvas",
  "caption",
  "center",
  "cite",
  "code",
  "col",
  "colgroup",
  "data",
  "datalist",
  "dd",
  "del",
  "details",
  "dfn",
  "dialog",
  "dir",
  "div",
  "dl",
  "dt",
  "em",
  "embed",
  "fieldset",
  "figcaption",
  "figure",
  "font",
  "footer",
  "form",
  "frame",
  "frameset",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "header",
  "hr",
  "i",
  "img",
  "input",
  "ins",
  "kbd",
  "label",
  "legend",
  "li",
  "link",
  "main",
  "map",
  "mark",
  "meter",
  "nav",
  "object",
  "ol",
  "optgroup",
  "option",
  "output",
  "p",
  "param",
  "picture",
  "pre",
  "progress",
  "q",
  "rp",
  "rt",
  "ruby",
  "s",
  "samp",
  "section",
  "select",
  "small",
  "source",
  "span",
  "strike",
  "strong",
  "style",
  "sub",
  "summary",
  "sup",
  "svg",
  "table",
  "tbody",
  "td",
  "template",
  "textarea",
  "tfoot",
  "th",
  "thead",
  "time",
  "title",
  "tr",
  "track",
  "tt",
  "u",
  "ul",
  "var",
  "video",
  "wbr",
];

const PREDICTIVE_SEARCH_DATA = {
  language: language_codes,
  fonts: fonts,
  htmlSelectors: htmlSelectors,
} as { [key: string]: PredictiveSearchItem[] };
export type TSearchDataType = keyof typeof PREDICTIVE_SEARCH_DATA;
export const getPredictiveSearchData = (key: TSearchDataType) => PREDICTIVE_SEARCH_DATA[key];
export default PREDICTIVE_SEARCH_DATA;
