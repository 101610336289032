import { metaIF, EMetaFieldTypes } from "../../types/item";
import { fieldsAll } from "../../constants";
export const CSS_PROPERTIES_OVERFLOW: metaIF[] = [
  {
    name: "overflow",
    category: ["OVERFLOW"],
    fields: [fieldsAll.overflowMain],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["overflow"],
      },
    ],
    default: { main: { value: "visible" } },
  },
  {
    name: "overflow-x",
    category: ["OVERFLOW"],
    fields: [fieldsAll.overflowMain],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["overflow-x"],
      },
    ],
    default: { main: { value: "visible" } },
  },
  {
    name: "overflow-y",
    category: ["OVERFLOW"],
    fields: [fieldsAll.overflowMain],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["overflow-y"],
      },
    ],
    default: { main: { value: "visible" } },
  },
  {
    name: "overflow-anchor",
    category: ["OVERFLOW"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [{ id: "auto" }, { id: "none" }],
        onOff: true,
      },
    ],
    default: {
      main: {
        value: "auto",
      },
    },
    help: "### overflow-anchor  \nSee for example [CSS-TRICKS - overflow-anchor](https://css-tricks.com/almanac/properties/o/overflow-anchor/)",
  },
  {
    name: "overflow-wrap",
    category: ["OVERFLOW"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "normal",
          },
          {
            id: "break-word",
          },
        ],
        help: "### overflow-wrap  \nSee for example [CSS-TRICKS - Handling Long Words and URLs](https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/)",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["overflow-wrap"],
      },
    ],
    default: {
      main: {
        value: "break-word",
      },
    },
  },
];
