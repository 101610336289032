import { itemIF, TSelectorData } from "../../../data/types/item";
import { TCreateComposedSelectorItem } from "./editComposedSelectorItemNew";
import { itemTypes } from "../../meta/getMetas";
import { getSelectorNameArray, getSelectorNameStr } from "../getSelectorName";
import { getShortHand, addPseudoelementContent } from "../shorthandGroups";
import { getCssCode } from "./getCssCode";

export const addCssStrForProperties = ({
  sData,
  properties,
  stylepack,
  selector,
  lineBreak = "",
  addSpaceAtLineStart = "",
  noCssPrefix = false,
}: {
  sData: TSelectorData;
  properties: itemIF[];
  stylepack?: itemIF;
  selector: itemIF;
  lineBreak?: string;
  addSpaceAtLineStart?: string;
  noCssPrefix?: boolean;
}) => {

  const posAndShs = getShortHand({
    itemType: itemTypes.CLASSES_CSS,
    items: properties,
    stylepack,
  });

  sData.posAndShs = posAndShs;

  for (const p of posAndShs) {
    addPseudoelementContent({
      selector,
      selectorProperties: properties,
      shortHand: p.sh,
    });
  }

  sData.css = getCssCode({
    posAndShs,
    lineBreak,
    addSpaceAtLineStart: addSpaceAtLineStart + "      ",
    noCssPrefix
  });
};

export const getCssForClassOrHtml = ({
  selector,
  selectors,
  properties,
  stylepack,
  lineBreak,
  addSpaceAtLineStart,
}: TCreateComposedSelectorItem): TSelectorData => {
  if (!selector || !selectors) {
    console.warn("getCssForClassOrHtml: no selector or selectors");
    return {} as TSelectorData;
  }

  const selectorNamesObjs = [] as { prefix: string; name: string }[];
  getSelectorNameArray({
    selector,
    selectorNamesObjs,
    selectors,
  });
  const nameStr = getSelectorNameStr({
    selectorNamesObjs: selectorNamesObjs,
  });

  const sData: TSelectorData = {
    selector: selector,
    nameStr: "",
  };
  sData.nameStr = nameStr;

  const selectorProperties = properties?.filter(
    (p) => p.classId === selector.id
  );

  const sorted = selectorProperties
    ?.slice()
    .sort((a, b) => a.position - b.position) as itemIF[];

  addCssStrForProperties({
    sData,
    properties: sorted,
    stylepack,
    selector,
    lineBreak,
    addSpaceAtLineStart,
  });
  return { ...sData } as TSelectorData;
};

export const sortSDatas = (sDatas: TSelectorData[]) => {
  sDatas.sort((a, b) => {
    if (a.selector.level && b.selector.level) {
      return a.selector.level > b.selector.level
        ? 1
        : a.selector.level < b.selector.level
          ? -1
          : 0;
    }
    return 0;
  });
  sDatas.sort((a, b) => {
    return a.selector.position > b.selector.position
      ? 1
      : a.selector.position < b.selector.position
        ? -1
        : 0;
  });
};
