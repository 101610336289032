import { FC } from "react";
import { useStateContext } from "../../data/main/state";
import { itemIF } from "../../data/types/item";
import { ReactComponent as ArrowLeft } from "../../assets/imgs/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/imgs/arrow-right.svg";
import { ReactComponent as ArrowDown } from "../../assets/imgs/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/imgs/arrow-up.svg";
import { ReactComponent as Copy } from "../../assets/imgs/copy-all.svg";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import { itemTypes } from "../../lib/meta/getMetas";
import { itemInTreeDelete } from "../../lib/reducer/items/actions";
import {
  isLeftBtnDisabled,
  isRightBtnDisabled,
  isUpBtnDisabled,
  isDownBtnDisabled,
} from "../../utils/html/MoveOrDelete";
import { copyIsDisabled } from "../../utils/svg/main";
import {
  moveItemDown,
  moveItemLeft,
  moveItemRightAction,
  moveItemUp,
  cloneItemAndChildren,
} from "../../lib/reducer/items/actions";
import { OpenCloseModal } from "../modules/TailwindModal";

const ConfirmSvgDelete: FC<{
  editItem?: itemIF;
  setIsModalOpen?: (isOpen: boolean) => void;
}> = ({ editItem, setIsModalOpen }) => {
  const { dispatch } = useStateContext();

  return (
    <div className="p-1r pl-15r">
      <p>Do you really want to delete the SVG and its descendants?</p>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className="button orange"
          onClick={() => {
            if (!editItem) return;

            dispatch(
              itemInTreeDelete({
                itemType: itemTypes.SVGS,
              })
            );
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Delete
        </button>
        <button
          type="button"
          className="button gray-outline"
          onClick={() => {
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export const MoveCopyOrDeleteButtons: FC<{
  svgs: itemIF[];
  editSvg?: itemIF | undefined;
}> = ({ editSvg, svgs }) => {
  const { dispatch } = useStateContext();
  const itemType = itemTypes.SVGS;

  const isDisabledLeft =
    !editSvg ||
    isLeftBtnDisabled({
      itemType,
      items: svgs,
      editItem: editSvg,
    });
  const isDisabledRight =
    !editSvg ||
    isRightBtnDisabled({
      itemType,
      items: svgs,
      editItem: editSvg,
    });
  const isDisabledUp =
    !editSvg ||
    isUpBtnDisabled({
      itemType,
      items: svgs,
      editItem: editSvg,
    });
  const isDisabledDown =
    !editSvg ||
    isDownBtnDisabled({
      itemType,
      items: svgs,
      editItem: editSvg,
    });
  const isDisabledClone = !editSvg || copyIsDisabled(editSvg);

  return (
    <div className="btn-row mb-1r form-elements-wrapper">
      <button
        type="button"
        className={`button blue-outline icon${isDisabledLeft ? " disabled" : ""}`}
        onClick={() => {
          if (isDisabledLeft) return;
          dispatch(moveItemLeft({ itemType }));
        }}
      >
        <span className="sr-only">Move left</span>
        <ArrowLeft />
      </button>
      <button
        type="button"
        className={`button blue-outline icon${isDisabledRight ? " disabled" : ""}`}
        onClick={() => {
          if (isDisabledRight) return;
          dispatch(moveItemRightAction({ itemType }));
        }}
      >
        <span className="sr-only">Move right</span>
        <ArrowRight />
      </button>
      <button
        type="button"
        className={`button blue-outline icon${isDisabledUp ? " disabled" : ""}`}
        onClick={() => {
          if (isDisabledUp) return;
          dispatch(moveItemUp({ itemType }));
        }}
      >
        <span className="sr-only">Move up</span>
        <ArrowUp />
      </button>
      <button
        type="button"
        className={`button blue-outline icon${isDisabledDown ? " disabled" : ""}`}
        onClick={() => {
          if (isDisabledDown) return;
          dispatch(moveItemDown({ itemType }));
        }}
      >
        <span className="sr-only">Move down</span>
        <ArrowDown />
      </button>

      <button
        type="button"
        className={`button blue icon${isDisabledClone ? " disabled" : ""}`}
        onClick={() => {
          if (isDisabledClone) return;
          dispatch(cloneItemAndChildren({ itemType }));
        }}
      >
        <span className="sr-only">Copy</span>
        <Copy />
      </button>

      <OpenCloseModal
        title="Delete SVG"
        component={<ConfirmSvgDelete editItem={editSvg} />}
      >
        <button
          type="button"
          className={`button orange icon ${!editSvg ? " disabled" : ""}`}
          onClick={() => {}}
        >
          <span className="sr-only">Delete SVG</span>
          <Delete />
        </button>
      </OpenCloseModal>
    </div>
  );
};
