import { actionIF } from "../../../data/types/item";
import { snippetIF, snippetsIF } from "../../../data/types/snippets";
import {
  REPLACE_SNIPPETS,
  REPLACE_SNIPPET,
  ADD_SNIPPET,
  COPY_SNIPPET,
  DELETE_SNIPPET,
  SET_EDIT_SNIPPET,
} from "./actions";
import { addOrCopy } from "./addOrCopy";

type TSnippetReducerNew = {
  snippet: snippetsIF;
  action: actionIF;
};

export const snippetReducer = ({ snippet, action }: TSnippetReducerNew) => {
  try {
    switch (action.type) {
      case REPLACE_SNIPPET:
        if (!action.snippet) {
          console.warn("REPLACE_SNIPPET no snippet is defined");
          return snippet;
        }

        const replaceId =
          action.oldId && action.oldId !== action.snippet.id
            ? action.oldId
            : action.snippet?.id;

        if (!replaceId) {
          console.warn("REPLACE_SNIPPET replaceId is not defined");
          return snippet;
        }

        const newSnippets = snippet.snippets.map((snip) => {
          if (snip.id === replaceId) {
            return {
              ...action.snippet,
            };
          }
          return snip;
        }) as snippetIF[];

        return {
          ...snippet,
          snippets: newSnippets,
          editSnippetId: action.snippet.id,
        } as snippetsIF;

      case REPLACE_SNIPPETS:
        if (!action.snippets) {
          return snippet;
        }
        return {
          ...snippet,
          snippets: action.snippets,
        };

      case COPY_SNIPPET:
      case ADD_SNIPPET:
        const newSnippet = addOrCopy({ action, snippet });
        if (!newSnippet) {
          console.warn("No newSnippet found");
          return snippet;
        }
        return {
          ...snippet,
          snippets: [...snippet.snippets, newSnippet],
          editSnippetId: newSnippet.id,
        } as snippetsIF;

      case DELETE_SNIPPET:
        const dsNewSnippets = snippet.snippets.filter(s => s.id !== snippet.editSnippetId);
        return {
          ...snippet,
          snippets: dsNewSnippets,
          editSnippetId: undefined,
        }

      case SET_EDIT_SNIPPET:
        if (action.snippetId) {
          return {
            ...snippet,
            editSnippetId: action.snippetId,
          } as snippetsIF;
        }

        return snippet;

      default:
    }
  } catch (error) {
    console.error("snippetReducer error", error);
    return snippet;
  }
};
