import { FC, useState } from "react";

type TStringInput = {
  isFieldDisabled?: boolean;
};
export const UploadInput: FC<TStringInput> = ({
  isFieldDisabled,
}) => {
  const [file, setFile] = useState<File | null>(null);
  console.log("file", file);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  return (
    <label className="label">
      <span className="sr-only">Upload image</span>
      <input className="input p-0" type="file" onChange={handleFileChange} accept="image/*" disabled={isFieldDisabled} />
    </label>
  );
};
