import { metaIF, EMetaFieldTypes } from "../types/item";

export type TFontFamilies = {
  editFontId: number;
};

export const getStylepackMeta = (name: string): metaIF | undefined => {
  return STYLEPACK_META.find((m) => m.name === name);
};

const STYLEPACK_META: metaIF[] = [
  {
    name: "stylepackItem",
    category: ["STYLEPACK"],

    fieldsOrder: ["name", "stylepackColors", "stylepackFonts"],

    fields: [
      {
        name: "name",
        type: EMetaFieldTypes.textarea,
        onOff: true,
      },
      {
        name: "stylepackColors",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
      },
      {
        name: "stylepackFonts",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
      },
    ],
    default: {
      name: { value: "default" },
      stylepackColors: {
        value: [],
      },
      stylepackFonts: {
        value: [],
      },
    },
  },
  {
    name: "stylepackColors",
    category: ["addOrRemoveFieldGroup"],
    fieldsOrder: ["color", "name"],
    fields: [
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
      },
      {
        name: "name",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
    ],
    group: [
      {
        name: "colorG",
        fields: ["color", "name"],
        display: ["color", "name"],
      },
    ],
    default: {
      color: { value: { r: 0, g: 0, b: 255, a: 1 } },
      name: { value: "blue" },
    },
  },
  {
    name: "font",
    category: ["FONT"],
    fieldsOrder: ["stylepackFonts"],
    fields: [
      {
        name: "stylepackFonts",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
      },
    ],
    group: [
      {
        name: "stylepackFontsG",
        fields: ["stylepackFonts"],
        display: ["fonts"],
      },
    ],
    default: {
      stylepackFonts: {
        value: [],
      },
    },
  },
  {
    name: "stylepackFonts",
    category: ["addOrRemoveFieldGroup"],
    fieldsOrder: ["font", "type", "importFamilyName", "newCss"],
    fields: [
      {
        name: "font",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      {
        name: "type",
        type: EMetaFieldTypes.select,
        options: [{ id: "googlefont" }, { id: "generic" }],
        onOff: true,
      },
      {
        name: "importFamilyName",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
      {
        name: "newCss",
        type: EMetaFieldTypes.string,
        onOff: true,
      },
    ],
    group: [
      {
        name: "fontG",
        fields: ["font", "type", "importFamilyName", "newCss"],
        display: ["name", "type", "import family", "css"],
      },
    ],

    default: {
      font: { value: "Roboto" },
      type: { value: "googlefont" },
      importFamilyName: { value: "Roboto" },
      newCss: { value: "'Roboto', sans-serif" },
    },
    help:
      "### Google Fonts  \n" +
      "Select here CSSexY default Google Fonts or add further Google Fonts.  \n\n" +
      "How can I add a Google Font in CSSexY SETTINGS?  \n" +
      "Go to [Google Fonts](https://fonts.google.com/). Select a font. Click on `+ Select this style`. Select in `Use on the web` the `@import` button.  \n" +
      "In the FONT SETTINGS of CSSexY select `value` in the FONT field. Copy and paste the following data of the Google Font:  \n" +
      "- from the `@import` directive the part between `family=` and `&` to the field `family name`  \n" +
      "- in `CSS rules` the part after `font-family:` to the field CSS  \n" +
      "- the name of the Google Font (you can change it) into the   `display` field.",
  },
];
