export type TPosition = {
  x: number;
  y: number;
  width: number;
  height: number;
};
export type TStyle = {
  left?: string;
  right?: string;
  width?: string;
};

/*
sets the left and right of the modal dependent on the width of the container
*/

export const getModalPosition = ({
  pPos,
  mPos,
}: {
  pPos?: TPosition;
  mPos?: TPosition;
}): TStyle | undefined => {
  if (!pPos || !mPos || mPos.width === 0) {
    return;
  }

  const CX = pPos.x; // container x
  const CW = pPos.width; // container width
  const BX = mPos.x; //button x in container
  const BW = mPos.width; // button width

  const SL = BX - CX; // space to the left of the button in the container
  const SR = CX + CW - (BX + BW); // space to the right of the button in the container

  const modalWidth = 400;

  // there is enough space to the left of the button
  if (SL >= modalWidth) {
    return {
      left: "auto",
      right: `0`,
      width: `${modalWidth}px`,
    };
  }

  // there is enough space to the right of the button
  if (SR >= modalWidth) {
    return {
      left: `0`,
      right: "auto",
      width: `${modalWidth}px`,
    };
  }

  return {
    left: SL > SR ? `${SL * -1}px` : "auto",
    right: SL > SR ? "auto" : `${SR * -1}px`,
    width: `${Math.min(CW, modalWidth)}px`,
  };
};
