import { metaIF, itemIF, EMetaFieldTypes } from "../../types/item";
import { getValueStr } from "../../../lib/reducer/items/getItemData";

const helpObj = {
  gridColRowStartEndHelp:
    "  \n" +
    "With `grid-column-start`, `grid-column-end`, `grid-row-start` and `grid-row-end` " +
    "you can change the position of cells in the grid. " +
    "For example, if the grid-column and -row start of the 1st cell " +
    "is 2 and the column and row end is 4, " +
    "the cell is not just stretching over 4 cells, " +
    "but is additionally relocated to start from 2nd column and 2nd row. ",
  allTopLeftBottomRightHelpText:
    "#### Select all, right, top, bottom or left  \n" +
    "When you select `all`, the values for this property are applied to all of its sides.  \n" +
    "To define the property for one side only, select `top`, `right`, `bottom` or `left`.  \n" +
    "If you want to set different values for the sides of the element, " +
    "add multiple properties, the general value with `all` first " +
    "and the more specific setting underneath. " +
    "Settings further down in the list overwrite settings on top.",
};

export const CSS_PROPERTIES_GRID: metaIF[] = [
  {
    name: "align-content-grid",
    code: "align-content",
    category: ["GRID"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "start",
            help: "#### start (default) \nThe rows are aligned at the top of the grid.",
          },
          {
            id: "center",
            help: "#### center  \nThe rows are aligned in the center of the grid.",
          },
          {
            id: "end",
            help: "#### end  \nThe rows are aligned at the bottom of the grid.",
          },
          {
            id: "space-evenly",
            help:
              "#### space-evenly  \n" +
              "The available vertical space is divided by the number of rows plus 1. " +
              "This space is added on top, between the rows and at the bottom of the grid.",
          },
          {
            id: "space-around",
            help:
              "#### space-around  \n" +
              "The available vertical space is divided by the number of columns. " +
              "The result is again divided by 2 and added on top and bottom of each row.",
          },
          {
            id: "space-between",
            help:
              "#### space-between  \n" +
              "The available vertical space is divided by the number of columns minus 1. " +
              "This space is added between the rows.",
          },
        ],
        help:
          "### align-content  \n" +
          "If there is space in a grid to move its rows up or down, " +
          "`align-content` defines how the rows are vertically aligned.  \n",
      },
    ],
    default: { main: { value: "center" } },
  },
  {
    name: "align-self-grid",
    code: "align-self",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "start",
          },
          {
            id: "center",
          },
          {
            id: "end",
          },
          {
            id: "stretch",
          },
        ],
        help:
          "### align-self  \n" +
          "Aligns the cell at the top of the row, the center, the bottom or stretches it from top to bottom.",
      },
    ],
    default: { main: { value: "center" } },
  },
  {
    name: "display-grid",
    code: "display",
    category: ["GRID"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "grid",
          },
          {
            id: "inline-grid",
          },
        ],
        help: "#### display:grid  \nDefines a `grid` container.",
        warning:
          "#### display:grid  \n" +
          "For any grid function to work, `display:grid` or `display:inline-grid` must be set on the container.",
      },
    ],
    default: { main: { value: "grid" } },
  },
  {
    name: "grid-column-start",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### grid-column-start  \n" +
          "Starting column for a cell which stretches over several columns." +
          helpObj.gridColRowStartEndHelp,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grid-column-start"],
      },
    ],
    default: {
      main: {
        value: 1,
      },
    },
  },
  {
    name: "grid-column-end",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### grid-column-end  \n" +
          "A cell stretching over several columns starts at the column in `grid-column-start` and ends before the column `grid-column-end`." +
          helpObj.gridColRowStartEndHelp,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grid-column-end"],
      },
    ],

    default: {
      main: {
        value: 3,
      },
    },
  },
  {
    name: "grid-gap",
    category: ["GRID"],
    setOnParent: true,
    fieldsOrder: ["row", "column"],
    fields: [
      {
        name: "row",
        type: EMetaFieldTypes.number,
        units: [{ id: "px" }, { id: "rem" }, { id: "%" }],
        help: "### grid-gap row  \nGap between the rows of the grid.",
      },
      {
        name: "column",
        type: EMetaFieldTypes.number,
        units: [{ id: "px" }, { id: "rem" }, { id: "%" }],
        help: "### grid-gap column  \nGap between the columns of the grid.",
      },
    ],

    group: [
      {
        name: "rowG",
        display: ["row"],
        fields: ["row"],
      },
      {
        name: "columnG",
        display: ["column"],
        fields: ["column"],
      },
    ],

    default: {
      row: {
        value: 10,
        unit: "px",
        onOff: true,
      },
      column: {
        value: 20,
        unit: "px",
        onOff: true,
      },
    },
  },
  {
    name: "grid-row-start",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### grid-row-start  \n" +
          "Starting row for a cell which stretches over several rows." +
          helpObj.gridColRowStartEndHelp,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grid-row-start"],
      },
    ],
    default: {
      main: {
        value: 1,
      },
    },
  },
  {
    name: "grid-row-end",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        help:
          "### grid-row-end  \n" +
          "A cell stretching over several rows starts at the row in `grid-row-start` and ends before the row `grid-row-end`." +
          helpObj.gridColRowStartEndHelp,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grid-row-end"],
      },
    ],
    default: {
      main: {
        value: 3,
      },
    },
  },

  {
    name: "grid-template-columns",
    category: ["GRID"],
    addToShorthand: "grid-template-columns",
    shorthandSeparator: " ",
    setOnParent: true,
    addMultipleTimes: true,
    fieldsOrder: ["templateColumns"],
    help:
      "### grid-template-columns / grid-template-rows  \n" +
      "See for example:  \n\n" +
      "[CSS-TRICKS - grid-template-columns / grid-template-rows](https://css-tricks.com/almanac/properties/g/grid-rows-columns/)  \n\n" +
      "[Joomlashack - The minmax() Function](https://www.joomlashack.com/blog/tutorials/css-grid-12-the-minimax-function/)\n\n" +
      "[CSS-TRICKS - An Introduction to the `fr` CSS unit]()",
    fields: [
      {
        name: "templateColumns",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
      },
    ],
    group: [
      {
        name: "templateColumnsG",
        fields: ["templateColumns"],
        display: ["columns"],
      },
    ],
    default: {
      templateColumns: {
        value: [],
        onOff: true,
      },
    },
  },
  {
    name: "templateColumns",
    category: ["addOrRemoveFieldGroup"],
    display: "column",
    fieldsOrder: ["main", "width"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        smallInput: true,
        onOff: true,
        options: [
          { id: "value" },
          { id: "none" },
          { id: "auto" },
          { id: "max-content" },
          { id: "min-content" },
        ],
      },
      {
        name: "width",
        type: EMetaFieldTypes.number,
        units: [
          { id: "%", min: 0, max: 100 },
          { id: "px" },
          { id: "rem" },
          { id: "fr" },
        ],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
        onOff: true,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main", "width"],
        display: ["select", "width"],
      },
    ],
    default: {
      main: {
        value: "value",
      },
      width: {
        value: 50,
        unit: "%",
      },
    },
  },

  {
    name: "grid-template-rows",
    category: ["GRID"],
    addToShorthand: "grid-template-rows",
    shorthandSeparator: " ",
    setOnParent: true,
    addMultipleTimes: true,
    fieldsOrder: ["repeatSelect", "numberOfRows", "templateRows"],
    fields: [
      {
        name: "repeatSelect",
        type: EMetaFieldTypes.select,
        options: [
          { id: "numberOfRows" },
          { id: "auto-fit" },
          { id: "auto-fill" },
        ],
        help: "### repeat  \nSee for example [Joomlashack - The Auto Keyword and Repeat Notation in CSS Grid](https://www.joomlashack.com/blog/tutorials/auto-keyword-and-repeat-notation/)",
      },
      {
        name: "numberOfRows",
        type: EMetaFieldTypes.number,
        dependentOn: {
          field: "repeatSelect",
          values: ["numberOfRows"],
        },
      },
      {
        name: "templateRows",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
      },
    ],
    group: [
      {
        name: "repeatG",
        fields: ["repeatSelect", "numberOfRows"],
        display: ["repeat", "number of columns"],
      },
      {
        name: "templateRowsG",
        fields: ["templateRows"],
        display: ["column height"],
      },
    ],
    wrapperForGroup: [
      {
        name: "gridTemplateRowFn",
        fields: ["repeatSelect", "numberOfRows", "templateRows"],
        wrapperFn: (vObj, items) => {
          let vStr = "";
          vStr = vObj.templateRows.value.reduce((r: string, i: itemIF) => {
            if (i.value?.main?.value === "auto") {
              r += `${r === "" ? "" : " "}auto`;
            }
            if (i.value?.main?.value === "value") {
              r += `${r === "" ? "" : " "}${getValueStr({
                valObj: i.value?.height,
              })}`;
            }
            if (i.value?.main?.value === "minmax") {
              r += `${r === "" ? "" : " "}minmax(${getValueStr({
                valObj: i.value?.minmaxMin,
              })}, ${getValueStr({
                valObj: i.value?.minmaxMax,
              })})`;
            }
            return r;
          }, "");
          if (vObj.repeatSelect?.onOff) {
            if (vObj.repeatSelect.value === "numberOfRows") {
              return `repeat(${getValueStr({
                valObj: vObj.numberOfRows,
              })}, ${vStr})`;
            }
            return `repeat(${getValueStr({
              valObj: vObj.numberOfRows,
            })}, ${vStr})`;
          }
          return vStr;
        },
      },
    ],
    default: {
      repeatSelect: {
        value: "numberOfRows",
      },
      numberOfRows: {
        value: 4,
      },
      templateRows: {
        value: [
          {
            id: 1,
            name: "templateRows",
            position: 1,
            value: {
              main: {
                value: "value",
              },
              height: {
                value: 50,
                unit: "%",
              },
              minmaxMin: {
                value: 100,
                unit: "px",
              },
              minmaxMax: {
                value: 1,
                unit: "fr",
              },
            },
          },
          {
            id: 2,
            name: "templateRows",
            position: 2,
            value: {
              main: {
                value: "value",
              },
              height: {
                value: 25,
                unit: "%",
              },
              minmaxMin: {
                value: 100,
                unit: "px",
              },
              minmaxMax: {
                value: 1,
                unit: "fr",
              },
            },
          },
        ],
        onOff: true,
      },
    },
    help:
      "### grid-template-columns / grid-template-rows  \n" +
      "See for example:  \n\n" +
      "[CSS-TRICKS - grid-template-columns / grid-template-rows](https://css-tricks.com/almanac/properties/g/grid-rows-columns/)  \n\n" +
      "[Joomlashack - The minmax() Function](https://www.joomlashack.com/blog/tutorials/css-grid-12-the-minimax-function/)\n\n" +
      "[CSS-TRICKS - An Introduction to the `fr` CSS unit]()",
  },
  {
    name: "templateRows",
    category: ["addOrRemoveFieldGroup"],
    display: "row",
    fieldsOrder: ["main", "height", "minmaxMin", "minmaxMin"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        smallInput: true,
        options: [{ id: "value" }, { id: "auto" }, { id: "minmax" }],
      },
      {
        name: "height",
        type: EMetaFieldTypes.number,
        units: [
          { id: "%", min: 0, max: 100 },
          { id: "px" },
          { id: "rem" },
          { id: "fr" },
        ],
        dependentOn: {
          field: "main",
          values: ["value"],
        },
      },
      {
        name: "minmaxMin",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "%", min: 0, max: 100 },
          { id: "px" },
          { id: "rem" },
          { id: "fr" },
        ],
        dependentOn: {
          field: "main",
          values: ["minmax"],
        },
      },
      {
        name: "minmaxMax",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "%", min: 0, max: 100 },
          { id: "px" },
          { id: "rem" },
          { id: "fr" },
        ],
        dependentOn: {
          field: "main",
          values: ["minmax"],
        },
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["select"],
      },
      {
        name: "widthG",
        fields: ["height"],
        display: ["row height"],
      },
      {
        name: "minmaxG",
        fields: ["minmaxMin", "minmaxMax"],
        display: ["min", "max"],
        help: "### minmax  \nSee for example [Joomlashack - The minmax() Function](https://www.joomlashack.com/blog/tutorials/css-grid-12-the-minimax-function/)",
      },
    ],
    default: {
      main: {
        value: "value",
      },
      height: {
        value: 50,
        unit: "%",
      },
      minmaxMin: {
        value: 100,
        unit: "px",
      },
      minmaxMax: {
        value: 1,
        unit: "fr",
      },
    },
  },

  {
    name: "justify-content-grid",
    code: "justify-content",
    category: ["GRID"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "start",
            help: "#### start (default)  \nColumns are aligned on the left of the grid.",
          },
          {
            id: "center",
            help:
              "#### center  \n" +
              "Columns are centered in the grid without extra space between the columns.",
          },
          {
            id: "end",
            help: "#### end  \nColumns are aligned on the right of the grid.",
          },
          {
            id: "space-evenly",
            help:
              "#### space-evenly  \n" +
              "The available horizontal space is divided by the number of columns plus 1. " +
              "This space is added on the left and right of the grid and between the columns.",
          },
          {
            id: "space-around",
            help:
              "#### space-around  \n" +
              "The available horizontal space is divided by the number of columns. " +
              "The result is again divided by 2 and added on the left and right side of each column.",
          },
          {
            id: "space-between",
            help:
              "#### space-between  \n" +
              "The available horizontal space is divided by the number of columns minus 1. " +
              "This space is added between the columns.",
          },
          {
            id: "stretch",
            help:
              "#### stretch (default)  \n" +
              "The available horizontal space is added in even measure to the columns with `grid-template-columns` width `auto`.",
          },
        ],
        help: "### justify-content  \n",
      },
    ],
    default: { main: { value: "center" } },
  },
  {
    name: "justify-self-grid",
    code: "justify-self",
    category: ["GRID"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "start",
          },
          {
            id: "center",
          },
          {
            id: "end",
          },
          {
            id: "stretch",
          },
        ],
        help:
          "### justify-self  \n" +
          "Aligns the cell to the left of the column, in the center, to the right or stretches it from the left to the right.",
      },
    ],
    default: { main: { value: "center" } },
  },
];
