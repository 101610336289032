import React, { FC } from "react";
import { snippetIF } from "../../data/types/snippets";
import { itemTypes } from "../../lib/meta/getMetas";
import { getMetaWithAllFields } from "../../lib/meta/getMetaWithAllFields";
import { itemWithChildrenIF, TObject, itemIF } from "../../data/types/item";
import BlockElement from "./GetHtmlOrSvgElement";
import RenderSvg from "../preview/RenderSvg";
import HtmlTree from "./HtmlTree";
import { getassignedClassNames } from "../../utils/render/css/get-assigned-selectors";
import { getKids } from "../../lib/reducer/items/getDescendantsAndAncestors";
import { getCssForSelectorIds } from "../../lib/render/css/getCssForDataSelectorIdsFontsSnippet";
import { getAssignedSelectorIds } from "../../lib/items/getAssignedSelectorsNew";
import { getItemValuesAsObj } from "../../lib/render/getItemValuesAsObject";
import { htmlSelectors, svgElements } from "../../data/html/htmls-list";
import { getItemsDataStr } from "../../lib/reducer/items/getItemData";

const getItemAttributes = ({
  itemType,
  item,
  items,
  selectors,
}: {
  itemType: itemTypes;
  item: itemWithChildrenIF;
  items: itemIF[];
  selectors?: itemIF[];
}) => {
  const attr = {} as TObject;

  getItemValuesAsObj({ itemType, item, items, attr });

  const assignedClassNames = getassignedClassNames({
    assignedIds: item?.assignedClasses || [],
    selectors,
  });

  if (assignedClassNames !== "") {
    attr.className = assignedClassNames;
  }

  return attr;
};

const RenderElementAndChildren: FC<{
  itemType: itemTypes;
  item: itemWithChildrenIF;
  htmlParentClassName?: string;
  editSnippet: snippetIF;
}> = ({ itemType, item, htmlParentClassName, editSnippet }) => {
  const dataStr = getItemsDataStr(itemType);
  const items = editSnippet?.data[dataStr] as itemIF[];

  if (!item) return null;

  const attr = getItemAttributes({
    itemType,
    item,
    items: items!,
    selectors: editSnippet?.data.selectors,
  });

  const meta = getMetaWithAllFields({
    itemType,
    itemName: item.name,
  });

  const renderSnippets: React.ReactElement[] = [];
  if (
    !htmlSelectors.includes(item.name) &&
    item.name !== "text" &&
    !svgElements.includes(item.name)
  ) {
    renderSnippets.push(
      <HtmlTree key={editSnippet!.id} editSnippet={editSnippet} />
    );
  }

  if (item.name === "svg" && item.value?.htmlSvgCodeSnippetSvgId) {
    let htmlParentClassName = "";
    if (attr.className) {
      htmlParentClassName = attr.className;
      delete attr.className;
    }

    return (
      <RenderSvg
        svgId={item.value?.htmlSvgCodeSnippetSvgId.value}
        htmlParentClassName={htmlParentClassName}
        editSnippet={editSnippet}
      />
    );
  }

  if (!item.children || item.children?.length === 0) {
    return (
      <BlockElement
        item={item}
        attr={attr}
        selectors={editSnippet?.data.selectors || []}
      >
        {renderSnippets}
      </BlockElement>
    );
  }

  item.children?.sort((a, b) =>
    a.position > b.position ? 1 : a.position < b.position ? -1 : 0
  );

  const renderChildren: React.ReactElement[] = [];
  item.children?.forEach((child) => {
    let isAttrChild = false;
    if (
      meta?.childrenAsAttributes &&
      meta.allowedChildren?.includes(child.name) &&
      meta?.childrenAsAttributes?.children.includes(child.name)
    ) {
      isAttrChild = true;
    }

    if (!isAttrChild) {
      renderChildren.push(
        <RenderElementAndChildren
          key={child.id}
          itemType={itemType}
          item={child}
          editSnippet={editSnippet}
        />
      );
    }
  });

  let svgCss = "";
  if (itemType === itemTypes.SVGS && item.name === "svg" && item.level === 1) {
    if (htmlParentClassName && htmlParentClassName !== "") {
      if (attr.className) {
        attr.className += " " + htmlParentClassName;
      } else {
        attr.className = htmlParentClassName;
      }
    }
    const svg = editSnippet?.data?.svgs?.find((s) => s.id === item.id);
    if (!svg) {
      return null;
    }

    const svgAndChildren = [svg];
    getKids({
      item: svg,
      items: editSnippet?.data.svgs || [],
      kids: svgAndChildren,
    });
    const assignedSelectorIds = [] as number[];

    getAssignedSelectorIds({
      items: svgAndChildren,
      assignedSelectorIds: assignedSelectorIds,
    });

    svgCss = getCssForSelectorIds({
      assignedSelectorIds,
      snippet: editSnippet,
      addSpaceAtLineStart: "               ",
    });
  }
  return (
    <BlockElement
      item={item}
      attr={attr}
      selectors={editSnippet.data.selectors}
      svgCss={svgCss}
    >
      {renderChildren}
    </BlockElement>
  );
};

export default RenderElementAndChildren;
