import { useState } from "react";
import { useStateContext } from "../../data/main/state";
import { itemIF } from "../../data/types/item";
import { ReactComponent as Copy } from "../../assets/imgs/copy-all.svg";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import {
  cloneItemAndChildren,
  itemInTreeDelete,
} from "../../lib/reducer/items/actions";
import { itemTypes } from "../../lib/meta/getMetas";
import { HtmlTreeForSnippet } from "../render/HtmlItemsTree";
import {
  SortAndFindSnippets,
  SelectSnippetButtons,
} from "../modules/SortAndFind";
import { SectionTitle } from "../modules/SectionTitle";

export const CopyHtmlBetweenSnippets = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();
  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const [selectedSnippetId, setSelectedSnippetId] = useState<
    string | undefined
  >(undefined);
  const snippetFrom = snippets.find((s) => s.id === selectedSnippetId);

  const [selectedItemFromSnippet, setSelectedItemFromSnippet] = useState(
    undefined as itemIF | undefined
  );
  const [selectedItemToSnippet, setSelectedItemToSnippet] = useState(
    undefined as itemIF | undefined
  );

  return (
    <>
      <div className="mt-1r">
        <SectionTitle label="Import HTML" size="large" />
      </div>
      <div className="mt-1r">
        <SortAndFindSnippets allSnippets={snippets.filter(s => s.id !== editSnippet?.id)} title="Select snippet">
          <SelectSnippetButtons
            selectedSnippetId={selectedSnippetId!}
            setSelectedSnippetId={setSelectedSnippetId}
            color="gray-outline"
          />
        </SortAndFindSnippets>
      </div>

      <div className="form-elements-wrapper flex-k50p mt-2r">
        <div>
          <div className="section-title medium mb-1r">
            <div className="mr-1r">Copy from</div>
            <div>
              <button
                type="button"
                className={`button icon blue${!selectedItemFromSnippet ? " disabled" : ""}`}
                onClick={() => {
                  if (!selectedItemFromSnippet) return;
                  dispatch(
                    cloneItemAndChildren({
                      itemType: itemTypes.BLOCK_HTML,
                      snippetFromId: selectedSnippetId!,
                      itemFromId: selectedItemFromSnippet?.id!,
                      itemToId: selectedItemToSnippet?.id!,
                    })
                  );
                }}
              >
                <span className="sr-only">Copy HTML</span>
                <Copy />
              </button>
            </div>
          </div>

          <HtmlTreeForSnippet
            snippet={snippetFrom}
            selectedItem={selectedItemFromSnippet}
            itemOnClick={(itemId) => {
              setSelectedItemFromSnippet(itemId);
            }}
            dontShowCodePreview={true}
          />
        </div>

        <div>
          <div className="section-title medium mb-1r flex justify-space-between">
            <div>Copy to</div>
            <button
              type="button"
              className={`button icon orange${!selectedItemToSnippet ? " disabled" : ""}`}
              onClick={() => {
                if (!selectedItemToSnippet) return;
                dispatch(
                  itemInTreeDelete({
                    itemType: itemTypes.BLOCK_HTML,
                    editItemId: selectedItemToSnippet.id!,
                  })
                );
              }}
            >
              <span className="sr-only">Delete HTML in Copy to snippet</span>
              <Delete />
            </button>
          </div>
          <HtmlTreeForSnippet
            snippet={editSnippet}
            selectedItem={selectedItemToSnippet}
            itemOnClick={(itemId) => {
              setSelectedItemToSnippet(itemId);
            }}
            dontShowCodePreview={true}
          />
        </div>
      </div>
    </>
  );
};
