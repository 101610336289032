import {
  FC,
  useRef,
  useState,
  useEffect,
  ReactNode,
  useLayoutEffect,
} from "react";
import { createPortal } from "react-dom";
import "./CustomIFrame.css";

const defaultStylesOnBody1 = `
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html.auto-height {
  overflow: hidden;
}
`;
const defaultStylesOnBody2 = ` 
body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow: hidden;
}
`;

type CustomIframeProps = {
  title: string;
  width?: string;
  height?: string;
  className?: string;
  children?: ReactNode;
  headContent?: string;
  htmlSelectorCss?: string;
};
export const CustomIframe: FC<CustomIframeProps> = ({
  title,
  width,
  height,
  className,
  children,
  headContent,
  htmlSelectorCss,
}) => {
  const [mountNode, setMountNode] = useState(
    undefined as HTMLElement | undefined
  );

  const frameRef = useRef<HTMLIFrameElement>(null);
  const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);

  const defaultStylesOnBody = `${defaultStylesOnBody1}${htmlSelectorCss}${defaultStylesOnBody2}`;

  const headContentWithDefaultStyles = `${headContent}
  ${defaultStylesOnBody}
  `;

  const iframe = frameRef.current;
  const body = iframe?.contentWindow?.document?.body;

  useLayoutEffect(() => {
    const body = frameRef.current?.contentWindow?.document?.body;
    if (body) {
      setIsIFrameLoaded(true);
    }
  }, []);

  useLayoutEffect(() => {
    if (!headContentWithDefaultStyles) return;

    const iframeDocument = iframe?.contentDocument;
    if (iframeDocument) {
      const head = iframeDocument.head;

      const existingStyleElement = head.querySelector("style");
      if (existingStyleElement) {
        existingStyleElement.textContent = headContentWithDefaultStyles;
      } else {
        const styleElement = document.createElement("style");
        styleElement.textContent = headContentWithDefaultStyles;
        head.appendChild(styleElement);
      }

      setIsIFrameLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headContentWithDefaultStyles, iframe, body, width, height]);

  useEffect(() => {
    if (body) {
      setMountNode(body);
    }
  }, [body, isIFrameLoaded]);

  useEffect(() => {
    if (!iframe) return;
    const htmlElement = iframe.contentDocument?.documentElement; // Access the <html> tag

    if (height === "auto") {
      htmlElement?.classList.add("auto-height");
    } else {
      htmlElement?.classList.remove("auto-height");
    }
  }, [height, iframe]);

  return (
    <iframe
      title={title}
      width={width ? width : "100%"}
      height={height && height !== "auto" ? height : "100%"}
      className={`${className}${height === "auto" ? " auto-height" : ""}`}
      ref={frameRef}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
