import { FC } from "react";
import "./SimpleList.css";

type TKeyValue = {
  key: string;
  value: string;
};

export const SimpleList: FC<{ data: TKeyValue[] }> = ({ data }) => {
  return (
    <div className="variables html">
      <ul className="default-list-group">
        {data.map((item, index) => (
          <li key={index}>
            <span className="key">{item.key}</span>
            <span className="value">{item.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
