import { metaIF, EMetaFieldTypes } from "../../types/item";
export const CSS_PROPERTIES_FLOAT: metaIF[] = [
  {
    name: "float",
    category: ["FLOAT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "left",
          },
          {
            id: "right",
          },
          {
            id: "none",
          },
        ],
        help: "### float  \nSee for example [w3schools - CSS Layout - float and clear](https://www.w3schools.com/css/css_float.asp)",
      },
    ],
    default: {
      main: {
        value: "right",
      },
    },
  },
  {
    name: "clear",
    category: ["FLOAT"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "left",
          },
          {
            id: "right",
          },
          {
            id: "both",
          },
          {
            id: "none",
          },
        ],
        help: "### clear  \nSee for example [w3schools - CSS Layout - clear and clearfix](https://www.w3schools.com/css/css_float_clear.asp)",
      },
    ],
    default: {
      main: {
        value: "right",
      },
    },
  },
];
