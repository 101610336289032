import { useEffect, useState, FC } from "react";
import { PAGES } from "../../data/settings/constants";
import { useStateContext } from "../../data/main/state";
import { TMsgType, TFormData, TUserState } from "../../lib/signinup/helpers";
import { setIsLoading, selectPage } from "../../lib/reducer/settings/actions";
import { userDataInSettings } from "../../lib/reducer/settings/actions";
import { signInGetTokens } from "../../lib/signinup/signin";
import { ValidateEmail } from "../../utils/user/main";
import { InputWithValidation } from "./InputWithValidation";
import { FormInputMessage } from "../atoms/formErrOrSuccessMessage";
import { AccountWrapper } from "./Account";

import "./Account.css";

export type SignInProps = {
  setSignType: (signType: TUserState) => void;
};

export const SignIn: FC<SignInProps> = ({ setSignType }) => {
  const { dispatch } = useStateContext();

  const [formData, setFormData] = useState({
    email: { data: "", errorMessage: "" },
    password: { data: "", errorMessage: "" },
    rememberMe: { data: "", errorMessage: "" },
  } as TFormData);
  const [signInErrorMessage, setSignInErrorMessage] = useState({} as TMsgType);

  useEffect(() => {
    if (signInErrorMessage.successMsg) {
      setFormData({
        ...formData,
        password: { data: "", errorMessage: "" },
        email: { data: "", errorMessage: "" },
        rememberMe: { data: "", errorMessage: "" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInErrorMessage.successMsg]);

  const signInBtnIsDisabled =
    formData.email.data === "" ||
    formData.password.data === "" ||
    formData.email.errorMessage !== "" ||
    formData.password.errorMessage !== "";

  const handleSignIn = async () => {
    if (!signInBtnIsDisabled) {
      const asyncSignInFn = async () => {
        dispatch(setIsLoading(true));
        const username = formData.email.data;
        const password = formData.password.data;
        const rememberMe = formData.rememberMe?.data === "checked";

        try {
          const result = await signInGetTokens({
            username,
            password,
            rememberMe,
          });

          if (result) {
            dispatch(userDataInSettings({ userData: { email: username } }));
            dispatch(selectPage(PAGES.CODE));
          }
        } catch (e) {
          const eMsg = (e as Error).message;
          setSignInErrorMessage({
            successMsg: "",
            errMsg: "Failed to sign in. Please try again.",
          });
          if (eMsg === "User is not confirmed.") {
            setSignType("verifyemail");
          }
        } finally {
          dispatch(setIsLoading(false));
        }
      };
      asyncSignInFn();
    }
  };

  return (
    <AccountWrapper title="Sign in to your account">
      <>
        <div className="login-form-field mt-2r">
          <InputWithValidation
            inputData={formData.email}
            label="Your email"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                email: {
                  ...formData.email,
                  data: newData,
                  errorMessage: ValidateEmail(newData)
                    ? ""
                    : "Enter a valid email address",
                },
              });
            }}
          />
          <FormInputMessage msg={{ errMsg: formData.email.errorMessage }} />
        </div>


        <div className="login-form-field mt-1r">
          <InputWithValidation
            inputData={formData.password}
            label="Password"
            onChange={(newData: string) => {
              setFormData({
                ...formData,
                password: {
                  ...formData.password,
                  data: newData,
                  errorMessage:
                    newData.length > 7
                      ? ""
                      : "Your password must have 8 or more characters",
                },
              });
            }}
            isPasswordWithEye={true}
          />
          <FormInputMessage msg={{ errMsg: formData.password.errorMessage }} />
        </div>
        <div className="login-form-field mt-1r">
          <label className="login-label-inline">
            <input
              type="checkbox"
              className="login-input login-checkbox"
              checked={formData.rememberMe?.data === "checked"}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  rememberMe: {
                    data: e.target.checked ? "checked" : "",
                    errorMessage: "",
                  },
                });
              }}
            />
            <span className="ml-05r">Remember me</span>
          </label>
        </div>
        <div className="login-form-field mt-1r">
          <a
            href="#home"
            className="login-link"
            onClick={() => {
              setSignType("forgottenPassword");
            }}
          >
            Forgot password?
          </a>
        </div>

        <div className="login-form-field mt-3r mb-1r">
          <button
            type="submit"
            aria-label="Update password"
            className={`login-button primary${signInBtnIsDisabled ? " disabled" : ""}`}
            disabled={signInBtnIsDisabled}
            onClick={handleSignIn}
          >
            Sign in
          </button>
          <FormInputMessage msg={signInErrorMessage} />
        </div>
        <div className="login-form-field mt-2r mb-1r">
          Don't have an account?
          <a
            href="#home"
            className="login-link ml-05r"
            onClick={() => {
              setSignType("signup");
            }}
          >
            Sign up
          </a>
        </div>
      </>
    </AccountWrapper>
  );
};
