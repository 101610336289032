import { itemIF, itemWithChildrenIF } from "../../data/types/item";

const sortBranch = (b: itemIF) => {
  if (!b.children) return;
  b.children.sort((a, b) =>
    a.position > b.position ? 1 : a.position < b.position ? -1 : 0
  );
  b.children.forEach((c) => {
    if (c.children && c.children.length > 0) {
      sortBranch(c);
    }
  });
};

export const addChildren = ({
  item,
  items,
  itemAndChildren,
}: {
  item: itemIF;
  items: itemIF[];
  itemAndChildren: itemIF[];
}) => {
  const children = items.filter((i) => i.parent === item.id);
  children.forEach((c) => {
    if (!itemAndChildren.find((i) => i.id === c.id)) {
      itemAndChildren.push(c);
      addChildren({
        item: c,
        items,
        itemAndChildren,
      });
    }
  });
};

export const addChildrenInTree = ({
  items,
}: {
  items: itemWithChildrenIF[];
}) => {
  if (items.length === 1) {
    return;
  }
  const copyItems = items.map((i) => i);
  copyItems.forEach((item) => {
    if (item.level === 1) {
      return;
    }
    const children = items.filter((i) => i.parent === item.id);
    // no children: add it to the children array of its parent
    if (children.length === 0) {
      const parentIndex = items.findIndex((i) => i.id === item.parent);
      if (parentIndex !== -1) {
        items[parentIndex].children.push(item);
      }
      const itemIndex = items.findIndex((i) => i.id === item.id);
      items.splice(itemIndex, 1);
    }
  });
  // if there are new items without children and level > 1, run again
  const noChildrenItem = items.find(
    (i) => i.level && i.level > 1 && !items.find((it) => it.parent === i.id)
  );
  if (noChildrenItem) {
    addChildrenInTree({
      items,
    });
  }
};

export const addBranch = ({
  topInBranch,
  items,
}: {
  topInBranch: itemIF;
  items: itemIF[];
}):itemWithChildrenIF => {
  const descendants = [topInBranch];
  addChildren({ item: topInBranch, items, itemAndChildren: descendants });
  const dWithChildren = descendants.map((d) => ({ ...d, children: [] }));
  addChildrenInTree({
    items: dWithChildren,
  });
  return dWithChildren[0];
};

export const buildTree = (items: itemIF[]): itemWithChildrenIF[] => {
  // item name is 'svg'? find all children of the assigned svg and add them item children

  const topInBranch = items?.filter((i) => i.level && i.level === 1);
  const itemsWithChildren = [] as itemWithChildrenIF[];
  topInBranch?.forEach((item) => {
    const itemWithChildren = addBranch({
      topInBranch: item,
      items,
    });
    itemsWithChildren.push(itemWithChildren);
  });

  itemsWithChildren.sort((a, b) =>
    a.position > b.position ? 1 : a.position < b.position ? -1 : 0
  );

  // sort all children in a branch using their position
  const itemsLevel1Sorted = itemsWithChildren.map((branch) => {
    if (branch.children && branch.children.length > 0) {
      // sort children and children of children ...
      sortBranch(branch);
      return branch;
    }
    return branch;
  });

  return itemsLevel1Sorted;
};
