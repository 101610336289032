import { itemIF } from "../../../data/types/item";

const addOrRemoveClassId = ({
  item,
  classIds,
}: {
  item: itemIF;
  classIds: number[];
}) => {
  if (item.assignedClasses && item.assignedClasses.length > 0) {
    if (item.assignedClasses.find((ac) => classIds.includes(ac))) {
      return item.assignedClasses.filter((ac) => !classIds.includes(ac));
    }
    if (classIds[0]) {
      return [...item.assignedClasses, classIds[0]];
    }
  }
  if (classIds[0]) {
    return [classIds[0]];
  }
  return [];
};

export const assignClassToHtmlOrSvg = ({
  items,
  itemId,
  classIds,
}: {
  itemId: number;
  items: itemIF[];
  classIds: number[];
}) => {
  return items.map((html) => {
    if (html.id === itemId) {
      const newAssignedClasses = addOrRemoveClassId({
        item: html,
        classIds,
      });
      return {
        ...html,
        assignedClasses: newAssignedClasses,
      };
    }
    return html;
  });
};
