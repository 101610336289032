import { itemIF } from "../../../data/types/item";
import { itemTypes } from "../../meta/getMetas";
import { sortTree2 } from "../../tree/sortTree";
import { getKidsIds } from "./getDescendantsAndAncestors";

export const deleteItem = ({
  items,
  editItemId,
  itemId,
  itemType,
}: {
  items: itemIF[];
  editItemId: number;
  itemId?: number;
  itemType: itemTypes;
}): { items: itemIF[]; editItemId: number } | undefined => {
  try {
    const editItem = items.find((i) => i.id === itemId);
    if (!editItem) {
      console.warn("ITEM_IN_TREE_DELETE editItem not found");
      const editItemOld = items.find((i) => i.id === editItemId);      
      return {
        items,
        editItemId: editItemOld ? editItemOld.id : 0,
      };
    }

    if (itemType === itemTypes.CLASSES_CSS) {
      console.error(
        "use ITEM_IN_TREE_DELETE_CSS instead of ITEM_IN_TREE_DELETE"
      );
      const itemsWithoutItem = items.filter((i) => i.id !== itemId);
      const nextSibling = itemsWithoutItem.find(
        (i) => i.parent === editItem.parent && i.position >= editItem.position
      );
      const newEditItemId = nextSibling
        ? nextSibling.id
        : itemsWithoutItem[0]?.id;
      return {
        items: itemsWithoutItem,
        editItemId: newEditItemId,
      };
    }

    const idsOfDescendants: number[] = [editItem.id];
    getKidsIds({
      item: editItem,
      items,
      kidsIds: idsOfDescendants,
    });

    const newTree: itemIF[] = items.filter(
      (i) => i.id !== itemId && !idsOfDescendants.includes(i.id)
    );
    const nextSibling = newTree.find(
      (i) => i.parent === editItem.parent && i.position >= editItem.position
    );

    const sortedTree = sortTree2(newTree);
    const newEditItemId = nextSibling ? nextSibling.id : (sortedTree?.[0]?.id || 0);

    return {
      items: sortedTree,
      editItemId: newEditItemId,
    };
  } catch (error) {
    console.error(error);
  }
};

export const deleteSelectorAndProperties = ({
  items,
  itemId,
  editItemId,
  properties,
}: {
  items: itemIF[];
  itemId: number;
  editItemId: number;
  properties: itemIF[];
}): { items: itemIF[]; editItemId: number; properties: itemIF[] } => {
  const deleteResult = deleteItem({
    items,
    itemId,
    itemType: itemTypes.SELECTORS,
    editItemId,
  });

  // get all deleted items
  if (!deleteResult?.items) {
    console.warn("deleteSelectorAndProperties: no deleteResult.items");
    return { items, editItemId, properties };
  }

  const deletedItemsIds = items
    .filter((i) => !deleteResult.items.find(ir => ir.id === i.id))
    .map((i) => i.id);
  // remove all properties assigned to the deleted selectors
  const newProperties = properties.filter(
    (p) => !deletedItemsIds.includes(p.classId!)
  );

  return {
    items: deleteResult.items,
    editItemId: deleteResult.editItemId,
    properties: newProperties,
  };
};
