import { itemIF } from "../../../data/types/item";
import {
  getChildrenSelectors,
  getAssignedAnimations,
  getAssignedSelectorIds,
  getAssignedClassNames,
} from "../../items/getAssignedSelectorsNew";
import { getKeyframesAndStepsForAssignedAnimations } from "../../render/css/animation";
import { getMediaSelectorsWithAssignedClassChild } from "./groupAndSortMediaSelectorNameNew";

const getRelevantPureClassSelectors = ({
  assignedClassNames,
  selectors,
  properties,
}: {
  assignedClassNames: string[];
  selectors: itemIF[];
  properties: itemIF[];
}) => {
  const assignedSelectorsPlusChildren: itemIF[] = [];
  const assignedLevel1Classes = selectors?.filter((s) => {
    const selectorName = s.value?.main?.value;

    const isClassnameAssigned =
      s.name === "class" &&
      s.level === 1 &&
      selectorName &&
      typeof selectorName === "string" &&
      assignedClassNames?.includes(selectorName);

    return isClassnameAssigned;
  });

  assignedLevel1Classes?.forEach((selector) => {
    assignedSelectorsPlusChildren.push(selector);
    getChildrenSelectors({
      selector,
      selectors,
      properties,
      assignedSelectorsPlusChildren,
    });
  });
  return assignedSelectorsPlusChildren;
};

const getRelevantKeyframeSelectors = ({
  assignedClassNames,
  selectors,
  properties,
}: {
  assignedClassNames: string[];
  selectors: itemIF[];
  properties: itemIF[];
}) => {
  const assignedAnimations = getAssignedAnimations({
    selectors,
    properties,
    assignedClassNames: assignedClassNames,
  });

  const assignedKeyframesAndSteps = getKeyframesAndStepsForAssignedAnimations({
    assignedAnimations,
    selectors,
    properties,
  });
  return assignedKeyframesAndSteps;
};

const getRelevantMediaSelectorsPlusChildren = ({
  assignedClassNames,
  selectors,
  properties,
}: {
  assignedClassNames: string[];
  selectors: itemIF[];
  properties: itemIF[];
}) => {
  const allRelevantMediaSelectors: itemIF[] = [];
  const mediaSelectorsWithAssignedChildClass =
    getMediaSelectorsWithAssignedClassChild({
      selectors,
      assignedClassNames: assignedClassNames || [],
    });
  mediaSelectorsWithAssignedChildClass?.forEach((mediaSelector) => {
    allRelevantMediaSelectors.push(mediaSelector);
    getChildrenSelectors({
      selector: mediaSelector,
      selectors,
      properties,
      assignedSelectorsPlusChildren: allRelevantMediaSelectors,
    });
  });
  return allRelevantMediaSelectors;
};

export const getFeaturingSelectors = ({
  htmls,
  selectors,
  properties
}: {
  htmls: itemIF[];
  selectors: itemIF[];
  properties: itemIF[];
}) => {
  const assignedSelectorIds: number[] = [];
  getAssignedSelectorIds({ items: htmls, assignedSelectorIds });

  const assignedClassNames =
    getAssignedClassNames({
      assignedSelectorIds,
      selectors,
    }) || [];

  const relevantPureClassSelectors = getRelevantPureClassSelectors({
    assignedClassNames,
    selectors,
    properties
  });

  const relevantMediaSelectors = getRelevantMediaSelectorsPlusChildren({
    assignedClassNames,
    selectors,
    properties,
  });

  const relevantKeyframeSelectors = getRelevantKeyframeSelectors({
    assignedClassNames,
    selectors,
    properties,
  });

  return {
    classSelectors: relevantPureClassSelectors,
    mediaSelectors: relevantMediaSelectors,
    keyframeSelectors: relevantKeyframeSelectors,
  };
};
