import { metaIF, EMetaFieldTypes } from "../../types/item";
import { getCategoryMetas } from "../../../utils/data/meta";
import { fieldsAll } from "../../constants";
import { CSS_PROPERTIES_BACKGROUND } from "./background";
import { CSS_PROPERTIES_BORDER } from "./border";
import { CSS_PROPERTIES_COLUMN } from "./column";
import { CSS_PROPERTIES_DIMENSION } from "./dimension";
import { CSS_PROPERTIES_FILTER } from "./filter";
import { CSS_PROPERTIES_FLEX } from "./flex";
import { CSS_PROPERTIES_GRID } from "./grid";
import { CSS_PROPERTIES_LIST } from "./list";
import { CSS_PROPERTIES_OVERFLOW } from "./overflow";
import { CSS_PROPERTIES_OTHER } from "./other";
import { CSS_PROPERTIES_POSITION } from "./position";
import { CSS_PROPERTIES_SHADOW } from "./shadow";
import { CSS_PROPERTIES_SVG } from "./svg";
import { CSS_PROPERTIES_TABLE } from "./table";
import { CSS_PROPERTIES_TEXT } from "./text";
import { CSS_PROPERTIES_TRANSFORM } from "./transform";
import { CSS_PROPERTIES_FLOAT } from "./float";
import { CSS_PROPERTIES_ANIMATION } from "./animation";
import { getValueStr } from "../../../lib/reducer/items/getItemData";

export const getCssMeta = (name: string) => {
  return CSS_PROPERTIES.find((a) => a.name === name);
};

export const CLASSES_CSS = "CLASSES_CSS";

export const ANIMATABLE_PROPERTIES: string[] = [
  "background",
  "background-color",
  "background-position",
  "background-size",
  "border",
  "border-bottom",
  "border-bottom-color",
  "border-bottom-left-radius",
  "border-bottom-right-radius",
  "border-bottom-width",
  "border-color",
  "border-left",
  "border-left-color",
  "border-left-width",
  "border-right",
  "border-right-color",
  "border-right-width",
  "border-spacing",
  "border-top",
  "border-top-color",
  "border-top-left-radius",
  "border-top-right-radius",
  "border-top-width",
  "bottom",
  "box-shadow",
  "clip-path",
  "column-count",
  "color",
  "column-gap",
  "column-rule",
  "column-rule-color",
  "column-rule-width",
  "column-width",
  "columns",
  "fill",
  "filter",
  "flex",
  "flex-basis",
  "flex-grow",
  "flex-shrink",
  "font",
  "font-size",
  "font-smooth",
  "font-stretch",
  "font-weight",
  "height",
  "left",
  "letter-spacing",
  "line-height",
  "margin",
  "margin-bottom",
  "margin-left",
  "margin-right",
  "margin-top",
  "max-height",
  "max-width",
  "min-height",
  "min-width",
  "object-position",
  "opacity",
  "order",
  "outline",
  "outline-color",
  "outline-offset",
  "outline-width",
  "padding",
  "padding-bottom",
  "padding-left",
  "padding-right",
  "padding-top",
  "perspective",
  "perspective-origin",
  "right",
  "text-decoration-color",
  "text-indent",
  "text-shadow",
  "top",
  "transform",
  "transform-origin",
  "stroke",
  "stroke-width",
  "vertical-align",
  "visibility",
  "width",
  "word-spacing",
  "z-index",
];

export const PROPERTY_CATEGORY_HELP: { [key: string]: string } = {
  flex:
    "### flex  \n" +
    "See for example [CSS Tricks - A Complete Guide to Flexbox](//css-tricks.com/snippets/css/a-guide-to-flexbox/)",
  grid:
    "### grid  \n" +
    "See for example [CSS Tricks - A Complete Guide to Grid](//https://css-tricks.com/snippets/css/complete-guide-grid/)",
};

export const CSS_PROPERTIES: metaIF[] = [
  {
    name: "ALL",
    level: "flag",
    category: ["ALL"],
    fieldsOrder: ["important"],
    fields: [
      {
        name: "important",
        javascript: "!important",
        type: EMetaFieldTypes.boolean,
      },
    ],
    group: [
      {
        name: "importantG",
        display: ["!important"],
        fields: ["important"],
      },
    ],
    default: {
      important: {
        value: false,
      },
    },
  },

  ...CSS_PROPERTIES_ANIMATION,
  ...CSS_PROPERTIES_BACKGROUND,
  ...CSS_PROPERTIES_BORDER,
  ...CSS_PROPERTIES_COLUMN,
  ...CSS_PROPERTIES_DIMENSION,
  ...CSS_PROPERTIES_FILTER,
  ...CSS_PROPERTIES_FLEX,
  ...CSS_PROPERTIES_GRID,
  ...CSS_PROPERTIES_LIST,
  ...CSS_PROPERTIES_OVERFLOW,
  ...CSS_PROPERTIES_OTHER,
  ...CSS_PROPERTIES_POSITION,
  ...CSS_PROPERTIES_SHADOW,
  ...CSS_PROPERTIES_SVG,
  ...CSS_PROPERTIES_TABLE,
  ...CSS_PROPERTIES_TEXT,
  ...CSS_PROPERTIES_TRANSFORM,
  ...CSS_PROPERTIES_FLOAT,

  {
    name: "opacity",
    category: ["OTHER", "SVG"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        min: 0,
        max: 1,
      },
    ],
    group: [
      {
        name: "opacityG",
        fields: ["main"],
        display: ["opacity"],
      },
    ],
    default: { main: { value: 1 } },
  },

  {
    name: "transition",
    category: ["TRANSITION"],
    addToShorthand: "transition",
    shorthandSeparator: ", ",
    addMultipleTimes: true,
    fieldsOrder: [
      "property",
      "duration",
      "timing",
      "x1",
      "y1",
      "x2",
      "y2",
      "intervals",
      "direction",
      "delay",
    ],
    fields: [
      {
        name: "property",
        type: EMetaFieldTypes.propertyOfClassAndAll,
        onOff: true,
        help:
          "#### css property or all  \n" +
          "Select here a CSS property of the current selector, " +
          "if you want to define the transition effect just for the one property, " +
          "or select `all` if the transition is meant to affect all css properties of the selector.",
      },
      {
        name: "duration",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [{ id: "s" }],
        default: { value: 0, unit: "s" },
      },
      fieldsAll.timing,
      fieldsAll.timingX1,
      fieldsAll.timingY1,
      fieldsAll.timingX2,
      fieldsAll.timingY2,
      fieldsAll.timingIntervals,
      fieldsAll.timingDirection,
      {
        name: "delay",
        type: EMetaFieldTypes.number,
        units: [{ id: "s" }],
        default: { value: 0, unit: "s" },
      },
    ],

    group: [
      {
        name: "propertyG",
        display: ["property"],
        fields: ["property"],
      },
      {
        name: "durationG",
        display: ["duration"],
        fields: ["duration"],
      },
      {
        name: "timingG",
        display: ["timing"],
        fields: ["timing"],
      },
      {
        name: "xYStartXYEnd",
        display: ["x1", "y1", "x2", "y2"],
        fields: ["x1", "y1", "x2", "y2"],
      },
      {
        name: "stepsG",
        fields: ["intervals", "direction"],
        display: ["steps", "direction"],
      },
      {
        name: "delayG",
        display: ["delay"],
        fields: ["delay"],
      },
    ],

    wrapperForGroup: [
      {
        name: "timingFnCurveOrSteps",
        fields: ["timing", "x1", "y1", "x2", "y2", "intervals", "direction"],
        wrapperFn: (valueObj, items) => {
          if (valueObj.timing.value === "cubic-bezier") {
            const x1 = `${getValueStr({
              valObj: valueObj.x1,
            })}`;
            const y1 = `${getValueStr({
              valObj: valueObj.y1,
            })}`;
            const x2 = `${getValueStr({
              valObj: valueObj.x2,
            })}`;
            const y2 = `${getValueStr({
              valObj: valueObj.y2,
            })}`;

            return `cubic-bezier(${x1},${y1},${x2},${y2})`;
          }
          if (valueObj.timing.value === "steps") {
            return `steps(${getValueStr({
              valObj: valueObj.intervals,
            })}, ${getValueStr({
              valObj: valueObj.direction,
            })})`;
          }
          if (valueObj.timing.value === "none") {
            return "";
          }
          return valueObj.timing.value;
        },
      },
    ],

    default: {
      property: { value: "all" },
      duration: { value: 0.5, unit: "s" },
      timing: { value: "ease" },
      x1: { value: 0.25 },
      y1: { value: 0.5 },
      x2: { value: 0.75 },
      y2: { value: 0.5 },
      intervals: { value: 3 },
      direction: { value: "end" },
      delay: { value: 0, unit: "s" },
    },
  },
  /**
   * CSS variable
   * a CSS variable is in scope when it is defined in :root, in a parent selector or the current selector
   * for a CSS variable are the same specificity rules valid as in all other CSS properties: more specific rules override less specific rules
   * and a CSS variable defined further down in a selector overrides a CSS variable defined further up
   * if CSS variables are in scope you can select one for a CSS property
   * the CSS variable string is then rendered instead of the CSS of the property
   */
  {
    name: "variable",
    category: ["VARIABLE"],
    addMultipleTimes: true,
    fieldsOrder: ["name", "string"],
    fields: [
      {
        name: "name",
        type: EMetaFieldTypes.string,
        dontRender: true,
        isPropertyName: true,
        onOff: true,
        wrapperFn: (valueObj) => {
          const variableName = valueObj.value;
          const result = variableName.replace(/^-+/g, "");
          return `--${result}`;
        },
      },
      {
        name: "string",
        type: EMetaFieldTypes.textarea,
        onOff: true,
      },
    ],
    default: {
      name: {
        value: "shadow-2",
      },
      string: {
        value: "0 2px 5px rgba(22,22,22,0.25)",
      },
    },
  },
  {
    name: "custom",
    category: ["VARIABLE"],
    useFieldAsPropertyName: "name",
    addMultipleTimes: true,
    fieldsOrder: ["name", "type", "color", "value", "string"],
    help:
      "### Custom CSS property  \n" +
      "CSS does offer access to CSS properties only which are not in an experimental state or are supported by most browser. For example, the property margin-block is not supported in IE, Edge, Safari, Opera, Android Browser and iOs Safari. But you may want to use one of those properties to try something out or check different methods to create a certain effect. In this case, use the `custom` property. Enter the name of the property in the `name` field, choose a value `type` and enter the value in the respective `value` field.",
    fields: [
      {
        name: "name",
        type: EMetaFieldTypes.string,
        dontRender: true,
        onOff: true,
      },
      {
        name: "type",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "color",
          },
          {
            id: "value",
          },
          {
            id: "string",
          },
        ],
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
        dependentOn: {
          field: "type",
          values: ["color"],
        },
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "none" },
          { id: "px" },
          { id: "rem" },
          { id: "%" },
          { id: "cm" },
          { id: "deg" },
          { id: "em" },
          { id: "fr" },
          { id: "in" },
          { id: "pt" },
          { id: "s" },
          { id: "vw" },
          { id: "vh" },
        ],
        dependentOn: {
          field: "type",
          values: ["value"],
        },
        wrapperFn: (vObj) =>
          `${vObj.unit === "none" ? vObj.value : `${vObj.value}${vObj.unit}`}`,
      },
      {
        name: "string",
        type: EMetaFieldTypes.string,
        onOff: true,
        dependentOn: {
          field: "type",
          values: ["string"],
        },
      },
    ],
    group: [
      {
        name: "nameG",
        fields: ["name"],
        display: ["name"],
      },
      {
        name: "typeG",
        fields: ["type", "color", "value", "string"],
        display: ["type", "color", "value", "string"],
      },
    ],
    default: {
      name: {
        value: "margin-inline-end",
      },
      type: {
        value: "value",
      },
      color: {
        value: { r: 60, g: 188, b: 195, a: 1 },
      },
      value: {
        value: 0.75,
        unit: "rem",
      },
      string: {
        value: "0.5rem 0.75rem",
      },
    },
  },
];

export type TCSSCategory =
  | "ANIMATION"
  | "BACKGROUND"
  | "addOrRemoveFieldGroup"
  | "BORDER"
  | "COLUMN"
  | "DIMENSION"
  | "FILTER"
  | "FLEX"
  | "GRID"
  | "LIST"
  | "OVERFLOW"
  | "OTHER"
  | "POSITION"
  | "SHADOW"
  | "SVG"
  | "TABLE"
  | "TEXT"
  | "TRANSFORM"
  | "FLOAT"
  | "TRANSITION"
  | "VARIABLE";

export const CSS_CATEGORIES: {
  [key: string]: { order?: string[] };
} = {
  ANIMATION: {},
  BACKGROUND: {
    order: [
      "background-color",
      "background",
      "background-linear-gradient",
      "background-radial-gradient",
      "background-position",
      "background-size",
      "background-repeat",
      "background-attachment",
      "background-clip",
      "background-origin",
      "background-image",
    ],
  },
  BORDER: {},
  COLUMN: {},
  DIMENSION: {
    order: [
      "width",
      "height",
      "min-width",
      "min-height",
      "max-width",
      "max-height",
      "object-fit",
      "clip",
    ],
  },
  FILTER: {
    order: [
      "blur",
      "brightness",
      "contrast",
      "drop-shadow",
      "grayscale",
      "hue-rotate",
      "invert",
      "opacity-filter",
      "saturate",
      "sepia",
      "url",
    ],
  },
  FLEX: {
    order: [
      "display-flex",
      "flex-direction",
      "flex-wrap",
      "justify-content",
      "align-items",
      "align-content",
      "gap",
      "flex",
      "flex-grow",
      "flex-shrink",
      "flex-basis",
      "order",
      "align-self",
    ],
  },
  FLOAT: {},
  GRID: {
    order: [
      "display-grid",
      "grid-template-columns",
      "grid-template-rows",
      "justify-content-grid",
      "align-content-grid",
      "grid-gap",
      "grid-column-start",
      "grid-column-end",
      "grid-row-start",
      "grid-row-end",
      "justify-self-grid",
      "align-self-grid",
    ],
  },
  LIST: {},
  OTHER: {},
  OVERFLOW: {},
  POSITION: {
    order: [
      "margin",
      "padding",
      "display",
      "position",
      "top",
      "right",
      "bottom",
      "left",
      "box-sizing",
      "z-index",
    ],
  },

  SHADOW: {},
  SVG: {
    order: [
      "fill",
      "fill-rule",
      "opacity",
      "stroke",
      "stroke-width",
      "stroke-linecap",
      "stroke-linejoin",
      "stroke-dasharray",
      "stroke-miterlimit",
    ],
  },
  TEXT: {},
  TABLE: {},
  TRANSFORM: {},
  TRANSITION: {},
  VARIABLE: {},
};

export const getCssCategoryMetas = (category: string) => {
  return getCategoryMetas({
    category,
    categories: CSS_CATEGORIES,
    metas: CSS_PROPERTIES,
  });
};
