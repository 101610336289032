import { FC, ReactElement, useState, useEffect } from "react";
import { itemIF, metaIF } from "../../data/types/item";
import { itemTypes } from "../../lib/meta/getMetas";
import { getMetaWithAllAndCanBeFields } from "../../lib/meta/getMetaWithAllAndCanBeFields";
import { getGroups } from "../../lib/items/editItem";
import { SectionTitle } from "../modules/SectionTitle";
import { Info } from "../atoms/alert/alert";
import { AssignVariable } from "./AssignVariable";
import { EditGroup } from "./EditGroup";
import { useDivPosition } from "../modules/GetPositionWrapper";

const getLabel = ({
  itemType,
  editItem,
  meta,
}: {
  itemType: string;
  editItem?: itemIF;
  meta?: metaIF;
}) => {
  const itemName = meta
    ? meta?.display
      ? meta.display
      : meta.name
    : editItem?.name;
  if (itemName) {
    return `Edit ${itemName}`;
  }

  if (itemTypes.SETTINGS) {
    return editItem?.name === "width"
      ? "Width"
      : editItem?.name === "height"
        ? "Height"
        : "Select Font";
  }

  return itemType === itemTypes.SVGS
    ? "Edit SVG"
    : itemType === itemTypes.CLASSES_CSS
      ? "Edit CSS Property"
      : itemType === itemTypes.SELECTORS
        ? "Edit Selector"
        : itemType === itemTypes.BLOCK_HTML
          ? "Edit HTML"
          : itemType === itemTypes.EVENT
            ? "Edit Event"
            : "EDIT ITEM";
};

export const noFieldText = (itemType: string) => {
  return itemType === itemTypes.SVGS
    ? "NO SVG ATTRIBUTES"
    : itemType === itemTypes.CLASSES_CSS
      ? "NO CSS ATTRIBUTES"
      : itemType === itemTypes.SELECTORS
        ? "NO SELECTOR ATTRIBUTES"
        : itemType === itemTypes.BLOCK_HTML
          ? "NO HTML ATTRIBUTES"
          : "ADD AN EVENT";
};

export type EditItemProps = {
  itemType: itemTypes;
  editItem: itemIF | undefined;
  isItemDisabled?: boolean;
  addOrRemoveEditItemId?: number;
  editSnippetIdOther?: string;
};

export const EditItem: FC<EditItemProps> = ({
  itemType,
  editItem,
  isItemDisabled,
  addOrRemoveEditItemId,
  editSnippetIdOther,
}) => {
  const [meta, setMeta] = useState<undefined | metaIF>(undefined);
  useEffect(() => {
    if (editItem) {
      const meta = getMetaWithAllAndCanBeFields({ itemType, item: editItem });
      setMeta(meta);
    }
  }, [editItem, itemType]);

  const { divRef, divPos } = useDivPosition();

  const label = getLabel({ itemType, editItem });
  if (!editItem || !meta) {
    return (
      <>
        <SectionTitle label={label} help={meta?.help} warning={meta?.warning} />
        <div className="p-2 text">
          <Info isAlert={true}>{noFieldText(itemType)}</Info>
        </div>
      </>
    );
  }

  const isAddOrRemoveItem = meta.category.includes("addOrRemoveFieldGroup");
  const allGroups = getGroups({
    meta,
  });

  return (
    <div className="form-elements-wrapper">
      {!isAddOrRemoveItem && (
        <div className="mb-1r" ref={divRef}>
          <SectionTitle
            label={label}
            help={meta.help}
            warning={meta.warning}
            size="large"
            parentPosition={divPos}
          />
        </div>
      )}
      {!isAddOrRemoveItem &&
        itemType === itemTypes.CLASSES_CSS &&
        editItem.name !== "variable" && (
          <div className="mb-2r">
            <AssignVariable
              assignedCssVariableName={
                editItem?.value?.assignedCssVariableName?.value
              }
              editSnippetIdOther={editSnippetIdOther}
            />
          </div>
        )}
      {allGroups?.map((group, i) => {
        return (
          <EditGroup
            key={i}
            group={group}
            meta={meta}
            editItem={editItem}
            itemType={itemType}
            isItemDisabled={isItemDisabled}
            addOrRemoveEditItemId={addOrRemoveEditItemId}
            editSnippetIdOther={editSnippetIdOther}
          />
        );
      })}
    </div>
  );
};

export const EditItemWrapper: React.FC<{ children: ReactElement[] }> = ({
  children,
}) => {
  return (
    <div className="tw-form">
      <div className="edit-group-box">
        <div className="inner">{children}</div>
      </div>
    </div>
  );
};
