import { IUser } from "../user/constants";

export enum PAGES {
  CODE = "CODE",
  GALLERY = "GALLERY",
  HOME = "HOME",
  NOTFOUND = "NOTFOUND",
  PRIVACY = "PRIVACY",
  SETTINGS = "SETTINGS",
  SIGNIN = "SIGNIN",
  TERMS = "TERMS",
  USER = "USER",
}

export enum SUB_PAGES {
  HTML = "HTML",
  CSS = "CSS",
  SVG = "SVG",
  FULLPAGEPREVIEW = "FULLPAGEPREVIEW",
  SETTINGS = "SETTINGS",
  SNIPPET = "SNIPPET",
  SNIPPETS = "SNIPPETS",
}

export type TCashedCode = {
  [key: string]: { name: string; code: string; css?: string };
};

/*
there is one stylepack for one snippet, when a snippet is created then a new default stylepack is also created
in User Stylepacks (when you are signed in), you can edit the default stylepack or overwrite it with a stylepack from another snippet
*/

export interface settingsIF {
  isLoading?: boolean;
  renderedHtml?: string;
  scrollPos?: number;
  selectedCategory: string;
  selectedPage: PAGES;
  selectedSubPage: SUB_PAGES;
  selectedPageBefore?: string;
  user?: IUser;
  userId?: string;

  // render code for html, selectors, css and svg only when something changes
  cashedCode?: TCashedCode;
}

export const initialSettings: settingsIF = {
  selectedCategory: "BACKGROUND",
  selectedPage: PAGES.HOME,
  selectedSubPage: SUB_PAGES.HTML,
  selectedPageBefore: "HOME",
  scrollPos: 0,
};
