import { FC } from "react";
import { useStateContext } from "../../data/main/state";
import { SUB_PAGES } from "../../data/settings/constants";
import { Tooltip } from "../atoms/tooltip";
import { getFormattedCode } from "../../utils/preview/format-html";
import { ReactComponent as CopyIcon } from "../../assets/imgs/copy.svg";
import { copyToClipboard } from "../../utils/preview/copyCodeToClipboard";

const htmlstart = `<!DOCTYPE html><html><head><title>CSSEXY PAGE</title></head><body class="body">`;
const htmlend = `</body></html>`;

export const CodePreview: FC<{ htmlStr: string; splitScreenMode: boolean }> = ({
  htmlStr,
  splitScreenMode,
}) => {
  const {
    state: {
      settings: { selectedSubPage },
    },
  } = useStateContext();

  const isInSvgView = selectedSubPage === SUB_PAGES.SVG;
  const docStart = isInSvgView ? "" : htmlstart;
  const docEnd = isInSvgView ? "" : htmlend;

  const formattedHtmlStr = getFormattedCode(htmlStr);

  return (
    <div
      className="code-box"
      style={{
        display: splitScreenMode ? "block" : "none",
        width: "50%",
        height: "100%",
      }}
    >
      <div className="title">
        <div className="tab">Code</div>
        <div
          className="tab button"
          onClick={() =>
            copyToClipboard(`${docStart}${htmlStr}${docEnd}`, true)
          }
        >
          <CopyIcon />
          <span>Copy</span>
          <Tooltip tooltipText="Copy to Clipboard" />
        </div>
      </div>
      <pre className="html-css-code">{formattedHtmlStr}</pre>
    </div>
  );
};
