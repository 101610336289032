import {
  CognitoIdentityProviderClient,
  GlobalSignOutCommand,
} from "@aws-sdk/client-cognito-identity-provider";

const client = new CognitoIdentityProviderClient({
  region: "eu-west-2",
});

export const signOutFn = async () => {
  try {
    const tokensStr = window.localStorage.getItem("tokens");
    if (!tokensStr) {
      throw new Error("No tokens found");
    }
    const tokens = JSON.parse(tokensStr);
    const AccessToken = tokens.AccessToken;

    const command = new GlobalSignOutCommand({
      AccessToken: AccessToken,
    });
    await client.send(command);

    return true;
  } catch (error) {
    console.error("Error signing out:", error);
  } finally {
    window.localStorage.removeItem("tokens");
    window.sessionStorage.removeItem("tokens");
  }
};
