import { useState, FC } from "react";
import { useStateContext } from "../../data/main/state";
import { SUB_PAGES } from "../../data/settings/constants";
import { replaceAHref } from "../../lib/render/replaceAHref";
import { CustomIframe } from "../atoms/CustomIFrame";
import { PreviewToolbar } from "../navbar/PreviewToolbar";
import { CodePreview } from "./CodePreview";

import "./Preview.css";

export const PreviewWithToolbar2: FC<{ snippetHtml:string, snippetName:string, snippetFonts?:string, htmlCss?:string }> = ({
  snippetName,
  snippetHtml,
  snippetFonts,
  htmlCss
}) => {
  const {
    state: {
      settings: { selectedSubPage },
    },
  } = useStateContext();
  const [pHeight, setPHeight] = useState(`320px`);
  const [pWidth, setPWidth] = useState(`100%`);
  const [splitScreenMode, setSplitScreenMode] = useState(false);

  const htmlString = replaceAHref(snippetHtml || "<div>SNIPPET IS EMPTY</div>");

  return (
    <div className="preview-board">
      <PreviewToolbar
        pWidth={pWidth}
        setPWidth={setPWidth}
        pHeight={pHeight}
        setPHeight={setPHeight}
        splitScreenMode={splitScreenMode}
        setSplitScreenMode={setSplitScreenMode}
      />
      <div
        className="code-preview-box"
        style={{
          height: pHeight === "auto" ? undefined : pHeight,
        }}
      >
        <div
          className={`preview-box${selectedSubPage === SUB_PAGES.SVG ? " drawing-board" : ""}`}
          style={{
            width: splitScreenMode ? "50%" : pWidth,
            height: pHeight,
          }}
        >
          <CustomIframe
            title={`snippet preview for ${snippetName}`}
            width={splitScreenMode ? "100%" : pWidth}
            height={pHeight}
            className="i-frame"
            headContent={snippetFonts}
            htmlSelectorCss={htmlCss}
          >
            <div dangerouslySetInnerHTML={{ __html: htmlString }} className="top-div" />
          </CustomIframe>
        </div>

        <CodePreview htmlStr={snippetHtml} splitScreenMode={splitScreenMode} />
      </div>
    </div>
  );
};
