export const buildCombinations = (allGroups: string[][]) => {
  const indexInArray = new Array(allGroups.length);
  indexInArray.fill(0);
  let arrayIndex = 0;
  const resultArray: string[] = [];
  while (allGroups[arrayIndex]) {
    let str = "";
    allGroups.forEach((g, index) => {
      str += g[indexInArray[index]];
    });
    resultArray.push(str);
    // if not last item in array already, switch index to next item in array
    if (indexInArray[arrayIndex] < allGroups[arrayIndex].length - 1) {
      indexInArray[arrayIndex] += 1;
    } else {
      // set item index for the next array
      indexInArray[arrayIndex] = 0;
      arrayIndex += 1;
      // exclude arrays with 1 element
      while (allGroups[arrayIndex] && allGroups[arrayIndex].length === 1) {
        arrayIndex += 1;
      }
      indexInArray[arrayIndex] = 1;
    }
  }
  return resultArray;
};
