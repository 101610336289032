import { itemIF } from "../../../data/types/item";
import { itemTypes } from "../../meta/getMetas";
import { sortTree2 } from "../../tree/sortTree";

const moveChildrenLeftOrRight = ({
  items,
  item,
  direction,
}: {
  items: itemIF[];
  item: itemIF;
  direction: "left" | "right";
}): itemIF[] => {
  item.level = item.level || 1;
  const updatedLevel = item.level + (direction === "right" ? 1 : -1);
  const updatedItem = { ...item, level: updatedLevel };
  let updatedItems = items.map((i) => (i.id === item.id ? updatedItem : i));

  const children = items.filter((i) => i.parent === item.id);
  children.forEach((child) => {
    updatedItems = moveChildrenLeftOrRight({
      items: updatedItems,
      item: child,
      direction,
    });
  });

  return updatedItems;
};

type TResult = {
  items: itemIF[];
  editItemId: number;
};

export const moveItemsLeft = ({
  items,
  editItem,
}: {
  items: itemIF[];
  editItem: itemIF;
}): TResult => {
  const parent = items.find((i) => i.id === editItem.parent);
  if (!parent) {
    console.warn("moveItemsLeft: no parent", editItem, items);
    return { items, editItemId: editItem.id };
  }

  const newEditItem = {
    ...editItem,
    parent: parent.parent,
    level: parent.level,
    position: parent.position + 1,
  };

  let newItems = items.map((i) => {
    if (i.id === editItem.id) {
      return newEditItem;
    } else if (i.parent === editItem.parent && i.position > editItem.position) {
      return { ...i, position: i.position + 1 };
    }
    return i;
  });

  const children = items.filter((i) => i.parent === editItem.id);
  children.forEach((child) => {
    newItems = moveChildrenLeftOrRight({
      items: newItems,
      item: child,
      direction: "left",
    });
  });

  return { items: newItems, editItemId: editItem.id };
};

export const moveItemsRight = ({
  items,
  editItem,
}: {
  items: itemIF[];
  editItem: itemIF;
}): TResult => {
  const siblingsBeforeEditItem = items.filter(
    (i) => i.parent === editItem.parent && i.position < editItem.position
  );
  const maxPosition = Math.max(...siblingsBeforeEditItem.map((i) => i.position), 0);

  const siblingBeforeEditItem = siblingsBeforeEditItem.find(
    (i) => i.position === maxPosition
  );

  if (!siblingBeforeEditItem) {
    console.warn("moveItemsRight: can't move right because no items before");
    return { items, editItemId: editItem.id };
  }

  const siblingBeforeEditItemLevel = siblingBeforeEditItem.level || 1;
  const siblingBeforeEditItemChildren = items.filter(
    (i) => i.parent === siblingBeforeEditItem.id
  );
  const siblingBeforeEditItemChildrenMaxPos = Math.max(...siblingBeforeEditItemChildren.map((i) => i.position), 0);
  const newEditItem = {
    ...editItem,
    parent: siblingBeforeEditItem.id,
    level: siblingBeforeEditItemLevel + 1,
    position: siblingBeforeEditItemChildrenMaxPos + 1,
  };

  let newItems = items.map((i) => {
    if (i.id === editItem.id) {
      return newEditItem;
    } else if (
      i.parent === siblingBeforeEditItem.parent &&
      i.position > siblingBeforeEditItem.position
    ) {
      return { ...i, position: i.position - 1 };
    }
    return i;
  });

  const children = items.filter((i) => i.parent === editItem.id);
  children.forEach((child) => {
    newItems = moveChildrenLeftOrRight({
      items: newItems,
      item: child,
      direction: "right",
    });
  });

  return { items: newItems, editItemId: editItem.id };
};

const swapUpwards = ({
  items,
  editItem,
}: {
  items: itemIF[];
  editItem: itemIF;
}) => {
  const itemsBefore1 = items.filter((i) => i.position < editItem.position);
  const itemBefore1 = itemsBefore1.reduce((prev, curr) =>
    prev.position > curr.position ? prev : curr
  );
  if (!itemBefore1) {
    console.warn("moveItemsUp: can't move up because no items before");
    return items;
  }

  const itemBeforeEditItemPosition = itemBefore1.position;
  return items.map((i) => {
    if (i.id === itemBefore1.id) {
      return {
        ...i,
        position: editItem.position,
      };
    }
    if (i.id === editItem.id) {
      return {
        ...i,
        position: itemBeforeEditItemPosition,
      };
    }
    return i;
  });
};

const swapDownwards = ({
  editItem,
  items,
}: {
  editItem: itemIF;
  items: itemIF[];
}) => {
  const itemsAfter = items.filter((i) => i.position > editItem.position);
  const itemAfter = itemsAfter.reduce((prev, curr) =>
    prev.position < curr.position ? prev : curr
  );

  if (!itemAfter) {
    console.warn("moveItemsDown: can't move down because no items after");
    return items;
  }

  const itemAfterEditItemPosition = itemAfter.position;
  return items.map((i) => {
    if (i.id === editItem.id) {
      return {
        ...i,
        position: itemAfterEditItemPosition,
      };
    }
    if (i.id === itemAfter.id) {
      return {
        ...i,
        position: editItem.position,
      };
    }
    return i;
  });
};

const combine = ({ items, items2 }: { items: itemIF[]; items2: itemIF[] }) => {
  return items.map((i) => {
    const ii = items2.find((i2) => i2.id === i.id);
    if (ii) {
      return ii;
    }
    return i;
  });
};

export const moveItemsUpDownLeftOrRight = ({
  itemType,
  items,
  editItem,
  direction,
  classId,
}: {
  itemType: itemTypes;
  items: itemIF[];
  editItem: itemIF;
  direction: "up" | "down" | "left" | "right";
  classId?: number;
}): { items: itemIF[]; editItemId: number } => { 
  // htmls, selectors and svgs are hierarchical, which can be moved up, down, left or right in the hierarchy, whereas css properties are a flat list which can be moved up or down in the list
  if (!editItem) {
    console.warn("moveItemsUpDownLeftOrRight: no editItem");
    return { items, editItemId: items[0].id };
  }
  if (!items[0]) {
    console.warn("moveItemsUpDownLeftOrRight: no items");
    return { items, editItemId: editItem.id };
  }

  if (itemType === itemTypes.CLASSES_CSS && !classId) {
    console.warn("moveItemsUpDownLeftOrRight: no classId");
    return { items, editItemId: editItem.id };
  }

  const items2 =
    itemType === itemTypes.CLASSES_CSS
      ? items.filter((i) => i.classId === classId)
      : items.filter((i) => i.parent === editItem.parent);

  const result = { items, editItemId: editItem.id };

  switch (direction) {
    case "up":
      const itemsAfterSwapUp = swapUpwards({ items: items2, editItem });
      result.items = combine({ items, items2: itemsAfterSwapUp });
      break;

    case "down":
      const itemsAfterSwapDown = swapDownwards({ editItem, items: items2 });
      result.items = combine({ items, items2: itemsAfterSwapDown });
      break;

    case "left":
      if (itemType === itemTypes.CLASSES_CSS) {
        console.warn(
          "moveItemsUpDownLeftOrRight: can't move left for css properties"
        );
        break;
      }
      result.items = moveItemsLeft({ items, editItem }).items;
      break;

    case "right":
      if (itemType === itemTypes.CLASSES_CSS) {
        console.warn(
          "moveItemsUpDownLeftOrRight: can't move right for css properties"
        );
        break;
      }
      result.items = moveItemsRight({ items, editItem }).items;
      break;

    default:
  }

  const sortedItems = sortTree2(result.items);
  return {
    items: sortedItems,
    editItemId: result.editItemId,
  };
};

