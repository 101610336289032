import { FC } from "react";
import "./TwButtons.css";

export const TwButtons: FC<{
  text: string;
  type: "primary" | "secondary" | "modal";
}> = ({ text, type }) => {
  switch (type) {
    case "modal":
      return (
        <button type="button" className="tawi-button-modal" title="close-btn">
          <svg
            width="24px"
            height="24px"
            viewBox="0 -960 960 960"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"></path>
          </svg>
        </button>
      );
    case "secondary":
      return (
        <button type="button" className="tawi-button secondary">
          {text}
        </button>
      );

    default:
      return (
        <button type="button" className="tawi-button primary">
          {text}
        </button>
      );
  }
};
