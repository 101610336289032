import { useState, useEffect } from "react";

export const UseNetworkChecker = () => {
  const [networkStatus, setNetworkStatus] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", (event) => {
      setNetworkStatus(false);
    });

    window.addEventListener("online", (event) => {
      setNetworkStatus(true);
    });

    return function cleanupListener() {
      window.removeEventListener("online", (event) => setNetworkStatus(true));
      window.removeEventListener("offline", (event) => setNetworkStatus(false));
    };
  }, []);

  return networkStatus;
};
