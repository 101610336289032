import { itemIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { getChildrenAndResetIdsParentsLevel } from "./addItems";
import { sortTree2 } from "../tree/sortTree";
import { getNewItem } from "./addItems";
import { TOldNewIds } from "./addSelectorsAndProperties";

/*
set new id, parent, level and position for new item/itemFrom
if new item is class, replace id in htmlsOSvgs assignedClassIds

find descendants of itemFrom
set new id, parent, level and position for descendants
*/

type TResult = {
  items: itemIF[];
  editItemId: number;
  oldNewIds?: TOldNewIds[];
};

export const addNewItemWithDescendants = ({
  itemType,
  itemsFrom,
  itemId,
  itemsTo,
  itemToId,
  isCopyBetweenSnippets
}: {
  itemType: itemTypes;
  itemsFrom: itemIF[];
  itemId: number;
  itemsTo: itemIF[];
  itemToId?: number;
  isCopyBetweenSnippets?: boolean;
}): TResult | undefined => {
  const itemFrom = itemsFrom.find((i) => i.id === itemId);
  if (!itemFrom) {
    console.warn("addNewItemWithDescendants: no itemFrom");
    return;
  }

  const newItemWithParentLevelPosition = getNewItem({
    itemType,
    itemFrom,
    itemsTo,
    itemToId,
  });

  const itemsToIncreaseSiblingPosition = itemsTo.map((i) => {
    if (
      i.parent === newItemWithParentLevelPosition.parent &&
      i.position >= newItemWithParentLevelPosition.position
    ) {
      return {
        ...i,
        position: i.position + 1,
      };
    }
    return i;
  });

  /*
  when copying between snippets, don't copy children of @media selector. 
  When copying selecors between snippets, the item to copy could be of a higher level than 2.
  In this case, the @media selector, if applicable, is copied as well with the to copy element on level 2
  */
  if (isCopyBetweenSnippets && itemFrom.name === "@media") {
    const newItemsToAll = [
      ...itemsToIncreaseSiblingPosition,
      newItemWithParentLevelPosition,
    ];
    const sorted = sortTree2(newItemsToAll);
    return {
      items: sorted,
      editItemId: newItemWithParentLevelPosition.id,
      oldNewIds: [
        { oldId: itemFrom.id, newId: newItemWithParentLevelPosition.id },
      ],
    };
  }

  const descendantsResult = getChildrenAndResetIdsParentsLevel({
    newItem: newItemWithParentLevelPosition,
    itemIdOld: itemFrom.id,
    itemsFrom,
  });

  const newItems = descendantsResult?.items as itemIF[];
  const newItemsToAll = [
    ...itemsToIncreaseSiblingPosition,
    newItemWithParentLevelPosition,
    ...newItems,
  ];
  const sorted = sortTree2(newItemsToAll);

  let data = {
    items: sorted,
    editItemId: newItemWithParentLevelPosition.id,
    oldNewIds: descendantsResult?.oldNewIds,
  } as TResult;
  return data;
};
