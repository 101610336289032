import { itemIF } from "../../../data/types/item";

export const getAncestorIds = ({
  items,
  item,
  ancestorIds,
}: {
  items: itemIF[];
  item: itemIF;
  ancestorIds: number[];
}) => {
  const parent = items.find(i => item.parent === i.id);
  if (parent) {
    ancestorIds.push(parent.id);
    getAncestorIds({ items, item: parent, ancestorIds });
  }
};

export const getAncestors = ({
  item,
  items,
  ancestors,
}: {
  item: itemIF;
  items: itemIF[];
  ancestors: itemIF[];
}) => {
  const parent = items.find((i) => i.id === item.parent);
  if (parent) {
    ancestors.push(parent);
    getAncestors({
      item: parent,
      items,
      ancestors,
    });
  }
};

export const getKidsIds = ({
  item,
  items,
  kidsIds,
}: {
  item: itemIF;
  items: itemIF[];
  kidsIds: number[];
}) => {
  const children = items.filter((i) => i.parent === item.id);

  children.forEach((c) => {
    kidsIds.push(c.id);
    getKidsIds({ item: c, items, kidsIds });
  });
};


export const getKids = ({
  item,
  items,
  kids,
}: {
  item: itemIF;
  items: itemIF[];
  kids: itemIF[];
}) => {
  const children = items.filter((i) => i.parent === item.id);
  children.forEach((c) => {
    kids.push(c);
    getKids({ item: c, items, kids });
  });
};
