import { metaIF, itemIF, EMetaFieldTypes } from "../../types/item";

export const SELECTORS_MEDIAQUERY: metaIF[] = [
  {
    name: "@media",
    level: "block",
    category: ["query"],
    availableOnlyLevel: 1,
    allowedChildren: ["class", "html"],
    fieldsOrder: ["type", "mediaFeature"],
    fields: [
      {
        name: "type",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "all",
          },
          {
            id: "print",
          },
          {
            id: "screen",
          },
          {
            id: "speech",
          },
        ],
        onOff: true,
      },
      {
        name: "mediaFeature",
        type: EMetaFieldTypes.addOrRemoveFieldGroup,
        onOff: true,
      },
    ],
    group: [
      {
        name: "mediaG",
        fields: ["type"],
        help:
          "### media query  \n" +
          "With the help of `media queries` you can define styles dependent on the output media of your page (`screen`, `print`, `speech`).  \n" +
          "For `only screen` and `only print` you can set conditions like `screen max-width: 600px`. For `only speech` (Screen reader) you can, for example, disable certain sections of the text.  \n" +
          "You can add classes and further selectors underneath the `@media` selector. The CSS properties of those selectors are applied only when the `@media` condition is met, for example when the screen has a width less than 600 pixel.  \n" +
          "See also [w3schools - CSS Media Queries - Examples](https://www.w3schools.com/css/css3_mediaqueries_ex.asp)",
      },
      {
        name: "mediaFeatureG",
        fields: ["mediaFeature"],
        display: ["media feature"],
      },
    ],

    default: {
      type: {
        value: "all",
      },
      mediaFeature: {
        value: [] as itemIF[],
      },
    },
  },

  {
    name: "mediaFeature",
    category: ["addOrRemoveFieldGroup"],
    fieldsOrder: ["and", "feature", "compare", "value", "orientation"],
    fields: [
      {
        name: "and",
        type: EMetaFieldTypes.select,
        smallInput: true,
        options: [
          {
            id: "and",
          },
          {
            id: "or",
          },
          {
            id: "not",
          },
        ],
        onOff: true,
      },

      {
        name: "feature",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "height",
          },
          {
            id: "width",
          },
          {
            id: "orientation",
          },
        ],
        dependentOn: {
          field: "type",
          values: ["all", "print", "screen"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "compare",
        type: EMetaFieldTypes.select,
        smallInput: true,
        options: [
          {
            id: "\u2264",
          },
          {
            id: "\u2265",
          },
        ],
        dependentOn: {
          field: "feature",
          values: ["height", "width"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
      },
      {
        name: "value",
        type: EMetaFieldTypes.number,
        units: [{ id: "px" }, { id: "em" }],
        warning:
          "### Relative units in media queries  \n" +
          "Be aware that the unit `em`, which is relative to the `font-size`, " +
          "in this case relates to the default `font-size` of the browser " +
          "and not to the `font-size` set for the `html` element.",
        dependentOn: {
          field: "feature",
          values: ["height", "width"],
          dontDisableDependentOnField: true,
        },
        prefix: ":",
        onOff: true,
      },
      {
        name: "orientation",
        type: EMetaFieldTypes.select,
        options: [
          {
            id: "landscape",
          },
          {
            id: "portrait",
          },
        ],
        dependentOn: {
          field: "feature",
          values: ["orientation"],
          dontDisableDependentOnField: true,
        },
        onOff: true,
        prefix: ":",
      },
    ],
    group: [
      {
        name: "conditionG",
        fields: ["and","feature", "compare", "value", "orientation"],
        display: ["connect", "feature", "compare", "value", "orientation"],
      },
    ],

    wrapperForGroup: [
      {
        name: "conditionWG",
        fields: ["and", "feature", "compare", "value", "orientation"],
        wrapperFn: ({ and, feature, compare, value, orientation }) => {
          const featureCss =
            feature.value === "orientation"
              ? feature.value
              : compare.value === "\u2264"
              ? `max-${feature.value}`
              : `min-${feature.value}`;
          const valueCss =
            feature.value === "orientation"
              ? orientation.value
              : `${value.value}${value.unit}`;
          return `${and.value} (${featureCss}:${valueCss})`;
        },
      },
    ],

    default: {
      and: {
        value: "and",
      },
      feature: {
        value: "width",
      },
      compare: {
        value: "\u2264",
      },
      value: {
        value: "600",
        unit: "px",
      },
      orientation: {
        value: "landscape",
      },
    },
  },
];
