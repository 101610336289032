import { itemIF } from "../../../data/types/item";

export const getTopSvg = ({
  editSvg,
  svgs,
}: {
  editSvg: itemIF;
  svgs: itemIF[];
}): itemIF => {
  if (editSvg?.parent === 0) {
    return editSvg;
  }
  const parent = svgs?.find((s) => s.id === editSvg?.parent);
  if (parent) {
    if (parent.parent === 0) {
      return parent;
    }
    return getTopSvg({ editSvg: parent, svgs: svgs });
  }
  return editSvg;
};