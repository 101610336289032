import { actionIF } from "../../../data/types/item";
import { snippetsIF, snippetIF } from "../../../data/types/snippets";
import { starterSnippt } from "../../../data/snippet/initalValues";
import { v4 as uuidv4 } from "uuid";
const clone = require("rfdc")();

export const getNewSnippet = () => {
  const newSnippet = {
    ...starterSnippt,
    name: "New snippet",
  };
  return newSnippet;
};

export const addOrCopy = ({
  action,
  snippet,
}: {
  action: actionIF;
  snippet: snippetsIF;
}): snippetIF | undefined => {
  const type = action.type;
  const isCopySnippet = type === "COPY_SNIPPET";

  const editSnippet = snippet.snippets.find(
    (s) => s.id === snippet.editSnippetId
  );

  const newSnip = action.snippet
    ? action.snippet
    : isCopySnippet
      ? editSnippet
      : getNewSnippet();

  if (!newSnip) {
    console.warn("No newSnippet found");
    return;
  }

  const newSnippet = clone(newSnip) as snippetIF;
  newSnippet.id = uuidv4();

  if (isCopySnippet) {
    newSnippet.name = `${newSnippet.name} copy`;
    delete newSnippet.createdAt;
    delete newSnippet.updatedAt;
  }

  return newSnippet;
};
