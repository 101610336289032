import { itemIF } from "../types/item";

export type TFontFamilies = {
  editFontId: number;
};

/*
there is one stylepack for one snippet, when a snippet is created then a new default stylepack is also created
in User Stylepacks (when you are signed in), you can edit the default stylepack or overwrite it with a stylepack from another snippet
*/

export const defaultStylepack2 = {
  id: 1,
  level: 1,
  position: 1,
  parent: 0,
  name: "stylepackItem",
  value: {
    name: { value: "default" },
    stylepackColors: {
      value: [
        {
          id: 1,
          name: "stylepackColors",
          position: 1,
          value: {
            color: { value: { r: 60, g: 188, b: 195, a: 1 } },
            name: { value: "blue" },
          },
        },
        {
          id: 2,
          name: "stylepackColors",
          position: 2,
          value: {
            color: { value: { r: 228, g: 12, b: 43, a: 1 } },
            name: { value: "red" },
          },
        },
        {
          id: 3,
          name: "stylepackColors",
          position: 3,
          value: {
            color: { value: { r: 235, g: 166, b: 63, a: 1 } },
            name: { value: "yellow" },
          },
        },
        {
          id: 4,
          name: "stylepackColors",
          position: 4,
          value: {
            color: { value: { r: 67, g: 137, b: 69, a: 1 } },
            name: { value: "green" },
          },
        },
        {
          id: 5,
          name: "stylepackColors",
          position: 5,
          value: {
            color: { value: { r: 252, g: 251, b: 247, a: 1 } },
            name: { value: "white" },
          },
        },
        {
          id: 6,
          name: "stylepackColors",
          position: 6,
          value: {
            color: { value: { r: 29, g: 29, b: 44, a: 1 } },
            name: { value: "black" },
          },
        },
        {
          id: 7,
          name: "stylepackColors",
          position: 7,
          value: {
            color: { value: { r: 169, g: 169, b: 198, a: 1 } },
            name: { value: "black-l2" },
          },
        },
        {
          id: 8,
          name: "stylepackColors",
          position: 8,
          value: {
            color: { value: { r: 240, g: 240, b: 245, a: 1 } },
            name: { value: "black-l4" },
          },
        },
      ],
    },
    stylepackFonts: {
      value: [
        {
          id: 1,
          name: "stylepackFonts",
          position: 1,
          value: {
            font: { value: "Alegreya" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Alegreya" },
            newCss: { value: "'Alegreya', serif" },
          },
        },
        {
          id: 2,
          name: "stylepackFonts",
          position: 2,
          value: {
            font: { value: "Amatic SC" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Amatic+SC" },
            newCss: { value: "'Amatic SC', cursive" },
          },
        },
        {
          id: 3,
          name: "stylepackFonts",
          position: 3,
          value: {
            font: { value: "Cinzel" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Cinzel" },
            newCss: { value: "'Cinzel', serif" },
          },
        },
        {
          id: 4,
          name: "stylepackFonts",
          position: 4,
          value: {
            font: { value: "Flavors" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Flavors" },
            newCss: { value: "'Flavors', cursive" },
          },
        },
        {
          id: 5,
          name: "stylepackFonts",
          position: 5,
          value: {
            font: { value: "Josefin Slab" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Josefin+Slab" },
            newCss: { value: "'Josefin Slab', serif" },
          },
        },
        {
          id: 6,
          name: "stylepackFonts",
          position: 6,
          value: {
            font: { value: "Lato" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Lato" },
            newCss: { value: "'Lato', sans-serif" },
          },
        },
        {
          id: 7,
          name: "stylepackFonts",
          position: 7,
          value: {
            font: { value: "Lora Regular Italic" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Lora:ital@1" },
            newCss: { value: "'Lora', serif" },
          },
        },
        {
          id: 8,
          name: "stylepackFonts",
          position: 8,
          value: {
            font: { value: "Merriweather Sans" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Merriweather+Sans" },
            newCss: { value: "'Merriweather Sans', sans-serif" },
          },
        },
        {
          id: 9,
          name: "stylepackFonts",
          position: 9,
          value: {
            font: { value: "Montserrat" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Montserrat" },
            newCss: { value: "'Montserrat', sans-serif" },
          },
        },
        {
          id: 10,
          name: "stylepackFonts",
          position: 10,
          value: {
            font: { value: "Open Sans" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Open+Sans" },
            newCss: { value: "'Open Sans', sans-serif" },
          },
        },
        {
          id: 12,
          name: "stylepackFonts",
          position: 12,
          value: {
            font: { value: "Playfair Display" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Playfair+Display" },
            newCss: { value: "'Playfair Display', serif" },
          },
        },

        {
          id: 13,
          name: "stylepackFonts",
          position: 13,
          value: {
            font: { value: "Noto Serif" },
            type: { value: "googlefont" },
            importFamilyName: {
              value: "Noto+Serif:ital,wght@0,100..900;1,100..900",
            },
            newCss: { value: '"Noto Serif", serif' },
          },
        },

        {
          id: 20,
          name: "stylepackFonts",
          position: 20,
          value: {
            font: { value: "Serif (generic)" },
            type: { value: "generic" },
            newCss: { value: "serif" },
          },
        },
        {
          id: 21,
          name: "stylepackFonts",
          position: 20,
          value: {
            font: { value: "Sans-serif (generic)" },
            type: { value: "generic" },
            newCss: { value: "sans-serif" },
          },
        },
        {
          id: 22,
          name: "stylepackFonts",
          position: 21,
          value: {
            font: { value: "Monospace (generic)" },
            type: { value: "generic" },
            newCss: { value: "monospace" },
          },
        },
        {
          id: 23,
          name: "stylepackFonts",
          position: 22,
          value: {
            font: { value: "Cursive (generic)" },
            type: { value: "generic" },
            newCss: { value: "cursive" },
          },
        },
        {
          id: 24,
          name: "stylepackFonts",
          position: 23,
          value: {
            font: { value: "Fantasy (generic)" },
            type: { value: "generic" },
            newCss: { value: "fantasy" },
          },
        },
        {
          id: 100,
          name: "stylepackFonts",
          position: 100,
          value: {
            font: { value: "Roboto" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Roboto" },
            newCss: { value: "'Roboto', sans-serif" },
          },
        },
        {
          id: 101,
          name: "stylepackFonts",
          position: 101,
          value: {
            font: { value: "Roboto Mono" },
            type: { value: "googlefont" },
            importFamilyName: { value: "Roboto+Mono" },
            newCss: { value: "'Roboto Mono', monospace" },
          },
        },
      ],
    },
  },
} as itemIF;