import { FC } from "react";
import { useStateContext } from "../../data/main/state";
import { itemIF } from "../../data/types/item";
import { ReactComponent as ArrowDown } from "../../assets/imgs/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/imgs/arrow-up.svg";
import { ReactComponent as Copy } from "../../assets/imgs/copy-all.svg";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";
import { itemTypes } from "../../lib/meta/getMetas";
import {
  itemInTreeDeleteCss,
  addItem,
  moveItemDownCss,
  moveItemUpCss,
} from "../../lib/reducer/items/actions";
import { SectionTitle } from "../modules/SectionTitle";
const clone = require("rfdc")();

const isUpBtnDisabledFlat = ({
  items,
  editItem,
}: {
  items: itemIF[];
  editItem?: itemIF | undefined;
}): boolean => {
  if (!editItem) {
    return true;
  }
  if (!items || items.length < 2) {
    return true;
  }
  if (items.find((i) => i.position < editItem.position)) {
    return false;
  }
  return true;
};

export const isDownBtnDisabledFlat = ({
  items,
  editItem,
}: {
  items: itemIF[];
  editItem?: itemIF | undefined;
}): boolean => {
  if (!editItem) {
    return true;
  }
  if (!items || items.length < 2) {
    return true;
  }
  if (items.find((i) => i.position > editItem.position)) {
    return false;
  }
  return true;
};

export const MoveOrDeleteCssPropsOfSelector: FC<{
  items: itemIF[];
  editItem?: itemIF;
  editSelectorId?: number;
  editPropertyId?: number;
}> = ({ items, editItem, editSelectorId, editPropertyId }) => {
  const { dispatch } = useStateContext();
  const itemType = itemTypes.CLASSES_CSS;

  const isDisabledUp = isUpBtnDisabledFlat({ items, editItem });
  const isDisabledDown = isDownBtnDisabledFlat({ items, editItem });

  return (
    <>
      <div className="mb-1r">
        <SectionTitle label="Move, clone or delete" />
      </div>
      <div className="btn-row">
        <button
          type="button"
          className={`button blue-outline icon${isDisabledUp ? " disabled" : ""}`}
          onClick={() => {
            if (!editSelectorId) {
              return;
            }

            dispatch(moveItemUpCss(editSelectorId));
          }}
        >
          <span className="sr-only">Move up</span>
          <ArrowUp />
        </button>
        <button
          type="button"
          className={`button blue-outline icon${isDisabledDown ? " disabled" : ""}`}
          onClick={() => {
            if (!editSelectorId) {
              return;
            }
            dispatch(moveItemDownCss(editSelectorId));
          }}
        >
          <span className="sr-only">Move down</span>
          <ArrowDown />
        </button>

        <button
          type="button"
          className={`button blue icon${!editItem ? " disabled" : ""}`}
          onClick={() => {
            if (!editItem) {
              return;
            }
            const newItem1 = clone(editItem);
            dispatch(
              addItem({
                itemType,
                item: newItem1,
                addAfterItemId: editPropertyId,
              })
            );
          }}
        >
          <span className="sr-only">Copy</span>
          <Copy />
        </button>

        <button
          type="button"
          className={`button orange icon${!editItem ? " disabled" : ""}`}
          onClick={() => {
            dispatch(itemInTreeDeleteCss());
          }}
        >
          <span className="sr-only">Delete</span>
          <Delete />
        </button>

      </div>
    </>
  );
};
