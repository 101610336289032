import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PAGES } from "../../data/settings/constants";
import { useStateContext } from "../../data/main/state";

const mapPageAndRoute = [
  {
    page: PAGES.HOME,
    routes: ["/", "/home"],
  },
  {
    page: PAGES.CODE,
    routes: ["/code"],
  },
  {
    page: PAGES.GALLERY,
    routes: ["/gallery"],
  },
  {
    page: PAGES.SIGNIN,
    routes: ["/signin"],
  },
  {
    page: PAGES.USER,
    routes: ["/user"],
  },
  {
    page: PAGES.TERMS,
    routes: ["/terms"],
  },
  {
    page: PAGES.PRIVACY,
    routes: ["/privacy"],
  },
  {
    page: PAGES.NOTFOUND,
    routes: ["/notfound"],
  },
];

export const Navigator = () => {
  const {
    state: {
      settings: { selectedPage },
    },
  } = useStateContext();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pr = mapPageAndRoute.find((pr) => pr.page === selectedPage);
    if (pr?.routes?.[0] && !pr.routes.includes(location.pathname)) {
      navigate(pr?.routes[0]);
    }
  }, [location.pathname, navigate, selectedPage]);

  return null;
};
