import { itemIF } from "../../../data/types/item";
import { snippetIF } from "../../../data/types/snippets";
import { ianIF } from "../../../lib/items/SortAndFind";

export const getSortedAndFoundItems = ({
  items,
  findItem,
  sort,
  getName,
}: {
  items: itemIF[];
  findItem: string;
  sort: TSortBy;
  getName?: (item: itemIF) => string;
}) => {
  const itemsFiltered = items.filter((s) => {
    const nameLower = getName
      ? getName(s)?.toLowerCase()
      : s.name?.toLowerCase();
    if (!nameLower) {
      console.warn("field to search in can't be found in item");
      return false;
    }
    const searchExpLower = findItem.toLowerCase();
    if (nameLower?.includes(searchExpLower)) {
      return true;
    }
    return false;
  });
  const itemsCopy = itemsFiltered.map((s) => ({ ...s })) as itemIF[];
  switch (sort) {
    case "name A-Z":
      itemsCopy.sort((a, b) => {
        const aStr = getName ? getName(a) : a.name;
        const bStr = getName ? getName(b) : b.name;
        return aStr.localeCompare(bStr);
      });
      break;
    case "name Z-A":
      itemsCopy.sort((a, b) => {
        const aStr = getName ? getName(a) : a.name;
        const bStr = getName ? getName(b) : b.name;
        return aStr.localeCompare(bStr) * -1;
      });
      break;
  }
  return itemsCopy;
};

export type TSortBy =
  | "name A-Z"
  | "name Z-A"
  | "updated New-Old"
  | "updated Old-New";

export const getSortedAndFoundSnippets = ({
  snippets,
  findItem,
  sort,
}: {
  snippets: snippetIF[];
  findItem: string;
  sort: TSortBy;
}) => {
  const snippetsFiltered = snippets.filter((s) => {
    const sname = s?.name as string | undefined;
    if (!sname) {
      return false;
    }
    const nameLower = sname.toLowerCase();
    const searchExpLower = findItem.toLowerCase();
    if (nameLower?.includes(searchExpLower)) {
      return true;
    }
    return false;
  });
  const snippetsCopy = snippetsFiltered.map((s) => ({ ...s }));
  switch (sort) {
    case "name A-Z":
      snippetsCopy.sort((a, b) => ("" + a.name).localeCompare(b.name));
      break;
    case "name Z-A":
      snippetsCopy.sort((a, b) => ("" + a.name).localeCompare(b.name) * -1);
      break;
    case "updated New-Old":
      snippetsCopy.sort(
        (a, b) => ("" + a.updatedAt).localeCompare(b.updatedAt!) * -1
      );
      break;
    case "updated Old-New":
      snippetsCopy.sort((a, b) =>
        ("" + a.updatedAt).localeCompare(b.updatedAt!)
      );
      break;
  }
  return snippetsCopy;
};

export const getSortedAndFindIams2 = ({
  idsandnames,
  findItem,
  sort,
}: {
  idsandnames: ianIF[];
  findItem: string;
  sort: TSortBy;
}) => {
  const iansFiltered = idsandnames?.filter((ian) => {
    const nameLower = ian?.name?.toLowerCase();
    const searchExpLower = findItem.toLowerCase();
    if (nameLower?.includes(searchExpLower)) {
      return true;
    }
    return false;
  });

  const iansCopy = iansFiltered.map((ian) => ({ ...ian }));
  switch (sort) {
    case "name A-Z":
      iansCopy.sort((a, b) => ("" + a.name).localeCompare(b.name));
      break;
    case "name Z-A":
      iansCopy.sort((a, b) => ("" + a.name).localeCompare(b.name) * -1);
      break;
    case "updated New-Old":
      iansCopy.sort(
        (a, b) => ("" + a.timeCreated).localeCompare(b.timeCreated!) * -1
      );
      break;
    case "updated Old-New":
      iansCopy.sort((a, b) =>
        ("" + a.timeCreated).localeCompare(b.timeCreated!)
      );
      break;
  }

  return iansCopy;
};
