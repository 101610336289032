import { itemIF, metaIF, wrapperForGroupIF } from "../../data/types/item";

// get fieldsorder of meta and metaAll, remove fields which are used in wrapperFn
export const getFieldsOrder = ({
  item,
  meta,
  disabledFields,
}: {
  item: itemIF;
  meta?: metaIF;
  disabledFields: string[];
}) => {
  const fieldsOrder = meta?.fieldsOrder ? [...meta.fieldsOrder] : ["main"];  
  const fieldsOrderAll = fieldsOrder.filter(
    (fieldName) => item.value && item.value[fieldName]
  );

  // Add fields in item.value which are not in fieldsOrder
  if (item.value) {
    Object.keys(item.value).forEach((fieldName) => {
      if (!fieldsOrder.includes(fieldName)) {
        const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);
        if (fieldMeta) {
          fieldsOrder.push(fieldName);
        }
      }
    });
  }

  const groupsWithWrapperFn: wrapperForGroupIF[] = [];
  if (meta?.group) {
    meta.group.forEach((g) => {
      if (g.wrapperFn) {
        groupsWithWrapperFn.push(g);
      }
    });
  }
  if (meta?.wrapperForGroup) {
    meta.wrapperForGroup.forEach((g) => {
      if (g.wrapperFn) {
        groupsWithWrapperFn.push(g);
      }
    });
  }

  groupsWithWrapperFn.forEach((g) => {
    let count = 1;
    g.fields.forEach((field) => {
      const index = fieldsOrderAll.findIndex((f) => f === field);
      if (index !== -1) {
        // if fields are disabled skip group
        if (
          count === 1 &&
          g.wrapperFn &&
          item.value &&
          !disabledFields.includes(field)
        ) {
          fieldsOrderAll.splice(index, 1, g.name);
          count += 1;
        } else {
          fieldsOrderAll.splice(index, 1);
        }
      }
    });
  });

  return fieldsOrderAll.filter(
    (fieldName) => !disabledFields.includes(fieldName)
  );
};
