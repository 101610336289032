import { FC } from "react";
import { ReactComponent as Add } from "../../assets/imgs/add.svg";
import { useStateContext } from "../../data/main/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { addFieldGroup } from "../../lib/reducer/items/actions";

export const AddOrRemoveFieldGroupAddButton: FC<{
  itemType: itemTypes;
  firstFieldInGroupName: string;
  isGroupDisabled?: boolean;
  editItemId: number;
  editSnippetIdOther?: string;
}> = ({ itemType, isGroupDisabled, firstFieldInGroupName, editItemId, editSnippetIdOther }) => {
  const { dispatch } = useStateContext();

  return (
    <div className="flex align-center">
      <button
        type="button"
        className={`button ${isGroupDisabled ? "disabled" : "blue-outline"}`}
        onClick={() => {
          if (!isGroupDisabled) {
            dispatch(
              addFieldGroup({
                fieldName: firstFieldInGroupName,
                itemType,
                editItemId,
                editSnippetId: editSnippetIdOther
              })
            );
          }
        }}
      >
        <span className="sr-only">Add</span>
        <Add />
      </button>
    </div>
  );
};
