import { FC } from "react";
import { itemIF } from "../../data/types/item";
import { useStateContext } from "../../data/main/state";

import { ReactComponent as ArrowLeft } from "../../assets/imgs/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/imgs/arrow-right.svg";
import { ReactComponent as ArrowDown } from "../../assets/imgs/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/imgs/arrow-up.svg";
import { ReactComponent as Copy } from "../../assets/imgs/copy-all.svg";
import { ReactComponent as Delete } from "../../assets/imgs/delete.svg";

import { itemTypes } from "../../lib/meta/getMetas";
import {
  cloneItemAndChildren,
  itemInTreeDelete,
  moveItemDown,
  moveItemLeft,
  moveItemRightAction,
  moveItemUp,
} from "../../lib/reducer/items/actions";
import { SectionTitle } from "../modules/SectionTitle";
import {
  isRightBtnDisabled,
  isUpBtnDisabled,
  isDownBtnDisabled,
} from "../../utils/html/MoveOrDelete";
import {
  OpenCloseWithPositioning,
} from "../modules/TailwindModal";
import {
  useDivPosition,
} from "../modules/GetPositionWrapper";


const ConfirmHtmlDelete: FC<{
  editItem?: itemIF;
  setIsModalOpen?: (isOpen: boolean) => void;
}> = ({ editItem, setIsModalOpen }) => {
  const { dispatch } = useStateContext();

  const itemType = itemTypes.BLOCK_HTML;

  return (
    <div className="p-1r pl-15r">
      <p>Do you really want to delete the HTML and its descendants?</p>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className="button orange"
          onClick={() => {
            dispatch(
              itemInTreeDelete({
                itemType,
              })
            );
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Delete
        </button>
        <button
          type="button"
          className="button gray-outline"
          onClick={() => {
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export const MoveOrDeleteButtonsHtml = () => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },

    dispatch,
  } = useStateContext();

  const editSnippet = snippets.find((s) => s.id === editSnippetId);

  const items = editSnippet?.data?.htmls || [];
  const editItem = items?.find((h) => h.id === editSnippet?.data?.editHtmlId);

  const itemType = itemTypes.BLOCK_HTML;

  const isDisabledDown = isDownBtnDisabled({ itemType, items, editItem });
  const isDisabledLeft =
    !editItem ||
    (editItem.level &&
      (editItem.level === 1 ||
        (editItem.name === "text" && editItem.level === 2)))
      ? true
      : false;
  const isDisabledRight = editItem
    ? isRightBtnDisabled({ itemType, items, editItem })
    : true;
  const isDisabledUp = editItem
    ? isUpBtnDisabled({ itemType, items, editItem })
    : true;

  const isDisabledDelete = items.length === 1;

  const { divRef, divPos } = useDivPosition();

  return (
    <div>
      <div className="mb-1r">
        <SectionTitle label="Move, clone or delete" />
      </div>
      <div className="btn-row mb-1r form-elements-wrapper" ref={divRef}>
        <button
          type="button"
          className={`button blue-outline icon${isDisabledLeft ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabledLeft) return;
            dispatch(moveItemLeft({ itemType }));
          }}
        >
          <span className="sr-only">Move left</span>
          <ArrowLeft />
        </button>
        <button
          type="button"
          className={`button blue-outline icon${isDisabledRight ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabledRight) return;

            dispatch(moveItemRightAction({ itemType }));
          }}
        >
          <span className="sr-only">Move right</span>
          <ArrowRight />
        </button>
        <button
          type="button"
          className={`button blue-outline icon${isDisabledUp ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabledUp) return;

            dispatch(moveItemUp({ itemType }));
          }}
        >
          <span className="sr-only">Move up</span>
          <ArrowUp />
        </button>
        <button
          type="button"
          className={`button blue-outline icon${isDisabledDown ? " disabled" : ""}`}
          onClick={() => {
            if (isDisabledDown) return;

            dispatch(moveItemDown({ itemType }));
          }}
        >
          <span className="sr-only">Move down</span>
          <ArrowDown />
        </button>

        <button
          type="button"
          className={`button blue icon${!editItem ? " disabled" : ""}`}
          onClick={() => {
            if (!editItem) return;

            dispatch(cloneItemAndChildren({ itemType }));
          }}
        >
          <span className="sr-only">Copy</span>
          <Copy />
        </button>

        <OpenCloseWithPositioning
          title="Delete HTML"
          component={<ConfirmHtmlDelete editItem={editItem} />}
          disabled={isDisabledDelete}
          parentPosition={divPos}
        >
          <button
            type="button"
            className={`button orange icon ${isDisabledDelete ? " disabled" : ""}`}
            onClick={() => {}}
          >
            <span className="sr-only">Delete HTML</span>
            <Delete />
          </button>
        </OpenCloseWithPositioning>
      </div>
    </div>
  );
};
