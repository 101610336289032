import { FC } from "react";
import { IOptions } from "../../data/types/item";
import { Tooltip } from "../atoms/tooltip";

type TEditFieldSelect = {
  options: IOptions[];
  fieldValue?: string;
  tooltipText?: string;
  isFieldDisabled?: boolean;
  setValue?: (value: string) => void;
};

export const EditFieldSelect: FC<TEditFieldSelect> = ({
  options,
  fieldValue,
  tooltipText,
  isFieldDisabled,
  setValue,
}) => {
  return (
    <>
      <select
        title="select"
        value={fieldValue}
        disabled={isFieldDisabled}
        onChange={(e) => {
          const optionId = e.target.value;
          setValue && setValue(optionId);
        }}
      >
        {options.map((option, i) => (
          <option key={i} value={option.id}>
            {option.id}
          </option>
        ))}
      </select>
      {tooltipText && <Tooltip tooltipText={tooltipText} />}
    </>
  );
};
