import React, { FC, useEffect, useState, useRef } from "react";
import { snippetIF } from "../../../data/types/snippets";
import { ianIF } from "../../../lib/items/SortAndFind";
import {
  TSortBy,
  getSortedAndFindIams2,
  getSortedAndFoundSnippets,
} from "./helpers";
import { ReactComponent as Search } from "../../../assets/imgs/search.svg";
import { ReactComponent as SortArrowDown } from "../../../assets/imgs/SortArrowDown.svg";
import { SectionTitle } from "../SectionTitle";
import { useOutsideAlerter } from "../../atoms/detectClickOutside";
import { SearchInputAndSortSelect } from "./SortAndFindItems";

type SelectSnippetButtonsProps = {
  snippets?: snippetIF[];
  selectedSnippetId: string;
  setSelectedSnippetId: (snippetId: string) => void;
  color?:"blue-outline" | "blue" | "gray-outline";
};

export const SelectSnippetButtons: FC<SelectSnippetButtonsProps> = ({
  snippets,
  selectedSnippetId,
  setSelectedSnippetId,
  color = "blue-outline",
}) => {

  useEffect(() => {
    const filteredSnippetEditSnippet = snippets?.find((s) => s.id === selectedSnippetId);
    if (!filteredSnippetEditSnippet && snippets && snippets[0]) {
       setSelectedSnippetId(snippets[0].id);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippets])

  if (!snippets || snippets.length === 0) {
    return (
      <div className="btn-row mt-1r">
        <button className="button disabled" disabled>
          NO SNIPPET
        </button>
      </div>
    );
  }

  return (
    <div className="btn-row mt-1r">
      {snippets.map((s, i) => {

        return (
          <button
            key={s.id}
            type="button"
            className={`button ${s.id === selectedSnippetId ? "blue" : color}`}
            onClick={() => {
              if (s.id !== selectedSnippetId) {
                setSelectedSnippetId(s.id);
              }
            }}
          >
            {s.name}
          </button>
        );
      })}
    </div>
  );
};

export const optionsNameAndDate = [
  "name A-Z",
  "name Z-A",
  "updated New-Old",
  "updated Old-New",
] as TSortBy[];

type SortAndFindProps = {
  allSnippets: snippetIF[];
  title?: string;
  children: React.ReactElement;
};
export const SortAndFindSnippets: FC<SortAndFindProps> = ({
  allSnippets,
  title,
  children,
}) => {
  const [findItem, setFindItem] = useState("");
  const [sort, setSort] = useState("name A-Z" as TSortBy);
  const [sfSnippets, setSfSnippets] = useState(allSnippets);

  useEffect(() => {
    const newSnippets = getSortedAndFoundSnippets({
      snippets: allSnippets,
      findItem,
      sort,
    });
    setSfSnippets(newSnippets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, findItem, allSnippets]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement<{ snippets?: snippetIF[] }>(child)) {
      return React.cloneElement(child, { snippets: sfSnippets });
    }
    return child;
  });

  return (
    <div className="form-elements-wrapper">
      {title && (
        <div className="mb-1r">
          <SectionTitle label={title!} />
        </div>
      )}
      <SearchInputAndSortSelect
        options={optionsNameAndDate}
        setFindItem={setFindItem}
        sort={sort}
        setSort={setSort}
      />
      {childrenWithProps}
    </div>
  );
};

type Props = {
  children: React.ReactElement;
  idsandnames?: ianIF[];
  options: TSortBy[];
  label?: string;
  header?: string;
  headerHelp?: string;
};

export const SortAndFindClone: FC<Props> = ({
  children,
  idsandnames,
  options,
  label,
  header,
  headerHelp,
}) => {
  const [findItem, setFindItem] = useState("");
  const [idsAndNamesFiltered, setIdsAndNamesFiltered] = useState<
    ianIF[] | undefined
  >(undefined);

  const [sort, setSort] = useState("name A-Z" as TSortBy);
  const [sortDropdownIsOpen, setSortDropdownIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter({ ref: wrapperRef, setOpen: setSortDropdownIsOpen });

  useEffect(() => {
    if (!idsandnames) {
      return;
    }
    const newIdsAndNames = getSortedAndFindIams2({
      idsandnames,
      findItem,
      sort,
    });
    setIdsAndNamesFiltered(newIdsAndNames);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, findItem, idsandnames]);

  return (
    <>
      <div className="sort-dropdown-main">
        <div className="sort-search-box">
          <div></div>
          <div>
            <div className="search-input-box">
              <div className="search-input-inner">
                <Search />
              </div>
              <input
                type="text"
                placeholder="Enter search expression"
                className="search-input"
                onChange={(event) => {
                  const containsNotAllowedCharacters =
                    /[!@#$%^&*(),.?":{}|<>]/.test(event.target.value);
                  if (!containsNotAllowedCharacters) {
                    setFindItem(event.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="sort-item">
            <button
              type="button"
              className="sort-button"
              onClick={() => {
                setSortDropdownIsOpen(!sortDropdownIsOpen);
              }}
            >
              Sort by
              <SortArrowDown />
            </button>
            <div
              className={`sort-dropdown-menu${sortDropdownIsOpen ? " sort-dropdown-on" : ""} `}
              ref={wrapperRef}
            >
              <div className="sort-dropdown-inner">
                {options.map((option) => {
                  return (
                    <a
                      key={option}
                      href={`"#${option}`}
                      className="sort-dropdown-link"
                      onClick={(e) => {
                        e.preventDefault();
                        setSort(option);
                        setSortDropdownIsOpen(false);
                      }}
                    >
                      {option}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sort-and-find-background">
        {header && <SectionTitle label={header} help={headerHelp} />}

        <div className="sort-and-find">
          <div>
            {React.cloneElement(children, {
              idsandnames: idsAndNamesFiltered,
            })}
          </div>
        </div>
      </div>
    </>
  );
};
