import { TObject, itemIF, metaFieldIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import {
  getDependendOnDisabledFields,
  disableDependentOnFields,
} from "../items/getDisabledFields";
import { addSnippetIdToAttributeValues } from "../items/getIds";
import {
  getFieldValueStr,
  getAddOrRemoveFieldGroup,
} from "../../utils/render/get-field-value";
import { getFieldsOrder } from "../../utils/render/get-fields-order";
import {
  getGroupValue,
  getFieldName,
  getDependentOnOptionDisplayName,
  getGroupAttributeName,
} from "../reducer/items/getItemData";
import { getMetaWithAllFields } from "../meta/getMetaWithAllFields";
const clone = require("rfdc")();

const canBeHacks = (item: itemIF) => {
  const copy = clone(item) as itemIF;

  if (!copy.value) {
    return;
  }

  if (copy.name === "width" || copy.name === "height") {
    if (
      copy.value &&
      copy.value.main &&
      copy.value.calculate &&
      copy.value.width
    ) {
      copy.value.mainSelect = copy.value.main;
      copy.value.main = copy.value.width;
      copy.value.mainCalculate = copy.value.calculate;
    }
    if (copy.value.calculate) {
      delete copy.value.calculate;
    }
    if (copy.value.width) {
      delete copy.value.width;
    }
  }

  if (copy.name === "max-width" || copy.name === "max-height") {
    // meta data was changed, main new is a select, not a number, to select max-width or max height none or value
    if (copy.value && !copy.value.value) {
      copy.value.value = { ...copy.value.main };
      copy.value.main = { value: "value" };
    }
  }
};

const isKeyframeId = ({
  fieldMeta,
  attr,
  item,
}: {
  fieldMeta: metaFieldIF | undefined;
  attr: TObject;
  item: itemIF;
}) => {
  if (fieldMeta?.name === "keyframeId") {
    attr["keyframeId"] =
      !item.value?.keyframeId?.value || item.value.keyframeId.value === ""
        ? "none"
        : item.value.keyframeId.value;
    return true;
  }
};

/*
called in 
RenderElementAndChildren
  getItemAttributes
    getItemValuesAsObj

SelectorTree
  getSelectorName
    getItemValuesAsObj

SelectorCodePreview
  getSelectorNameArray
    getSelectorName
      getItemValuesAsObj

getCssForMedia
  getSelectorNameArray
getCssForClassOrHtml
  getSelectorNameArray
  getGroupedAndSortMediaSelectors
  getSelectorNameArray

*/

export const getItemValuesAsObj = ({
  itemType,
  item,
  items,
  attr,
  stylepack,
}: {
  itemType: itemTypes;
  item?: itemIF;
  items?: itemIF[];
  attr: TObject;
  stylepack?: itemIF;
}) => {
  if (!item || !item.value) {
    return;
  }

  const meta = getMetaWithAllFields({
    itemType,
    itemName: item.name,
  });

  if (!meta || !meta.fields) {
    return;
  }

  const assignedCSSVariableName = item.value?.assignedCssVariableName?.value;
  if (assignedCSSVariableName) {
    attr["assignedCssVariableName"] = `var(--${assignedCSSVariableName})`;
    return;
  }

  canBeHacks(item);

  const disabledFields = [] as string[];
  getDependendOnDisabledFields({
    meta,
    item,
    items,
    disabledFields,
  });

  disableDependentOnFields({ itemType, meta, item, disabledFields });

  const fieldsOrder = getFieldsOrder({ item, meta, disabledFields });

  const itemSnippetIdAdded = addSnippetIdToAttributeValues({
    item,
    meta,
  });

  fieldsOrder.forEach((fieldName) => {
    const fieldMeta = meta?.fields?.find((f) => f?.name === fieldName);

    if (
      isKeyframeId({
        fieldMeta,
        attr,
        item: itemSnippetIdAdded,
      })
    ) {
      return;
    }

    if (fieldMeta?.type === "addOrRemoveFieldGroup") {
      getAddOrRemoveFieldGroup({
        itemType,
        fieldMeta,
        item: itemSnippetIdAdded,
        items,
        attr,
      });
      return;
    }

    const attrTmp: TObject = {};

    if (fieldMeta) {
      if (
        !(itemSnippetIdAdded.value && itemSnippetIdAdded.value[fieldMeta.name])
      ) {
        return;
      }

      const valueStr = getFieldValueStr({
        fieldMeta,
        item,
        items,
      });

      if (valueStr || valueStr === "") {
        attrTmp[fieldMeta.name] = valueStr;
      }

    } else {
      getGroupValue({
        fieldName,
        meta,
        item: itemSnippetIdAdded,
        items,
        attr: attrTmp,
      });
    }


    // if !fieldMeta -> the value is computed from a wrapperFn in groups or in wrapperForGroup, check if there is a javascript name on the group and use this one as attribute name instead of fieldName
    const dependentOnOptionDisplayName = getDependentOnOptionDisplayName({
      fieldMeta,
      meta,
    });
    const attrName = fieldMeta
      ? dependentOnOptionDisplayName
        ? dependentOnOptionDisplayName
        : getFieldName({ fieldMeta, item })
      : getGroupAttributeName({ groupName: fieldName, meta });

    if (item.name === "font-family" && stylepack) {
      const stylepackFonts = stylepack?.value?.stylepackFonts.value as itemIF[];
      const ffName = item.value?.main?.value;
      const itemFont = stylepackFonts?.find(
        (f) => f.value?.font?.value === ffName
      );

      const propertyValue = itemFont?.value?.newCss?.value;
      if (propertyValue) {
        attrTmp[fieldName] = propertyValue;
      }
    }

    if (attrName === "!important" && attrTmp.important !== "") {
      attr.important = "!important";
      return;
    }
    if (attrTmp[fieldName]) {
      attr[attrName] = attrTmp[fieldName];
    }
  });
};
