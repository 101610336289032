import { FC } from "react";
import "./tooltip.css";

type TDirection =
  | "bottom-center"
  | "bottom-left"
  | "bottom-right"
  | "left-center"
  | "right-center"
  | "top-center"
  | "top-left"
  | "top-right";

export const Tooltip: FC<{
  tooltipText: string;
  direction?: TDirection;
}> = ({ tooltipText, direction = "left-center" }) => {
  return (
    <span className={`tooltip-container${direction ? ` ${direction}` : ""}`}>
      {tooltipText}
      {["bottom-center", "bottom-left", "bottom-right"].includes(direction) && (
        <span className="triangle-up"></span>
      )}
      {["left-center"].includes(direction) && (
        <span className="triangle-right"></span>
      )}
      {["right-center"].includes(direction) && (
        <span className="triangle-left"></span>
      )}
      {["top-center", "top-left", "top-right"].includes(direction) && (
        <span className="triangle-down"></span>
      )}
    </span>
  );
};
