import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnitsNoPct } from "./utils";
import { getValueStr } from "../../../lib/reducer/items/getItemData";
export const CSS_PROPERTIES_FILTER: metaIF[] = [
  {
    name: "blur",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        min: 0,
        wrapperFn: (valueObj) =>
          `blur(${valueObj.value > 0 ? valueObj.value : 0}px)`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["blur"],
      },
    ],
    default: {
      main: {
        value: 3,
      },
    },
  },
  {
    name: "brightness",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        min: 0,
        wrapperFn: (valueObj) =>
          `brightness(${valueObj.value > 0 ? valueObj.value : 0}%)`,
        help:
          "### brightness  \n" +
          "0 will make the element black (no colour), 100 is the original colour and greater than 100 is brighter than the original.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["brightness"],
      },
    ],
    default: {
      main: {
        value: 200,
      },
    },
  },

  {
    name: "contrast",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        min: 0,
        wrapperFn: (valueObj) =>
          `contrast(${valueObj.value > 0 ? valueObj.value : 0}%)`,
        help:
          "### contrast  \n" +
          "0 will make the element black, 100 is the contrast in the original and greater than 100 is more contrast than in the original.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["contrast"],
      },
    ],
    default: {
      main: {
        value: 200,
      },
    },
  },
  {
    name: "drop-shadow",
    category: ["FILTER"],
    addMultipleTimes: true,
    addToShorthand: "filter",
    shorthandSeparator: " ",
    fieldsOrder: ["hOffset", "vOffset", "blur", "color"],
    fields: [
      {
        name: "hOffset",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnitsNoPct,
      },
      {
        name: "vOffset",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnitsNoPct,
      },
      {
        name: "blur",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnitsNoPct,
      },
      {
        name: "color",
        type: EMetaFieldTypes.colourPicker,
        onOff: true,
      },
    ],
    group: [
      {
        name: "hVOffset",
        display: ["h-offset", "v-offset"],
        fields: ["hOffset", "vOffset"],
        help:
          "### drop-shadow  \n" +
          "Like `box-shadow` and `text-shadow`, `drop-shadow` has:  \n" +
          "- horizontal and vertical `offset`, means the shadow is to the right or left (positive or negativ horizontal `offset`), above or below the selected element,  \n" +
          "- a `blur` value, to make it fluffy and  \n" +
          "- a `color` value, which can be transparent when you choose in the `color select` an `alpha` value smaller than 100.  \n\n" +
          "The `drop-shadow` property `spread` does not work in all browsers, so we have not added it.  \n" +
          "The difference to `box-` and `text-shadow` is, that if you set a `drop-shadow` on an SVG, the shadow is added to the SVG path and not just to the outer box of the element.",
      },
      {
        name: "blurG",
        display: ["blur"],
        fields: ["blur"],
      },
      {
        name: "colorG",
        display: ["color"],
        fields: ["color"],
      },
    ],

    wrapperForGroup: [
      {
        name: "dropShadowGW",
        fields: ["hOffset", "vOffset", "blur", "color"],
        wrapperFn: ({ hOffset, vOffset, blur, color }) => {
          return `drop-shadow(${getValueStr({
            valObj: hOffset,
          })} ${getValueStr({
            valObj: vOffset,
          })} ${getValueStr({
            valObj: blur,
          })} ${getValueStr({
            valObj: color,
          })})`;
        },
      },
    ],

    default: {
      hOffset: { value: 2, unit: "px" },
      vOffset: { value: 2, unit: "px" },
      blur: { value: 3, unit: "px" },
      color: { value: { r: 68, g: 68, b: 68, a: 1 } },
    },
  },
  {
    name: "grayscale",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        min: 0,
        wrapperFn: (valueObj) =>
          `grayscale(${
            0 < valueObj.value && valueObj.value < 100
              ? valueObj.value
              : valueObj.value <= 0
              ? 0
              : 100
          }%)`,
        help:
          "### grayscale  \n" +
          "The image has all its colours when the grayscale is 0 and no color but black, white and grey with a value of 100.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["grayscale"],
      },
    ],
    default: {
      main: {
        value: 100,
      },
    },
  },
  {
    name: "hue-rotate",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        min: 0,
        onOff: true,
        wrapperFn: (valueObj) =>
          `hue-rotate(${
            0 < valueObj.value && valueObj.value < 360
              ? valueObj.value
              : valueObj.value <= 0
              ? 0
              : 360
          }deg)`,

        help:
          "### hue-rotate  \n" +
          "Enter a value from 0 to 360 and replace so the colours of the image with the next colour in the colour wheel. Depending on the colour to start with, the sequence of the colours (with a step with of 30) is as follows: blue - purple - red - yellow - green - turquoise - blue.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["hue-rotate"],
      },
    ],
    default: {
      main: {
        value: 0,
      },
    },
  },
  {
    name: "invert",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        min: 0,
        onOff: true,
        wrapperFn: (valueObj) =>
          `invert(${
            0 <= valueObj.value && valueObj.value <= 100
              ? valueObj.value
              : valueObj.value < 0
              ? 0
              : 100
          }%)`,

        help:
          "### invert  \n" +
          "A value of 0 means no invertion whereas all colours are inverted when the value is set to 100.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["invert"],
      },
    ],
    default: {
      main: {
        value: 100,
      },
    },
  },
  {
    name: "opacity-filter",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        min: 0,
        wrapperFn: (valueObj) =>
          `opacity(${
            0 <= valueObj.value && valueObj.value <= 100
              ? valueObj.value
              : valueObj.value < 0
              ? 0
              : 100
          }%)`,

        help:
          "### opacity  \n" +
          "A value of 100 leaves the image unaltered, whereas a higher up to 100 makes the image more and more transparent.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["opacity-filter"],
      },
    ],
    default: {
      main: {
        value: 50,
      },
    },
  },
  {
    name: "saturate",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        min: 0,
        wrapperFn: (valueObj) =>
          `saturate(${0 <= valueObj.value ? valueObj.value : 0}%)`,
        help:
          "### saturate  \n" +
          "Color saturation refers to the intensity of color in an image. A value of 0 leaves the image unsaturated, the colour is gone. Moving towards 100 gives the image its colour back. Values greater than 100 oversaturate all hues up to their pure value.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["saturate"],
      },
    ],
    default: {
      main: {
        value: 50,
      },
    },
  },
  {
    name: "sepia",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        wrapperFn: (valueObj) =>
          `sepia(${
            0 <= valueObj.value && valueObj.value <= 100
              ? valueObj.value
              : valueObj.value < 0
              ? 0
              : 100
          }%)`,
        min: 0,
        help:
          "### sepia  \n" +
          "Turns the image colours to various shapes of brown (`sepia`) and gives it an antique look. Set a value close to 0 for a little `sepia` effect and a value closer to 100 for a strong effect.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["sepia"],
      },
    ],
    default: {
      main: {
        value: 50,
      },
    },
  },

  {
    name: "url",
    category: ["FILTER"],
    addToShorthand: "filter",
    shorthandSeparator: " ",
    addMultipleTimes: true,
    fieldsOrder: ["filterId"],
    fields: [
      {
        name: "filterId",
        type: EMetaFieldTypes.assignSvgFilterId,
        onOff: true,
        wrapperFn: (valueObj) => `url(#${valueObj.value})`,
      },
    ],
    group: [
      {
        name: "filterIdG",
        fields: ["filterId"],
        display: ["filterId"],
      },
    ],
    default: {
      filterId: {
        value: "",
      },
    },
  },
];
