import { itemIF } from "../../../data/types/item";
import { getMeta, itemTypes } from "../../meta/getMetas";

export const addFieldGroup = ({
  fieldName,
  editItem,
  itemType,
}: {
  fieldName: string;
  editItem?: itemIF;
  itemType: itemTypes;
}) => {
  try {
    if (!editItem) throw new Error("No editItem");
    if (!editItem.value || !editItem.value[fieldName]) {
      throw new Error(`No value for ${editItem.name} ${fieldName}`);
    }
    const values =
      (editItem.value && editItem.value[fieldName].value
        ? editItem.value[fieldName].value
        : []) as itemIF[];

    const meta = getMeta(itemType, fieldName);
    if (
      !meta ||
      !meta.category.includes("addOrRemoveFieldGroup") ||
      !meta.default
    ) {
      return;
    }

    const newId = Math.max(...values.map((v) => v.id), 0) + 1;
    const newPos = Math.max(...values.map((v) => v.position), 0) + 1;
    const newItem = {
      id: newId,
      name: fieldName,
      position: newPos,
      value: meta.default,
    };
    const newValues = [...values, newItem];
    const newIitem = {
      ...editItem,
      value: {
        ...editItem.value,
        [fieldName]: {
          ...editItem.value[fieldName],
          value: newValues,
        },
      },
    };
    return newIitem;
  } catch (e) {
    console.error(e);
  }
};


export const removeFieldGroup = ({
  items,
  editItemId,
  fieldName,
  fieldGroupId,
}: {
  items: itemIF[];
  editItemId: number;
  fieldName: string;
  fieldGroupId: number;
}): { items: itemIF[]; editItemId: number } => {  
  const rfgEditItem = items.find((i) => i.id === editItemId);
  if (!rfgEditItem) {
    console.warn("REMOVE_FIELD_GROUP: no editItem");
    return { items, editItemId };
  }
  const rfgFieldName = fieldName;
  if (!rfgFieldName) {
    console.warn("REMOVE_FIELD_GROUP: no fieldName");
    return { items, editItemId };
  }

  const fgObj = rfgEditItem?.value?.[rfgFieldName];
  if (!fgObj || !fgObj.value) {
    console.warn("REMOVE_FIELD_GROUP: no fieldGroups");
    return { items, editItemId };
  }

  const newFgs = fgObj.value.filter((fg: any) => fg.id !== fieldGroupId);

  const newRfgEditItem = {
    ...rfgEditItem,
    value: {
      ...rfgEditItem.value,
      [rfgFieldName]: {
        ...fgObj,
        value: newFgs,
      },
    },
  };
  return {
    items: items.map((i) => {
      if (i.id === newRfgEditItem.id) {
        return newRfgEditItem;
      }
      return i;
    }),
    editItemId,
  };
};
