import { FC, useEffect, useState } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/imgs/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/imgs/arrow-right.svg";
import "./Paging.css";

type TSize = "normal" | "large";
export const Paging: FC<{ size?: TSize }> = ({ size }) => {
  return (
    <nav
      className={`flowbite-pagination-with-icons${size === "large" ? " large" : ""}`}
    >
      <ul>
        <li>
          <a href="#home">
            <span className="sr-only">Previous</span>
            <ArrowLeft />
          </a>
        </li>
        <li>
          <a href="#home" className="active">
            <span>1</span>
          </a>
        </li>
        <li>
          <a href="#home">
            <span>2</span>
          </a>
        </li>
        <li>
          <a href="#home">
            <span>3</span>
          </a>
        </li>
        <li>
          <a href="#home">
            <span className="sr-only">Next</span>
            <ArrowRight />
          </a>
        </li>
      </ul>
    </nav>
  );
};

const getStartLastEnd = ({
  currentPage,
  itemsPerPage,
  howManyItems,
}: {
  currentPage: number;
  itemsPerPage: number;
  howManyItems: number;
}) => {
  const start = (currentPage - 1) * itemsPerPage;
  const last = currentPage * itemsPerPage;
  const end = last > howManyItems ? howManyItems : last;
  return { start, end };
};

export const SimplePagination: FC<{
  snippetIds: string[];
  setDisplaySnippets: (ids: string[]) => void;
  itemsPerPage?: number;
  loading: boolean;
}> = ({ snippetIds, setDisplaySnippets, itemsPerPage = 10, loading }) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!loading) {
      setDisplaySnippets(snippetIds.slice(0, itemsPerPage));
      setCurrentPage(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const { start, end } = getStartLastEnd({
    currentPage,
    itemsPerPage,
    howManyItems: snippetIds.length,
  });

  useEffect(() => {
    setDisplaySnippets(snippetIds.slice(start, end));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const totalPages = Math.ceil(snippetIds.length / itemsPerPage);

  const previousIsDisabled = currentPage === 1;
  const nextIsDisabled = currentPage === totalPages;

  return (
    <div className="form-elements-wrapper table-data-pagination-wrapper">
      <button
        type="button"
        className={`button icon blue-outline${previousIsDisabled ? " disabled" : ""}`}
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
        }}
      >
        <span className="sr-only">Previous</span>
        <ArrowLeft />
      </button>
      <span>
        Showing <strong>{start + 1}</strong> to <strong>{end}</strong> of{" "}
        <strong>{snippetIds.length}</strong>
      </span>
      <button
        type="button"
        className={`button icon blue-outline${nextIsDisabled ? " disabled" : ""}`}
        onClick={() => {
          if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
          }
        }}
      >
        <span className="sr-only">Next</span>
        <ArrowRight />
      </button>
    </div>
  );
};
