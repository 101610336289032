import React, { FC, useRef } from "react";
import { useOutsideAlerter } from "../atoms/detectClickOutside";

export const Notification: FC<{
  msg: string;
  notificationPosition?: "right";
  setShowMsg: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ notificationPosition, setShowMsg, msg }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter({ ref: wrapperRef, setOpen: setShowMsg });

  return (
    <div
      className={`notification${msg.includes("Error") ? " warning" : ""}${notificationPosition && notificationPosition === "right" ? " right" : ""}`}
      ref={wrapperRef}
    >
      {msg}
    </div>
  );
};
